import { fork } from 'redux-saga/effects';
import token from '../login/store/sagas';
import companies from '../apps/SuperAdmin/store/sagas';
import users from '../apps/Admin/store/sagas';
import dokstor from '../apps/dokstor/store/sagas/rootSaga';
import data from '../apps/referenceData/store/sagas';
import cases from '../apps/caseManagement/store/sagas';
import common from '../apps/common/store/sagas';

export default function* rootSaga() {
    yield fork(token);
    yield fork(users);
    yield fork(companies);
    yield fork(dokstor);
    yield fork(data);
    yield fork(cases);
    yield fork(common);
}
