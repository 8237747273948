import isArray  from 'lodash/isArray'
import isObject from 'lodash/isObject'

export const getQueryParamFromListConfig = (listConfig, currentFilter, pagination = false) => {
    let first = 0;
    let max = 10;
    let sortingQueryParam = '';
    let filtersQueryParam = '';
    if (listConfig) {
        const {pagination, sorting, filters} = listConfig;
        first = pagination.currentPage * pagination.rowsPerPage;
        max = pagination.rowsPerPage;
        if (sorting) {
            sortingQueryParam = `&sortingColumn=${sorting.sortingColumn.toUpperCase()}&sortingDirection=${sorting.sortingDirection}`;
        }
        if (filters) {
            filters.currentFilters.map(filter => {
                filtersQueryParam += processFilter(filter)
                return filter;
            })
        }
        if (currentFilter) {
            filtersQueryParam += processFilter(currentFilter);
        }
    } else {
        //console.warn('no config');
    }
    return listConfig || pagination ? `first=${first}&max=${max}${sortingQueryParam}${filtersQueryParam}` : '';
}

export const getQueryParamFromListConfig_LEGACY = (listConfig, currentFilter) => {
    let first = 0;
    let max = 10;
    let sortingQueryParam = '';
    let filtersQueryParam = '';
    if (listConfig) {
        const {pagination, sorting, filters} = listConfig;
        first = pagination.currentPage * pagination.rowsPerPage;
        max = pagination.rowsPerPage;
        if (sorting) {
            sortingQueryParam = `&orderColumn=${sorting.sortingColumn.toUpperCase()}&orderType=${sorting.sortingDirection}`;
        }
        if (filters) {
            filters.currentFilters.map(filter => {
                filtersQueryParam += processFilter(filter)
                return filter
            })
        }
        if (currentFilter) {
            filtersQueryParam += processFilter(currentFilter);
        }
    } else {
        //console.warn('no config');
    }
    return `first=${first}&max=${max}${sortingQueryParam}${filtersQueryParam}`;
}

const processFilter = (filter) => {
    if (isArray(filter.value)) {
        let result = ''
        filter.value.map(value => {
            if (isArray(value)) {
                value.map(v => {
                    if (v.includes(',')) {
                        v.split(',').map(splitted => {
                            result += filter.dynamicKey
                                ? `&filterKey=${filter.titleLabel}&filterValue=${splitted.trim() }`
                                : `&${filter.titleLabel}=${splitted.trim()}`
                            return splitted
                        })
                    }
                    else {
                        result += filter.dynamicKey
                            ? `&filterKey=${filter.titleLabel}&filterValue=${v}`
                            : `&${filter.titleLabel}=${v}`
                    }
                    return v;
                })
            } else {
                result += filter.dynamicKey
                    ? `&filterKey=${filter.titleLabel}&filterValue=${value}`
                    : `&${filter.titleLabel}=${value}`
            }
            return value;
        })
        return result;
    } else if (isObject(filter.value)) {
        let result = '';
        Object.keys(filter.value).map((key, i) => {
            result += filter.dynamicKey
                ? `&filterKey${i === 0 ? 'From' : 'To'}=${filter.titleLabel}&filterValue${i === 0 ? 'From' : 'To'}=${filter.value[key]}`
                : `&${key}=${filter.value[key]}`
            return key;
        })
        return result;
    } else {
        let result = ''
        if (filter.value !== null && filter.value !== undefined && filter.value !== '') {
            result = filter.dynamicKey
                ? `&filterKey=${filter.titleLabel}&filterValue=${filter.value}`
                : `&${filter.titleLabel}=${filter.value}`
        } 
            
        
        return result
    }
}