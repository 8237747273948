import React from 'react'
import { useTranslation } from 'react-i18next';

import HomeLayout from './HomeLayout'
import usePageTitle from '../../hooks/usePageTitle'
import withNavigation from '../../apps/common/navigation/hoc/withNavigation'

const HomeContainer = ({ name }) => {

    const { t } = useTranslation()
    usePageTitle(`${t('home')} - Heliocor Platform`)

    return <HomeLayout name={name} t={t}/>
}

export default withNavigation(HomeContainer) 