import React, { Component } from 'react';

class E404Layout extends Component{

    render() {
        return (
            <div>
                <div>Page not found</div>
            </div>
        )
    }
}
export default E404Layout