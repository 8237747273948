import React from 'react'

import settingsActive from '../../../../assets/icons/admin-active.png'
import settings from '../../../../assets/icons/admin.png'
import dokstorActive from '../../../../assets/icons/dokstor-active.png'
import dokstor from '../../../../assets/icons/dokstor.png'
import referenceDataActive from '../../../../assets/icons/referencedata-active.png'
import referenceData from '../../../../assets/icons/referencedata.png'
import caseMagnamentActive from '../../../../assets/icons/robo-active.png'
import caseMagnament from '../../../../assets/icons/robo.png'
import { PERMISSIONS } from '../../../../login/consts/permissions.enum'
import { routesPermissions } from '../../../../router/routesPermissions'
import { Translate } from '../../Translate'
import { snakeToKebabCase } from '../../../../config/helpers'
import { isEmpty } from 'lodash'

const shouldDisableMenuItem = (permissions, url) => {
    const currentPermissions = routesPermissions(url)
    return !permissions[currentPermissions.app] || !permissions[currentPermissions.app].some(permission => currentPermissions.permissions?.includes(permission)) || !currentPermissions
}

export const menuItems = (permissions, datasources, dashboards, hideWachtlist) => [
    {
        title: <Translate id='dokstor' />,
        disabledFn: () => !permissions.DOKSTOR,
        icon: dokstor,
        iconActive: dokstorActive,
        children: [
            {
                title: <Translate id='dashboards' />,
                link: `dokstor/dashboards/${snakeToKebabCase(dashboards[0] || '')}`,
                disabledFn: () => isEmpty(dashboards) || shouldDisableMenuItem(permissions, '/dokstor/dashboards'),
                subMenu: true,
                children: dashboards.map(key => ({
                    title: <Translate id={key} />,
                    link: `dokstor/dashboards/${snakeToKebabCase(key)}`,
                    disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/dashboards')
                }))
            },
            {
                title: <Translate id='workspace' />,
                link: 'dokstor/workspace',
                disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/workspace')
            },
            {
                title: <Translate id='Persons' />,
                link: 'dokstor/person',
                disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/person')
            },
            {
                title: <Translate id='companies' />,
                link: 'dokstor/company',
                disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/company')
            },
            {
                title: <Translate id='settings' />,
                link: 'dokstor/settings/CAFe',
                disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings'),
                subMenu: true,
                children: [
                    {
                        title: <Translate id='CAFe' />,
                        link: 'dokstor/settings/CAFe',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/CAFe')
                    },
                    {
                        title: <Translate id='kycTypes' />,
                        link: 'dokstor/settings/kyc-types',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/kyc-types')
                    },
                    {
                        title: <Translate id='fields' />,
                        link: 'dokstor/settings/fields',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/fields')
                    },
                    {
                        title: <Translate id='docs' />,
                        link: 'dokstor/settings/documents',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/documents')
                    },
                    {
                        title: <Translate id='signatureDocs' />,
                        link: 'dokstor/settings/signature-documents',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/signature-documents') || datasources.documentSignature === 'DISABLED'
                    },
                    {
                        title: <Translate id='validations' />,
                        link: 'dokstor/settings/validations',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/validations')
                    },
                    {
                        title: <Translate id='riskAssessment' />,
                        link: 'dokstor/settings/risk-assessment',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/risk-assessment')
                    },
                    {
                        title: <Translate id='watchlist' />,
                        link: 'dokstor/settings/watchlist',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/watchlist') || hideWachtlist
                    },
                    {
                        title: <Translate id='approval' />,
                        link: 'dokstor/settings/approval',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/approval')
                    },
                    {
                        title: <Translate id='dashboards' />,
                        link: 'dokstor/settings/dashboards',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/dokstor/settings/dashboards')
                    }
                ]
            }
        ]
    },
    {
        title: <Translate id='robolitics' />,
        disabledFn: () => !permissions.ROBOLITICS,
        icon: caseMagnament,
        iconActive: caseMagnamentActive,
        children: [
            {
                title: <Translate id='dashboards' />,
                link: 'case-management/dashboards/home',
                disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/dashboards/home'),
                subMenu: true,
                // children: ["HOME", "CUSTOMER_ACTIVITY", "INTERNATIONAL_TRANSACTIONS", "ALGOS_ROBOTS"].map(key => ({
                children: ["HOME", "CUSTOMER_ACTIVITY"].map(key => ({
                    title: <Translate id={key} />,
                    link: `case-management/dashboards/${snakeToKebabCase(key)}`,
                    disabledFn: () => shouldDisableMenuItem(permissions, `/case-management/dashboards/${snakeToKebabCase(key)}`)
                }))
            },
            // {
            //     title: <Translate id='reporting' />,
            //     link: 'case-management/reports',
            //     disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/reports')
            // },
            {
                title: <Translate id='cases' />,
                link: 'case-management/robolitics',
                disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/robolitics')
            },
            {
                title: <Translate id='alerts' />,
                link: 'case-management/alerts',
                disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/alerts')
            },
            {
                title: <Translate id='scenarios' />,
                link: 'case-management/algos',
                disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/algos')
            },
            {
                title: <Translate id='settings' />,
                link: 'case-management/settings/watch-lists',
                disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/settings'),
                subMenu: true,
                children: [
                    {
                        title: <Translate id='watchLists' />,
                        link: 'case-management/settings/watch-lists',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/settings/watch-lists')
                    },
                    {
                        title: <Translate id='countriesRisks' />,
                        link: 'case-management/settings/countries-risks',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/case-management/settings/countries-risks')
                    }
                ]
            }
        ]
    },
    {
        title: <Translate id='referenceData' />,
        disabledFn: () => !permissions.ROBOLITICS?.find(p => p === PERMISSIONS.ROBOLITICS.ROBOLITICS_REF_DATA || p === PERMISSIONS.ROBOLITICS.FULL),
        icon: referenceData,
        iconActive: referenceDataActive,
        children: [
            {
                title: <Translate id='dataGenerator' />,
                link: 'reference-data/data-generator',
                disabledFn: () => shouldDisableMenuItem(permissions, '/reference-data/data-generator') || datasources.DATA_GENERATOR === 'DISABLED'
            },
            {
                title: <Translate id='coreData' />,
                link: 'reference-data/customers',
                disabledFn: () => shouldDisableMenuItem(permissions, '/reference-data/core-data'),
                subMenu: true,
                children: [
                    {
                        title: <Translate id='customers' />,
                        link: 'reference-data/customers',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/reference-data/customers')
                    },
                    {
                        title: <Translate id='accounts' />,
                        link: 'reference-data/accounts',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/reference-data/accounts')
                    },
                    {
                        title: <Translate id='cards' />,
                        link: 'reference-data/cards',
                        disabledFn: () => shouldDisableMenuItem(permissions, '/reference-data/cards')
                    }
                ]
            },
            {
                title: <Translate id='transactions' />,
                link: 'reference-data/payments',
                disabledFn: () => shouldDisableMenuItem(permissions, '/reference-data/payments')
            }
        ]
    },
    {
        title: <Translate id='admin' />,
        disabledFn: () => shouldDisableMenuItem(permissions, '/admin'),
        icon: settings,
        iconActive: settingsActive,
        children: [
            {
                title: <Translate id='users' />,
                link: 'admin/users',
                disabledFn: () => shouldDisableMenuItem(permissions, '/admin/users')
            },
            {
                title: <Translate id='teams' />,
                link: 'admin/teams',
                disabledFn: () => shouldDisableMenuItem(permissions, '/admin/teams')
            },
            {
                title: <Translate id='roles' />,
                link: 'admin/roles',
                disabledFn: () => shouldDisableMenuItem(permissions, '/admin/roles')
            },
            {
                title: <Translate id='apiKeys' />,
                link: 'admin/api-keys',
                disabledFn: () => shouldDisableMenuItem(permissions, '/admin/api-keys')
            },
            {
                title: <Translate id='settings' />,
                link: 'admin/settings',
                disabledFn: () => shouldDisableMenuItem(permissions, '/admin/settings')
            }
        ]
    }
]