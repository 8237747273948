import {applyMiddleware, compose, createStore} from 'redux'
import createSagaMiddleware                    from 'redux-saga'
import reducer                                 from './reducers'
import rootSaga                                from './sagas'

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        // &&  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
        || compose

    const store = createStore(
        reducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware
            )
        )
    )

    sagaMiddleware.run(rootSaga)
    return store
}