/* LIST ACTIONS */

export const UPDATE_LIST_SORTING = 'UPDATE_LIST_SORTING'

export const UPDATE_LIST_FILTERS = 'UPDATE_LIST_FILTERS'

export const UPDATE_LIST_PAGINATION = 'UPDATE_LIST_PAGINATION'

export const RESET_CURRENT_PAGE = 'RESET_CURRENT_PAGE'

/* DOCUMENT ACTIONS */

export const SAVE_VIEWER_CONFIG = 'SAVE_VIEWER_CONFIG'
export const CLEAR_VIEWER_CONFIG = 'CLEAR_VIEWER_CONFIG'

/* DRAWER ACTIONS */

export const REMOVE_LAST_DRAWER = 'REMOVE_LAST_DRAWER'
export const ADD_DRAWER_ELEMENT = 'ADD_DRAWER_ELEMENT'
export const REMOVE_ALL_DRAWERS = 'REMOVE_ALL_DRAWERS'

export const MODIFY_DRAWER_ELEMENT = 'MODIFY_DRAWER_ELEMENT'

export const MODIFY_DRAWER_PROPS = 'MODIFY_DRAWER_PROPS'

/* FORMATTERS */

export const GET_COMMON_SETTINGS = 'GET_COMMON_SETTINGS'
export const SET_COMMON_SETTINGS = 'SET_COMMON_SETTINGS'

/* API STATUS */

export const SET_API_STATUS = 'SET_API_STATUS'
