import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import {
    CLEAR_DOKSTOR_STATUS,
    DELETE_COMPANY,
    DELETE_OWNERSHIP,
    DELETE_PERSON,
    DOC_STATUS_UPDATE,
    GET_COMPANY_SUGGESTED_STRUCTURE,
    GET_CURRENT_ENTITY_HITS,
    SET_CURRENT_CP_HITS,
    GET_ENTITY_RISKS, REQUEST_ADD_COMPANY,
    REQUEST_ADD_DOC,
    REQUEST_ADD_PERSON,
    REQUEST_ADD_REQUEST,
    REQUEST_BLACKLIST_COMPANY,
    REQUEST_BLACKLIST_PERSON, REQUEST_CASES_STATISTICS,
    REQUEST_COMPANIES,
    REQUEST_COMPANY,
    REQUEST_COMPANY_RISKRATING_CHANGE,
    REQUEST_CONNECTED_PARTY,
    REQUEST_DELETE_COMPANY,
    REQUEST_DELETE_COMPANY_DOCS,
    REQUEST_DELETE_DOCS,
    REQUEST_DELETE_ENTITY,
    REQUEST_DELETE_FILE,
    REQUEST_DELETE_PERSON,
    REQUEST_DELETE_REQUEST,
    REQUEST_DOC_DATA,
    REQUEST_DOC_STATUS_UPDATE,
    REQUEST_FINISH_CASE,
    REQUEST_FORCE_RECHECK,
    REQUEST_GET_CURRENT_HIT,
    REQUEST_GET_PHASES,
    REQUEST_PERSON,
    REQUEST_PERSONS,
    REQUEST_PHASE_CHANGE,
    REQUEST_RECHECK_AML,
    REQUEST_RISK_APPROVAL,
    REQUEST_RISKRATING_CHANGE,
    REQUEST_SET_PENDING_APPROVAL,
    REQUEST_UPDATE_COMPANY,
    REQUEST_UPDATE_HITS_STATUS,
    REQUEST_UPDATE_ENTITY_OWNER,
    REQUEST_UPDATE_ENTITY_STATUS,
    REQUEST_UPDATE_KYCTYPE,
    REQUEST_UPDATE_PERSON,
    REQUEST_UPLOAD_DOC,
    SAVE_ENTITY_DOC_COMMENT,
    SET_COMPANY_SUGGESTED_STRUCTURE,
    SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS,
    SET_CURRENT_CONNECTED_PARTY,
    SET_CURRENT_DOC,
    SET_CURRENT_DOC_COMMENT,
    SET_CURRENT_ENTITY,
    SET_CURRENT_ENTITY_DOC_DATA,
    SET_CURRENT_ENTITY_HITS,
    SET_CURRENT_ENTITY_STRUCTURE,
    SET_CURRENT_ENTITY_TREE_STRUCTURE,
    SET_CURRENT_HIT_DETAILS,
    SET_DOKSTOR_STATUS,
    SET_ENTITY_RISKS,
    SET_PHASES,
    SUCCEED_REQUEST_COMPANIES,
    SUCCEED_REQUEST_PERSONS,
    UPDATE_DOC,
    UPSERT_COMPANY_STRUCTURE,
    SET_HITS_FROM_AN_ENTITY,
    REQUEST_HITS_FROM_AN_ENTITY,
    REQUEST_UPDATE_ADDITIONAL_RISKS,
    REQUEST_DELETE_FILES_FROM_DOC,
    REQUEST_UPDATE_ENTITY,
    UPDATE_CP_HITS,
    SET_POST_ERRORS,
    REQUEST_RECHECK_RISK,
    REQUEST_WEB_SEARCH_HITS,
    GET_CURRENT_WEB_SEARCH_HITS,
    SET_CURRENT_WEB_SEARCH_HITS,
    REQUEST_UPDATE_WEB_SEARCH_HITS,
    REQUEST_RECHECK_WEB_SEARCH_HITS
} from "../actionTypes"
import React from "react"

import contextTypes from '../contextTypes.enum'
import { getQueryParamFromListConfig } from "../../../common/MainTable/helpers/tableHelpers"
import { db, db_thunk } from "../../../../api/db"
import { takeLatestSafe } from "../../../../config/helpers/sagasHelper"
import { arrayOfStringsToUpperCase } from "../../../../config/helpers"
import { b64ToBlob } from "heliocor-ui"
import { getDocDatas } from "../../api/person"
import { OPEN_NOTIFICATION } from "../../../../notifications/actionTypes"
import {
    listConfigs,
    getTranslationFn,
    getEntityData,
    getCurrentEntityId
} from './storeGetters'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { SET_API_STATUS } from "../../../common/store/actionTypes"
import { getEntityPath, getDocumentsPath } from "../../api/pathGetters"
import { Translate } from "../../../common/Translate"

const defPagination = {
    pagination: {
        currentPage: 0,
        rowsPerPage: 10
    }
}

export default function* entitySagas() {
    yield all([fork(watcher())])
}

const watcher = () =>
    function* watch() {
        yield takeLatest(REQUEST_PERSONS, runRequestEntities, { isCompany: false })
        yield takeLatest(REQUEST_COMPANIES, runRequestEntities, { isCompany: true })
        yield takeLatestSafe(REQUEST_PERSON, runRequestEntity, { isCompany: false })
        yield takeLatestSafe(REQUEST_COMPANY, runRequestEntity, { isCompany: true })
        yield takeLatestSafe(REQUEST_CONNECTED_PARTY, runRequestCP)
        yield takeLatestSafe(REQUEST_ADD_PERSON, runRequestAddEntity, { isCompany: false })
        yield takeLatestSafe(REQUEST_ADD_COMPANY, runRequestAddEntity, { isCompany: true })
        yield takeLatestSafe(REQUEST_ADD_REQUEST, runRequestAddRequest)
        yield takeLatestSafe(REQUEST_DELETE_REQUEST, runRequestDeleteRequest)
        yield takeLatestSafe(REQUEST_UPDATE_KYCTYPE, runRequestUpdateKYCType);
        yield takeLatestSafe(REQUEST_UPDATE_ENTITY, runRequestUpdateAnyEntity)
        yield takeLatestSafe(REQUEST_UPDATE_PERSON, runRequestUpdateEntity, { isCompany: false })
        yield takeLatestSafe(REQUEST_UPDATE_COMPANY, runRequestUpdateEntity, { isCompany: true })
        yield takeLatestSafe(REQUEST_RISKRATING_CHANGE, runRequestChangeRiskRating, { isCompany: false })
        yield takeLatestSafe(REQUEST_COMPANY_RISKRATING_CHANGE, runRequestChangeRiskRating, { isCompany: true })
        yield takeLatestSafe(REQUEST_DELETE_ENTITY, runRequestDeleteAnyEntity)
        yield takeLatestSafe(REQUEST_DELETE_PERSON, runRequestDeleteEntity, { isCompany: false })
        yield takeLatestSafe(REQUEST_DELETE_COMPANY, runRequestDeleteEntity, { isCompany: true })
        yield takeEvery(REQUEST_DOC_STATUS_UPDATE, runRequestUpdateDocStatus)
        yield takeLatestSafe(REQUEST_UPDATE_HITS_STATUS, runRequestUpdateHitStatus)
        yield takeLatestSafe(REQUEST_UPLOAD_DOC, runRequestUploadDoc)
        yield takeLatestSafe(REQUEST_DELETE_DOCS, runRequestDeleteDocs)
        yield takeLatestSafe(REQUEST_DELETE_COMPANY_DOCS, runRequestDeleteDocs, { isCompany: true })
        yield takeLatestSafe(REQUEST_DELETE_FILE, runRequestDeleteFile)
        yield takeLatestSafe(GET_ENTITY_RISKS, runGetEntityRisks)
        yield takeLatestSafe(UPSERT_COMPANY_STRUCTURE, runRequestUpsertStructureEntity)
        yield takeLatestSafe(DELETE_OWNERSHIP, runDeleteOwnershipRelation)
        yield takeEvery(REQUEST_DOC_DATA, runRequestAddressDocData)
        yield takeLatestSafe(GET_CURRENT_ENTITY_HITS, runRequestGetCurrentEntityHits)
        yield takeLatestSafe(REQUEST_UPDATE_ENTITY_STATUS, runRequestUpdateEntityStatus)
        yield takeLatestSafe(REQUEST_FINISH_CASE, runRequestFinishCase)
        yield takeLatestSafe(REQUEST_SET_PENDING_APPROVAL, runRequestSetPendingApproval)
        yield takeLatestSafe(REQUEST_FORCE_RECHECK, runRequestRecheckEntity)
        yield takeLatestSafe(REQUEST_RECHECK_AML, runRequestRecheckAML)
        yield takeLatestSafe(REQUEST_RECHECK_RISK, runRequestRecheckRisk)
        yield takeLatestSafe(REQUEST_GET_PHASES, runRequestGetPhases)
        yield takeLatestSafe(REQUEST_PHASE_CHANGE, runRequestPhaseChange)
        yield takeLatestSafe(REQUEST_GET_CURRENT_HIT, runRequestGetHitDetails)
        yield takeLatestSafe(REQUEST_RISK_APPROVAL, runRequestRiskApproval)
        yield takeLatestSafe(SAVE_ENTITY_DOC_COMMENT, runRequestSaveEntityDocComment)
        yield takeLatestSafe(REQUEST_UPDATE_ENTITY_OWNER, runRequestUpdateEntityOwner)
        yield takeLatestSafe(REQUEST_ADD_DOC, runRequestAddDocument)
        yield takeLatestSafe(REQUEST_BLACKLIST_PERSON, runRequestBlacklistEntity, { isCompany: false })
        yield takeLatestSafe(REQUEST_BLACKLIST_COMPANY, runRequestBlacklistEntity, { isCompany: true })
        yield takeLatestSafe(GET_COMPANY_SUGGESTED_STRUCTURE, runRequestCompanySuggestedStructure)
        yield takeLatestSafe(REQUEST_HITS_FROM_AN_ENTITY, runRequestGetHitsFromAnEntity)
        yield takeLatestSafe(REQUEST_UPDATE_ADDITIONAL_RISKS, runRequestUpdateAdditionalRisks)
        yield takeLatestSafe(REQUEST_DELETE_FILES_FROM_DOC, runRequestDeleteFilesFromDoc)
        yield takeLatestSafe(REQUEST_WEB_SEARCH_HITS, runRequestGetWebSearchHits)
        yield takeLatestSafe(GET_CURRENT_WEB_SEARCH_HITS, runRequestGetWebSearchHits)
        yield takeLatestSafe(REQUEST_UPDATE_WEB_SEARCH_HITS, runRequestUpdateWebSearchHits)
        yield takeLatestSafe(REQUEST_RECHECK_WEB_SEARCH_HITS, runRequestRecheckWebSearchHits)
    }

function* throwErrorMessage(error) {
    const translationFn = yield select(getTranslationFn)
    yield put({
        type: OPEN_NOTIFICATION, data:
            { type: 'error', message: error.code ? translationFn('notification.' + error.code) : translationFn('genericErrorMessage') }
    })
}

function* handlerError(errors) {
    yield put({ type: SET_DOKSTOR_STATUS, dokstorError: true })
    yield all(errors.map(error => throwErrorMessage(error)))
}

function* runRequestAnyEntity(entityid, cpid, isCompany) {
    if (cpid) {
        yield call(runRequestCP, { isCompany, entityid, cpid })
    } else {
        yield call(runRequestEntity, { isCompany }, { entityid })
    }
}

function* runRequestEntities(args, { listId, currentFilter }) {
    const isCompany = args.isCompany
    const entityType = isCompany ? 'company' : 'person'
    const context = isCompany ? contextTypes.LOADING_COMPANIES : contextTypes.LOADING_PERSONS

    yield put({
        type: SET_API_STATUS,
        action: 'UPDATE',
        context,
        value: true
    })
    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig || defPagination, currentFilter);

    const entities = yield db('get', `dokstor-bo-api/api/${entityType}?${queryParam}`)

    entities.errors
        ? yield handlerError(entities.errors)
        : yield put({ type: isCompany ? SUCCEED_REQUEST_COMPANIES : SUCCEED_REQUEST_PERSONS, entities })

    yield put({
        type: SET_API_STATUS,
        action: 'CLEAR',
        context
    })
}

function* runRequestEntity(args, payload = {}) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })

    const isCompany = args.isCompany
    const entityType = isCompany ? 'company/' : 'person/'
    let { entityid, hits = [], suggestedStructure } = payload
    let errors = [], response;

    if (entityid) {
        response = yield db('get', 'dokstor-bo-api/api/' + entityType + entityid)

        if (response.errors) {
            yield handlerError(response.errors)
            errors = errors.concat(response.errors)
        }
        const data = isCompany ? response.company : response.person
        const { custom = {}, ...entityData } = data

        const customFields = Object
            .keys((custom || {}))
            .reduce((acc, key) => {
                let value
                // We try to parse the value as JSON if the value is an object
                // (Object, Array, etc.) or boolean, we assign it to the value variable
                try {
                    const _value = JSON.parse(custom[key])
                    const shouldParse = typeof _value === 'object' || typeof _value === 'boolean'
                    value = shouldParse ? _value : custom[key]
                } catch (_) {
                    value = custom[key]
                }
                acc[key] = value
                return acc
            }, {})

        const entity = { ...entityData, ...customFields }

        let res = yield db('get', 'dokstor-bo-api/api/' + entityType + entityid + '/request')
        const request = res?.request
        request?.types && (request.types = arrayOfStringsToUpperCase(request.types))

        res = yield db('get', 'dokstor-bo-api/api/' + entityType + entityid + '/document')
        const documents = (res?.documents) || []

        const signatureDocsResponse = yield db('get', `dokstor-bo-api/api/${entityType}${entityid}/signature-documents`)
        const signatureDocuments = signatureDocsResponse || {};
        
        if (isEmpty(errors)) {
            if (isCompany) {                
                yield call(runRequestGetCompanyOwnership, entityid)
                yield put({ type: GET_ENTITY_RISKS, entityId: entityid, isCompany: true })

            } else {
                const selfieDoc = documents.find(doc => doc.type === 'SELFIE')
                if (selfieDoc) {
                    const selfieDatas = yield getDocDatas(entity.identifier, selfieDoc.identifier)
                    if (selfieDatas[0]) {
                        const blob = b64ToBlob(selfieDatas[0].content)
                        entity.selfie = window.URL.createObjectURL(blob);
                    }
                }
                yield put({ type: GET_ENTITY_RISKS, entityId: entityid, isCompany: false })
            }

            yield put({
                type: SET_CURRENT_ENTITY,
                entity: { ...entity, documents, signatureDocuments, request, hits, isCompany }
            })

            if (suggestedStructure) yield put({ type: GET_COMPANY_SUGGESTED_STRUCTURE, entityid })

            yield call(runRequestGetPhases, entityid)
            yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })

        } else {
            yield put({ type: SET_CURRENT_ENTITY, entity: {} })
        }

    } else {
        yield put({ type: SET_CURRENT_ENTITY, entity: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })
    }
}

function* runRequestCP({ entityid, cpid, isCompany }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })

    const cpType = isCompany ? 'connected-companies' : 'connected-persons'
    let errors = [], res;

    if (cpid) {
        res = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${cpType}/${cpid}`)

        if (res.errors) {
            yield handlerError(res.errors)
            errors = errors.concat(res.errors)
        }
        let entity = isCompany ? res.connectedCompany : res.connectedPerson

        entity = { ...entity, ...entity?.custom }
        delete entity.custom

        res = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${isCompany ? 'connected-companies' : 'connected-persons'}/${cpid}/request`)
        const request = res?.request
        request?.types && (request.types = arrayOfStringsToUpperCase(request.types))

        res = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${cpType}/${cpid}/documents`)
        const documents = (res?.documents) || []

        if (isEmpty(errors)) {

            yield put({
                type: SET_CURRENT_CONNECTED_PARTY,
                entity: {
                    ...entity, documents, hits: [], isCompany, request
                }
            })

            yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })

        } else {
            yield put({ type: SET_CURRENT_CONNECTED_PARTY, entity: {} })
        }

    } else {
        yield put({ type: SET_CURRENT_CONNECTED_PARTY, entity: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })
    }
}

function* runRequestAddEntity(args, payload = {}) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    let errors = [], response
    const isCompany = args.isCompany
    const entityType = isCompany ? 'company' : 'person'
    const { entity, listId } = payload
    const translationFn = yield select(getTranslationFn)

    try {
        response = yield db('post', 'dokstor-bo-api/api/' + entityType, entity)
    } catch (e) {
        yield handlerError([e])
        errors = errors.concat(e)
    }

    const hasPostErrors = response?.errors?.some(e => e.field)

    if (hasPostErrors) {
        yield put({ type: SET_POST_ERRORS, errors: response.errors })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorError: 'postErrors' })
        errors = response.errors
    } else if (response.errors) {
        yield handlerError(response.errors)
        errors = errors.concat(response.errors)
    } else {
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: listId })
    }

    if (isEmpty(errors)) {
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing' })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn(`${entityType}SuccesSaved`), type: 'success' } })
        yield put({ type: REQUEST_CASES_STATISTICS })
    }
}

function* runRequestAddRequest({ isCompany, entityid, cpid, docids, request, documents, reRequest, remind, message, profile, structure }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    let errors = [], res

    const companyStructure = isCompany ? { structure } : {}

    if (remind && request && request?.status !== 'COMPLETED') {
        request.documentTypes?.forEach(cat => {
            cat.documents && cat.documents.forEach(doc => {
                let reqDoc = documents.find(d => d.type === doc.type)
                reqDoc && docids.push(reqDoc.identifier)
            })
        })
    }
    
    if (cpid) {
        res = yield db('put', `dokstor-bo-api/api/companies/${entityid}/${isCompany ? 'connected-companies' : 'connected-persons'}/${cpid}/request`,
            { documents: docids, reRequest: !!reRequest, message, profile })
        yield put({ type: REQUEST_CONNECTED_PARTY, entityid, cpid, isCompany })
        yield put({ type: REQUEST_COMPANY, entityid })

    } else {
        res = yield db('put', `dokstor-bo-api/api/${isCompany ? 'company' : 'person'}/${entityid}/request`,
            { documents: docids, reRequest: !!reRequest, message, profile, ...companyStructure })
        yield put({ type: isCompany ? REQUEST_COMPANY : REQUEST_PERSON, entityid })
    }
    
        
    if (res.errors) {
        errors = errors.concat(res.errors)
        yield handlerError(res.errors)
    }

    if (isEmpty(errors)) {
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing' })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success', dokstorSuccess: 'Request successfully added' })
    }
}

function* runRequestDeleteRequest({ isCompany, entityid, cpid }) {

    const entityPath = getEntityPath(isCompany, entityid, cpid)

    const res = yield db('delete', entityPath + '/request')
    if (res.errors) {
        yield handlerError(res.errors)
    } else if (cpid) {
        yield put({ type: REQUEST_CONNECTED_PARTY, entityid, cpid, isCompany })
        yield call(runRequestEntity, { isCompany: true }, { entityid })
    } else {
        yield call(runRequestEntity, { isCompany }, { entityid })
    }
}

function* runRequestUpdateKYCType({ isCompany, entityId, kycType }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS });

    const entityType = isCompany ? 'company' : 'person';
    yield db(
        'put',
        `dokstor-bo-api/api/${entityType}/${entityId}/kyc-type`, { kycType });

    yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} });
    yield put({ type: isCompany ? REQUEST_COMPANY : REQUEST_PERSON, entityid: entityId });
}

function* runRequestUpdateAnyEntity({ payload: { entity, isCompany, entityid } }) {
    if (entityid) {
        yield call(runRequestUpdateCP, { isCompany, entity, entityid })
    } else {
        yield call(runRequestUpdateEntity, { isCompany }, { entity })
    }
}

function* runRequestUpdateEntity(args, payload = {}) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_ENTITY, value: true })

    const isCompany = args.isCompany
    const entityType = isCompany ? 'company/' : 'person/'
    const { entity, statusToUpdate } = payload
    let entityBody, status, entityid, response

    const translationFn = yield select(getTranslationFn)

    const { identifier, setStatus, ...other } = entity
    status = setStatus
    entityid = identifier
    entityBody = { ...other }

    if (statusToUpdate === 'Identity') yield call(runUpdateEntityStatus, { entity, isCompany, type: 'IDENTITY' })
    if (statusToUpdate === 'Address') yield call(runUpdateEntityStatus, { entity, isCompany, type: 'ADDRESS' })

    let errors = []
    if (status) {
        yield db('put', 'dokstor-bo-api/api/' + entityType + entityid + '/status', { status: status })
        entity.status = status
    }
    response = yield db('put', 'dokstor-bo-api/api/' + entityType + entityid, entityBody)

    const hasPostErrors = response?.errors?.some(e => e.field)

    if (hasPostErrors) {
        yield put({ type: SET_POST_ERRORS, errors: response.errors })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
        errors = response.errors
    } else if (response.errors) {
        yield handlerError(response.errors)
        errors = errors.concat(response.errors)
    } else {
        yield call(runRequestEntity, { isCompany }, { entityid })
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
    }

    if (isEmpty(errors)) yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing', dokstorSuccess: 'Entity successfully modified' })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_ENTITY })
}

function* runRequestUpdateCP({ entity, isCompany, entityid }) {

    const entityType = isCompany ? 'connected-companies' : 'connected-persons'
    let errors = [], entityBody, cpid, response;

    const translationFn = yield select(getTranslationFn)

    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_ENTITY, value: true })

    const { identifier, ...other } = entity
    cpid = identifier
    entityBody = { ...other }

    response = yield db('put', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}`, entityBody)

    const hasPostErrors = response?.errors?.some(e => e.field)

    if (hasPostErrors) {
        yield put({ type: SET_POST_ERRORS, errors: response.errors })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorError: 'postErrors', dokstorStatus: null })
        errors = response.errors
    } else if (response.errors) {
        yield call(handlerError, response.errors)
        errors = errors.concat(response.errors)
    }

    if (isEmpty(errors)) {
        yield call(runRequestCP, { entityid, cpid, isCompany })
        yield call(runRequestGetCompanyOwnership, entityid)
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing', dokstorSuccess: 'Entity successfully modified' })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_ENTITY })
}

function* runRequestChangeRiskRating(args, payload = {}) {
    const { identifier, riskScore } = payload
    const isCompany = args.isCompany
    const entityType = isCompany ? 'company/' : 'person/'
    let response, errors = []

    response = yield db('put', `dokstor-bo-api/api/${entityType + identifier}/riskrating`, { riskScore, riskScoreReason: payload.reasonText })

    if (response.errors) {
        errors = errors.concat(response.errors)
        yield call(handlerError, errors)
    }
    else {
        yield call(runRequestEntity, { isCompany }, { entityid: identifier })
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
    }
}

function* runRequestDeleteAnyEntity({ payload: { entityid, isCompany, listId, cpid } }) {
    if (cpid) {
        yield call(runRequestDeleteCP, { isCompany, entityid, cpid })
    } else {
        yield call(runRequestDeleteEntity, { isCompany }, { entityid, listId })
    }
}

function* runRequestDeleteEntity(args, payload = {}) {

    const { listId, identifier } = payload
    const isCompany = args.isCompany
    const currentId = yield select(getCurrentEntityId)
    const entityid = identifier || currentId
    const entityType = isCompany ? 'company/' : 'person/'
    let _listId = listId || (isCompany ? 'companyList' : 'personList')
    let errors = [], response

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DELETING_ENTITY, value: true })
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    response = yield db('delete', 'dokstor-bo-api/api/' + entityType + entityid)

    if (response.errors) {
        yield handlerError(response.errors)
        errors = errors.concat(response.errors)
    }

    if (isEmpty(errors)) {
        if (isCompany) yield put({ type: DELETE_COMPANY, entityid })
        else {
            yield put({ type: DELETE_PERSON, entityid })
        }

        yield put({ type: SET_CURRENT_ENTITY, entity: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'deleted', dokstorSuccess: 'Deleted successfully' })
        yield put({ type: OPEN_NOTIFICATION, data: { message:  isCompany ? <Translate id='notification.COMPANY_SUCCESS_DELETED' /> : <Translate id='notification.PERSON_SUCCESS_DELETED' />, type: 'success' } })
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: _listId, currentFilter: {} })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DELETING_ENTITY })
}

function* runRequestDeleteCP({ entityid, isCompany, cpid }) {

    const entityType = isCompany ? 'connected-companies' : 'connected-persons'
    let errors = [], response;

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DELETING_ENTITY, value: true })
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    response = yield db('delete', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}`)

    if (response.errors) {
        yield handlerError(response.errors)
        errors = errors.concat(response.errors)
    }

    if (isEmpty(errors)) {
        yield put({ type: SET_CURRENT_CONNECTED_PARTY, entity: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'deleted', dokstorSuccess: 'Deleted successfully' })
        yield put({ type: OPEN_NOTIFICATION, data: { message: isCompany ? <Translate id='notification.COMPANY_SUCCESS_DELETED' /> : <Translate id='notification.PERSON_SUCCESS_DELETED' />, type: 'success' } })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DELETING_ENTITY })
}

function* runRequestUpdateDocStatus({ payload: { documentid, status, reasonText } }) {
    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    const response = yield db('put', `${documentsPath}/${documentid}/status`, { status, reason: reasonText })

    if (response.errors) {
        yield handlerError(response.errors)
    } else {
        yield put({ type: DOC_STATUS_UPDATE, status })
        if (cpid) {
            yield call(runRequestCP, { entityid, cpid, isCompany })
        } else {
            yield call(runRequestEntity, { isCompany }, { entityid })
        }
    }
}

function* runRequestUpdateHitStatus({ hitids, status, message, cpidFromParent, isCompanyFromParent }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_HIT, value: hitids })

    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const entityPath = getEntityPath(isCompanyFromParent || isCompany, entityid, cpidFromParent || cpid)

    const response = yield db('put', `${entityPath}/hits/status`, { hitsIdentifiers: hitids, status, message });

    if (response.errors) {
        yield handlerError(response.errors)
    } else {
        if (cpid) {
            yield call(runRequestGetCurrentCPHits, { entityid, cpid, isCompany })
        } else {
            yield call(runRequestGetCurrentParentEntityHits, { entityid, isCompany })
            yield call(runGetEntityRisks, { entityId: entityid, isCompany })
        }

        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closeAMLBar', dokstorSuccess: 'Record status successfully modified' })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed' })
        yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_HIT })
    }
}

function* runRequestUploadDoc({ payload: { content, type, subtype = null, extraImage = null } }) {
    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })
    let errors = [], returnedDoc, docData, doc, document, response, newDoc
    const { isCompany, entityid, cpid } = yield select(getEntityData)

    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    const documents = yield db('get', documentsPath)
    if (type === 'CUSTOM') {
        document = documents?.documents?.find(doc => doc.subtype === subtype)
    } else {
        document = documents?.documents?.find(doc => doc.type === type)
    }
    if (document) newDoc = document
    else {
        response = yield db('post', documentsPath, { type, subtype })
        newDoc = response.document
        if (response.errors) {
            yield handlerError(response.errors)
            errors = errors.concat(response.errors)
        }
    }

    if (extraImage) {
        const images = [extraImage, content]
        for (let i = 0; i < images.length; i++) {
            docData = yield db('post',
                `${documentsPath}/${newDoc.identifier}/data${cpid ? 's' : ''}`,
                { index: i, content: images[i] })
        }
    } else {
        if (content) {
            const documentData = yield db('get', `${documentsPath}/${newDoc.identifier}`)
            docData = documentData && documentData.document && documentData.document.datas
            if (!docData) {
                docData = yield db('post',
                    `${documentsPath}/${newDoc.identifier}/data${cpid ? 's' : ''}`,
                    { index: 0, content });
                docData = docData.code || { ...docData.data, length: 1, datas: [{ content }] }
            } else {
                let maxIndex = 0
                docData.forEach(d => {
                    if (d.index > maxIndex) maxIndex = d.index
                })
                docData = yield db('post',
                    `${documentsPath}/${newDoc.identifier}/data${cpid ? 's' : ''}`,
                    { index: maxIndex + 1, content })
                docData = docData.code || { ...docData.data, length: 1, datas: [{ content }] }
            }
        }
    }

    if (docData && docData.errors) errors = errors.concat(docData.errors)
    else {
        returnedDoc = yield db('get', `${documentsPath}/${newDoc.identifier}`)
        const res = yield db('get', `${documentsPath}/${newDoc.identifier}/data${cpid ? 's' : ''}`)
        const resDatas = res && res.datas.map(resData => {
            const blob = b64ToBlob(resData.content);
            const newData = window.URL.createObjectURL(blob);
            return { ...resData, content: newData };
        })
        returnedDoc.document.datas = resDatas
        returnedDoc.document.length = res && res.datas && res.datas.length
    }

    if (returnedDoc && returnedDoc.errors) errors = errors.concat(returnedDoc.errors)
    else {
        if (docData) delete docData.identifier
        doc = returnedDoc.document
        yield put({ type: UPDATE_DOC, doc })
        yield call(runRequestAnyEntity, entityid, cpid, isCompany)
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
    }
    if (isEmpty(errors)) yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed', dokstorSuccess: 'File successfully uploaded' })
}

function* runRequestDeleteDocs({ docids, docTypes, request, documents }) {
    let errors = [], response
    const { isCompany, entityid, cpid } = yield select(getEntityData)

    const entityPath = getEntityPath(isCompany, entityid, cpid)
    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    if (docTypes[0].requestStatus === 'PENDING_REQUEST') {
        const response = yield db('delete', `${entityPath}/documents`, { documents: docids })
        if (response.errors) errors = errors.concat(response.errors)
    } else {
        for (let docid of docids) {
            response = yield db('delete', `${documentsPath}/${docid}/data${cpid ? 's' : ''}`)
            if (response.errors) errors = errors.concat(response.errors)
        }
    }

    if (!isEmpty(errors)) {
        yield handlerError(errors)
    } else {
        let documentsId = []
        if (request) {
            let originalRequest = cloneDeep(request)
            let newRequest = false

            request.documentTypes.forEach((category, index) => {
                category.documents = category.documents
                    .filter(doc => !docTypes
                        .some(d => d.type === doc.type))
                if (!isEqual(originalRequest.documentTypes[index].documents, category.documents)) {
                    newRequest = true
                }
                if (!isEmpty(category.documents)) {
                    category.documents.forEach(doc => {
                        let d = documents.find(d => d.type === doc.type)
                        d && documentsId.push(d.identifier)
                    })
                }
            })

            if (documentsId.length === 0) {
                yield db('delete', entityPath + '/request')

            } else if (newRequest && request.status === 'PENDING') {
                yield db('put', entityPath + '/request',
                    {
                        documents: documentsId,
                        reRequest: false
                    })
            }
        }
    }

    if (isEmpty(errors)) {
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'delete', dokstorSuccess: 'Document successfully deleted' })
        yield call(runRequestAnyEntity, entityid, cpid, isCompany)
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed', dokstorSuccess: 'Document successfully deleted' })
    }
}

function* runRequestDeleteFile({ payload: { fileid, docId } }) {
    let errors = [], doc
    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    doc = yield db('delete', `${documentsPath}/${docId}/data${cpid ? 's' : ''}/${fileid}`)
    if (doc.errors) {
        yield handlerError(doc.errors)
        errors = errors.concat(doc.errors)
    }

    let returnedDoc = yield db('get', `${documentsPath}/${docId}`)

    const res = yield db('get', `${documentsPath}/${docId}/data${cpid ? 's' : ''}`)
    returnedDoc.document.datas = res.datas
    returnedDoc.document.length = res.datas.length

    if (isEmpty(errors)) {
        const docData = returnedDoc.document
        yield put({ type: SET_CURRENT_DOC, docData })
        yield call(runRequestAnyEntity, entityid, cpid, isCompany)
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed', dokstorSuccess: 'File successfully deleted' })
    }
}

function* runRequestDeleteFilesFromDoc({ payload: { docId } }) {
    let errors = [], doc

    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    doc = yield db('delete', `${documentsPath}/${docId}/data${cpid ? 's' : ''}`)
    if (!doc || (doc && doc.errors)) {
        yield handlerError(doc.errors)
        errors = errors.concat(doc.errors)
    }

    const returnedDoc = yield db('get', `${documentsPath}/${docId}`)
    if (!returnedDoc || (returnedDoc && returnedDoc.errors)) {
        yield handlerError(returnedDoc.errors)
        errors = errors.concat(returnedDoc.errors)
    }

    if (isEmpty(errors)) {
        const docData = { ...returnedDoc.document, datas: [] }
        yield put({ type: SET_CURRENT_DOC, docData })
        yield call(runRequestAnyEntity, entityid, cpid, isCompany)
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed', dokstorSuccess: 'File successfully deleted' })
    }
}

function* runGetEntityRisks({ entityId, isCompany }) {
    const entityType = isCompany ? 'company' : 'person'
    const risksInfo = yield db('get', `dokstor-bo-api/api/${entityType}/${entityId}/risk`)
    const additionalRisksList = yield db('get', `dokstor-bo-api/api/${entityType}/settings/additional-risks`)

    yield put({ type: SET_ENTITY_RISKS, risksInfo, additionalRisksList: additionalRisksList.values })
}

function* runRequestUpdateAdditionalRisks({ entityId, entityType, riskIds, action, idRemoved }) {

    const isCompany = entityType === 'company'
    const value = (action === contextTypes.ADD_ADDITIONAL_RISK) || idRemoved

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes[action], value })

    const response = yield db('put', `dokstor-bo-api/api/${entityType}/${entityId}/additional-risks`, { additionalRisks: riskIds })

    if (response.errors) {
        yield handlerError(response.errors)
    } else {
        yield call(runGetEntityRisks, { entityId, isCompany })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes[action] })
}

function* runRequestGetCompanyOwnership(companyId) {

    yield put({ type: SET_CURRENT_ENTITY_TREE_STRUCTURE, structure: [] })

    function flattenStructure(toMix, orig = [], level = 1) {
        const mix = toMix.reduce((acc, cur) => {
            const { connectedParties, ...rest } = cur

            return connectedParties?.length > 0
                ? flattenStructure(cur.connectedParties, [...acc, { ...rest, level }], level + 1)
                : [...acc, { ...cur, level }]
        }, [])
        return [...orig, ...mix]
    }

    const res = yield db('get', 'dokstor-bo-api/api/companies/' + companyId + '/structure')

    if (res.errors) {
        yield handlerError(res.errors)
    } else {
        const structure = flattenStructure(res.connectedParties)
        yield put({ type: SET_CURRENT_ENTITY_STRUCTURE, structure })
        yield put({ type: SET_CURRENT_ENTITY_TREE_STRUCTURE, structure: res.connectedParties })
    }
}

function* runRequestUpsertStructureEntity({ _entity, isCompany }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    let errors = [], response
    const translationFn = yield select(getTranslationFn)
    const companyId = yield select(getCurrentEntityId)

    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })

    const { identifier,
        channel, status, archived, validations, createdBy, creationTimestamp, lookup,
        updatedBy, updateTimestamp, ...currentEntity
    } = _entity

    const hasPostErrors = response => response?.errors?.some(e => e.field)

    if (isCompany) {
        if (identifier) {
            response = yield db('put', `dokstor-bo-api/api/companies/${companyId}/connected-companies/${identifier}`, currentEntity)

            if (hasPostErrors(response)) {
                yield put({ type: SET_POST_ERRORS, errors: response.errors })
                yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
                yield put({ type: SET_DOKSTOR_STATUS, dokstorError: 'postErrors', dokstorStatus: null })
                errors = response.errors
            } else if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            }
        } else {
            response = yield db('post', `dokstor-bo-api/api/companies/${companyId}/connected-companies`, currentEntity)

            if (hasPostErrors(response)) {
                yield put({ type: SET_POST_ERRORS, errors: response.errors })
                yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
                yield put({ type: SET_DOKSTOR_STATUS, dokstorError: 'postErrors', dokstorStatus: null })
                errors = response.errors
            } else if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            }
        }
    } else {
        if (identifier) {
            response = yield db('put', `dokstor-bo-api/api/companies/${companyId}/connected-persons/${identifier}`, currentEntity)

            if (hasPostErrors(response)) {
                yield put({ type: SET_POST_ERRORS, errors: response.errors })
                yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
                yield put({ type: SET_DOKSTOR_STATUS, dokstorError: 'postErrors', dokstorStatus: null })
                errors = response.errors
            } else if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            }
        } else {
            response = yield db('post', `dokstor-bo-api/api/companies/${companyId}/connected-persons`, currentEntity)

            if (hasPostErrors(response)) {
                yield put({ type: SET_POST_ERRORS, errors: response.errors })
                yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.BUSINESS_VALIDATION'), type: 'error' } })
                yield put({ type: SET_DOKSTOR_STATUS, dokstorError: 'postErrors', dokstorStatus: null })
                errors = response.errors
            } else if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            }
        }
    }

    if (isEmpty(errors)) {
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
        yield call(runRequestGetCompanyOwnership, companyId)
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing', dokstorSuccess: isCompany ? 'Company successfully created' : 'Person successfully created' })
        yield put({ type: OPEN_NOTIFICATION, data: { message: isCompany ? translationFn('companySuccesSaved') : translationFn('personSuccesSaved'), type: 'success' } })
    } else {
        yield put({ type: CLEAR_DOKSTOR_STATUS})

    }
}

function* runUpdateEntityStatus({ identifier, status, isCompany, type }) {
    let response;
    const entityType = isCompany ? 'company/' : 'person/'

    response = yield db('put', 'dokstor-bo-api/api/' + entityType + identifier + '/validation', { type, status })

    if (response.errors) yield call(handlerError, response.errors)
}

function* runDeleteOwnershipRelation({ companyId, ownerEntityId, isCompany }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })

    const endpoint = isCompany ? 'ownercompany' : 'ownerperson';

    const response = yield db('delete', `dokstor-bo-api/api/company/${companyId}/${endpoint}/${ownerEntityId}`)

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing', dokstorSuccess: 'Entity successfully deleted' })
        yield put({
            type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS,
            listId: isCompany ? 'companyList' : 'personList',
            currentFilter: {}
        })
        yield call(runRequestGetCompanyOwnership, companyId)
    }
}

function* runRequestAddressDocData({ doc, entityId, entityType, docType }) {
    let document = null
    let docData;
    const _entityType = entityType === 'PERSON' ? 'person/' : 'company/'

    docData = yield db('get', `dokstor-bo-api/api/${_entityType + entityId}/document/${doc.identifier}/data`);

    if (docData.datas && docData.datas.length > 0) {
        document = {}
        const blob = b64ToBlob(docData.datas[0].content);
        document.image = window.URL.createObjectURL(blob);
        document.type = blob.type
        document.title = doc.type
        yield put({ type: SET_CURRENT_ENTITY_DOC_DATA, document, docType })
    }
}

function* runRequestGetWebSearchHits({entityId, currentFilter = {}, isCompany}) {
    const entityType = isCompany ? 'company' : 'person'
    const listConfig = yield select(listConfigs, 'websearch')
    const queryParam = listConfig ? getQueryParamFromListConfig(listConfig, currentFilter) : 'first=0&max=10'

    const webSearchHitsList = yield db('get', `dokstor-bo-api/api/${entityType}/${entityId}/web-hit?${queryParam}`)
    const webSearchHitsSummary = yield db('get', `dokstor-bo-api/api/${entityType}/${entityId}/web-hit/summary`)
    const entityInformation = yield db('get', `dokstor-bo-api/api/${entityType}/${entityId}`)

    const entityValidations = entityInformation?.[entityType]?.validations || []
    const webSearchValidation = entityValidations.find(validation => validation.type === 'WEB') || {}

    const webSearchHits = {...webSearchHitsList, summary: webSearchHitsSummary, validation: webSearchValidation}

    yield put({ type: SET_CURRENT_WEB_SEARCH_HITS, webSearchHits })
}

function* runRequestUpdateWebSearchHits({ entityId, hitIds, status, message, isCompany }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_HIT, value: hitIds })

    const entityType = isCompany ? 'company' : 'person'
    const response = yield db('put', `dokstor-bo-api/api/${entityType}/${entityId}/web-hits/status`, { hitsIdentifiers: hitIds, status, message })

    if (response.errors) {
        yield handlerError(response.errors)
    } else {
        yield call(runRequestGetWebSearchHits, { entityId, isCompany })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed' })
        yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_HIT })
    }
}

function* runRequestRecheckWebSearchHits({entityId, isCompany}) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.RECHECK_WEBSEARCH, value: true })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_SCREENING, value: true })

    const entityType = isCompany ? 'company' : 'person'
    const translationFn = yield select(getTranslationFn)

    let res = yield db('post', `dokstor-bo-api/api/${entityType}/${entityId}/recheck-web-screening`)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.RECHECK_WEBSEARCH, value: true })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_SCREENING, value: true })

    if (res.errors) {
        yield call(handlerError, res.errors)
    } else {
        yield call(runRequestGetWebSearchHits, { entityId, isCompany })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('recheckSuccess'), type: 'success' } })
    }
}

function* runRequestGetCurrentEntityHits({ entityid, cpid, isCompany }) {
    yield cpid
        ? call(runRequestGetCurrentCPHits, { entityid, cpid, isCompany })
        : call(runRequestGetCurrentParentEntityHits, { entityid, isCompany })
}

function* runRequestGetCurrentCPHits({ entityid, cpid, isCompany }) {
    let result = {}, currentCP = {}
    const entityType = isCompany ? 'connected-companies' : 'connected-persons'

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_SCREENING, value: true })

    const res = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}`)
    const cp = isCompany ? res.connectedCompany : res.connectedPerson

    if (entityid) {

        if (isCompany) {

            const listConfig = yield select(listConfigs, cp.name)
            const queryParam = listConfig ? getQueryParamFromListConfig(listConfig, {}) : 'first=0&max=10'

            const currentCompanyHits = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}/hits?${queryParam}`)

            let hitSummary = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}/hits/summary`)
            if (hitSummary.errors) hitSummary = {}
            else {
                const { companyCriteria, ...other } = hitSummary
                hitSummary = { ...other, criteria: companyCriteria }
            }

            currentCP = [{
                name: cp.name,
                type: 'company',
                hits: currentCompanyHits.hits,
                count: currentCompanyHits.count,
                identifier: cp.identifier,
                lastLookup: currentCompanyHits.lastLookup,
                hitSummary,
                country: cp.registeredAddressCountry,
                amlStatus: (cp.validations?.find(obj => obj.type === 'AML')) || null
            }]

            result = {
                ...result,
                'company': currentCP
            }
        } else {
            const listConfig = yield select(listConfigs, cp.name)
            const queryParam = listConfig ? getQueryParamFromListConfig(listConfig, {}) : 'first=0&max=10'

            const currentPersonHits = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}/hits?${queryParam}`)
            let hitSummary = yield db('get', `dokstor-bo-api/api/companies/${entityid}/${entityType}/${cpid}/hits/summary`)
            if (hitSummary.errors) hitSummary = {}
            else {
                const { personCriteria, ...other } = hitSummary
                hitSummary = { ...other, criteria: personCriteria }
            }

            currentCP = [{
                name: cp.name,
                surname: cp.surname,
                nationalities: cp.nationalities,
                birthDate: cp.birthDate,
                gender: cp.gender,
                type: 'person',
                hits: currentPersonHits.hits,
                count: currentPersonHits.count,
                identifier: cp.identifier,
                lastLookup: currentPersonHits.lastLookup,
                hitSummary,
                amlStatus: (cp.validations?.find(obj => obj.type === 'AML')) || null
            }]

            result = {
                ...result,
                'person': currentCP,
            }
        }
        yield put({ type: SET_CURRENT_CP_HITS, entityHits: result })
        yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_SCREENING })
    }
}

function* runRequestGetCurrentParentEntityHits({ entityid, isCompany }) {
    let result = {}, currentEntity = {}

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_SCREENING, value: true })

    if (entityid) {
        const entityType = isCompany ? 'company' : 'person'

        const res = yield db('get', 'dokstor-bo-api/api/' + entityType + '/' + entityid)
        const entity = isCompany ? res.company : res.person

        if (isCompany) {

            const listConfig = yield select(listConfigs, entity.name)
            const queryParam = listConfig ? getQueryParamFromListConfig(listConfig, {}) : 'first=0&max=10'

            const currentCompanyHits = yield db('get', `dokstor-bo-api/api/company/${entityid}/hit?${queryParam}`)

            let hitSummary = yield db('get', `dokstor-bo-api/api/company/${entityid}/hit/summary`)
            if (hitSummary.errors) hitSummary = {}
            else {
                const { companyCriteria, ...other } = hitSummary
                hitSummary = { ...other, criteria: companyCriteria }
            }

            currentEntity = [{
                name: entity.name,
                type: 'company',
                hits: currentCompanyHits.hits,
                count: currentCompanyHits.count,
                identifier: entity.identifier,
                lastLookup: currentCompanyHits.lastLookup,
                hitSummary,
                country: entity.registeredAddressCountry,
                amlStatus: (entity.validations?.find(obj => obj.type === 'AML')) || null
            }]

            result = {
                ...result,
                'company': currentEntity
            }
        } else {
            const listConfig = yield select(listConfigs, entity.name)
            const queryParam = listConfig ? getQueryParamFromListConfig(listConfig, {}) : 'first=0&max=10'

            const currentPersonHits = yield db('get', `dokstor-bo-api/api/person/${entityid}/hit?${queryParam}`)
            let hitSummary = yield db('get', `dokstor-bo-api/api/person/${entityid}/hit/summary`)
            if (hitSummary.errors) hitSummary = {}
            else {
                const { personCriteria, ...other } = hitSummary
                hitSummary = { ...other, criteria: personCriteria }
            }

            currentEntity = [{
                name: entity.name,
                surname: entity.surname,
                nationalities: entity.nationalities,
                birthDate: entity.birthDate,
                gender: entity.gender,
                type: 'person',
                hits: currentPersonHits.hits,
                count: currentPersonHits.count,
                identifier: entity.identifier,
                lastLookup: currentPersonHits.lastLookup,
                hitSummary,
                amlStatus: (entity.validations?.find(obj => obj.type === 'AML')) || null
            }]

            result = {
                ...result,
                'person': currentEntity,
            }
        }
        yield put({ type: SET_CURRENT_ENTITY_HITS, entityHits: result })
        yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_SCREENING })
    }
}

function* runRequestGetHitsFromAnEntity({ entityType, entityKey, listId, currentFilter, isUpdate }) {

    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter)

    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const entityPath = getEntityPath(isCompany, entityid, cpid)

    if (!entityid) return

    const response = yield db('get', `${entityPath}/hit${cpid ? 's' : ''}?${queryParam}`)


    if (cpid) {
        yield put({ type: UPDATE_CP_HITS, isCompany, hits: response.hits })
    } else {
        if (isUpdate) {
            let hitSummary = yield db('get', `${entityPath}/hit/summary`)

            const entity = yield db('get', `${entityPath}`)
            let amlStatus = {}

            if (entity && entity[entityType]) {
                amlStatus = (entity[entityType].validations?.find(obj => obj.type === 'AML')) || {}
            }

            if (!hitSummary || hitSummary.errors) hitSummary = {}
            else {
                const { companyCriteria, personCriteria, ...other } = hitSummary
                hitSummary = { ...other, criteria: isCompany ? companyCriteria : personCriteria }
            }

            if (!response || (response?.errors)) {
                yield call(handlerError, response.errors)
            } else {
                yield put({ type: SET_HITS_FROM_AN_ENTITY, entityInfo: { ...response, hitSummary, amlStatus }, entityKey, entityid })
            }
        } else {
            if (!response || (response?.errors)) {
                yield call(handlerError, response.errors)
            } else {
                yield put({ type: SET_HITS_FROM_AN_ENTITY, entityInfo: { ...response }, entityKey, entityid })
            }
        }
    }
}

function* runRequestUpdateEntityStatus({ isCompany, entityid, status, statusToUpdate, cpid, reason, options, onSuccess }) {

    let response = {}
    const entityPath = getEntityPath(isCompany, entityid, cpid)

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.UPDATING_STATUS, value: true })

    if (statusToUpdate) {
        response = yield db('put', `${entityPath}/${cpid ? 'validations' : 'validation'}`, { type: statusToUpdate, status, reason, options: JSON.stringify(options) })
    } else {
        response = yield db('put', `${entityPath}/status`, { status })
    }

    if (response.errors) yield call(handlerError, response.errors)
    else {
        if (cpid) {
            yield call(runRequestCP, { entityid, cpid, isCompany })
            yield call(runRequestEntity, { isCompany: isCompany || !!cpid }, { entityid })
        } else {
            yield call(runRequestEntity, { isCompany }, { entityid })
        }
        yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList', currentFilter: {} })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.UPDATING_STATUS })
    onSuccess && onSuccess()
}

function* runRequestFinishCase({ identifier, action, finalApproval, comment, reason, ownerId, teamId }) {

    yield db('post', `dokstor-bo-api/api/cases/${identifier}/status/${action}`, {
        comment,
        ...( action === 'approve' && { finalApproval, ownerId, teamId }),
        ...( action === 'reject' && { reason })
    })

    const entity = yield select(getEntityData)

    yield call(runRequestEntity, { isCompany: entity.isCompany }, { entityid: identifier })
}

function* runRequestSetPendingApproval({ isCompany, entityId, taskIdentifier, approved }) {
    yield db('put', `dokstor-bo-api/api/cases/${entityId}/pending-approvals`, { taskIdentifier, approved })
    yield call(runRequestEntity, { isCompany }, { entityid: entityId })
    yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList' })
}

function* runRequestRecheckEntity() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.RECHECK, value: true })

    const translationFn = yield select(getTranslationFn)

    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const entityPath = getEntityPath(isCompany, entityid, cpid)

    let res = yield db('post', `${entityPath}/recheck`)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.RECHECK })

    if (res.errors) yield call(handlerError, res.errors)
    else {
        yield call(runRequestAnyEntity, entityid, cpid, isCompany)
        yield call(runRequestGetCurrentEntityHits, { entityid, cpid, isCompany })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'succeed' })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('recheckSuccess'), type: 'success' } })
    }
}

function* runRequestRecheckAML() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_SCREENING, value: true })

    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const entityPath = getEntityPath(isCompany, entityid, cpid)

    let res = yield db('post', `${entityPath}/recheck-screening`)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_SCREENING })

    if (res.errors) yield call(handlerError, res.errors)
    else {
        yield call(runRequestGetCurrentEntityHits, { entityid, cpid, isCompany })
    }
}

function* runRequestRecheckRisk() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_RISK_ASSESMENT, value: true })

    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const entityPath = getEntityPath(isCompany, entityid, cpid)

    let res = yield db('post', `${entityPath}/recheck-risk`)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_RISK_ASSESMENT })

    if (res.errors) yield call(handlerError, res.errors)
    else {
        yield put({type: isCompany ? REQUEST_COMPANY : REQUEST_PERSON, entityid})
        yield put({ type: GET_ENTITY_RISKS, entityId: entityid, isCompany })
    }
}

function* runRequestGetPhases(entityid, casePhase) {
    const phases = yield db('get', `dokstor-bo-api/api/cases/${entityid}/completed-phases`)
    yield put({ type: SET_PHASES, phases, casePhase })
}

function* runRequestPhaseChange({ isCompany, identifier, payload }) {
    const { sourcePhase, targetPhase, message, ownerId, teamId } = payload
    let body = { sourcePhase, targetPhase, message }
    if(ownerId) body.ownerId = ownerId
    if(teamId) body.teamId = teamId

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.PHASE_CHANGE, value: true})
    yield db('put', `dokstor-bo-api/api/cases/${identifier}/phase`, body)
    yield call(runRequestEntity, { isCompany }, { entityid: identifier })
    yield put({ type: isCompany ? REQUEST_COMPANIES : REQUEST_PERSONS, listId: isCompany ? 'companyList' : 'personList' })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.PHASE_CHANGE })
}

function* runRequestGetHitDetails(payload = {}) {
    const { entityType, entityId, hitId } = payload
    let response

    response = yield db('get', `dokstor-bo-api/api/${entityType}/${entityId}/hit/${hitId}/details`)

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: SET_CURRENT_HIT_DETAILS, currentHitDetails: response })
    }
}

function* runRequestRiskApproval(payload = {}) {
    const { entityId, riskScore, message, isCompany, calculatedScore, scaleMaxValue } = payload
    const endpoint = `dokstor-bo-api/api/cases/${entityId}/pending-approvals/risk`;
    const body = {
        riskScore,
        riskScoreReason: message,
        calculatedScore,
        scaleMaxValue
    }

    let response = yield db('post', endpoint, body)

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield call(runRequestEntity, { isCompany }, { entityid: entityId })
    }
}

function* runRequestSaveEntityDocComment({ documentId, comment }) {
    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_COMMENT, value: true })

    const response = yield db('put', `${documentsPath}/${documentId}/comments`, { comments: comment });

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'Comment successfully saved!', type: 'success' } })
        yield put({ type: SET_CURRENT_DOC_COMMENT, comment, documentId, cpid })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'comment save successfully' })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_COMMENT })
}

function* runRequestUpdateEntityOwner(payload = {}) {
    let response

    const translationFn = yield select(getTranslationFn)
    const { caseId, caseOwnerId, caseTeamId, message, isCompany } = payload
    const endpoint = `dokstor-bo-api/api/cases/${caseId}/owner`
    let body = { message }
    if(caseOwnerId) body.ownerId = caseOwnerId
    if(caseTeamId) body.teamId = caseTeamId  

    response = yield db('put', endpoint, body)

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield call(runRequestEntity, { isCompany }, { entityid: caseId })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success assign case' })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('assignCaseSucces'), type: 'success' } })
    }
}

function* runRequestAddDocument({ doc }) {
    const { isCompany, entityid, cpid } = yield select(getEntityData)
    const translationFn = yield select(getTranslationFn)
    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    const response = yield db('post', documentsPath, { type: doc.name })

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('docSucces'), type: 'success' } })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })
        yield call(runRequestAnyEntity, entityid, cpid, isCompany)
    }
}

function* runRequestBlacklistEntity(args, payload = {}) {
    const { isCompany } = args
    const { entityId, blackRisks } = payload
    const entityType = isCompany ? 'company/' : 'person/'
    let errors = []

    yield db_thunk('put', 'dokstor-bo-api/api/' + entityType + entityId + '/blacklisting', { blackRisks })
        .catch(e => errors = errors.concat(e))

    if (errors) yield call(handlerError, errors)
    else {
        yield call(runRequestEntity, { isCompany }, { entityid: entityId })
    }
}

function* runRequestCompanySuggestedStructure({ entityid }) {
    const response = yield db('get', `dokstor-bo-api/api/company/${entityid}/structure`)

    if (response.errors) {
        yield put({ type: SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS, found: false })
    }
    else if (response.structure.length) {
        yield put({ type: SET_COMPANY_SUGGESTED_STRUCTURE, suggestedStructure: response.structure })
        yield put({ type: SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS, found: true })
    }
    else {
        yield put({ type: SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS, found: false })
    }
}