import React                            from 'react';
import {ChipStyled, GridChipsContainer} from './AdvancedSearch.styles';
import {getTablePeriod}                 from "../../../../config/helpers";

const FilterChips = ({chips, onDelete, t}) => {

    return (
        <GridChipsContainer item xs={12}>
            {chips && chips.map((chip, i) =>{
                const label = t(chip.titleLabel, { defaultValue: chip.name })
                const {type} = chip && chip.selectorType;
                
                const valueLabel = ()=>{
                    if(type === 'select' && !chip.selectorType.dynamicOptions) {
                        return chip.valueLabel;
                    } else if(type === 'date'){
                        const dateTranslation = getTablePeriod(chip.valueLabel);
                        return t('fromTo', {fromDate: dateTranslation.substr(0,10), toDate: dateTranslation.substr(11,10)});
                    } else if(type === 'numberRange' || type === 'sliderRange'){
                        if(chip.value[chip.selectorType.toKey] === ''){
                            return t('greaterThan', {value: chip.value[chip.selectorType.fromKey]})
                        } else {
                            return t('fromTo', {fromDate: chip.value[chip.selectorType.fromKey], toDate: chip.value[chip.selectorType.toKey]})
                        }
                    }else{
                        return chip.valueLabel;
                    }
                }

                return <ChipStyled
                    data-cy={`${chip.titleLabel}-chip`}
                    label= {`${label}: ${valueLabel()}`}
                    onDelete={() => onDelete(chip.valueLabel)}
                    color="primary"
                    key= {i}
                />
            }
            )}
        </GridChipsContainer>);
};

export default FilterChips;