import React                                        from 'react';
import {Fab}                                        from '@material-ui/core';
import ContentAdd                                   from '@material-ui/icons/Add';
import PropTypes                                    from 'prop-types';
import {ActionTitle, CardStrapLine, FlexContainer}  from 'heliocor-ui';


export const AddNewButton = ({disabled = false, onClick, titleTranslationId, subTitleTranslationId}) => (

        <FlexContainer alignItems='center'>
            <Fab disabled={disabled} size="small" color="primary" aria-label="Add" onClick={onClick} style={{minWidth: '40px'}}>
                <ContentAdd />
            </Fab>
            <FlexContainer column>
                <ActionTitle  margin='0 0 0 10px' translate={titleTranslationId} />
                    { subTitleTranslationId && <CardStrapLine margin={'0 0 0 10px'} translate={subTitleTranslationId} />}
            </FlexContainer>
        </FlexContainer>

)



AddNewButton.propTypes = {
    onClick:    PropTypes.func,
    title:       PropTypes.string,
    subTitle:   PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}
