import React, { Fragment } from 'react';
import { Paragraph } from 'heliocor-ui'
import { List, ListedItem, DoneStyled, ArrowBackStyled, GetBackLine } from './LanguageSwitcher.styles'
import { Translate } from '../Translate';

const languages = [
    { value: 'zh-CN', label: '中文(简体'},
    { value: 'en-EN', label: 'English'},
    { value: 'es-ES', label: 'Español'},
    { value: 'fr-FR', label: 'Français'},
    { value: 'de-DE', label: 'Deutsch'},
    { value: 'ja-JA', label: '日本語'}
]

const LanguageList = ({languages, onLanguageChange, language}) => (
    <List>
    {languages.map(lang => 
        <ListedItem onClick={() => onLanguageChange(lang.value)} key={lang.label}>
            <Paragraph padding='8px 8px 8px 0' margin='0' color='greyTint' value={lang.label} />
            {language === lang.value && <DoneStyled/>} 
        </ListedItem>
        )
    }
    </List>
)

const GetBackTo = ({Tab}) => (
    <Tab id="firstScreen">
        <GetBackLine>
            <ArrowBackStyled />
            <Paragraph margin='0' padding='0 0 0 8px' color='greyTint'>
                <Translate id="changeLanguage" />
            </Paragraph>
        </GetBackLine>
    </Tab>
)

const LanguageSwitcher = ({onLanguageChange, language, Tab}) => (
    <Fragment>
        <GetBackTo Tab={Tab} />
        <LanguageList 
            languages={languages} 
            onLanguageChange={onLanguageChange}
            language={language}
        />
    </Fragment>
)

export default LanguageSwitcher
