import {APPLICATIONS, PERMISSIONS} from '../login/consts/permissions.enum' 

export const routesPermissions = (route) => {

    const checkRegex = () => {
        return route
            .split('?')[0]
            .split(/[0-9a-zA-Z]{32}/).join(':param')
    }

    switch (checkRegex()) {
        case '/home':
            return {
                app: APPLICATIONS.AUTH,
                permissions:[PERMISSIONS.AUTH.LOGIN]
            }

        //DOKSTOR
        case '/dokstor/dashboards':
        case '/dokstor/dashboards/application-status':
        case '/dokstor/dashboards/cases-by-risk-&-kyc':
        case '/dokstor/dashboards/in-progress-cases':
        case '/dokstor/dashboards/rejected-cases':
        case '/dokstor/dashboards/conversion-rate':
        case '/dokstor/dashboards/completion-time':
        case '/dokstor/dashboards/client-summary':
            return {
                app: APPLICATIONS.DOKSTOR,
                permissions: [
                    PERMISSIONS.DOKSTOR.DOKSTOR_REPORTING
                ]
            }
        case '/dokstor/workspace':
        case '/dokstor/person':
        case '/dokstor/person/:param':
        case '/dokstor/company':
        case '/dokstor/company/:param':
        case '/dokstor/company/:param/connected-person/:param':
        case '/dokstor/company/:param/connected-company/:param':
            return {
                app: APPLICATIONS.DOKSTOR,
                permissions: [
                    PERMISSIONS.DOKSTOR.DOKSTOR_ACTIVITY,
                    PERMISSIONS.DOKSTOR.DOKSTOR_APPROVE,
                    PERMISSIONS.DOKSTOR.DOKSTOR_SECOND_APPROVE,
                    PERMISSIONS.DOKSTOR.DOKSTOR_ASSESS_RISK,
                    PERMISSIONS.DOKSTOR.DOKSTOR_CAPTURE_DOCUMENTS,
                    PERMISSIONS.DOKSTOR.DOKSTOR_CREATE_PARTY,
                    PERMISSIONS.DOKSTOR.DOKSTOR_EDIT_PARTY,
                    PERMISSIONS.DOKSTOR.DOKSTOR_SCREEN,
                    PERMISSIONS.DOKSTOR.DOKSTOR_STRUCTURE,
                    PERMISSIONS.DOKSTOR.DOKSTOR_VALIDATE_DOCUMENTS,
                ]
            }
        case '/dokstor/settings':
            return {
                app: APPLICATIONS.DOKSTOR,
                permissions: [
                    PERMISSIONS.DOKSTOR.ADMIN,
                    PERMISSIONS.DOKSTOR.DOKSTOR_ADMIN_WATCH_LIST,
                    PERMISSIONS.DOKSTOR.DOKSTOR_ADMIN_WATCH_LIST_READ_ONLY,
                ]
            }
        case '/dokstor/settings/CAFe':
        case '/dokstor/settings/kyc-types':
        case '/dokstor/settings/documents':
        case '/dokstor/settings/signature-documents':  
        case '/dokstor/settings/validations':
        case '/dokstor/settings/approval':
        case '/dokstor/settings/risk-assessment':
        case '/dokstor/settings/dashboards':
        case '/dokstor/settings/fields':
            return {
                app: APPLICATIONS.DOKSTOR,
                permissions: [
                    PERMISSIONS.DOKSTOR.ADMIN,
                ]
            }
        case '/dokstor/settings/watchlist':
            return {
                app: APPLICATIONS.DOKSTOR,
                permissions: [
                    PERMISSIONS.DOKSTOR.ADMIN,
                    PERMISSIONS.DOKSTOR.DOKSTOR_ADMIN_WATCH_LIST,
                    PERMISSIONS.DOKSTOR.DOKSTOR_ADMIN_WATCH_LIST_READ_ONLY
                ]}

        //REFERENCE DATA
        case '/reference-data/data-generator':
        case '/reference-data/data-generator/new-core-data/:param':
        case '/reference-data/data-generator/new-payments/:param':
        case '/reference-data/core-data':
        case '/reference-data/customers':
        case '/reference-data/accounts':
        case '/reference-data/payments':
        case '/reference-data/cards':
        case '/reference-data/details':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [
                    PERMISSIONS.ROBOLITICS.FULL,
                    PERMISSIONS.ROBOLITICS.ROBOLITICS_REF_DATA
                ]
            }

        case '/trades/securities':
        case '/trades/alerts':
        case '/trades/investors':
        case '/trades/accounts':
        case '/trades/orders':
        case '/trades/alerts/alert-details/:param':
        case '/trades/securities/security-details/:param':
        case '/trades/orders/order-details/:param':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [PERMISSIONS.ROBOLITICS.FULL]
            }

            //CASE MANAGEMENT
        case '/case-management/dashboards':
        case '/case-management/dashboards/home':
        case '/case-management/dashboards/customer-activity':
        case '/case-management/dashboards/algos-robots':
        case '/case-management/dashboards/international-transactions':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [
                    PERMISSIONS.ROBOLITICS.ROBOLITICS_DASHBOARD
                ]
            }
        case '/case-management/robolitics':
        case '/case-management/robolitics/case-details/:param':
        case '/case-management/robolitics/case-details/:param/alert-details/:param':
        case '/case-management/alerts':
        case '/case-management/alerts/triage':
        case '/case-management/alerts/alert-details/:param':
        case '/case-management/alerts-rank':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [
                    PERMISSIONS.ROBOLITICS.FULL,
                    PERMISSIONS.ROBOLITICS.ROBOLITICS_CASE_MANAGEMENT
                ]
            }
        case '/case-management/radars/radar-details/:param':
        case '/case-management/radars':
        case '/case-management/algos':
        case '/case-management/algos/robot-detail/:param':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [
                    PERMISSIONS.ROBOLITICS.FULL,
                    PERMISSIONS.ROBOLITICS.ROBOLITICS_ALGOS
                ]
            }
        case '/case-management/reports':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [
                    PERMISSIONS.ROBOLITICS.FULL,
                    PERMISSIONS.ROBOLITICS.ROBOLITICS_REPORTING
                ]
            }
        case '/case-management/settings':
        case '/case-management/settings/watch-lists':
        case '/case-management/settings/watch-lists/:param':
        case '/case-management/settings/countries-risks':
        case '/case-management/settings/algos-default-values':
            return {
                app: APPLICATIONS.ROBOLITICS,
                permissions: [
                    PERMISSIONS.ROBOLITICS.FULL,
                    PERMISSIONS.ROBOLITICS.ADMIN
                ]
            }

        //ADMIN
        case '/admin':
            return {
                app: APPLICATIONS.AUTH,
                permissions: [
                    PERMISSIONS.AUTH.ADMIN,
                    PERMISSIONS.AUTH.ADMIN_USER,
                    PERMISSIONS.AUTH.ADMIN_SERVICE_ACCOUNT,
                    PERMISSIONS.AUTH.ADMIN_SETTING,
                    PERMISSIONS.AUTH.ADMIN_ROLE,
                    PERMISSIONS.AUTH.ADMIN_TEAM,
                ]
            }
        case '/admin/users':
            return {
                app: APPLICATIONS.AUTH,
                permissions: [
                    PERMISSIONS.AUTH.ADMIN,
                    PERMISSIONS.AUTH.ADMIN_USER,
                ]
            }
        case '/admin/api-keys':
            return {
                app: APPLICATIONS.AUTH,
                permissions: [
                    PERMISSIONS.AUTH.ADMIN,
                    PERMISSIONS.AUTH.ADMIN_SERVICE_ACCOUNT,
                ]
            }
        case '/admin/settings':
            return {
                app: APPLICATIONS.AUTH,
                permissions: [
                    PERMISSIONS.AUTH.ADMIN,
                    PERMISSIONS.AUTH.ADMIN_SETTING,
                ]
            }
        case '/admin/roles':
            return {
                app: APPLICATIONS.AUTH,
                permissions: [
                    PERMISSIONS.AUTH.ADMIN,
                    PERMISSIONS.AUTH.ADMIN_ROLE,
                ]
            }
        case '/admin/teams':
            return {
                app: APPLICATIONS.AUTH,
                permissions: [
                    PERMISSIONS.AUTH.ADMIN,
                    PERMISSIONS.AUTH.ADMIN_TEAM,
                ]
            }

        //SYSTEM ADMIN
        case '/sysadmin':
        case '/sysadmin/orgs':
        case '/sysadmin/types':
            return {
                app: APPLICATIONS.SYSTEM,
                permissions: Object.keys(PERMISSIONS.SYSTEM)
            }

        default:
            return {}
    }
}

