import React, { useState } from 'react';
import { Tab, Translate } from '../';
import { SettingsBar, ListStyle, ListedItems } from './Settings.styles';


const SettingsMenuBar = ({menuOptions}) => {

    const [isActive, setIsActive] = useState('firstScreen')

    return(
        <SettingsBar height={menuOptions.length * 72}>
            <ListStyle>
                {menuOptions.map((setting,i) =>
                    <Tab id={setting.id} key={i}>
                        <ListedItems active={isActive === setting.id} onClick={() => setIsActive(setting.id)}>
                            <Translate id={setting.translationId} />
                        </ListedItems>
                    </Tab>
                )}
            </ListStyle>
        </SettingsBar>
    )
}


export default SettingsMenuBar;