import React from "react";
import { FlexContainer } from "heliocor-ui";
import Tooltip from "@material-ui/core/Tooltip";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import IconButton from "@material-ui/core/IconButton";
import PreviousIcon from "@material-ui/icons/NavigateBefore";
import NextIcon from "@material-ui/icons/NavigateNext";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { Translate } from "../Translate";

const Wrapper = (props) => {
    return (
        <FlexContainer padding="0 30px">
            {props.children}
        </FlexContainer>
    )
}

const Action = (config) => (props) => {
    return (
        <IconButton onClick={props.onClick} disabled={props.disabled}>
            <Tooltip title={<Translate id={config.translationId} />}>
              {config.icon}
            </Tooltip>
        </IconButton>
    )
}

const MediaViewerActions = {
    Wrapper,
    RotateLeft: Action({ translationId: 'mediaViewer.rotateLeft', icon: <RotateLeftIcon /> }),
    RotateRight: Action({ translationId: 'mediaViewer.rotateRight', icon: <RotateRightIcon /> }),
    ZoomIn: Action({ translationId: 'mediaViewer.zoomIn', icon: <ZoomInIcon /> }),
    ZoomOut: Action({ translationId: 'mediaViewer.zoomOut', icon: <ZoomOutIcon /> }),
    Previous: Action({ translationId: 'mediaViewer.previousPage', icon: <PreviousIcon /> }),
    Next: Action({ translationId: 'mediaViewer.nextPage', icon: <NextIcon /> }),
}

export { MediaViewerActions };
