import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { styles } from '../../config/styles'
import { colors } from '../../config/colors'
import ContentCircle from '@material-ui/icons/PlayCircleFilled';
import { TitleH4 } from 'heliocor-ui';

const {
    cardSubTitle,
    horizontalFlex,
} = styles.content;

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const CustomizedDialogDemo = props => {
  const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (
      <div>
        <a href='/' style={horizontalFlex} target="_blank" onClick={handleClickOpen}>
            <ContentCircle color="primary" cursor="pointer"/>
            <TitleH4 style={Object.assign({}, cardSubTitle, {color: colors.main, cursor:"pointer", marginBottom:0} )} translate="howToStart"/>
        </a>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          PaperProps={{
            style: {
              maxWidth: '1000px',
              height: '80vh',
              width: '80vw'
            },
          }}
        >
            <div>
                <DialogContent>
                        <iframe
                            title={'video'}
                            style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                            }}
                            src={props.url}
                            frameBorder="0"
                        />
                </DialogContent>
            </div>
        </Dialog>
      </div>
    );
  
}

export default CustomizedDialogDemo;