import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { db } from '../../../api/db'
import { b64ToBlob, getISO2FromISO3CountryCode, convertBlobToBase64 } from 'heliocor-ui'
import { takeLatestSafe } from '../../../config/helpers/sagasHelper'
import { OPEN_NOTIFICATION } from '../../../notifications/actionTypes'
import { getQueryParamFromListConfig } from '../../common/MainTable/helpers/tableHelpers'
import { SET_API_STATUS } from '../../common/store/actionTypes'
import contextTypes from '../../dokstor/store/contextTypes.enum'

import {
    ADD_LOADING_COUNTRY_RISK_ID,
    ADD_NEW_ALERT_COMMENT,
    BULK_ASSIGN_ALERTS,
    CHANGE_CASE_STATUS,
    CHANGE_ROBOT_STATUS,
    CLEAR_CASE_STATUS,
    LOCK_CASE,
    DELETE_ALERT_COMMENT,
    DELETE_CASE,
    EDIT_ALERT_COMMENT,
    EXECUTE_ALGO,
    GET_ABCT_DATA,
    GET_ABGA_DATA,
    GET_ABSCEN_DATA,
    GET_ABSTATUS_DATA,
    GET_ALERT_COMMENTS,
    GET_ALERT_NBA,
    GET_ALERT_REPORT,
    GET_ALERT_SIMILARITY,
    GET_ALERTS_BY_SAME_TRANSACTION,
    GET_ALERTS_HISTORY,
    GET_ALERTS_TRIAGE,
    GET_ALGOS,
    GET_CLASSIFICATION_TYPES,
    GET_CLUSTER_ALERTS,
    GET_CLUSTER_CUSTOMERS,
    GET_CLUSTERS,
    GET_COUNTRIES_RISKS_SETTINGS,
    GET_ALL_COUNTRIES_RISKS_SETTINGS,
    GET_CURRENT_ALERT,
    GET_CURRENT_ALERT_BELOW_RADAR,
    GET_CURRENT_ALERT_CUSTOMER,
    GET_CURRENT_ALERT_CUSTOMER_ALERTS,
    GET_CURRENT_ALERT_CUSTOMER_CASES,
    GET_CURRENT_CHART_BELOW_RADAR,
    GET_CURRENT_CHART_ISSUES,
    GET_CURRENT_NUM_OF_ALERTS,
    GET_CURRENT_RADAR_BELOW_RADAR,
    GET_CURRENT_RADAR_CHART,
    GET_CURRENT_RADAR_ISSUES,
    GET_NBA_DATA,
    GET_RADAR,
    GET_RADAR_AUDITS,
    GET_REPORTS,
    GET_ROBOT,
    GET_ROBOT_AUDITS,
    GET_ROBOT_ISSUES,
    GET_ROBOT_ISSUES_HISTORY,
    GET_ROBOT_SIMULATION,
    GET_ROBOTS,
    GET_ROBOTS_BY_ALGO,
    GET_UA_DATA,
    GET_USER_DASHBOARD_CONFIG,
    GET_WATCH_LISTS,
    GET_WATCHLIST,
    GET_WATCHLIST_MEMBERS,
    GET_WC_DATA,
    REFRESH_CURRENT_CASE,
    RELOAD_WIDGETS,
    REMOVE_LOADING_COUNTRY_RISK_ID,
    REQUEST_ALERT_AUDITS,
    REQUEST_ALERT_EVIDENCE,
    REQUEST_ALERTS,
    REQUEST_CASE_ALERTS,
    REQUEST_CASE_AUDITS,
    REQUEST_CASES,
    REQUEST_DELETE_ALERT_EVIDENCE,
    REQUEST_DELETE_CASE,
    REQUEST_UPSERT_ALERT_EVIDENCE,
    REQUEST_UPDATE_ALERTS,
    SAVE_ROBOT_PARAMS,
    SET_ALERT_COMMENTS,
    SET_ALERT_NBA,
    SET_ALERT_REPORT,
    SET_ALERTS_HISTORY,
    SET_ALERTS_TRIAGE,
    SET_ALGOS,
    SET_CASE_MANAGEMENT_STATUS,
    SET_CASE_STATUS,
    SET_CLASSIFICATION_TYPES,
    SET_CLUSTERS,
    SET_COUNTRIES_RISKS_SETTINGS,
    SET_CURRENT_ALERT,
    SET_CURRENT_ALERT_BELOW_RADAR,
    SET_CURRENT_ALERT_CUSTOMER,
    SET_CURRENT_ALERT_CUSTOMER_ALERTS,
    SET_CURRENT_ALERT_CUSTOMER_TRANSACTIONS,
    SET_CURRENT_ALERT_SAME_TXN_ALERTS,
    SET_CURRENT_ALERT_SIMILARITY,
    SET_CURRENT_CASE,
    SET_CURRENT_CHART_BELOW_RADAR,
    SET_CURRENT_CHART_ISSUES,
    SET_CURRENT_CLUSTER_ALERTS,
    SET_CURRENT_CLUSTER_CUSTOMERS,
    SET_CURRENT_NUM_OF_ALERTS,
    SET_CURRENT_RADAR,
    SET_CURRENT_RADAR_BELOW_RADAR,
    SET_CURRENT_RADAR_CHART,
    SET_CURRENT_RADAR_ISSUES,
    SET_CURRENT_ROBOT,
    SET_CURRENT_WATCHLIST,
    SET_CURRENT_WATCHLIST_MEMBERS,
    SET_NBA_DATA,
    SET_RADAR_AUDITS,
    SET_REPORTS,
    SET_ROBOT_AUDITS,
    SET_ROBOT_ISSUES,
    SET_ROBOT_ISSUES_HISTORY,
    SET_ROBOT_SIMULATION,
    SET_ROBOTS_TRANSACTION_DATA,
    SET_ROBOTS,
    SET_USER_DASHBOARD_CONFIG,
    SET_WATCH_LISTS,
    SET_WIDGET_DATA,
    SUCCEED_REQUEST_ALERT_AUDITS,
    SUCCEED_REQUEST_ALERT_EVIDENCE,
    SUCCEED_REQUEST_ALERTS,
    SUCCEED_REQUEST_CASE_ALERTS,
    SUCCEED_REQUEST_CASE_AUDITS,
    SUCCEED_REQUEST_CASES,
    UPDATE_ALERT_VALIDATIONS,
    UPDATE_ALERTS_AS_CURRENT,
    UPDATE_COUNTRY_RISK,
    ADD_DASHBOARD_CHART,
    REMOVE_DASHBOARD_CHART,
    UPSERT_RADAR,
    UPSERT_ROBOT,
    UPSERT_WATCHLIST,
    UPSERT_WATCHLIST_MEMBER,
    SET_CURRENT_EVIDENCE,
    REQUEST_CASE_SPLIT,
    DELETE_ROBOT,
    DELETE_WATCHLIST_MEMBER,
    GET_ROBOTS_TRANSACTION_DATA
} from './actionTypes'
import { t } from 'i18next'

const listConfigs = (state, listId) => state.common.lists[listId]
const currentAlertStore = (store) => store.caseManagement.currentAlert
const getCurrentCaseId = store => store.caseManagement.currentCase.identifier
const getCurrentAlertId = store => store.caseManagement.currentAlert.identifier
const getCurrentRobot = store => store.caseManagement.currentRobot
const getDashboardConfig = store => store.caseManagement.dashboardSettings

const watcher = () =>
    function* watch() {
        yield takeLatestSafe(REQUEST_CASES, runRequestCases, {})
        yield takeLatestSafe(REQUEST_ALERTS, runRequestAlerts, {})
        yield takeLatestSafe(REQUEST_DELETE_CASE, runRequestDeleteCase, {})
        yield takeLatestSafe(REQUEST_CASE_ALERTS, runRequestCaseAlerts, {})
        yield takeLatestSafe(REQUEST_ALERT_AUDITS, runRequestAlertAudits, {})
        yield takeLatestSafe(REQUEST_ALERT_EVIDENCE, runRequestAlertEvidence, {})
        yield takeLatestSafe(GET_ALGOS, runRequestAlgos)
        yield takeLatestSafe(GET_ROBOTS, runRequestGetRobots)
        yield takeEvery(GET_ROBOTS_BY_ALGO, runRequestGetRobotsByAlgo)
        yield takeLatestSafe(GET_ROBOT, runRequestGetRobot)
        yield takeLatestSafe(EXECUTE_ALGO, runRequestExecuteAlgo)
        yield takeLatestSafe(REQUEST_UPSERT_ALERT_EVIDENCE, runRequestUpsertAlertEvidence)
        yield takeLatestSafe(SAVE_ROBOT_PARAMS, runRequestSaveRobotParams)
        yield takeLatestSafe(GET_CURRENT_ALERT_CUSTOMER, runRequestGetAlertCustomer)
        yield takeLatestSafe(GET_CURRENT_ALERT_CUSTOMER_ALERTS, runRequestAlertCustomerAlerts)
        yield takeLatestSafe(GET_CURRENT_ALERT_CUSTOMER_CASES, runRequestAlertCustomerCases)
        yield takeLatestSafe(GET_ALERTS_BY_SAME_TRANSACTION, runRequestAlertsSameTransaction)
        yield takeLatestSafe(REQUEST_DELETE_ALERT_EVIDENCE, runRequestDeleteAlertEvidence)
        yield takeLatestSafe(GET_CURRENT_NUM_OF_ALERTS, runRequestGetCurrentNumberOfAlerts)
        yield takeLatestSafe(GET_USER_DASHBOARD_CONFIG, runRequestGetUserDashboardConfig)
        yield takeLatestSafe(ADD_DASHBOARD_CHART, runAddChart)
        yield takeLatestSafe(REMOVE_DASHBOARD_CHART, runRemoveChart)
        yield takeLatestSafe(GET_ABGA_DATA, getAlertsByGeographicalArea)
        yield takeLatestSafe(GET_ABCT_DATA, getAlertsByCustomerType)
        yield takeLatestSafe(GET_ABSTATUS_DATA, getAlertsByStatus)
        yield takeLatestSafe(GET_WC_DATA, getCasesByStatus)
        yield takeLatestSafe(GET_ABSCEN_DATA, getAlertsByScenarios)
        yield takeLatestSafe(GET_UA_DATA, getUnclosedAlerts)
        yield takeLatestSafe(GET_CURRENT_ALERT, getCurrentAlert)
        yield takeLatestSafe(GET_ALERT_REPORT, getAlertReport)
        yield takeLatestSafe(RELOAD_WIDGETS, reloadWidgets)
        yield takeLatestSafe(GET_ALERTS_HISTORY, getAlertsHistory)
        yield takeLatestSafe(UPSERT_ROBOT, upsertRobot)
        yield takeLatestSafe(DELETE_ROBOT, deleteRobot)
        yield takeLatestSafe(GET_ROBOT_SIMULATION, getRobotSimulation)
        yield takeLatestSafe(GET_ROBOT_ISSUES_HISTORY, getRobotIssuesHistory)
        yield takeLatestSafe(GET_ROBOT_AUDITS, getRobotAudits)
        yield takeLatestSafe(GET_RADAR, getRadar)
        yield takeLatestSafe(GET_CURRENT_RADAR_ISSUES, getRadarIssues)
        yield takeLatestSafe(GET_CURRENT_RADAR_BELOW_RADAR, getRadarBelowRadar)
        yield takeLatestSafe(UPSERT_RADAR, upsertRadar)
        yield takeLatestSafe(GET_RADAR_AUDITS, getRadarAudits)
        yield takeLatestSafe(GET_CURRENT_ALERT_BELOW_RADAR, getCurrentAlertIssues)
        yield takeLatestSafe(CHANGE_CASE_STATUS, changeCaseStatus)
        yield takeLatestSafe(REFRESH_CURRENT_CASE, refreshCurrentCase)
        yield takeLatestSafe(LOCK_CASE, lockCase)
        yield takeLatestSafe(GET_CLUSTERS, getClusters)
        yield takeLatestSafe(GET_REPORTS, runRequestReports)
        yield takeLatestSafe(GET_CLUSTER_ALERTS, runRequestClusterAlerts)
        yield takeLatestSafe(GET_CLUSTER_CUSTOMERS, runRequestClusterCustomers)
        yield takeLatestSafe(GET_WATCH_LISTS, runRequestWatchLists)
        yield takeLatestSafe(GET_WATCHLIST, runRequestWatchList)
        yield takeLatestSafe(GET_WATCHLIST_MEMBERS, runRequestWatchListMembers)
        yield takeLatestSafe(UPSERT_WATCHLIST, upsertWatchlist)
        yield takeLatestSafe(UPSERT_WATCHLIST_MEMBER, upsertWatchlistMember)
        yield takeLatestSafe(DELETE_WATCHLIST_MEMBER, deleteWatchlistMember)
        yield takeLatestSafe(GET_COUNTRIES_RISKS_SETTINGS, runRequestCountriesRisksSettings)
        yield takeLatestSafe(GET_ALL_COUNTRIES_RISKS_SETTINGS, runRequestAllCountriesRisksSettings)
        yield takeLatestSafe(UPDATE_COUNTRY_RISK, updateCountryRisk)
        yield takeLatestSafe(GET_CURRENT_CHART_ISSUES, runRequestChartIssues)
        yield takeLatestSafe(GET_CURRENT_CHART_BELOW_RADAR, runRequestChartBelowRadar)
        yield takeLatestSafe(GET_CURRENT_RADAR_CHART, runRequestRadarChart)
        yield takeLatestSafe(GET_ALERT_SIMILARITY, getAlertSimilarity)
        yield takeLatestSafe(GET_ALERT_NBA, getAlertNextBestAction)
        yield takeLatestSafe(GET_ALERT_COMMENTS, getAlertComments)
        yield takeLatestSafe(ADD_NEW_ALERT_COMMENT, addNewComment)
        yield takeLatestSafe(DELETE_ALERT_COMMENT, deleteComment)
        yield takeLatestSafe(EDIT_ALERT_COMMENT, editComment)
        yield takeLatestSafe(UPDATE_ALERT_VALIDATIONS, updateValidations)
        yield takeLatestSafe(GET_NBA_DATA, getNBAData)
        yield takeLatestSafe(UPDATE_ALERTS_AS_CURRENT, bulkUpdateAlertsAsCurrent)
        yield takeLatestSafe(GET_ALERTS_TRIAGE, getAlertsTriage)
        yield takeLatestSafe(GET_ROBOT_ISSUES, getRobotIssues)
        yield takeLatestSafe(CHANGE_ROBOT_STATUS, changeRobotStatus)
        yield takeLatestSafe(BULK_ASSIGN_ALERTS, bulkAssignAlerts)
        yield takeLatestSafe(GET_CLASSIFICATION_TYPES, getAlgosClassificationTypes)
        yield takeLatestSafe(REQUEST_CASE_AUDITS, runRequestCaseAudits)
        yield takeLatestSafe(REQUEST_UPDATE_ALERTS, runRequestUpdateAlerts)
        yield takeLatestSafe(REQUEST_CASE_SPLIT, caseSplit)
        yield takeLatestSafe(GET_ROBOTS_TRANSACTION_DATA, runRequestGetRobotTransactionData)
    }


function* getAlgosClassificationTypes() {
    const response = yield db('get', 'algos-api/api/classification-types')

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_CLASSIFICATION_TYPES, classificationTypes: response.classificationTypes, count: response.count })
    }
}

function* updateValidations({ currentAlert, validations, oldValue, newValue }) {
    const response = yield db('put', `case-management-api/api/alerts/${currentAlert.identifier}/validations`, { validations: JSON.stringify(validations), oldValue, newValue })

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    }
}

function* changeRobotStatus({ newStatus }) {

    const robot = yield select(getCurrentRobot)
    const { algo, identifier, createdBy, creationTimestamp, updatedBy, updateTimestamp, parameters, executedBy, executionTimestamp, issues, transactionData, ...body } = robot

    const _parameters = parameters.map(p => ({ code: p.code, value: p.value }))

    const response = yield db('put', `algos-api/api/robots/${identifier}`, { ...body, status: newStatus, parameters: _parameters })

    if (response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    } else {
        yield put({ type: GET_ROBOT, robotId: identifier })
    }
}

function* getRobotIssues({ robotId, currentFilter, listId }) {

    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter, true);

    const response = yield db('get', 'case-management-api/api/issues?robotId=' + robotId + '&' + queryParam);

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_ROBOT_ISSUES, issues: response.issues, count: response.count })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_ISSUES_REQUEST_ENDED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getAlertsTriage({ listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_TRIAGE' })

    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter, true);

    const response = yield db('get', `case-management-api/api/alerts/triage?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_ALERTS_TRIAGE, alertsTriage: response.relatedAlerts, count: response.count, max: response.maxRelatedAlerts })
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* bulkUpdateAlertsAsCurrent({ currentAlertId, alertsList }) {

    const response = yield db('put', `case-management-api/api/alerts/${currentAlertId}/bulk` +
        '?alerts=' + alertsList.join('&alerts='));

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ALERTS_UPDATED_AS_CURRENT' })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    }
}

function* getNBAData({ alertId, listId, currentFilter, sameRadar, closestK }) {

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_NBA_DATA' })

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter, true);

    const response = yield db('get', `case-management-api/api/alerts/${alertId}/nba?${queryParam}` +
        `&neighboursToCheck=${closestK}&onlySameRadar=${sameRadar}`);

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({
            type: SET_NBA_DATA,
            neighbours: response.neighbours,
            neighboursCount: response.neighboursCount,
            neighboursNbaCount: response.neighboursNbaCount,
            nba: response.nba
        });
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getAlertComments({ alertId }) {
    const response = yield db('get', `case-management-api/api/alerts/${alertId}/audits?first=0&max=100&type=COMMENTED`);

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({
            type: SET_ALERT_COMMENTS, alertComments: response.audits.map(c =>
                ({ id: c.identifier, author: c.createdBy, authorId: c.createdById, text: c.payload.comment, time: c.creationTimestamp }))
        });
}

function* addNewComment({ alertId, text }) {
    const response = yield db('post', `case-management-api/api/alerts/${alertId}/comment`, { comment: text })

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: GET_ALERT_COMMENTS, alertId })
    }
}

function* deleteComment({ alertId, commentId }) {
    const response = yield db('delete', `case-management-api/api/alerts/${alertId}/comment/${commentId}`)

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: GET_ALERT_COMMENTS, alertId })
    }
}

function* editComment({ alertId, commentId, editedComment }) {
    const response = yield db('put', `case-management-api/api/alerts/${alertId}/comment/${commentId}`, { comment: editedComment })

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: GET_ALERT_COMMENTS, alertId })
    }
}

function* getAlertSimilarity({ listId, alertId, currentFilter }) {

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter, true);

    const alertSimilarity = yield db('get', `case-management-api/api/alerts/${alertId}/similar-alerts?${queryParam}`);
    if (alertSimilarity === undefined || alertSimilarity.hasOwnProperty('errorCode') || alertSimilarity.hasOwnProperty('errors')) {
        yield handlerError(alertSimilarity);
    }
    else {
        alertSimilarity.alerts = alertSimilarity.alerts.map(a => ({ ...a, identifier: a.alert.identifier, alert: a.alert }))
        yield put({ type: SET_CURRENT_ALERT_SIMILARITY, alertSimilarity })
    }
}

function* getAlertNextBestAction({ alertId }) {
    const response = yield db('get', `case-management-api/api/alerts/${alertId}/nba?neighboursToCheck=10`)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_ALERT_NBA, nba: response.nba })
    }
}

function* runRequestRadarChart({ algoCode, settings, date }) {
    // add 30 days to date
    const d = new Date(date);
    d.setDate(d.getDate() + 30);
    const stringDate = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
    const response = yield db('post', 'case-management-api/api/radars/simulate-count-by-day', {
        algoCode: algoCode,
        severity: settings.severity,
        amountMin: settings.quantity[0],
        amountMax: settings.quantity[1],
        paymentsMin: settings.numPayments[0],
        paymentsMax: settings.numPayments[1],
        countries: settings.countries,
        initDate: stringDate + 'T00:00:00'
    })

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    } else {
        yield put({ type: SET_CURRENT_RADAR_CHART, chartValues: response.simulatedRadar.countList })
    }
}

function* throwErrorMessage(error) {
    const translationFn = t;
    yield put({
        type: OPEN_NOTIFICATION, data: {
            type: 'error', message: error.message ? error.message :
                translationFn('genericErrorMessage')
        }
    })
}

function* handlerError(response) {
    const translationFn = t;
    if (response) {
        if (response.errors && response.errors.length > 0) {
            yield all(response.errors.map(error => throwErrorMessage(error)))
        }
        else {
            yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: response.message || response.detail } })
        }
    }
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: translationFn('genericErrorMessage') } })
    }
}

function* getCurrentAlertIssues({ currentFilter, listId, radarId }) {

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);
    const response = yield db('get', `case-management-api/api/radars/${radarId}/below-the-radar?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_ALERT_BELOW_RADAR, belowRadarData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ALERT_ISSUES_LOADED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestChartIssues({ radarId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_CHART_ISSUES' })
    const response = yield db('get', `case-management-api/api/radars/${radarId}/issues?sortingColumn=CREATION_TIMESTAMP&sortingDirection=DESC&first=0&max=100`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        const issuesChart = response.issues.map(item => ({
            amount: item.amount,
            creationTimestamp: item.creationTimestamp,
            identifier: item.identifier,
            transactionCount: item.transactionCount,
            severity: item.severity
        }))
        yield put({ type: SET_CURRENT_CHART_ISSUES, issuesChart })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}
function* runRequestChartBelowRadar({ radarId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_CHART_BELOW_RADAR' })
    const response = yield db('get', `case-management-api/api/radars/${radarId}/below-the-radar?sortingColumn=CREATION_TIMESTAMP&sortingDirection=DESC&first=0&max=100`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        const belowRadarIssues = response.issues.map(item => ({
            amount: item.amount,
            creationTimestamp: item.creationTimestamp,
            identifier: item.identifier,
            transactionCount: item.transactionCount,
            severity: item.severity
        }))
        yield put({ type: SET_CURRENT_CHART_BELOW_RADAR, belowRadarIssues })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* updateCountryRisk({ countryRisk }) {
    const { identifier, ...body } = countryRisk;
    yield put({ type: ADD_LOADING_COUNTRY_RISK_ID, countryRiskId: identifier })
    const response = yield db('put', `algos-api/api/countries-risks/${identifier}`, body);
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    // SUCCESS
    else {
        yield put({ type: GET_COUNTRIES_RISKS_SETTINGS, listId: 'risksCountriesList' })
    }

    yield put({ type: REMOVE_LOADING_COUNTRY_RISK_ID, countryRiskId: identifier })
}

function* runRequestCountriesRisksSettings({ listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_COUNTRIES_RISKS' })
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);
    const response = yield db('get', 'algos-api/api/countries-risks?' + queryParam);
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    // SUCCESS
    else {
        yield put({ type: SET_COUNTRIES_RISKS_SETTINGS, countriesRisksData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestAllCountriesRisksSettings() {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_COUNTRIES_RISKS' })
    const response = yield db('get', 'algos-api/api/countries-risks?first=1&max=100');

    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    // SUCCESS
    else {
        if(response.count > 100){
            for (let i = 1; i < Math.ceil(response.count / 100); i++){
                const res = yield db('get', `algos-api/api/countries-risks?first=${100*i}&max=100`)
                response.countriesRisks.push(...res.countriesRisks)
            }
        }
        yield put({ type: SET_COUNTRIES_RISKS_SETTINGS, countriesRisksData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* upsertWatchlist({ watchlistData }) {
    const { identifier, ...body } = watchlistData;
    let response;
    // UPDATE
    if (identifier)
        response = yield db('put', `algos-api/api/watch-lists/${identifier}`, body);
    // INSERT
    else
        response = yield db('post', 'algos-api/api/watch-lists', body);
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    // SUCCESS
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'WATCHLIST_UPSERTED' })
        const translationFn = t;
        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn(identifier ? 'successfullyUpdated' : 'successfullyInserted') }
        })
        if (identifier) yield put({ type: GET_WATCHLIST, watchListId: identifier })
        else yield put({ type: GET_WATCH_LISTS, listId: 'watchListList' })
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* upsertWatchlistMember({ watchlistMemberData, watchlistId }) {
    const { identifier, ...body } = watchlistMemberData;
    let response;
    // UPDATE
    if (identifier) {
        response = yield db('put', `algos-api/api/watch-lists/${watchlistId}/members/${identifier}`, body);
    }
    // INSERT
    else {
        response = yield db('post', `algos-api/api/watch-lists/${watchlistId}/members`, body);
    }
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    // SUCCESS
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'WATCHLIST_MEMBER_UPSERTED' })
        const translationFn = t;
        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn(identifier ? 'successfullyUpdated' : 'successfullyInserted') }
        })
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    yield put({ type: GET_WATCHLIST_MEMBERS, watchListId: watchlistId, listId: 'watchlistMembersList' })
}

function* deleteWatchlistMember({ watchlistMemberId, watchlistId }) {
    let response;

    response = yield db('delete', `algos-api/api/watch-lists/${watchlistId}/members/${watchlistMemberId}`)
   
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    // SUCCESS
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'WATCHLIST_MEMBER_UPSERTED' })
        const translationFn = t;
        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn('successfullyDeleted') }
        })
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    yield put({ type: GET_WATCHLIST_MEMBERS, watchListId: watchlistId, listId: 'watchlistMembersList' })
}

function* runRequestWatchList({ watchListId }) {

    const response = yield db('get', `algos-api/api/watch-lists/${watchListId}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_WATCHLIST, watchListInfo: response.watchList })
    }
}

function* runRequestWatchListMembers({ watchListId, listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_WATCH_LIST_MEMBERS' })

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);

    const response = yield db('get', `algos-api/api/watch-lists/${watchListId}/members?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_WATCHLIST_MEMBERS, membersData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestWatchLists({ listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_WATCH_LISTS' })

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);

    const response = yield db('get', `algos-api/api/watch-lists?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_WATCH_LISTS, watchListsData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestClusterCustomers({ clusterId, listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_CUSTOMER_ALERT_ISSUES' })
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);
    const response = yield db('get', `case-management-api/api/kmdata/${clusterId}/cluster-customers?${queryParam === '' ? 'first=0&max=10' : queryParam}`);
    if (!response || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({
            type: SET_CURRENT_CLUSTER_CUSTOMERS,
            currentClusterCustomers: {
                customers: response.customers,
                count: response.count
            },
            currentClusterId: clusterId
        });
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestClusterAlerts({ clusterId, listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_CUSTOMER_ALERT_ISSUES' })
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);
    const response = yield db('get', `case-management-api/api/kmdata/${clusterId}/cluster-alerts?${queryParam === '' ? 'first=0&max=10' : queryParam}`);
    if (!response || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({
            type: SET_CURRENT_CLUSTER_ALERTS,
            currentClusterAlerts: {
                alerts: response.alerts,
                count: response.count
            },
            currentClusterId: clusterId
        });
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestReports({ listId, currentFilter }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_REPORTS' })
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);
    const response = yield db('get', 'case-management-api/api/alerts/reports?' + queryParam)
    if (response && !(response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))) {
        yield put({ type: SET_REPORTS, reportsData: { reports: response.reports, count: response.count } })
    }
    else {
        yield handlerError(response);
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getClusters({ numOfClusters, column1, column2, filters }) {
    const query = []
    if (filters.owner)
        query.push('AssigneeId=' + filters.owner);
    if (filters.status)
        query.push('status=' + filters.status)
    if (filters.reporterName)
        query.push('algoName=' + filters.reporterName)
    if (filters.dateRange && filters.dateRange.start)
        query.push('createdDateFrom=' + filters.dateRange.start.format("YYYY-MM-DD") + 'T00:00:00')
    if (filters.dateRange && filters.dateRange.end)
        query.push('createdDateTo=' + filters.dateRange.end.format("YYYY-MM-DD") + 'T00:00:00')
    if (filters.severity[0] && filters.severity[0] !== '')
        query.push('severityMin=' + filters.severity[0])
    if (filters.severity[1] && filters.severity[1] !== '')
        query.push('severityMax=' + filters.severity[1])
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_CLUSTERS' })
    const response = yield db('post', 'case-management-api/api/kmdata/executes-by-params?' +
        query.join('&'), { clusterCount: numOfClusters, column1, column2 })
    if (response && !(response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))) {
        yield put({ type: SET_CLUSTERS, clustersData: response })
    }
    else {
        yield handlerError(response);
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* lockCase({ caseId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'CREATING_NEW_CASE_AND_BLOCKING_CURRENT' })

    const response = yield db('post', `case-management-api/api/cases/${caseId}/lock`)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    } else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'CASE_STATUS_CHANGED' })

        const currentCase = yield db('get', `case-management-api/api/cases/${caseId}`)
        yield put({ type: SET_CURRENT_CASE, currentCase: currentCase.case })
    }
}

function* changeCaseStatus({ currentCase, newStatus }) {

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'CHANGING_CASE_STATUS' })
    const res = yield db('put', 'case-management-api/api/cases/' + currentCase.identifier, {
        status: newStatus,
        severity: currentCase.severity
    })

    if (res === undefined || res.hasOwnProperty('errorCode') || res.hasOwnProperty('errors')) {
        yield handlerError(res);
    }
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'CASE_STATUS_CHANGED' })
        yield put({ type: REFRESH_CURRENT_CASE, caseId: currentCase.identifier })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* refreshCurrentCase({ caseId }) {
    const response = yield db('get', 'case-management-api/api/cases/' + caseId);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_CASE, currentCase: response.case_ })
    }
}

function* getRadarAudits({ radarId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_RADAR_AUDITS' })
    const response = yield db('get', `case-management-api/api/radars/${radarId}/audits?sortingColumn=CREATION_TIMESTAMP&sortingDirection=ASC`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_RADAR_AUDITS, audits: response.audits })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* upsertRadar({ radarId, algoCode, radarSettings }) {

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'SAVING_RADAR' })
    const translationFn = t;
    let error;
    let notificationMsg = '';

    // if is an existing radar (edition)
    if (radarId) {
        const { countries, quantity, numPayments, severity } = radarSettings;
        const settings = {
            algoCode: algoCode,
            amountMax: quantity[1],
            amountMin: quantity[0],
            countries: countries,
            paymentsMax: numPayments[1],
            paymentsMin: numPayments[0],
            severity: severity
        }
        const response = yield db('put', 'case-management-api/api/radars/' + radarId, { ...settings });
        if (response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
            error = response.errorCode;
            notificationMsg = response.message;
        }
        else {
            yield put({ type: SET_CURRENT_RADAR, radar: response })
            notificationMsg = translationFn('radarUpdatedSuccessfully')
        }
    }
    // if is a new radar (creation)
    else {
        // TODO: create new radar
    }

    if (!error) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: notificationMsg, type: 'success' } })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'RADAR_UPSERT_SUCCESS' })
    }
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: notificationMsg, type: 'error' } })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'RADAR_UPSERT_ERROR' })
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getRadarBelowRadar({ currentFilter, listId, radarId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_RADAR_BELOW_RADAR' })
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter, true);
    const response = yield db('get', `case-management-api/api/radars/${radarId}/below-the-radar?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_RADAR_BELOW_RADAR, belowRadarData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getRadarIssues({ currentFilter, listId, radarId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_RADAR_ISSUES' })
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter, true);
    const response = yield db('get', `case-management-api/api/radars/${radarId}/issues?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_RADAR_ISSUES, issuesData: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getRadar({ radarId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_RADAR' })
    const response = yield db('get', 'case-management-api/api/radars/' + radarId);
    if (response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CURRENT_RADAR, radar: response })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'RADAR_SUCCESSFULLY_LOADED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* getRobotAudits({ robotId, listId, currentFilter }) {
    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);
    const response = yield db('get', `algos-api/api/robots/${robotId}/audits?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_ROBOT_AUDITS, audits: response.audits, count: response.count })
    }
}

function* getRobotIssuesHistory({ robot }) {
    const response =
        yield db('get', 'case-management-api/api/issues/count-by-day-last-month?robotId=' + robot.identifier)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_ROBOT_ISSUES_HISTORY, issuesHistory: response })
    }
}

function* getRobotSimulation({ paramValues, selectedAlgo, settings, date }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_SIMULATION_STARTED' })

    const { scheduleFrequency, scheduleInterval } = settings

    const robotExecutionParameters = [];
    selectedAlgo.parameters.map(param => {
        robotExecutionParameters.push({
            code: param.code,
            type: param.type,
            value: Array.isArray(paramValues[param.code]) ? JSON.stringify(paramValues[param.code]) : paramValues[param.code]
        })
        return param;
    })

    const response = yield db('post', 'algos-api/api/robots/simulate?' +
        `algo-id=${selectedAlgo.identifier}&scheduleInterval=${scheduleInterval}&scheduleFrequency=${scheduleFrequency}&date=${date.toISOString()}`,
        { robotExecutionParameters });

    if (response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_SIMULATION_ERROR' })
    }
    else {
        if (response.issues === 0) {
            yield put({ type: OPEN_NOTIFICATION, data: { message: 'no issues generated', type: 'info' } })
        }
        yield put({ type: SET_ROBOT_SIMULATION, simulationResult: response.issues })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_SIMULATION_SUCCEED' })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* upsertRobotTransactionData({method, robotId, transactionValues}) {
    const endpoint = `algos-api/api/robots/${robotId}/algo-selector`
    const customerTypes = transactionValues?.customerTypes || []
    const operationCodes = transactionValues?.operationCodes || []
    const paymentMethods = transactionValues?.paymentMethods || []

    const response = yield db(method, endpoint, { customerTypes, operationCodes, paymentMethods })
    if(response?.errorCode || response?.errors) {
        yield handlerError(response)
    }
}

function* upsertRobot({ settings, paramValues, transactionValues }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING' })

    const translationFn = t;
    let error
    let notificationMsg = ''

    const parameters = Object.keys(paramValues).map(key => ({ code: key, value: Array.isArray(paramValues[key]) ? JSON.stringify(paramValues[key]) : paramValues[key] }))

    const { algo, identifier, createdBy, creationTimestamp, updatedBy, updateTimestamp, executedBy, executionTimestamp, issues, transactionData, ...settings2save } = settings
    settings2save.severity = Number(settings2save.severity)

    if (identifier) {
        const response = yield db('put', 'algos-api/api/robots/' + identifier, { ...settings2save, parameters })
        if (response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
            error = response.errorCode
            notificationMsg = response.message
        }
        else {
            yield call(upsertRobotTransactionData, { method: 'put', robotId: identifier, transactionValues })
            notificationMsg = translationFn('robotUpdatedSuccessfully')
        }
    } else {
        settings2save.status = 'ENABLED'

        const response = yield db('post', 'algos-api/api/robots', { ...settings2save, parameters })

        if (response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
            error = response.errors
            notificationMsg = response.message
        }
        else {
            const robotId = response?.robot?.identifier
            yield call(upsertRobotTransactionData, { method: 'post', robotId, transactionValues })
            notificationMsg = translationFn('robotCreatedSuccessfully')
        }
    }

    if (!error) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: notificationMsg, type: 'success' } })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_UPSERT_SUCCESS' })
    }
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: notificationMsg, type: 'error' } })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_UPSERT_ERROR' })
    }
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* deleteRobot({ robotId }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING' })

    // We must delete the algo selector before deleting the robot
    const deleteAlgoSelectorResponse = yield db('delete', `algos-api/api/robots/${robotId}/algo-selector`)

    if(deleteAlgoSelectorResponse.errors) {
        return yield handlerError(deleteAlgoSelectorResponse)
    }

    const response = yield db('delete', `algos-api/api/robots/${robotId}`)

    if (!response.errors) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'Robot successfully deleted', type: 'success' } })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_DELETED' })
    }
    else {
        yield handlerError(response)
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    }
}

function* getAlertsHistory({ algoName }) {
    const response = yield db('get', 'case-management-api/api/alerts/count-by-day-last-month?algoName=' + algoName);

    if (response.countList) {
        const result = {}
        const accum = {}
        response.countList.map(item => {
            result[item.value.substr(0, 11)] = (result[item.value] || 0) + item.count;
            accum[item.value.substr(0, 11)] = (accum[item.value] || 0) + item.accumulatedCount;
            return item;
        })

        yield put({ type: SET_ALERTS_HISTORY, alertsHistory: { count: result, accum: accum } })
    }
}

function* reloadWidgets({ date }) {

    yield put({ type: SET_WIDGET_DATA, widget: 'ABGA', data: undefined });
    yield put({ type: GET_ABGA_DATA, date: date?.toISOString().split('.')[0] });
    yield put({ type: SET_WIDGET_DATA, widget: 'ABCT', data: undefined });
    yield put({ type: GET_ABCT_DATA, date: date?.toISOString().split('.')[0] });
    yield put({ type: SET_WIDGET_DATA, widget: 'ABSTATUS', data: undefined });
    yield put({ type: GET_ABSTATUS_DATA, date: date?.toISOString().split('.')[0] });
    yield put({ type: SET_WIDGET_DATA, widget: 'WC', data: undefined });
    yield put({ type: GET_WC_DATA, date: date?.toISOString().split('.')[0] });
    yield put({ type: SET_WIDGET_DATA, widget: 'ABSCEN', data: undefined });
    yield put({ type: GET_ABSCEN_DATA, date: date?.toISOString().split('.')[0] });
    yield put({ type: SET_WIDGET_DATA, widget: 'UA', data: undefined });
    yield put({ type: GET_UA_DATA, date: date?.toISOString().split('.')[0] });
}

function* getAlertReport({ alertId, download = true }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'REPORT_GENERATION' })

    const response = yield db('post', `case-management-api/api/alerts/${alertId}/report`)

    if (response.report) {
        if (download) {
            const currentAlertFromStore = yield select(currentAlertStore);
            if (currentAlertFromStore && currentAlertFromStore.report) window.URL.revokeObjectURL(currentAlertFromStore.report)
            const blob = b64ToBlob(response.report.data)
            const reportURL = window.URL.createObjectURL(blob);
            yield put({ type: SET_ALERT_REPORT, reportURL })
        }

        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'REPORT_READY' })
    }
}

function* getCurrentAlert({ alertId }) {

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'GETTING_CURRENT_ALERT' })
    const response = yield db('get', `case-management-api/api/alerts/${alertId}`)

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    } else {
        
        const transactionsObject = {};

        response.alert.transactions?.map(transaction => {
            if (!transactionsObject.hasOwnProperty(transaction.type)) transactionsObject[transaction.type] = [];

            transactionsObject[transaction.type].push({
                ...transaction,
            });
            return transaction
        });

        yield put({ type: SET_CURRENT_ALERT_CUSTOMER_TRANSACTIONS, transactions: transactionsObject })

        yield put({ type: SET_CURRENT_ALERT, currentAlert: response.alert });

        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'CURRENT_ALERT_SET' })
    }
}

function* getAlertsByGeographicalArea({ date }) {

    const response = yield db('get',
        'case-management-api/api/alerts/count-by-column?alertCountByColumn=COUNTRY' +
        (date ? '&dateFrom=' + date : ''))

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        const newData = [['Country', 'Alerts']]

        response.countList.map(item => {
            const aux = [getISO2FromISO3CountryCode(item.value), item.count]
            if (getISO2FromISO3CountryCode(item.value)) newData.push(aux);
            return item;
        })

        yield put({ type: SET_WIDGET_DATA, widget: 'ABGA', data: newData })
    }
}

function* getAlertsByCustomerType({ date }) {

    const response = yield db('get', 'case-management-api/api/alerts/count-by-column?alertCountByColumn=CUSTOMER_TYPE&alertCountByColumn=ALGO_NAME' + (date ? '&dateFrom=' + date : ''))
    const algosRes = yield db('get', 'algos-api/api/algos')

    const scenarios = algosRes.algos.map(algo => algo.name)

    const data = {}
    if (response.countList) {
        response.countList.map(item => {
            if (!data.hasOwnProperty(item.value)) {
                data[item.value] = new Array(scenarios.length).fill(0)
            }
            const index = scenarios.indexOf(item.value1)
            if (index !== -1) {
                data[item.value][index] = item.count
            }
            return item
        })
    }

    yield put({ type: SET_WIDGET_DATA, widget: 'ABCT', data: { value: data, labels: scenarios } })
}

function* getAlertsByStatus({ date }) {

    const response = yield db('get', 'case-management-api/api/alerts/count-by-column?alertCountByColumn=STATUS' + (date ? '&dateFrom=' + date : ''))
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    } else {
        const statusNew = response.countList.find(status => status.value === 'NEW')
        const statusOpened = response.countList.find(status => status.value === 'OPEN')
        const statusClosed = response.countList.find(status => status.value === 'CLOSED')

        yield put({
            type: SET_WIDGET_DATA,
            widget: 'ABSTATUS',
            data: [
                statusNew?.count,
                statusOpened?.count,
                statusClosed?.count
            ]
        })
    }
}

function* getCasesByStatus({ date }) {

    const response = yield db('get', 'case-management-api/api/cases/count-by-column?caseCountByColumn=STATUS' + (date ? '&dateFrom=' + date : ''))

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    } else {
        const statusNew = response.countList.find(status => status.value === 'NEW')
        const statusOpened = response.countList.find(status => status.value === 'OPEN')
        const statusClosed = response.countList.find(status => status.value === 'CLOSED')
        const statusLocked = response.countList.find(status => status.value === 'LOCKED')

        yield put({
            type: SET_WIDGET_DATA,
            widget: 'WC',
            data: [
                statusNew?.count,
                statusOpened?.count,
                statusClosed?.count,
                statusLocked?.count
            ]
        })
    }
}

function* getAlertsByScenarios({ date }) {

    const response = yield db('get', 'case-management-api/api/alerts/count-by-column?alertCountByColumn=ALGO_NAME' + (date ? '&dateFrom=' + date : ''))
    const algosRes = yield db('get', 'algos-api/api/algos');

    const scenarios = algosRes.algos.map(algo => algo.name);

    const values = [];
    scenarios.map(algo => {
        const v = response.countList.find(item => item.value === algo)
        if (v) {
            values.push(v.count);
        }
        else values.push(0);
        return algo;
    })

    yield put({ type: SET_WIDGET_DATA, widget: 'ABSCEN', data: { values: { MERCHANT: values }, labels: scenarios } })
}

function* getUnclosedAlerts() {

    const response = yield db('get', 'case-management-api/api/alerts/count-by-period')

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    } else {
        yield put({ type: SET_WIDGET_DATA, widget: 'UA', data: response.countList })
    }
}

function* runRequestGetUserDashboardConfig() {
    const response = yield db('get', 'auth-api/api/profile/settings/ROBOLITICS/DASHBOARD_SETTINGS')

    if (response.hasOwnProperty('setting')) {
        yield put({ type: SET_USER_DASHBOARD_CONFIG, config: JSON.parse(response.setting.value) })
    }
}

function* runAddChart({ code }) {
    const dashboardConfig = yield select(getDashboardConfig)

    const newDashboardConfig = dashboardConfig ? [...dashboardConfig, code] : [code]

    yield db('put', 'auth-api/api/profile/settings/ROBOLITICS/DASHBOARD_SETTINGS',
        { value: JSON.stringify(newDashboardConfig) })
    yield put({ type: SET_USER_DASHBOARD_CONFIG, config: newDashboardConfig  })
}

function* runRemoveChart({ code }) {
    const dashboardConfig = yield select(getDashboardConfig)

    const newDashboardConfig = dashboardConfig.filter(ch => ch !== code)

    yield db('put', 'auth-api/api/profile/settings/ROBOLITICS/DASHBOARD_SETTINGS',
        { value: JSON.stringify(newDashboardConfig) })
    yield put({ type: SET_USER_DASHBOARD_CONFIG, config: newDashboardConfig  })
}

function* runRequestAlertCustomerAlerts({ listId, currentAlert, currentFilter }) {

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);

    const response = yield db('get', `case-management-api/api/alerts/${currentAlert.identifier}/` +
        `related-alerts?customerId=${currentAlert.customerId}&${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_CURRENT_ALERT_CUSTOMER_ALERTS, alerts: response });

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ALERT_SAME_CUSTOMER_ALERTS_LOADED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })

}

function* runRequestAlertCustomerCases({ listId, currentAlert, currentFilter }) {

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'LOADING_ALERT_CASES' })

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);

    const response = yield db('get', `case-management-api/api/cases?customerId=${currentAlert.customerId}&${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_CURRENT_ALERT_SAME_TXN_ALERTS, cases: response });

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestAlertsSameTransaction({ listId, currentFilter, currentAlert }) {

    const listConfig = yield select(listConfigs, listId);
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);

    let transactions = ''
        
    currentAlert.transactions?.forEach(i => {
        transactions += `&transactionId=${i.identifier}`
    })

    const response = yield db(
        'get',
        `case-management-api/api/alerts/${currentAlert.identifier}/related-alerts?${queryParam}${transactions}`);

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_CURRENT_ALERT_SAME_TXN_ALERTS, alerts: response });

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ALERTS_BY_SAME_TXN_LOADED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestSaveRobotParams({ type, ...args }) {

    const { robotId, settings } = args;

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'SAVE_PENDING' })

    for (let i = 0; i < settings.length; ++i) {
        const { robotParameterId, ...body } = settings[i]
        yield db('put', `algos-api/api/robots/${robotId}/parameters/${robotParameterId}`, { ...body })
    }

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_SETTINGS_SAVED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    yield put({ type: OPEN_NOTIFICATION, data: { message: 'Settings successfully saved!', type: 'success' } })
}

function* runRequestExecuteAlgo({ type, ...args }) {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'EXECUTION_PENDING' })

    const response = yield db('post', args.date
        ? `algos-api/api/robots/${args.algoId}/run?date=${args.date}T00%3A00%3A00`
        : `algos-api/api/robots/${args.algoId}/run`);

    yield put({ type: OPEN_NOTIFICATION, data: { message: `${response.issues} new ${response.issues === 1 ? 'alert' : 'alerts'} has been created!`, type: 'info' } })

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ROBOT_EXECUTED' })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* runRequestGetRobot({ type, ...args }) {

    const response = yield db('get', `algos-api/api/robots/${args.robotId}`)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else {
        const robotTransactionDataResponse = yield db('get', `algos-api/api/robots/${args.robotId}/algo-selector`)
        const customerTypes = robotTransactionDataResponse?.customerTypes || []
        const operationCodes = robotTransactionDataResponse?.operationCodes || []
        const paymentMethods = robotTransactionDataResponse?.paymentMethods || []

        yield put({ type: SET_CURRENT_ROBOT, robot: {...response.robot, transactionData: {customerTypes, operationCodes, paymentMethods}} })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    }
}

function* runRequestGetRobotTransactionData() {
    const customerTypesResponse = yield db('get', 'retail-data-api/api/customer-types')
    const operationCodesResponse = yield db('get', 'retail-data-api/api/operation-codes')
    const paymentMethodsResponse = yield db('get', 'retail-data-api/api/payment-methods')

    const isErrorResponse = (r) => !r || r.hasOwnProperty('errorCode') || r.hasOwnProperty('errors')

    if (isErrorResponse(customerTypesResponse)) {
        yield handlerError(customerTypesResponse)
    } else if (isErrorResponse(operationCodesResponse)) {
        yield handlerError(operationCodesResponse)
    } else if (isErrorResponse(paymentMethodsResponse)) {
        yield handlerError(paymentMethodsResponse)
    } else {
        const customerTypes = customerTypesResponse?.customerTypes?.map(c => ({identifier: c.identifier, name: c.name, description: c.description})) || [];
        const operationCodes = operationCodesResponse?.operationCodes?.map(c => ({identifier: c.identifier, name: c.name, description: c.description})) || [];
        const paymentMethods = paymentMethodsResponse?.paymentMethods?.map(c => ({identifier: c.identifier, name: c.name, description: c.description})) || [];

        const robotsTransactionData = { customerTypes, operationCodes, paymentMethods }
        yield put({ type: SET_ROBOTS_TRANSACTION_DATA, robotsTransactionData })
    }
}

function* runRequestGetRobots() {
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })

    const response = yield db('get', 'algos-api/api/robots');
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_ROBOTS, robotsData: response })
}

function* runRequestGetRobotsByAlgo({ algoId, listId, currentFilter }) {
    const listConfig = yield select(listConfigs, listId);

    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter);

    const response = yield db('get', `algos-api/api/algos/${algoId}/robots?${queryParam}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_ROBOTS, robotsData: response, algoId })
}

function* runRequestAlgos() {

    const response = yield db('get', 'algos-api/api/algos')

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    } else {
        response.algos = response.algos.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
        })
        yield call(runRequestGetRobotTransactionData)
        yield put({ type: SET_ALGOS, algosData: response })
    }
}

function* runRequestGetAlertCustomer({ type, ...args }) {
    const response = yield db('get', `retail-data-api/api/customers/${args.customerId}`)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_CURRENT_ALERT_CUSTOMER, currentCustomer: response.customer })
}

function* runRequestCases(args, payload = {}) {
    yield put({ type: CLEAR_CASE_STATUS })

    yield put({ type: SET_CASE_STATUS, caseStatus: 'loading' })
    const listConfig = yield select(listConfigs, payload.listId);
    const queryParam = getQueryParamFromListConfig(listConfig, payload.currentFilter);

    const response = yield db('get', `case-management-api/api/cases?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else {
        yield put({ type: SUCCEED_REQUEST_CASES, cases: response })
        yield put({ type: SET_CASE_STATUS, caseStatus: 'success' })
    }
}

function* runRequestAlerts(args, payload = {}) {
    yield put({ type: CLEAR_CASE_STATUS })

    yield put({ type: SET_CASE_STATUS, caseStatus: 'loading' })

    const listConfig = yield select(listConfigs, payload.listId, true);
    const queryParam = getQueryParamFromListConfig(listConfig, payload.currentFilter);
    const response = yield db('get', `case-management-api/api/alerts?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else {
        yield put({ type: SUCCEED_REQUEST_ALERTS, alerts: response })
        yield put({ type: SET_CASE_STATUS, caseStatus: 'success' })
    }
}

function* runRequestDeleteCase(args, payload = {}) {
    yield put({ type: CLEAR_CASE_STATUS })
    let error = null

    yield db('delete', `case-management-api/api/cases/${payload.caseId}`);
    if (error) {
        yield put({ type: SET_CASE_STATUS, caseError: error })
    }
    else {
        yield put({ type: DELETE_CASE, identifier: payload.caseId })
        yield put({ type: SET_CURRENT_CASE, case: {} })
        yield put({ type: SET_CASE_STATUS, caseStatus: 'closing', caseSuccess: 'Deleted successfully' })
        yield put({ type: REQUEST_CASES, listId: 'casesList', currentFilter: {} })
    }
}

function* runRequestCaseAlerts(args, payload = {}) {
    yield put({ type: CLEAR_CASE_STATUS })

    yield put({ type: SET_CASE_STATUS, caseStatus: 'loading' })

    const listConfig = yield select(listConfigs, payload.listId);
    const queryParam = getQueryParamFromListConfig(listConfig, payload.currentFilter);

    const caseAlerts = yield db('get', `case-management-api/api/cases/${payload.caseId}/alerts?${queryParam}`)
    const currentCase = yield db('get', `case-management-api/api/cases/${payload.caseId}`)

    yield put({ type: SUCCEED_REQUEST_CASE_ALERTS, caseAlerts })
    yield put({ type: SET_CURRENT_CASE, currentCase: currentCase.case })
    yield put({ type: SET_CASE_STATUS, caseStatus: 'success' })
}

function* runRequestCaseAudits({ currentCase }) {
    yield put({ type: CLEAR_CASE_STATUS })

    yield put({ type: SET_CASE_STATUS, caseStatus: 'loading_audits' })

    const response = yield db('get', `case-management-api/api/cases/${currentCase.identifier}/audits?first=0&max=100&` +
        'type=CREATED&type=UPDATED')
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else {
        const { audits, count } = response;
        yield put({ type: SUCCEED_REQUEST_CASE_AUDITS, audits, count })
        yield put({ type: SET_CASE_STATUS, caseStatus: 'success' })
    }
}

function* runRequestAlertAudits(args, payload = {}) {
    yield put({ type: CLEAR_CASE_STATUS })

    yield put({ type: SET_CASE_STATUS, caseStatus: 'loading_audits' })

    const response = yield db('get', `case-management-api/api/alerts/${payload.currentAlert.identifier}/audits?first=0&max=100&` +
        'type=CREATED&type=ACTIVITY_CHANGED&type=RESOLUTION_CHANGED&type=RESOLUTION_CAUSE_CHANGED&type=NBA_RESOLUTION&type=VALIDATIONS_CHANGED&' +
        'type=STATUS_CHANGED&type=ASSIGNEE_CHANGED&type=SEVERITY_CHANGED&type=BULK_UPDATED&type=BULK_SOURCED')
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else {
        yield put({ type: SUCCEED_REQUEST_ALERT_AUDITS, alertAudits: response })
        yield put({ type: SET_CASE_STATUS, caseStatus: 'success' })
    }
}

function* runRequestAlertEvidence(args, payload = {}) {
    let newAlertEvidence = {}

    yield put({ type: CLEAR_CASE_STATUS })

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'loading_evidences' })

    const listConfig = yield select(listConfigs, payload.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, payload.currentFilter)

    const alertEvidence = yield db('get', `case-management-api/api/alerts/${payload.alertId}/evidences?${queryParam}`)

    if (alertEvidence) {
        const { evidences } = alertEvidence;
        newAlertEvidence = evidences.map(evidence => {
            const blob = evidence.data ? b64ToBlob(evidence.data) : ''
            const newData = evidence.data ? window.URL.createObjectURL(blob) : ''
            return { ...evidence, data: { data: newData, type: blob.type } }
        })
    }

    yield put({ type: SUCCEED_REQUEST_ALERT_EVIDENCE, alertEvidence: { ...alertEvidence, alertEvidences: newAlertEvidence } })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'success' })

}

function* runRequestUpsertAlertEvidence({ alertId, evidence }) {
    yield put({ type: CLEAR_CASE_STATUS })
    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'SAVING_EVIDENCE' })
    const { identifier, type, ...evidenceBody } = evidence
    const body = { ...evidenceBody }

    if (body.data.match(/\b(https?:\/\/\S*\b)/g)) {
        const newData = yield call(convertBlobToBase64, body.data)
        body.data = newData
    }
    let response
    // UPDATE
    if (identifier) {
        response = yield db('put', `case-management-api/api/alerts/${alertId}/evidences/${identifier}`, body)
    } else {
        // INSERT
        response = yield db('post', `case-management-api/api/alerts/${alertId}/evidences`, body)
        yield put({ type: SET_CURRENT_EVIDENCE, evidence: response.evidence })
    }
    // ERROR
    if (response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    }
    // SUCCESS
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'EVIDENCE_UPSERTED' })
        yield put({ type: REQUEST_ALERT_EVIDENCE, alertId })
        const translationFn = t
        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn(identifier ? 'notification.EVIDENCE_SUCCESS_UPDATED' : 'notification.EVIDENCE_SUCCESS_INSERTED') }
        })
    }
}

function* runRequestDeleteAlertEvidence({ type, ...args }) {
    yield put({ type: CLEAR_CASE_STATUS })

    let error = null
    const { evidenceId, identifier } = args

    yield db('delete', `case-management-api/api/alerts/${identifier}/evidences/${evidenceId}`)
    if (error) {
        yield put({ type: SET_CASE_STATUS, caseError: error })
    } else {
        const translationFn = t

        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn('notification.EVIDENCE_SUCCESS_DELETED') }
        })
        yield put({ type: SET_CURRENT_EVIDENCE, evidence: {} })
        yield put({ type: REQUEST_ALERT_EVIDENCE, alertId: identifier })
    }
}

function* runRequestGetCurrentNumberOfAlerts({ algoName }) {

    const response = yield db('GET', `case-management-api/api/alerts?algoName=${algoName}`);
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors'))
        yield handlerError(response);
    else
        yield put({ type: SET_CURRENT_NUM_OF_ALERTS, numOfAlerts: response.count })

    yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
}

function* bulkAssignAlerts({ alertIdsList, ownerId }) {
    const response = yield db('put', `case-management-api/api/alerts/bulk/assignee` +
        `?assignee-id=${ownerId}&alerts=` + alertIdsList.join('&alerts='));

    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response);
    }
    else {
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: 'ALERTS_ASSIGNED' })
        yield put({ type: SET_CASE_MANAGEMENT_STATUS, status: '' })
    }
}

function* bulkAlertAction(body) {
    const response = yield db('put', `case-management-api/api/alerts/bulk/actions`, body)
    return response
}

function* runRequestUpdateAlerts({ actionType, comment, assigneeId, alertIds, from }) {
    const context = contextTypes.UPDATE_ALERTS
    const id = yield from === 'cases'
        ? select(getCurrentCaseId)
        : select(getCurrentAlertId)
    const translationFn = t

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context, value: true })

    let status, activity, resolution
    switch (actionType) {
        case 'ASSIGN':
            activity = 'INVESTIGATION'
            status = 'OPEN'
            resolution = 'PENDING'
            break
        case 'ESCALATION':
            activity = 'ESCALATION'
            status = 'OPEN'
            resolution = 'PENDING'
            break
        case 'APPROVE':
            activity = 'RESOLVED'
            status = 'CLOSED'
            resolution = 'APPROVED'
            break
        case 'REVIEWED':
            activity = 'RESOLVED'
            status = 'CLOSED'
            resolution = 'REVIEWED'
            break
        case 'REJECT':
            activity = 'RESOLVED'
            status = 'CLOSED'
            resolution = 'REJECTED'
            break
        case 'CONFISCATE':
            activity = 'RESOLVED'
            status = 'CLOSED'
            resolution = 'CONFISCATED'
            break
        default:
    }

    const response = yield* bulkAlertAction({ alertIds, status, activity, resolution, assigneeId, comment })

    if (from === 'cases') {
        yield put({ type: REQUEST_CASE_ALERTS, caseId: id, listId: 'caseAlertsList' })
    } else if (from === 'alert') {
        yield put({ type: GET_CURRENT_ALERT, alertId: id })
        yield put({ type: GET_ALERT_COMMENTS, alertId: id })
    } else {
        yield put({ type: REQUEST_ALERTS, listId: 'alertsList' })
    }
    yield put({ type: SET_CASE_STATUS, caseStatus: 'FORCE_CLEAN_CHECKS' })
    yield put({ type: CLEAR_CASE_STATUS })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context })

    const notification = response?.errors 
        ? { message: response.errors?.[0]?.message, type: 'error' }
        : { message: translationFn('alertsUpdatedSuccessfully'), type: 'success' }
        
    yield put({ type: OPEN_NOTIFICATION, data: notification })
}

function* caseSplit({ caseId, alertIds }) {
    const translationFn = t
    const context = contextTypes.UPDATE_ALERTS

    const res = yield db('post', `case-management-api/api/cases/${caseId}/split`, alertIds)

    if (res === undefined || res.hasOwnProperty('errorCode') || res.hasOwnProperty('errors')) {
        yield handlerError(res);
    } else {
        yield put({ type: REQUEST_CASE_ALERTS, caseId, listId: 'caseAlertsList' })
        yield put({ type: SET_CASE_STATUS, caseStatus: 'FORCE_CLEAN_CHECKS' })
        yield put({ type: CLEAR_CASE_STATUS })
        yield put({ type: SET_API_STATUS, action: 'CLEAR', context })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('alertsUpdatedSuccessfully'), type: 'success' } })
    }
}

export default function* rootSaga() {
    yield all([fork(watcher())])
}