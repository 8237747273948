
export const REQUEST_ORGS = 'REQUEST_ORGS';
export const SUCCEED_REQUEST_ORGS = 'SUCCEED_REQUEST_ORGS';
export const REQUEST_ORG = 'REQUEST_ORG';
export const SET_CURRENT_ORG = 'SET_CURRENT_ORG';

export const REQUEST_ADD_OR_UPDATE_ORG = 'REQUEST_ADD_OR_UPDATE_ORG';
export const REQUEST_DELETE_ORG = 'REQUEST_DELETE_ORG';
export const ADD_ORG = 'ADD_ORG';
export const UPDATE_ORG = 'UPDATE_ORG';
export const DELETE_ORG = 'DELETE_ORG';
export const CLEAR_CREATED_ORG = 'CLEAR_CREATED_ORG';

export const REQUEST_ADD_ADMIN = 'REQUEST_ADD_ADMIN';
export const REQUEST_DELETE_ADMIN = 'REQUEST_DELETE_ADMIN';
export const REQUEST_UPDATE_ADMIN_STATUS = 'REQUEST_UPDATE_ADMIN_STATUS';
export const REQUEST_ADMIN_PWD_CHANGE = 'REQUEST_ADMIN_PWD_CHANGE';

export const SET_SYSADMIN_STATUS = 'SET_SYSADMIN_STATUS';
export const CLEAR_SYSADMIN_STATUS = 'CLEAR_SYSADMIN_STATUS';

export const SET_SYSADMIN_FILTERS = 'SET_SYSADMIN_FILTERS';

export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const SET_APPLICATIONS = 'SET_APPLICATIONS'
