import styled from 'styled-components'
import CloudIcon from '@material-ui/icons/CloudUpload'
import React from 'react'

const dashedGrey =  props => `2px dashed ` + props.theme.colors.grey500
const dashedMain = props => `2px dashed ` + props.theme.colors.main400

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border: ${props => props.isDragActive ? props.content ? dashedMain : dashedMain : props.content ? '2px solid transparent' : dashedGrey};
    color: ${props => props.isDragActive ? props.theme.colors.main400 : props.theme.colors.grey500};
    outline: none;
    // transition: border .24s ease-in-out;
    height: ${props => props.height ? props.height : props.content ? 'auto' : '350px'};
    cursor: ${props => props.content ? 'default' : 'pointer'};
`
const Paragraph = styled.p`
    font-size: ${props => props.fontSize ? props.fontSize : '20px'};
    margin-top: 0px;
    text-align: center;
`

const StyledIcon = styled(({fontSizeIcon, ...rest}) => <CloudIcon {...rest}/>)`
    &&{
        font-size: ${props => props.fontSizeIcon ? props.fontSizeIcon : '40px'};
    }
`

const ContentWrapper = styled.div`
    width: 100%
`

export { Container, Paragraph, StyledIcon, ContentWrapper }