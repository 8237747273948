import React, { useState } from 'react';
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Eye from '@material-ui/icons/RemoveRedEye'
import Input from "@material-ui/core/Input/Input";
import InputAdornment from '@material-ui/core/InputAdornment';

const PasswordInput = (props) => {

    const [type, setType] = useState('password')

    return(
        <Container className={props.className}>
            <InputStyled {...props} type={type} fullWidth 
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onMouseDown={()=>setType('text')}
                            onMouseUp={()=>setType('password')}
                            >
                            <EyeFilled />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Container>
    )
}

const Container = styled.div`display: block; position: relative; width: 100%;`
const InputStyled = styled(Input)`&&{ border-color: ${props => props.theme.colors.grey500}; }`
const EyeFilled = styled(Eye)`&&{ fill: ${props => props.theme.colors.grey500}; }`

export default PasswordInput
