import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Login from '../login/page/Container'
import MCBGroups from '../login/MCBGroups/Container'
import { SET_LANGUAGE } from '../login/store/actionTypes'
import Forbidden403 from './../403'
import E404 from './../404/E404Container'
import Home from './../apps/Home/HomeContainer'
import PrivateRoute from './PrivateRoute'
import ScrollToTop from './ScrollToTop'

/*
* Lazy loading
*/
const Dokstor = React.lazy(() => import('../apps/dokstor/pages/DokstorContainer'))
const Admin = React.lazy(() => import('../apps/Admin/pages/AdminContainer'))
const SuperAdmin = React.lazy(() => import('../apps/SuperAdmin/pages/SuperAdminContainer'))
const ReferenceData = React.lazy(() => import('./../apps/referenceData/pages/ReferenceDataContainer'))
const CaseManagement = React.lazy(() => import('./../apps/caseManagement/pages/CaseManagementContainer'))

const mapStateToProps = store => ({
    language: store.auth.language
})

const mapDispatchToProps = dispatch => ({
    setLanguage: (language) => dispatch({ type: SET_LANGUAGE, language }),
})

class RoboloticsRouter extends React.Component {

    render() {
        return (<Router>
            <>
                <ScrollToTop />
                <Switch>
                    <Route
                        exact path="/"
                        render={props => (<Login {...props} />)}
                    />
                    <Route
                        exact path="/login-mcb"
                        render={props => (<MCBGroups {...props} />)}
                    />
                    <Route
                        path="/admin"
                        render={props => ((<PrivateRoute
                            component={Admin}
                            {...props}
                        />))}
                    />
                    <Route
                        path="/sysadmin"
                        render={props => ((<PrivateRoute
                            component={SuperAdmin}
                            {...props}
                        />))}
                    />
                    <Route
                        exact path="/home"
                        render={props => ((<PrivateRoute
                            component={Home}
                            {...props}
                        />))}
                    />
                    <Route
                        path="/dokstor"
                        render={props => ((<PrivateRoute
                            component={Dokstor}
                            {...props}
                        />))}
                    />
                    <Route
                        path="/reference-data"
                        render={props => ((<PrivateRoute
                            component={ReferenceData}
                            {...props}
                        />))}
                    />
                    <Route
                        path="/case-management"
                        render={props => ((<PrivateRoute
                            component={CaseManagement}
                            {...props}
                        />))}
                    />
                    <Route
                        path="/forbidden"
                        render={props => ((<Forbidden403
                            {...props}
                        />))}
                    />
                    <Route render={() => <E404 />} />
                </Switch>
            </>
        </Router>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoboloticsRouter)