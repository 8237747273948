import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'

import HeliocorStorage from "../HeliocorStorage"
import { WarningBox } from '../../apps/common/'
import { CLEAR_STORE } from "../../store/actionTypes"

const InvalidToken = (props) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const clearStore = () => dispatch({ type: CLEAR_STORE })
    const generalState = useSelector(store => store.auth.generalState)


    const signOut = () => {
        HeliocorStorage.clear()
        window.location.href = props.AD ? '/login-mcb' : '/'
        clearStore()
    }

    return (
        <React.Fragment>
            <WarningBox
                open={generalState === 'Invalid token'}
                title={t('warning.invalidToken')}
                content={t("warning.invalidTokenText")}
                buttons={[{ type: 'default', onClick: signOut, value: t('logout'), autoFocus: true }]}
            />
        </React.Fragment>
    )
}

export default InvalidToken