const MEDIA_TYPES = {
    VIDEO: 'video',
    IMAGE: 'image',
    PDF: 'pdf'
}

const SUPPORTED_VIDEO_HEADERS = ['data:video/mp4;base64,', 'data:video/webm;base64,']
const SUPPORTED_VIDEO_TYPES = ['video/quicktime', 'application/x-matroska', 'video/mp4', 'video/webm']

const SUPPORTED_IMAGE_HEADERS = ['data:image/jpeg;base64,', 'data:image/png;base64,']
const SUPPORTED_IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png']

const SUPPORTED_PDF_HEADERS = ['data:application/pdf;base64,']
const SUPPORTED_PDF_TYPES = ['application/pdf']

const getMediaType = ({type = '', header = ''}) => {
    if (SUPPORTED_VIDEO_TYPES.includes(type) || SUPPORTED_VIDEO_HEADERS.includes(header)) {
        return MEDIA_TYPES.VIDEO
    } else if (SUPPORTED_IMAGE_TYPES.includes(type) || SUPPORTED_IMAGE_HEADERS.includes(header)) {
        return MEDIA_TYPES.IMAGE
    } else if (SUPPORTED_PDF_TYPES.includes(type) || SUPPORTED_PDF_HEADERS.includes(header)) {
        return MEDIA_TYPES.PDF
    }
}

export {
    getMediaType,
    MEDIA_TYPES,
    SUPPORTED_VIDEO_HEADERS, 
    SUPPORTED_VIDEO_TYPES,
    SUPPORTED_IMAGE_HEADERS,
    SUPPORTED_IMAGE_TYPES,
    SUPPORTED_PDF_HEADERS,
    SUPPORTED_PDF_TYPES
}