import isEmpty from 'lodash/isEmpty'
import { formInputTypes, VALIDATIONS } from 'heliocor-ui'
import { getParsedDependencies } from '../../helpers'

const { PHONE, COMPANY_EXIST } = VALIDATIONS

/**
 * Reads type from API and transforms it to an internal one.
 *
 * @param {string} type The type from API.
 * @return {string} The internal type to use in app.
 */
function getInternalInputType(type) {

    switch (type) {
        case 'RADIO':
            return formInputTypes.radio
        case 'SELECT':
        case 'MULTI_SELECT':
            return formInputTypes.select
        case 'BOOLEAN':
            return formInputTypes.check
        case 'DATE':
            return formInputTypes.date
        case 'NUMBER':
            return formInputTypes.numberFormat
        case 'CREDIT_CARD':
            return formInputTypes.creditCard
        case 'IBAN':
            return formInputTypes.iban
        case 'SORT':
            return formInputTypes.sortCode
        case 'SWIFT_BIC':
            return formInputTypes.bic
        case 'TEXT_AREA':
            return formInputTypes.textArea
        case 'PHONE':
            return formInputTypes.telephone
        case 'CURRENCY':
            return formInputTypes.currency
        case 'TEXT':
        default:
            return formInputTypes.inputText
    }
}

/**
 * Reads type from API and transforms it to an internal one.
 *
 * @param {string} type The type from API.
 * @return {string} The internal type to use in app.
 */
export const addRequiredFieldValidations = (field, kycType) => {
    return {
        ...field,
        mandatory: null,
        validations: [...field.validations || [], ...field.mandatory?.[kycType] ? ['REQUIRED'] : []]
    }
}


/**
 * Reads field config from the API and extracts usable array of validations.
 *
 * @param {Object} field The field configuration from API.
 * @return {Array} The extracted validations from field API config.
 */
const validationInjector = ({ field, options }) => {

    const { valueType, code, minLength, maxLength, validation } = field

    function injectMaxDateLimit() {
        const d = new Date();
        return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
    }

    const dataValidation = {
        YEAR: ['YEAR_IS_VALID'],
        MONTH: ['MONTH_IS_VALID'],
        DATE: ['DATE_IS_VALID']
    }

    const optionsAreValidObject = typeof options === 'object' && options !== null

    let validations = [...options?.validations || []]
    let max, debounceTiming, validationDependency
    switch (true) {
        case valueType === 'DATE' && optionsAreValidObject:
            validations.push(...dataValidation[options.format])
            if (options?.validations?.includes('DATE_IS_IN_THE_FUTURE')) max = injectMaxDateLimit()
            break
        case valueType === 'DATE':
            validations.push('DATE_IS_VALID')
            break 
        case code === 'CONTACT_EMAIL':
            validations.push('VALID_EMAIL')
            debounceTiming = 1000
            break 
        case code === 'EMAIL':
            validations.push('VALID_EMAIL', 'EMAIL_IN_USE')
            debounceTiming = 1000
            break 
        case code === 'REGISTRATION_NUMBER':
            validations.push(COMPANY_EXIST)
            debounceTiming = 1000
            validationDependency = 'registeredAddressCountry'
            break 
        case code === 'PHONE':
            validations.push(PHONE)
            debounceTiming = 1000
            break 
        default:
    }

    if (minLength) validations.push('MIN_LENGTH')
    if (maxLength) validations.push('MAX_LENGTH')
    if (validation) validations.push('REGEX_CHECK')
       
    return { validations, max, debounceTiming, validationDependency }
}


/**
 * Reads API categories config and transforms it to an internal usable categories Object.
 *
 * @param {Object} categories The categories configuration from API.
 * @return {Array} The transformed Object.
 */
export const fieldsParser = categories => categories
    .map(category => ({
        ...category,
        dependencies: getParsedDependencies(category.dependencies),
        fields: category.fields.map(field => {

            let isStandard = field.scope === 'STANDARD'
            let visibility = [], dependencies = {}
            let validationDependency


            let deps = getParsedDependencies(field.dependencies)
            let options = field.options && JSON.parse(field.options)

            const backOfficeDependencies = deps?.BACK_OFFICE || {}
            const kycTypesVisibility = deps?.kycTypesVisibility || {}

            if (deps && !isEmpty(deps)) {
                if (deps.type === 'SELECT') {
                    visibility.push({ field: deps.field, values: deps.values })
                } else {
                    const specificDep = deps.BACK_OFFICE
                    validationDependency = deps.field

                    if (specificDep?.true && !specificDep?.false) {
                        visibility.push({ field: validationDependency, value: true })
                    } else if (!specificDep?.true && specificDep?.false) {
                        visibility.push({ field: validationDependency, value: false })
                    }
                }
            }

            if (field.valueType === 'SELECT' && options && options.subType && options.subType !== 'COUNTRY'){
                field.valueType = options.subType
            }

            if(field.valueType === 'TEXT' && (field.code === 'PHONE' || options?.validations?.includes('PHONE'))) {
                field.valueType = 'PHONE'
            }
            
            return ({
                ...field,
                id: field.internalName,
                type: getInternalInputType(field.valueType),
                customOptions: field.values && field.values.map(value => ({
                    code: value.code,
                    value: value.value,
                    label: value.name
                })),
                options,
                label: field.code || field.name,
                ...(validationInjector({ field, options, deps })),
                ...(field.valueType === 'MULTI_SELECT' && { isMulti: true }),
                ...(options && options.format ? { dateFormat: options.format } : {}),
                ...(isStandard && field.code ? { label: field.code } : { customLabel: field.name }),
                ...(dependencies && { dependencies: {...backOfficeDependencies, kycTypesVisibility} }),
                ...(!isEmpty(visibility) ? { visibility } : {}),
                ...(validationDependency ? { validationDependency } : {}),
                ...(field.internalName === 'registryStatus' ? { disabled: true } : {})
            })
        })
    }))