import { b64ToBlob } from "heliocor-ui"
import { db_thunk } from "../../../api/db"
import { orderArray } from "../../../config/helpers"

export const getForensicAnalysis = async ({docId, cpId, entityId}) => {
    let imageProcessing = {}
    let data = []
    const orderedFields = ["ForensImg", "maskMap1", "heatMap1", "ocrSeg1", "photo"]

    const endpoint = cpId
        ? `dokstor-bo-api/api/companies/${entityId}/connected-persons/${cpId}/documents/${docId}/forensic`
        : `dokstor-bo-api/api/person/${entityId}/document/${docId}/forensic`

    return db_thunk('get', endpoint)
        .then(response => {
            if (!response || response.errorCode || response.errors) {
                return {}
            } else {
                imageProcessing = { ...response }
                imageProcessing.forensicImages && orderArray(Object.keys(imageProcessing.forensicImages), orderedFields).forEach(key => {
                    const blob = b64ToBlob(imageProcessing.forensicImages[key])
                    const objUrl = window.URL.createObjectURL(blob)
                    data.push({ image: objUrl, type: blob.type })
                })
                imageProcessing.forensicImages = data

                const { forensicImages, resolution, forensicParameters, forensicScore } = imageProcessing
                
                return {                        
                    forensicImages,
                    forensicParameters,
                    forensicScore,
                    details: [{ key: "imageResolution", value: resolution }]
                }
            }
        })
}