import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorParagraph, Paragraph, TitleH1 } from 'heliocor-ui'

import Loader from '../../../apps/common/Loader'
import { ButtonStyled, PasswordTextFieldStyled, SpacerV40 } from '../Login.styles'

const Credentials = ({ error, handleInputChange, loginSubstatus, redirectTo, requestSetCredentials }) => {

    const { t } = useTranslation()

    return (
        <React.Fragment>
            <TitleH1 value={t('cred.recovery')} />

            {loginSubstatus === 'checking' && <Loader height='400px' />}

            {loginSubstatus === 'success' &&
                <React.Fragment>
                    <Paragraph value={t('cred.please')} />
                    <div>
                        <Paragraph value={t('pass.new')} />
                        <PasswordTextFieldStyled
                            onChange={handleInputChange('newpwd1')}
                            style={{ width: '100%' }}
                            type="password"
                        />
                        <Paragraph value={t('pass.confirm')} />
                        <PasswordTextFieldStyled
                            onChange={handleInputChange('newpwd2')}
                            style={{ width: '100%' }}
                            type="password"
                        />
                        <ErrorParagraph
                            error={error && true}
                            value={error}
                        />
                    </div>
                    <ButtonStyled
                        color='primary'
                        margin='70px 0 0 0'
                        onClick={requestSetCredentials}
                        variant='contained'
                        value={t("button.reset")}
                    />
                </React.Fragment>
            }

            {loginSubstatus === 'error' &&
                <React.Fragment>
                    <Paragraph value={t('cred.invalid')} />
                    <SpacerV40 />
                    <ButtonStyled
                        color='primary'
                        margin='70px 0 0 0'
                        onClick={() => redirectTo('recoverPassword')}
                        variant='contained'
                        value={t("cred.request")}
                    />
                </React.Fragment>
            }

            {loginSubstatus === 'setError' &&
                <React.Fragment>
                    <Paragraph value={t("cred.problem")} />
                    <SpacerV40 />
                    <ButtonStyled
                        color='primary'
                        margin='70px 0 0 0'
                        onClick={() => redirectTo('recoverPassword')}
                        variant='contained'
                        value={t("cred.try")}
                    />
                </React.Fragment>
            }

            {loginSubstatus === 'setSuccess' &&
                <React.Fragment>
                    <Paragraph value={t("cred.updated")} />
                    <Loader
                        height='400px'
                        onClick={setTimeout(() => redirectTo('login'), 2000)}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Credentials