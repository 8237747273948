import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { styledTheme } from '../../../styles'
import { Paragraph, FlexContainer } from 'heliocor-ui'
import CollapsableBarSection from '../CollapsableBarSection'
import MediaViewer from '../MediaViewer'
import Paginator from '../Paginator/Paginator'
import ToolBarImage from '../ToolBarImage/ToolBarImage'
import { MEDIA_TYPES } from '../../../config/media'
import CardCircularLoader from '../CardCircularLoader';

const Layout = ({ forensicLoading, handleToolbar, heatmapImage, heatmapIndex, imageForensicDetails, setHeatmapIndex }) => {
    
    const { t } = useTranslation()

    const chartOptions = {
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        },
        responsive: true,
        cutoutPercentage: 70,
        maintainAspectRatio: true
    }

    const chartData = {
        datasets: [{
            data: [imageForensicDetails.forensicScore, 100 - imageForensicDetails.forensicScore],
            backgroundColor: [styledTheme.colors.main, styledTheme.colors.grey300],
            borderWidth: 0.2
        }],
    }

    const loading = forensicLoading 
    const error = !loading && isEmpty(imageForensicDetails)
    const loaded = !loading && !error

    return (
        <CollapsableBarSection title={t("forensicImageProcessing")}>
            <FlexContainer padding="10px 20px 20px 20px" data-cy="forensicDetails" column>
                <Paragraph value={t("forensicDescription")} />
                {loading && <CardCircularLoader />}
                {error && <Paragraph value={t("forensicError")} />}
                {loaded && (
                    <React.Fragment>
                        <FlexContainer padding="20px 20px" data-cy="forensicDetails" column>
                            <FlexContainer flex='1' justifyContent='space-between' alignItems='center'>
                                <Paragraph fontWeight='bold' value={t("riskScore")} />
                                <DoughnutContainer>
                                    <div style={{ position: 'absolute', fontWeight: 1000, marginTop: '8px' }}>
                                        {(imageForensicDetails.forensicScore || 0) + '%'}
                                    </div>
                                    <Doughnut data={chartData} options={chartOptions} />
                                </DoughnutContainer>
                            </FlexContainer>
                            {imageForensicDetails.details.map(detail =>
                                <FlexContainer key={detail.key} flex='1' justifyContent='space-between' alignItems='center'>
                                    <Paragraph fontWeight='bold' value={t(detail.key)} />
                                    <FlexContainer>
                                        {detail.icon}
                                        <Paragraph fontSize='14px'>
                                            {detail.translateId ? t(detail.translateId) : detail.value}
                                        </Paragraph>
                                    </FlexContainer>
                                </FlexContainer>
                            )}
                            {imageForensicDetails.forensicParameters.map((parameter, index) =>
                                parameter.status !== 'NOT_CHECKED' &&
                                <FlexContainer
                                    alignItems='center'
                                    flex='1'
                                    justifyContent='space-between'
                                    key={index}
                                    margin='0 0 -10px 0'
                                >
                                    <Paragraph value={t(parameter.code)} />
                                    <FlexContainer>
                                        {parameter.status === 'VALID_AUTO'
                                            ? <Paragraph fontSize='14px' fontWeight='bold' color={'green100'} value={t("checked")} />
                                            : <Paragraph fontSize='14px' fontWeight='bold' color={'red100'} value={t("issue")} />
                                        }
                                    </FlexContainer>
                                </FlexContainer>
                            )}
                        </FlexContainer>
                        <FlexContainer flex='1' justifyContent='space-between' alignItems='center' margin='20px 0 20px 20px'>
                            <Paragraph fontWeight='bold' value={t('heatmaps')} />
                            <ToolBarImage
                                handleToolbar={handleToolbar}
                                toolBarConfig={{
                                    zoom: { show: true }
                                }}
                            />
                        </FlexContainer>
                        <PaginationContainer>
                            <Paginator
                                length={imageForensicDetails.forensicImages?.length}
                                setIndex={setHeatmapIndex}
                                index={heatmapIndex}
                                isEmpty={heatmapImage === 'isEmpty'}
                                type='Documents'
                                dataState={heatmapImage}
                                padding={false}
                                showAllNumbers={true}
                            />
                        </PaginationContainer>
                        {heatmapImage
                            ? <MediaViewer mediaType={MEDIA_TYPES.IMAGE} file={heatmapImage.image} />
                            : <NoResults>{t("noResults")}</NoResults>
                        }
                    </React.Fragment>
                )}
            </FlexContainer>
        </CollapsableBarSection>
    )
}

const NoResults = styled.p`
    margin:10px 20px;
    font-weight:300;
    color: ${props => props.theme.colors.red};
    font-size: 12px;
    text-align: center;
`

const DoughnutContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 120px;
    height: 60px;
    align-items: center;
    margin-top: 2px;
    margin-right: -30px;
`

const PaginationContainer = styled.div`
    padding: 0 30px;
    text-align: center;
`
export default Layout