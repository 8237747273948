import React from 'react'
import { withTranslation } from 'react-i18next'
import { Button, ErrorParagraph, Paragraph, TitleH1 } from 'heliocor-ui'
import { InputStyled, PasswordTextFieldStyled, SpacerV40 } from '../Login.styles'

const Login = ({ loginError, handleInputChange, onLogin, showRecoverPassword, username, t, loginLoading, recoverEnabled }) => (
    <React.Fragment>
        <TitleH1
            margin='40px 0 4px 0'
            value={t('login.welcome')}
            data-cy='loginWelcome'
        />
        <Paragraph
            margin='0'
            value={t('login.form')}
        />
        <SpacerV40/>
        <Paragraph
            margin='16px 0 0 0'
            value={t('username')}
        />
        <InputStyled
            name="username"
            onChange={handleInputChange('username')}
            value={username}
        />
        <Paragraph
            margin='16px 0 0 0'
            value={t('password')}
        />
        <PasswordTextFieldStyled
            name="password"
            onChange={handleInputChange('password')}
            type="password"
        />
        <ErrorParagraph error={loginError} value={loginError} data-cy='loginError'/>
        <Button
            color='primary'
            onClick={onLogin}
            variant='contained'
            data-cy='loginButton'
            isLoading={loginLoading}
            value={t("button.login")}
            fixDimension={{ width: '100%' }}
            margin='70px 0 0'
        />
        {recoverEnabled && <Paragraph
            onClick={showRecoverPassword}
            cursor='pointer'
            color='main'
            value={t('login.forgot')}
        />}
    </React.Fragment>
);

export default withTranslation()(Login);