import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import ContentAdd from '@material-ui/icons/Add'
import React from 'react'
import styled from 'styled-components'

const GridInputsContainer = styled(Grid)`
    &&{
        display: flex;
        margin-bottom: 20px;
        min-height: 43px;
    }
`

const GridInputType = styled(Grid)`
    &&{
        margin-left: 30px; 
        display: flex; 
        width: 350px;
    }
`

const GridSelect = styled(Grid)`
    &&{ 
        align-self: center;
        min-width: 150px; 
            cursor: pointer;
}
`

const GridContentAdd = styled(Grid)`
    &&{ 
        display: flex;
        margin-right: 30px;
    }
`

const ContentAddStyled = styled(({ isDisabled, ...props }) => <ContentAdd {...props} />)`
    &&{
        align-self: center;
        font-size: 30px; 
        ${props => !props.isDisabled && 'cursor: pointer;'}
        color:${props => props.isDisabled ? props.theme.colors.grey500 : props.theme.colors.main};
    }
`

const GridChipsContainer = styled(Grid)`&&{ justify-content: center }`

const ChipStyled = styled(Chip)`
    &&{
        margin: 5px;
        font-size: 0.929rem;
        font-weight: 300;
        font-color: ${props => props.theme.colors.white};
    }
`

const DivStyled = styled.div`
    margin-top: ${props => props.marginTop};
    align-self: center;
    max-width: 317px;
    width: 100%;
`

const SliderWrapper = styled.div`
    margin-top: -12px;
    margin-bottom: -30px;
    align-self: center;
    max-width: 317px;
    width: 100%;
`

const DivLabel = styled.div`
    display: flex;
    align-items: center; 
    text-transform: capitalize;
`

export {
    GridInputType, GridInputsContainer, GridSelect, GridContentAdd,
    ContentAddStyled, GridChipsContainer, ChipStyled, DivStyled, DivLabel,
    SliderWrapper
}