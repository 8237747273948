import {
    ADD_APIKEY,
    ADD_USER,
    CLEAR_ADMIN_STATUS,
    CLEAR_APITOKEN,
    DELETE_APIKEY,
    DELETE_USER,
    SET_ADMIN_STATUS,
    SET_APIKEYS,
    SET_ORG_ROLES,
    SET_ORG_TEAMS,
    SUCCEED_REQUEST_ADMITTED_USERS,
    SUCCEED_REQUEST_USERS,
    UPDATE_USER,
    SET_PERMISSIONS,
    SET_USER_AUDITS,
    SET_DOKSTOR_URL_CALLBACK,
    SET_ROBOLITICS_URL_CALLBACK,
    SET_ROBOLITICS_TRANSACTION_URL_CALLBACK
} from "./actionTypes"

function admin(state = {
    usersCount: 0,
    users: [],
    usersGetParameters: {
        first: 0, max: 10
    },
    callback: {}
}, action) {
    switch (action.type) {
        case SUCCEED_REQUEST_USERS:
            return { ...state, users: action.users.users, usersCount: action.users.count }

        case SET_APIKEYS:
            return { ...state, APIKeys: action.APIKeys.serviceAccounts, APIKeysCount: action.APIKeys.count }

        case SET_ADMIN_STATUS:
            return { ...state, adminError: action.adminError, adminStatus: action.adminStatus, adminSuccess: action.adminSuccess }

        case ADD_USER:
            return { ...state, users: [...state.users, action.user] }

        case ADD_APIKEY:
            return { ...state, APIKeys: [...state.APIKeys, action.APIKey], newAPIToken: action.newAPIToken }

        case UPDATE_USER:
            const users = state.users.map(item => {
                if (item.identifier === action.user.identifier) {
                    return { ...item, ...action.user }
                }
                return item
            })
            return { ...state, users }

        case DELETE_USER:
            return { ...state, users: state.users.filter(user => user.identifier !== action.identifier) }

        case DELETE_APIKEY:
            return { ...state, APIKeys: state.APIKeys.filter(key => key.identifier !== action.identifier) }

        case CLEAR_APITOKEN:
            return { ...state, newAPIToken: null, adminStatus: 'closing' }

        case CLEAR_ADMIN_STATUS:
            return { ...state, adminError: null, adminSuccess: null, adminStatus: null }

        case SET_ORG_ROLES:
            return { ...state, orgRoles: action.roles }

        case SET_ORG_TEAMS:
            return { ...state, orgTeams: action.teams }

        case SUCCEED_REQUEST_ADMITTED_USERS:
            return { ...state, admittedUsers: action.admittedUsers.users }

        case SET_PERMISSIONS:
            return { ...state, orgPermissions: action.permissions }

        case SET_USER_AUDITS:
            return { ...state, userAudits: action.userAudits, userAuditsCount: action.userAuditsCount }

        case SET_DOKSTOR_URL_CALLBACK:
            return { ...state, dokstorCallback: action.callback }
        
        case SET_ROBOLITICS_URL_CALLBACK:
            return { ...state, roboliticsCallback: action.callback }
        
        case SET_ROBOLITICS_TRANSACTION_URL_CALLBACK:
            return { ...state, roboliticsTransactionCallback: action.callback }
        
        default:
            return state
    }
}

export default admin