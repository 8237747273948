import styled from 'styled-components'
import { device } from 'heliocor-ui'

const SettingsBar = styled.div`
    width: 100vw;
    height: ${props => `${props.height}px`};
    padding: 16px;
    margin-bottom: 16px;
    background-color: white;
    box-shadow: none;
    @media ${device.midSize} {
        box-shadow: ${props => props.theme.shadows.menu};
        height: 80vh;
        width: 240px;
    }
`

const ListStyle = styled.ul`
    list-style-type: none;
    cursor: pointer;
    margin: 0px;
    padding: 8px 0;
`

const ListedItems = styled.li`
    padding: 8px 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey777};
    align-items: baseline;
    border-bottom: ${(props) => `2px solid ${
        props.active ? props.theme.colors.main : props.theme.colors.greySettingBar}`};
    padding: 16px;
    text-transform: uppercase;
`

export {
    SettingsBar,
    ListStyle,
    ListedItems
}