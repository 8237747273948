import React, { Component } from 'react'
import { Button, FlexContainer } from 'heliocor-ui'
import {
    CollapseStyled, ExpandLess, ExpandMore, FullDiv, HeaderWrapper, SettingsSpan,
    SubTitle, SubtitleContainer,
    TitleH3Mod, TitleH5Styled
} from './CollapsableBarSection.styles'
import { styles } from '../../../config/styles'

class CollapsableBarSection extends Component {

    state = {
        collapseOpen: !this.props.collapsed
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.collapseOpen === nextProps.collapsed && nextProps.collapsed === false) {
            return { collapseOpen: !nextProps.collapsed }
        }
        return null
    }

    collapseHandle = () => {
        if (this.props.externalCollapsed !== undefined) {
            this.props.handleCollapseExternal()
        }
        else {
            this.setState({ collapseOpen: !this.state.collapseOpen })
        }
    }

    render() {

        const {
            padding, margin, externalCollapsed, fullWidth, borders, titleStyle, dragHandleProps, overflowx = 'auto', overflow, id = '',
            title, counter, icon, subTitle, component, button, level = 0, collapsible = true, isLoading = false, overflowy = 'visible',
            textTransform = 'capitalize', customButton = false
        } = this.props
        const { collapseOpen } = this.state

        const backgroundColor = titleStyle === 'secundary' ? '#f5f5f5' : 'none'

        return (
            <FullDiv data-cy={id} borders={borders} margin={margin} fullWidth={fullWidth}>
                <HeaderWrapper onClick={this.collapseHandle} backgroundColor={backgroundColor} {...dragHandleProps} canDrag={!!dragHandleProps}>
                    <FlexContainer justifyContent="start" alignItems="center" padding={`0 0 0 ${level * 12}px`}>
                        {(collapsible && !isLoading) && <SettingsSpan>
                            {(externalCollapsed !== undefined ? !externalCollapsed : collapseOpen)
                                ? <ExpandLess />
                                : <ExpandMore />
                        }
                        </SettingsSpan>}
                        {icon ? icon : null}
                        {titleStyle === 'secondary'
                            ? <TitleH5Styled>{title}</TitleH5Styled>
                            : <TitleH3Mod textTransform={textTransform}>{title}</TitleH3Mod>
                        }
                        {counter && <span style={styles.superIndex}>{counter}</span>}
                        {subTitle !== undefined && <SubtitleContainer><SubTitle>{subTitle}</SubTitle></SubtitleContainer>}
                    </FlexContainer>
                    {component}
                    {button &&
                        <FlexContainer padding='4px 0 0 0'>
                            {customButton
                                ? button
                                : <Button
                                    {...button.props}
                                    onClick={e => {
                                        e.stopPropagation()
                                        button.props.onClick()
                                    }}
                                />
                            }
                        </FlexContainer>
                    }
                </HeaderWrapper>
                <CollapseStyled in={(externalCollapsed !== undefined ? !externalCollapsed : collapseOpen)}
                    timeout="auto" unmountOnExit padding={padding} overflowx={overflowx} overflowy={overflowy} overflow={overflow}>
                    {this.props.children}
                </CollapseStyled>
            </FullDiv>)
    }
}

export default CollapsableBarSection
