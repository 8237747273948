import React from 'react'
import { dateConverter, dateFormatterFromString } from '../../config/helpers'
import { TitleH5, FlexContainer, Paragraph2, getBase64FileHeader } from 'heliocor-ui'
import CollapsableBarSection from './CollapsableBarSection'
import { useTranslation } from 'react-i18next';
import ValidationIcon from './ValidationIcon'

const AutoDataExtraction = ({ docType, properties }) => {

    const { t } = useTranslation()
    const propsToFilter = ['AGE_HIGH', 'AGE_LOW', 'FACE', 'GENDER', 'MATCH']
    const filteredProperties = !properties ? [] : properties.filter(obj => propsToFilter.includes(obj.name))
    const propsToTranslate = ['FACE', 'GENDER', 'MATCH']
    const propertiesByDocType = docType === 'SELFIE' ? filteredProperties : properties

    const getValue = (keyName) => {
        switch (keyName.name) {
            case 'timestamp':
            case 'lastUpdate':
                return dateConverter(keyName.value || '')
            case 'birthDate':
            case 'expirationDate':
                return dateFormatterFromString(keyName.value, 'YYYY/MM/DD')
            default:
                return keyName.value
        }
    }

    return (
        <>
            <FlexContainer column margin='0 0 5px 0'>
                <CollapsableBarSection title={t("extractedData")}>
                    <FlexContainer padding="0px 20px" column>
                        {propertiesByDocType.map(p =>
                            <React.Fragment key={p.name}>
                                <FlexContainer flex='1' justifyContent='space-between' alignItems='center'>
                                    <TitleH5 value={t(p.name)} />
                                    {p.type === 'TEXT' &&
                                        <div style={{ textAlign: 'right' }}>
                                            <Paragraph2 value={propsToTranslate.includes(p.name)
                                                ? t(getValue(p))
                                                : getValue(p)} />
                                        </div>
                                    }
                                    {p.type === 'BOOLEAN' &&
                                        <div style={{ marginRight: -2, marginTop: -8 }}>
                                            <ValidationIcon statusValidation={p.value?.toUpperCase()} />
                                        </div>
                                    }
                                </FlexContainer>
                                {p.type === 'IMAGE' &&
                                    <FlexContainer key={p.name} flex='1' justifyContent='flex-end'>
                                        <img
                                            alt='auto-data'
                                            src={getBase64FileHeader(p.value[0]) + p.value}
                                            style={{ width: '50%', marginBottom: 10 }}
                                        />
                                    </FlexContainer>
                                }
                            </React.Fragment>
                        )}
                    </FlexContainer>
                </CollapsableBarSection>
            </FlexContainer>
        </>
    )
}

export default AutoDataExtraction