import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { REMOVE_ALL_DRAWERS } from '../apps/common/store/actionTypes'

export default function useCloseDrawersOnUnmount() {
    const dispatch = useDispatch()

    const closeDrawers = useCallback(
        () => dispatch({ type: REMOVE_ALL_DRAWERS }),
        [dispatch]
    )

    useEffect(() => {
        return () => {
            closeDrawers()
        }
    }, [])
}
