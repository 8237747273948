import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Eye from '@material-ui/icons/RemoveRedEye'
import Input from "@material-ui/core/Input/Input"
import InputAdornment from '@material-ui/core/InputAdornment'

const PasswordTextField = (props) => {

    const [type, setType] = useState('password')

    return (
        <div style={{
            display: 'block',
            position: 'relative',
            width: '100%'
        }}>
            <Input
                {...props}
                type={type}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onMouseDown={() => setType('text')}
                            onMouseUp={() => setType('password')}
                        >
                            <Eye style={{ fill: '#dddddd' }} />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    )
}

export default PasswordTextField