import {
    CLEAR_DOKSTOR_STATUS,
    CLEAR_SETTINGS_IMAGES,
    DELETE_PERSON,
    DOC_STATUS_UPDATE,
    SET_DOKSTOR_FILTERS,
    SET_DOKSTOR_STATUS,
    SUCCEED_REQUEST_PERSONS,
    SET_DOKSTOR_SETTINGS,
    UPDATE_PERSON,
    SET_DOKSTOR_SETTING,
    UPDATE_SUMMARY,
    UPDATE_DOC,
    SET_CURRENT_DOC,
    CLEAR_CURRENT_DOC,
    SUCCEED_REQUEST_COMPANIES,
    DELETE_COMPANY,
    UPDATE_COMPANY_SUMMARY,
    SET_CURRENT_ENTITY,
    SET_ENTITIES_GET_PARAMETERS,
    SET_ENTITY_RISKS,
    SET_CURRENT_ENTITY_STRUCTURE,
    SET_RISK_SETTINGS,
    SET_RISK_CHANGE_REASONS,
    SET_CURRENT_ENTITY_DOC_DATA,
    SET_RISKS_VALUES,
    SET_CURRENT_RISK,
    ADD_ENTITY_DOCS_TO_STRUCTURE,
    REMOVE_CURRENT_ENTITY_DOCS_DATA,
    SET_CURRENT_OWNER,
    SET_CURRENT_ENTITY_HITS,
    SET_CURRENT_CP_HITS,
    SET_CURRENT_WEB_SEARCH_HITS,
    SET_CASES_STATISTICS,
    SET_CASES_ACTIVITIES,
    SET_PHASES,
    SET_CURRENT_HIT_DETAILS,
    SET_CURRENT_DOC_COMMENT,
    SET_KYC_CONFIG,
    SET_CURRENT_PREDEFINED_DOCS,
    SET_DASHBOARD_CONFIG,
    SET_DASHBOARDS_CONFIG,
    SET_FIELDS,
    SET_COMPANY_SUGGESTED_STRUCTURE,
    SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS,
    SET_FIELDS_SETTINGS,
    SET_FIELD_CATEGORIES,
    CLEAN_CURRENT_ENTITY_DATA,
    SET_HITS_FROM_AN_ENTITY,
    REMOVE_FIELD_SETTINGS,
    SET_CURRENT_FIELD,
    SET_CURRENT_CONNECTED_PARTY,
    CLEAN_CURRENT_CP_DATA,
    UPDATE_CP_HITS,
    SET_CURRENT_ENTITY_TREE_STRUCTURE,
    SET_KYC_TYPES,
    UPDATE_KYC_FILTERS, 
    SET_APPROVAL_SETTINGS,
    SET_SIGNATURE_DOCUMENTS,
    SET_SIGNATURE_DOCUMENT_SETTINGS,
    UPDATE_PARTY_RISK_SETTINGS,
    SET_POST_ERRORS,
    DELETE_POST_ERROR,
    CLEAR_POST_ERRORS,
    SET_WATCH_LIST_CATEGORIES
} from './actionTypes'
import { capitalizeFirst } from "../../../config/helpers/helpers"
import { b64ToBlob } from 'heliocor-ui';
import { SET_WATCH_LISTS, SET_COMPANY_WATCH_LISTS } from '../../caseManagement/store/actionTypes'

function dokstor(
    state = {
        filters: {},
        settings: [],
        currentCP: {},
        currentEntity: {},
        currentEntityStructure: [],
        currentField: {},
        currentRisk: {},
        entitiesGetParameters: {
            first: 0,
            max: 10,
        },
        kycConfig: [],
        currentPredefinedDocs: [],
        currentValidationDocuments: [],
        fieldCategories: {},
        fields: {},
        fieldsSettings: {},
        currentDoc: {},
        watchList: {},
        companyWatchList: {},
        dashboardsConfig: []
    }, action) {
    switch (action.type) {

        case SET_ENTITIES_GET_PARAMETERS:
            return { ...state, entitiesGetParameters: action.entitiesGetParameters }

        case SUCCEED_REQUEST_PERSONS:
            return {
                ...state,
                persons: action.entities.persons,
                personsCount: action.entities.count,
                personsSummary: {
                    countNotChecked: action.entities.countNotChecked,
                    countReview: action.entities.countReview,
                    countApproved: action.entities.countApproved,
                    countRejected: action.entities.countRejected,
                    countCancelled: action.entities.countCancelled
                }
            }

        case SUCCEED_REQUEST_COMPANIES:
            return {
                ...state,
                companies: action.entities.companies,
                companiesCount: action.entities.count,
                companiesSummary: {
                    countNotChecked: action.entities.countNotChecked,
                    countReview: action.entities.countReview,
                    countApproved: action.entities.countApproved,
                    countRejected: action.entities.countRejected,
                    countCancelled: action.entities.countCancelled
                },
            }

        case SET_DOKSTOR_STATUS:
            return {
                ...state,
                dokstorError: action.dokstorError,
                dokstorStatus: action.dokstorStatus,
                dokstorSuccess: action.dokstorSuccess
            }

        case UPDATE_PERSON:
            let persons = state.persons.map(item => {
                if (item.identifier === action.entity.identifier) {
                    return { ...item, ...action.entity }
                }
                return item
            })
            return { ...state, persons, currentEntity: { ...state.currentEntity, ...action.entity } }

        case DELETE_PERSON:
            return { ...state, persons: state.persons.filter(person => person.identifier !== action.identifier) }

        case DELETE_COMPANY:
            return { ...state, companies: state.companies.filter(company => company.identifier !== action.identifier) }

        case DOC_STATUS_UPDATE:
            return { ...state, currentDoc: { ...state.currentDoc, status: action.status } }

        case SET_CURRENT_ENTITY:
            if (state.currentEntity.selfie) {
                window.URL.revokeObjectURL(state.currentEntity.selfie);
            }

            const scpEntity = action.entity
            let { documents = [] } = scpEntity

            return { ...state, currentEntity: { ...action.entity, documents } }

        case SET_CURRENT_CONNECTED_PARTY:
            // if (state.currentEntity.selfie) {
            //     window.URL.revokeObjectURL(state.currentEntity.selfie);
            // }

            return { ...state, currentCP: action.entity }

        case SET_COMPANY_SUGGESTED_STRUCTURE:
            return { ...state, currentEntity: { ...state.currentEntity, suggestedStructure: action.suggestedStructure } }

        case SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS:
            return { ...state, currentEntity: { ...state.currentEntity, suggestedStructureFound: action.found } }

        case UPDATE_DOC:
            return { ...state, currentDoc: { ...action.doc } }

        case SET_CURRENT_DOC:
            return { ...state, currentDoc: { ...action.docData }, dokstorError: null, dokstorStatus: null, dokstorSuccess: null }

        case SET_CURRENT_DOC_COMMENT:
            const entityNode = action.cpid ? 'currentCP' : 'currentEntity'
            const docs = [...state[entityNode].documents];
            const doc = docs.find(d => d.identifier === action.documentId);
            const docCommentChanged = { ...doc, comments: action.comment };
            docs.splice(docs.indexOf(doc), 1, docCommentChanged);
            return { ...state, currentDoc: { ...state.currentDoc, comments: action.comment }, [entityNode]: { ...state[entityNode], documents: docs } }

        case CLEAR_CURRENT_DOC:
            const { datas } = state.currentDoc;
            if (datas) {
                datas.forEach(docData => {
                    if ((docData.content).match(/\b(https?:\/\/\S*\b)/g)) {
                        window.URL.revokeObjectURL(docData.content);
                    } else {
                        console.error('The image is not an URL.')
                    }
                })
            }
            return { ...state, currentDoc: {}, dokstorError: null, dokstorStatus: null, dokstorSuccess: null }

        case SET_DOKSTOR_FILTERS:
            return { ...state, filters: { ...action.filters } }

        case SET_DOKSTOR_SETTINGS:
            let CAFePersonalisation, newCAFePersonalisation;

            const newSettings = [...action.settings];

            if (action.settings.CAFE_PERSONALISATION) {
                CAFePersonalisation = (action.settings).find(setting => setting.key === "CAFE_PERSONALISATION");

                newCAFePersonalisation = { key: CAFePersonalisation.key, value: { ...CAFePersonalisation.value } }

                if (CAFePersonalisation.value.LOGO_IMAGE) {
                    const b64Image = CAFePersonalisation.value.LOGO_IMAGE;
                    const blob = b64ToBlob(b64Image);
                    const newLogoImageURL = window.URL.createObjectURL(blob);
                    newCAFePersonalisation.value.LOGO_IMAGE = newLogoImageURL;
                }
                newSettings[action.settings.indexOf(CAFePersonalisation)] = newCAFePersonalisation;
            }

            return { ...state, settings: newSettings, dataSources: action.dataSources, validations: action.validations }

        case SET_DOKSTOR_SETTING:
            const { key, value } = action;
            let newLogoImageURL;

            if (key === 'CAFE_PERSONALISATION' && value.LOGO_IMAGE && value.LOGO_IMAGE !== null) {
                const b64Image = value.LOGO_IMAGE;
                const blob = b64ToBlob(b64Image);
                newLogoImageURL = window.URL.createObjectURL(blob);
            }
            const newValue = key === 'CAFE_PERSONALISATION' && value.LOGO_IMAGE !== null ? { ...value, LOGO_IMAGE: newLogoImageURL } : value;

            if (state.settings.some(setting => setting.key === key)) {
                const settings = state.settings.map(item => {
                    if (item.key === key) {
                        return { key, value: newValue }
                    }
                    return item
                })
                return { ...state, settings }
            } else {
                return { ...state, settings: [...state.settings, { key, value: newValue }] }
            }

        case CLEAR_DOKSTOR_STATUS:
            return { ...state, dokstorError: null, dokstorStatus: null, dokstorSuccess: null }

        case UPDATE_SUMMARY:
            const preKey = 'count' + capitalizeFirst(action.status.toLowerCase())
            const postKey = 'count' + capitalizeFirst(action.newStatus.toLowerCase())
            return {
                ...state, personsSummary: {
                    ...state.personsSummary,
                    [preKey]: state.personsSummary[preKey] - 1,
                    [postKey]: state.personsSummary[postKey] + 1
                }
            }

        case UPDATE_COMPANY_SUMMARY:
            const c_preKey = 'count' + capitalizeFirst(action.status.toLowerCase())
            const c_postKey = 'count' + capitalizeFirst(action.newStatus.toLowerCase())
            return {
                ...state, companiesSummary: {
                    ...state.companiesSummary,
                    [c_preKey]: state.companiesSummary[c_preKey] - 1,
                    [c_postKey]: state.companiesSummary[c_postKey] + 1
                }
            }

        case SET_CURRENT_OWNER:
            return { ...state, currentOwner: action.owner }

        case SET_ENTITY_RISKS:
            return { ...state, currentEntityRisksInfo: { ...action.risksInfo, additionalRisksList: action.additionalRisksList} }

        case SET_CURRENT_ENTITY_STRUCTURE:
            return {
                ...state,
                currentEntityStructure: action.structure
            }

        case SET_CURRENT_ENTITY_TREE_STRUCTURE:
            return {
                ...state,
                currentEntityTreeStructure: action.structure
            }

        case SET_RISK_SETTINGS:
            return {
                ...state,
                riskSettings: action.riskSettings
            }

        case UPDATE_PARTY_RISK_SETTINGS:
            return {
                ...state,
                riskSettings: {...state.riskSettings, [action.partyType]: action.riskSettings}
            }

        case SET_RISK_CHANGE_REASONS:
            return {
                ...state,
                changeRiskReasons: action.reasons
            }

        case SET_RISKS_VALUES:
            return {
                ...state,
                risks: action.risks
            }

        case SET_CURRENT_RISK:
            return {
                ...state,
                currentRisk: action.risk
            }

        case SET_CURRENT_ENTITY_DOC_DATA:
            return {
                ...state,
                currentValidationDocuments: [...state.currentValidationDocuments, action.document]
            }

        case REMOVE_CURRENT_ENTITY_DOCS_DATA:
            state.currentValidationDocuments.forEach(docData => {
                window.URL.revokeObjectURL(docData.image);
            })
            return {
                ...state,
                currentValidationDocuments: []
            }

        case ADD_ENTITY_DOCS_TO_STRUCTURE:
            return {
                ...state,
                currentEntityStructure: [
                    ...action.structureWithDocs
                ]
            }

        case SET_CURRENT_ENTITY_HITS:
            return {
                ...state,
                currentEntityHits: action.entityHits
            }

        case SET_CURRENT_CP_HITS:
            return {
                ...state,
                currentCPHits: action.entityHits
            }

        case SET_CURRENT_WEB_SEARCH_HITS:
            return {
                ...state,
                currentWebSearchHits: action.webSearchHits
            }    

        case UPDATE_CP_HITS:
            const entity = action.isCompany ? 'company' : 'person'
            return {
                ...state,
                currentCPHits: { [entity]: [{ ...state.currentCPHits[entity][0], hits: action.hits }] }
            }

        case SET_HITS_FROM_AN_ENTITY:
            const entityHits = { ...state.currentEntityHits }
            const foundEntityIndex = entityHits[action.entityKey].findIndex(entity => entity.identifier === action.entityid)
            const foundEntity = entityHits[action.entityKey].find(entity => entity.identifier === action.entityid)
            if (foundEntity) {
                entityHits[action.entityKey].splice(foundEntityIndex, 1, { ...foundEntity, ...action.entityInfo })
            }
            return {
                ...state,
                currentEntityHits: entityHits
            }

        case SET_CASES_STATISTICS:
            return {
                ...state,
                casesStatistics: action.casesStatistics
            }

        case SET_PHASES:
            return {
                ...state,
                currentEntity: {
                    ...state.currentEntity,
                    phases: action.phases,
                    casePhase: action.casePhase || state.currentEntity.casePhase
                }
            }

        case SET_CASES_ACTIVITIES:
            return {
                ...state,
                currentEntityActivities: action.activities
            }

        case SET_CURRENT_HIT_DETAILS:
            return {
                ...state,
                currentHitDetails: action.currentHitDetails
            }

        case SET_KYC_CONFIG:
            return action.entityType
                ? {
                    ...state,
                    kycConfig: state.kycConfig.map(kyc => kyc.entityType === action.entityType
                        ? action.kycConfig
                        : kyc
                    )
                } : {
                    ...state,
                    kycConfig: action.kycConfig
                }
        
        case SET_KYC_TYPES:
            return {
                ...state,
                kycTypes: action.types
            }
        
        case UPDATE_KYC_FILTERS:
            return {
                ...state,
                kycFilters: {
                    ...state.kycFilters,
                    [action.entityType]: action.types,
                }
            }
        case SET_CURRENT_PREDEFINED_DOCS:
            return {
                ...state,
                currentPredefinedDocs: action.currentPredefinedDocs
            }
        
        case SET_DASHBOARD_CONFIG:
            return {
                ...state,
                dashboardsConfig: state.dashboardsConfig?.map(dashboard => dashboard.identifier === action.identifier ? action.config : dashboard) || []
            }

        case SET_DASHBOARDS_CONFIG:
            return {
                ...state,
                dashboardsConfig: action.config
            }

        case CLEAR_SETTINGS_IMAGES:
            const CAFePersonalisationRevoke = (state.settings).find(setting => setting.key === "CAFE_PERSONALISATION");
            if (CAFePersonalisationRevoke) {
                if ((CAFePersonalisationRevoke.value.LOGO_IMAGE || '').match(/\b(https?:\/\/\S*\b)/g)) {
                    window.URL.revokeObjectURL(CAFePersonalisationRevoke.value.LOGO_IMAGE);
                } else {
                    console.error('The image is not an URL.');
                }
            }
            return state;

        case SET_FIELDS:
            return {
                ...state,
                fields: { ...state.fields, ...action.fields }
            }

        case SET_FIELDS_SETTINGS:
            return {
                ...state,
                fieldsSettings: { ...state.fieldsSettings, ...action.fields }
            }

        case REMOVE_FIELD_SETTINGS:
            return {
                ...state,
                fieldsSettings: {
                    ...state.fieldsSettings,
                    [action.context]: {
                        ...state.fieldsSettings[action.context],
                        [action.category]: {
                            ...state.fieldsSettings[action.context][action.category],
                            fields: state.fieldsSettings[action.context][action.category].fields.filter(field => field.identifier !== action.identifier)
                        }
                    }
                }
            }

        case SET_FIELD_CATEGORIES:
            return {
                ...state,
                fieldCategories: {
                    ...state.fieldCategories,
                    [action.context]: action.fieldCategories
                }
            }

        case CLEAN_CURRENT_ENTITY_DATA:
            return {
                ...state,
                currentEntity: {},
                personCompanies: [],
                currentEntityStructure: []
            }

        case CLEAN_CURRENT_CP_DATA:
            return {
                ...state,
                currentCP: {},
                currentCPHits: {}
            }

        case SET_CURRENT_FIELD:
            return {
                ...state,
                currentField: action.currentField
            }
        
        case SET_WATCH_LISTS:
            return {
                ...state,
                watchList: {
                    watchList: action.watchList,
                    watchListCount: action.count
                }
            }
        
        case SET_COMPANY_WATCH_LISTS:
            return {
                ...state,
                companyWatchList: {
                    companyWatchList: action.watchList,
                    companyWatchListCount: action.count
                }
            }
        
        case SET_WATCH_LIST_CATEGORIES:
                    return {
                        ...state,
                        watchListCategories: action.categories
                    }

        case SET_SIGNATURE_DOCUMENTS:
            return {
                ...state,
                signatureDocuments: {
                    ...(state.signatureDocuments || {}),
                    documents: action.documents,
                }
            }

        case SET_SIGNATURE_DOCUMENT_SETTINGS:
            return {
                ...state,
                signatureDocuments: {
                    ...(state.signatureDocuments || {}),
                    settings: action.settings
                }
            }
        
        case SET_APPROVAL_SETTINGS:
            return {
                ...state,
                finalApproval: {
                    persons: action.persons || state.finalApproval.persons,
                    companies: action.companies || state.finalApproval.companies
                }
            }

        case SET_POST_ERRORS:
            return {
                ...state,
                postErrors: action.errors
            }

        case DELETE_POST_ERROR:
            const newPostErrors = state.postErrors.filter(e => e.field !== action.fieldId)
            
            return {
                ...state,
                postErrors: newPostErrors,
                dokstorError: newPostErrors.length === 0 ? null : state.dokstorError
            }

        case CLEAR_POST_ERRORS:
            return {
                ...state,
                postErrors: null
            }

        default:
            return state
    }
}

export default dokstor