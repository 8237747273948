import React from 'react'
import { useTranslation } from 'react-i18next';

const Translate = ({id, data = {}}) => {
  const { t } = useTranslation()

  return <>{t(id, data)}</>
}

export { Translate }

