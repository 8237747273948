import { PERMISSIONS } from '../../login/consts/permissions.enum'

const { DOKSTOR: { DOKSTOR_CAPTURE_DOCUMENTS, DOKSTOR_VALIDATE_DOCUMENTS, DOKSTOR_SCREEN, DOKSTOR_ASSESS_RISK, DOKSTOR_APPROVE, DOKSTOR_SECOND_APPROVE }} = PERMISSIONS

export const TYPES_WITH_DEFAULT = ['SELECT', 'COUNTRY']



export const AMLKeys = {
    person: [
        'gender',
        'lastUpdate',
        'matchName',
        'matchType',
        'name',
        'nationality',
        'title',
        'birthDate',
        'risks',
    ],
    company: [
        'lastUpdate',
        'matchName',
        'matchType',
        'name',
        'nationality',
        'title',
        'risks',
    ]
}

export const VALIDATION_DOCS = ['PASSPORT', 'DRIVING_LICENCE', 'ID_CARD', 'SELFIE', 'PERSONAL_ADDRESS']

export const TWO_MANDATORY_SIDES_DOCS = ['DRIVING_LICENCE', 'ID_CARD']

export const KYCTypes = ["Simplified Due Diligence (SDD)", "Due Diligence (DD)", "Enhanced Due Diligence (EDD)", "Extended Due Diligence (Medium Risk)","Extended Due Diligence (High Risk)"]

export const companiesHouseAPIKey = btoa('MlhE_BLv3eEZMcHp_z_lz1FMu14p_ujjm0YF5dyO:')

export const riskScoreTypes = [{value:null, label: "notSet"},
                                {value:"0", label:"veryLow"},
                                {value:"1", label:"low"},
                                {value:"2", label:"medium"},
                                {value:"3", label:"high"},
    { value: "4", label: "veryHigh" }]

export const getKYCObj = (isCompany, translate) => isCompany
    ? [
        { value: 'SDD', title: translate('statusCases.SDD'), subtitle: "(SDD)" },
        { value: 'DD', title: translate('statusCases.DD'), subtitle: "(DD)" },
        { value: 'EDD', title: translate('statusCases.EDD') },
        { value: 'EDD_MEDIUM_RISK', title: translate('statusCases.EDD'), subtitle: "(Medium Risk)" },
        { value: 'EDD_HIGH_RISK', title: translate('statusCases.EDD'), subtitle: "(High Risk)" }
    ] : [
        { value: 'DD', title: translate('statusCases.DD'), subtitle: "(DD)" },
        { value: 'ENDD', title: translate('statusCases.ENDD'), subtitle: "(EDD)" },
        { value: 'EDD', title: translate('statusCases.EDD') },
        { value: 'EDD_MEDIUM_RISK', title: translate('statusCases.EDD'), subtitle: "(Medium Risk)" },
        { value: 'EDD_HIGH_RISK', title: translate('statusCases.EDD'), subtitle: "(High Risk)" }
    ]

export const dataExtractionDocs = ['PASSPORT']

export const docFileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'mp4', 'mov'];

export const nonSharedParamsList = {

    "listId":"0d9a3d2fea094344b6f1f26a5a6ffc42",
    "risks": [
      {
         "name": "More than 5 years",
          "code": "MORE_FIVE_YEARS",
          "identifier":"0d9a3d2fea094344b6f1f26a5a6ffcee",
          "lastUpdate": 1562252885000,
          "min":5,
          "shared":false,
          "score":2
      },
      {
         "name": "3 to 5 years",
          "code": "THREE_FIVE_YEARS",
          "identifier":"0d9a3d2fea094344b6f1f26a5a6ffcge",
          "lastUpdate": 1562252885000,
          "min":3,
          "max":5,
          "shared":false,
          "score":2
      }
    ],
    "results": 2
}

export const selectOptionsRisk = [
    {label: 1, value: 1, labelText: 'veryLow'},
    {label: 2, value: 2, labelText: 'low'},
    {label: 3, value: 3, labelText: 'medium'},
    {label: 4, value: 4, labelText: 'high'},
    {label: 5, value: 5, labelText: 'veryHigh'},
]

export const riskClassification = (translate) =>[
    { value: 3, label: `${translate('low')}, ${translate('medium')}, ${translate('high')}` },
    { value: 5, label: `${translate('veryLow')}, ${translate('low')}, ${translate('medium')}, ${translate('high')}, ${translate('veryHigh')}` }
]

export const riskScale = [
    { value: 3, label: '1 - 3' },
    { value: 5, label: '1 - 5' },
    { value: 10, label: '1 - 10' },
    { value: 100, label: '1 - 100' }
]

export const activitiesOptions = (translate) => {
    const values = [
        { label: translate('call'), value: 'CALL'},
        { label: translate('meeting'), value: 'MEETING'},
        { label: translate('note'), value: 'NOTE'}
    ]
    return values;
}

export const shareholdersPercentOptions = [
    {label: 'None', value: 0},
    {label: '5%', value: 5},
    {label: '10%', value: 10},
    {label: '15%', value: 15},
    {label: '20%', value: 20},
    {label: '25%', value: 25}
]

export const directorsNumberOptions = [
    {label: 'None', value: 0},
    {label: 1, value: 1},
    {label: 2, value: 2},
    {label: 3, value: 3},
    {label: 4, value: 4},
    {label: 5, value: 5},
    {label: 'All', value: 100}
]

export const entityTypes = [
    "PERSON",
    "COMPANY",
    "SHAREHOLDER",
    "DIRECTOR",
    "AUTH_SIGNATORY",
    "UBO"
]

export const getTabs = isConnectedParty => {
    const tabs = [
    {
        dataCy: 'documentsCaptureTab',
        title: 'documentsCapture',
        key: 'DOCUMENTS_CAPTURE',
        permissions: [DOKSTOR_CAPTURE_DOCUMENTS]
    },
    {
        dataCy: 'validationsTab',
        title: 'validations',
        key: 'VALIDATIONS',
        permissions: [DOKSTOR_VALIDATE_DOCUMENTS]
    },
    {
        dataCy: 'screeningTab',
        title: 'screening',
        key: 'SCREENING',
        permissions: [DOKSTOR_SCREEN]
    },
    {
        dataCy: 'riskAssessmentTab',
        title: 'riskAssessment',
        key: 'RISK_ASSESSMENT',
        permissions: [DOKSTOR_ASSESS_RISK]
    },
    {
        dataCy: 'approvalTab',
        title: 'approval',
        key: 'APPROVAL',
        permissions: [DOKSTOR_APPROVE, DOKSTOR_SECOND_APPROVE]
    }
    ]
    if (isConnectedParty) tabs.splice(-2)
    return tabs
}

export const tabPermissions = [DOKSTOR_CAPTURE_DOCUMENTS, DOKSTOR_VALIDATE_DOCUMENTS, DOKSTOR_SCREEN, DOKSTOR_ASSESS_RISK, DOKSTOR_APPROVE, DOKSTOR_SECOND_APPROVE ]
   