export const REQUEST_ADD_REQUEST = 'REQUEST_ADD_REQUEST'
export const REQUEST_ADD_COMPANY_REQUEST = 'REQUEST_ADD_COMPANY_REQUEST'

export const UPDATE_PERSON = 'UPDATE_PERSON'

export const DELETE_PERSON = 'DELETE_PERSON'
export const DELETE_COMPANY = 'DELETE_COMPANY'

export const REQUEST_DOC_STATUS_UPDATE = 'REQUEST_DOC_STATUS_UPDATE'

export const DOC_STATUS_UPDATE = 'DOC_STATUS_UPDATE'

export const SET_DOKSTOR_STATUS = 'SET_DOKSTOR_STATUS'
export const CLEAR_DOKSTOR_STATUS = 'CLEAR_DOKSTOR_STATUS'

export const SET_CURRENT_PERSON = 'SET_CURRENT_PERSON'

export const REQUEST_UPDATE_HITS_STATUS = 'REQUEST_UPDATE_HITS_STATUS'

export const REQUEST_UPLOAD_DOC = 'REQUEST_UPLOAD_DOC'

export const REQUEST_DELETE_DOCS = 'REQUEST_DELETE_DOCS'
export const REQUEST_DELETE_COMPANY_DOCS = 'REQUEST_DELETE_COMPANY_DOCS'
export const REQUEST_DELETE_REQUEST = 'REQUEST_DELETE_REQUEST'

export const UPDATE_DOC = 'UPDATE_DOC'
export const UPDATE_COMPANY_DOC = 'UPDATE_COMPANY_DOC'

export const REQUEST_DELETE_FILE = 'REQUEST_DELETE_FILE'

export const SET_CURRENT_DOC = 'SET_CURRENT_DOC'
export const SET_CURRENT_DOC_COMMENT = 'SET_CURRENT_DOC_COMMENT'

export const CLEAR_CURRENT_DOC = 'CLEAR_CURRENT_DOC'

export const REQUEST_DOKSTOR_SETTINGS = 'REQUEST_DOKSTOR_SETTINGS'

export const REQUEST_SET_DOKSTOR_SETTINGS = 'REQUEST_SET_DOKSTOR_SETTINGS'

export const SET_DOKSTOR_SETTINGS = 'SET_DOKSTOR_SETTINGS'

export const SET_DOKSTOR_SETTING = 'SET_DOKSTOR_SETTING'

export const SET_DOKSTOR_FILTERS = 'SET_DOKSTOR_FILTERS'

export const UPDATE_SUMMARY = 'UPDATE_SUMMARY'

export const REQUEST_RISKRATING_CHANGE = 'REQUEST_RISKRATING_CHANGE'
export const REQUEST_COMPANY_RISKRATING_CHANGE = 'REQUEST_COMPANY_RISKRATING_CHANGE'

export const SET_CURRENT_ENTITY = 'SET_CURRENT_ENTITY'
export const SET_CURRENT_CONNECTED_PARTY = 'SET_CURRENT_CONNECTED_PARTY'

export const REQUEST_PERSON = 'REQUEST_PERSON'
export const REQUEST_COMPANY = 'REQUEST_COMPANY'
export const REQUEST_CONNECTED_PARTY = 'REQUEST_CONNECTED_PARTY'

export const REQUEST_ADD_PERSON = 'REQUEST_ADD_PERSON'
export const REQUEST_ADD_COMPANY = 'REQUEST_ADD_COMPANY'

export const REQUEST_UPDATE_PERSON = 'REQUEST_UPDATE_PERSON'
export const REQUEST_UPDATE_COMPANY = 'REQUEST_UPDATE_COMPANY'
export const REQUEST_UPDATE_ENTITY = 'REQUEST_UPDATE_ENTITY'

export const REQUEST_DELETE_PERSON = 'REQUEST_DELETE_PERSON'
export const REQUEST_DELETE_COMPANY = 'REQUEST_DELETE_COMPANY'
export const REQUEST_DELETE_ENTITY = 'REQUEST_DELETE_ENTITY'

export const REQUEST_PERSONS = 'REQUEST_PERSONS'
export const REQUEST_COMPANIES = 'REQUEST_COMPANIES'

export const SET_ENTITIES_GET_PARAMETERS = 'SET_ENTITIES_GET_PARAMETERS'

export const SUCCEED_REQUEST_PERSONS = 'SUCCEED_REQUEST_PERSONS'

export const SUCCEED_REQUEST_COMPANIES = 'SUCCEED_REQUEST_COMPANIES'

export const UPDATE_COMPANY_SUMMARY = 'UPDATE_COMPANY_SUMMARY'

export const GET_ENTITY_RISKS = 'GET_ENTITY_RISKS'
export const SET_ENTITY_RISKS = 'SET_ENTITY_RISKS'

export const GET_COMPANY_SUGGESTED_STRUCTURE = 'GET_COMPANY_SUGGESTED_STRUCTURE'
export const SET_COMPANY_SUGGESTED_STRUCTURE = 'SET_COMPANY_SUGGESTED_STRUCTURE'

export const SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS = 'SET_COMPANY_SUGGESTED_STRUCTURE_RESULTS'

export const ADD_ENTITY_DOCS_TO_STRUCTURE = 'ADD_ENTITY_DOCS_TO_STRUCTURE'

export const UPSERT_COMPANY_OWNERSHIP = 'UPSERT_COMPANY_OWNERSHIP'

export const UPSERT_COMPANY_STRUCTURE = 'UPSERT_COMPANY_STRUCTURE'

export const DELETE_OWNERSHIP = 'DELETE_OWNERSHIP'

export const SET_CURRENT_ENTITY_STRUCTURE = 'SET_CURRENT_ENTITY_STRUCTURE'
export const SET_CURRENT_ENTITY_TREE_STRUCTURE = 'SET_CURRENT_ENTITY_TREE_STRUCTURE'
export const ADD_CP_TO_CURRENT_ENTITY_STRUCTURE = 'ADD_CP_TO_CURRENT_ENTITY_STRUCTURE'

export const REQUEST_GET_PHASES = 'REQUEST_GET_PHASES'
export const SET_PHASES = 'SET_PHASES'
export const REQUEST_PHASE_CHANGE = 'REQUEST_PHASE_CHANGE'
export const SET_CURRENT_OWNER = 'SET_CURRENT_OWNER'

export const GET_RISK_CHANGE_REASONS = 'GET_RISK_CHANGE_REASONS'
export const SET_RISK_CHANGE_REASONS = 'SET_RISK_CHANGE_REASONS'

export const REQUEST_DOC_DATA = 'REQUEST_DOC_DATA'
export const SET_CURRENT_ENTITY_DOC_DATA = 'SET_CURRENT_ENTITY_DOC_DATA'

export const REMOVE_CURRENT_ENTITY_DOCS_DATA = 'REMOVE_CURRENT_ENTITY_DOCS_DATA'

export const REQUEST_WEB_SEARCH_HITS = 'REQUEST_WEB_SEARCH_HITS';
export const SET_CURRENT_WEB_SEARCH_HITS = 'SET_CURRENT_WEB_SEARCH_HITS';
export const GET_CURRENT_WEB_SEARCH_HITS = 'GET_CURRENT_WEB_SEARCH_HITS';
export const REQUEST_UPDATE_WEB_SEARCH_HITS = 'REQUEST_UPDATE_WEB_SEARCH_HITS';
export const REQUEST_RECHECK_WEB_SEARCH_HITS = 'REQUEST_RECHECK_WEB_SEARCH_HITS';

export const GET_CURRENT_ENTITY_HITS = 'GET_CURRENT_ENTITY_HITS'
export const SET_CURRENT_CP_HITS = 'SET_CURRENT_CP_HITS'
export const UPDATE_CP_HITS = 'UPDATE_CP_HITS'

export const SET_CURRENT_ENTITY_HITS = 'SET_CURRENT_ENTITY_HITS'

export const REQUEST_UPDATE_KYCTYPE = 'REQUEST_UPDATE_KYCTYPE'

export const REQUEST_UPDATE_ENTITY_STATUS = 'REQUEST_UPDATE_ENTITY_STATUS'
export const REQUEST_FINISH_CASE = 'REQUEST_FINISH_CASE'

export const REQUEST_FORCE_RECHECK = 'REQUEST_FORCE_RECHECK'

export const REQUEST_CASES_STATISTICS = 'REQUEST_CASES_STATISTICS'
export const SET_CASES_STATISTICS = 'SET_CASES_STATISTICS'

export const REQUEST_UPDATE_SCALE_RISK = 'REQUEST_UPDATE_SCALE_RISK'


export const REQUEST_CASES_ACTIVITIES = "REQUEST_CASES_ACTIVITIES"
export const REQUEST_ADD_ACTIVITY = "REQUEST_ADD_ACTIVITY"
export const SET_CASES_ACTIVITIES = "SET_CASES_ACTIVITIES"

export const REQUEST_GET_CURRENT_HIT = 'REQUEST_GET_CURRENT_HIT'

export const SET_CURRENT_HIT_DETAILS = 'SET_CURRENT_HIT_DETAILS'

export const REQUEST_RISK_APPROVAL = "REQUEST_RISK_APPROVAL"
export const REQUEST_SET_PENDING_APPROVAL = 'REQUEST_SET_PENDING_APPROVAL'

export const SAVE_ENTITY_DOC_COMMENT = 'SAVE_ENTITY_DOC_COMMENT'

export const REQUEST_UPDATE_ENTITY_OWNER = 'REQUEST_UPDATE_ENTITY_OWNER'

export const REQUEST_GET_ALL_KYC_CONFIG = 'REQUEST_GET_ALL_KYC_CONFIG'
export const SET_KYC_CONFIG = 'SET_KYC_CONFIG'
export const REQUEST_SAVE_KYC_CONFIG = 'REQUEST_SAVE_KYC_CONFIG'
export const REQUEST_SAVE_CATEGORY_CONFIG = 'REQUEST_SAVE_CATEGORY_CONFIG'
export const REQUEST_GET_CURRENT_PREDEFINED_DOCS = 'REQUEST_GET_CURRENT_PREDEFINED_DOCS'
export const SET_CURRENT_PREDEFINED_DOCS = 'SET_CURRENT_PREDEFINED_DOCS'
export const REQUEST_SAVE_DEFAULT_DOCUMENT = 'REQUEST_SAVE_DEFAULT_DOCUMENT'
export const REQUEST_DELETE_DEFAULT_DOCUMENT = 'REQUEST_DELETE_DEFAULT_DOCUMENT'

export const REQUEST_ADD_DOC = 'REQUEST_ADD_DOC'
export const REQUEST_ADD_DOC_CATEGORY = 'REQUEST_ADD_DOC_CATEGORY'
export const REQUEST_UPDATE_DOC_CATEGORY = 'REQUEST_UPDATE_DOC_CATEGORY'
export const REQUEST_DELETE_CUSTOM_DOC_CATEGORY = 'REQUEST_DELETE_CUSTOM_DOC_CATEGORY'

export const REQUEST_GET_DASHBOARD_CONFIG = 'REQUEST_GET_DASHBOARD_CONFIG'
export const REQUEST_GET_DASHBOARDS_CONFIG = 'REQUEST_GET_DASHBOARDS_CONFIG'
export const REQUEST_UPDATE_DASHBOARD_CONFIG = 'REQUEST_UPDATE_DASHBOARD_CONFIG'
export const SET_DASHBOARD_CONFIG = 'SET_DASHBOARD_CONFIG'
export const SET_DASHBOARDS_CONFIG = 'SET_DASHBOARDS_CONFIG'

export const CLEAR_SETTINGS_IMAGES = 'CLEAR_SETTINGS_IMAGES';

export const REQUEST_BLACKLIST_PERSON = 'REQUEST_BLACKLIST_PERSON'
export const REQUEST_BLACKLIST_COMPANY = 'REQUEST_BLACKLIST_COMPANY'

export const REQUEST_ADD_CUSTOM_FIELD = 'REQUEST_ADD_CUSTOM_FIELD'
export const REQUEST_GET_FIELDS = 'REQUEST_GET_FIELDS'
export const REQUEST_GET_FIELDS_SETTINGS = 'REQUEST_GET_FIELDS_SETTINGS'
export const SET_FIELDS = 'SET_FIELDS'
export const SET_FIELDS_SETTINGS = 'SET_FIELDS_SETTINGS'
export const REQUEST_UPDATE_CUSTOM_FIELD = 'REQUEST_UPDATE_CUSTOM_FIELD'
export const REQUEST_DELETE_CUSTOM_FIELD = 'REQUEST_DELETE_CUSTOM_FIELD'
export const REQUEST_FIELD_CATEGORIES = 'REQUEST_FIELD_CATEGORIES'
export const SET_FIELD_CATEGORIES = 'SET_FIELD_CATEGORIES'

export const CLEAN_CURRENT_ENTITY_DATA = 'CLEAN_CURRENT_ENTITY_DATA'
export const CLEAN_CURRENT_CP_DATA = 'CLEAN_CURRENT_CP_DATA'

export const SET_HITS_FROM_AN_ENTITY = 'SET_HITS_FROM_AN_ENTITY'
export const REQUEST_HITS_FROM_AN_ENTITY = 'REQUEST_HITS_FROM_AN_ENTITY'

export const REMOVE_FIELD_SETTINGS = 'REMOVE_FIELD_SETTINGS'

export const REQUEST_RECHECK_AML = 'REQUEST_RECHECK_AML'
export const REQUEST_RECHECK_RISK = 'REQUEST_RECHECK_RISK'

export const REQUEST_UPDATE_ADDITIONAL_RISKS = 'REQUEST_UPDATE_ADDITIONAL_RISKS'

export const REQUEST_ADD_CUSTOM_CATEGORY = 'REQUEST_ADD_CUSTOM_CATEGORY'
export const REQUEST_UPDATE_CUSTOM_CATEGORY = 'REQUEST_UPDATE_CUSTOM_CATEGORY'
export const REQUEST_DELETE_CUSTOM_CATEGORY = 'REQUEST_DELETE_CUSTOM_CATEGORY'

export const REQUEST_ADD_FIELD_VALUE = 'REQUEST_ADD_FIELD_VALUE'
export const REQUEST_ADD_FIELD_VALUES = 'REQUEST_ADD_FIELD_VALUES'
export const REQUEST_DELETE_FIELD_VALUES = 'REQUEST_DELETE_FIELD_VALUES'
export const REQUEST_SORT_FIELD_VALUES = 'REQUEST_SORT_FIELD_VALUES'
export const REQUEST_GET_FIELD = 'REQUEST_GET_FIELD'
export const SET_CURRENT_FIELD = 'SET_CURRENT_FIELD'
export const REQUEST_UPDATE_BASE64_FILE_OPTIONS = 'REQUEST_UPDATE_BASE64_FILE_OPTIONS'

export const REQUEST_DELETE_FILES_FROM_DOC = 'REQUEST_DELETE_FILES_FROM_DOC'

export const REQUEST_SORT_FIELDS_LIST = 'REQUEST_SORT_FIELDS_LIST'
export const REQUEST_SORT_FIELDS_CATEGORIES = 'REQUEST_SORT_FIELDS_CATEGORIES'

export const REQUEST_SORT_DOCUMENTS_LIST = 'REQUEST_SORT_DOCUMENTS_LIST'
export const REQUEST_SORT_DOCUMENTS_CATEGORIES = 'REQUEST_SORT_DOCUMENTS_CATEGORIES'

export const GET_WATCH_LIST = 'GET_WATCH_LIST'
export const SET_WATCH_LIST = 'SET_WATCH_LIST'
export const UPSERT_MEMBER_WATCHLIST = 'UPSERT_MEMBER_WATCHLIST'
export const DELETE_MEMBER_WATCHLIST = 'DELETE_MEMBER_WATCHLIST'
export const CLEAR_PERSON_WATCHLIST = 'CLEAR_PERSON_WATCHLIST'
export const CLEAR_COMPANY_WATCHLIST = 'CLEAR_COMPANY_WATCHLIST'
export const GET_WATCH_LIST_CATEGORIES = 'GET_WATCH_LIST_CATEGORIES'
export const UPSERT_CATEGORY_WATCHLIST = 'UPSERT_CATEGORY_WATCHLIST'
export const DELETE_CATEGORY_WATCHLIST = 'DELETE_CATEGORY_WATCHLIST'
export const SET_WATCH_LIST_CATEGORIES = 'SET_WATCH_LIST_CATEGORIES'

export const REQUEST_GET_KYC_TYPES = 'REQUEST_GET_KYC_TYPES'
export const SET_KYC_TYPES = 'SET_KYC_TYPES'
export const REQUEST_UPSERT_KYC_TYPE = 'REQUEST_UPSERT_KYC_TYPE'
export const REQUEST_DELETE_KYC_TYPE = 'REQUEST_DELETE_KYC_TYPE'
export const REQUEST_SORT_KYC_TYPES = 'REQUEST_SORT_KYC_TYPES'
export const UPDATE_KYC_FILTERS = 'UPDATE_KYC_FILTERS'

export const REQUEST_GET_APPROVAL_SETTINGS = 'REQUEST_GET_APPROVAL_SETTINGS'
export const REQUEST_SET_APPROVAL_SETTINGS = 'REQUEST_SET_APPROVAL_SETTINGS'
export const SET_APPROVAL_SETTINGS = 'SET_APPROVAL_SETTINGS'

export const REQUEST_DELETE_RISK_VALUES = 'REQUEST_DELETE_RISK_VALUES'
export const REQUEST_RISKS_VALUES = 'REQUEST_RISKS_VALUES'
export const SET_RISKS_VALUES = 'SET_RISKS_VALUES'
export const REQUEST_CURRENT_RISK = 'REQUEST_CURRENT_RISK'
export const SET_CURRENT_RISK = 'SET_CURRENT_RISK'
export const REQUEST_ADD_RISK_VALUES = 'REQUEST_ADD_RISK_VALUES'
export const REQUEST_UPSERT_RISK_CATEGORY = 'REQUEST_UPSERT_RISK_CATEGORY'
export const REQUEST_UPDATE_RISK_CATEGORY = 'REQUEST_UPDATE_RISK_CATEGORY'
export const REQUEST_DELETE_RISK_CATEGORY = 'REQUEST_DELETE_RISK_CATEGORY'
export const REQUEST_GET_RISK_SETTINGS = 'REQUEST_GET_RISK_SETTINGS'
export const SET_RISK_SETTINGS = 'SET_RISK_SETTINGS'
export const UPDATE_PARTY_RISK_SETTINGS = 'UPDATE_PARTY_RISK_SETTINGS'
export const REQUEST_UPSERT_RISK = 'REQUEST_UPSERT_RISK'
export const REQUEST_DELETE_RISK = 'REQUEST_DELETE_RISK'
export const REQUEST_SORT_RISKS = 'REQUEST_SORT_RISKS'
export const REQUEST_SORT_RISKS_CATEGORIES = 'REQUEST_SORT_RISKS_CATEGORIES'

export const REQUEST_UPDATE_VALIDATION_SETTINGS = 'REQUEST_UPDATE_VALIDATION_SETTINGS'

export const SET_POST_ERRORS = 'SET_POST_ERRORS'
export const DELETE_POST_ERROR = 'DELETE_POST_ERROR'
export const CLEAR_POST_ERRORS = 'CLEAR_POST_ERRORS'

export const REQUEST_UPDATE_DOC_TYPE = 'REQUEST_UPDATE_DOC_TYPE'

export const REQUEST_GET_SIGNATURE_DOCUMENT_SETTINGS = 'REQUEST_GET_SIGNATURE_DOCUMENT_SETTINGS'
export const REQUEST_UPDATE_SIGNATURE_DOCUMENT_SETTINGS = 'REQUEST_UPDATE_SIGNATURE_DOCUMENT_SETTINGS'
export const REQUEST_ADD_SIGNATURE_DOCUMENT = 'REQUEST_ADD_SIGNATURE_DOCUMENT'
export const REQUEST_GET_SIGNATURE_DOCUMENTS = 'REQUEST_GET_SIGNATURE_DOCUMENTS'
export const REQUEST_UPDATE_SIGNATURE_DOCUMENT = 'REQUEST_UPDATE_SIGNATURE_DOCUMENT'
export const REQUEST_DELETE_SIGNATURE_DOCUMENT = 'REQUEST_DELETE_SIGNATURE_DOCUMENT'
export const SET_SIGNATURE_DOCUMENTS = 'SET_SIGNATURE_DOCUMENTS'
export const SET_SIGNATURE_DOCUMENT_SETTINGS = 'SET_SIGNATURE_DOCUMENT_SETTINGS'