import React from 'react'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/KeyboardArrowUp'
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowDown'

import * as styledTheme from "../../../config/colors"
import { FlexContainer, TitleH3 as TH3, TitleH5 } from 'heliocor-ui'


const HeaderWrapper = styled(FlexContainer)`
    background-color: ${props => props.backgroundColor};
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-bottom:1px solid #ddd;
    justify-content: space-between;
    &:hover{
        background-color: #f5f5f5;
    }
`

const TitleH3 = (props) => (
    <TitleH3Mod {...props}>
        {props.children}
    </TitleH3Mod>
)

const CollapseStyled = styled(Collapse)`
    padding: ${props => props.padding};
    overflow-x: ${props => props.overflowx} !important;
    overflow-y: ${props => props.overflowy} !important;
    overflow: ${props => props.overflow ? props.overflow + ' !important' : 'unset'} ;
`

const SpanStyle = styled.div`
    margin-bottom: -11px;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
`

const SettingsSpan = styled.div`
    margin-top: 8px;
`

const TitleH3Mod= styled(TH3)`
    text-transform: ${props => props.textTransform ||"capitalize"};
    font-size:15px;
    font-weight:600;
    margin:0;
    font-family:'Lato', sans-serif;
    max-height:50px;
    padding:10px;
    cursor:pointer;
    color: ${props => props.theme.colors.grey100};
    
`

const FullDiv = styled.div`
    ${props => props.borders && 'border-top: 1px solid lightgrey;'}
    ${props => props.borders && 'border-bottom: 1px solid lightgrey;'}
    ${props => props.margin && `margin: ${props.margin};`}
    ${props => props.fullWidth && `width: 100%;`}
`;

const SubTitle = styled(TitleH5)`
    && {
        font-weight: 400;
        font-family:'Lato', sans-serif;
        margin: 0 20px;
        margin-left: 24px;
        color: ${styledTheme.colors.main};
        white-space: nowrap;
    }
`

const TitleH5Styled = styled(TitleH3)`
    && {
        font-weight:400;
        padding: 0px 15px;
        cursor:pointer;
        margin: 0;
        font-family:'Lato', sans-serif;
        max-height:50px;
        border-bottom: 0.5px #ddd;
        padding:10px;
    }
`

const TitleH3II = styled(TitleH3)`
    margin-bottom: ${props => props.marginTitle};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const ExpandLess = styled(ExpandLessIcon)`
    && {
        font-size: ${props => props.size ? props.size : '1.6'}rem;
    }
`

const ExpandMore = styled(ExpandMoreIcon)`
    && {
        font-size: ${props => props.size ? props.size : '1.6'}rem;
    }
`

const SubtitleContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: -2px;
    align-self: center;
`

export {
    HeaderWrapper,
    TitleH3Mod,
    CollapseStyled,
    SpanStyle,
    SettingsSpan,
    SubtitleContainer,
    ExpandLess,
    ExpandMore,
    TitleH3II,
    FullDiv,
    TitleH5Styled,
    SubTitle
}