import styled from "styled-components"

const StyledImage = styled.img`
  cursor: grab;
  user-drag: none;
  object-fit: contain;
  width: ${props => props.rotation % 180 === 0 ? '32rem' : '16rem'};
  transform: rotate(${props => props.rotation}deg) scale(${props => props.scale});
  transition: all 0.2s ease-in-out;
`

const StyledImageExpanded = styled.img`
  cursor: grab;
  user-drag: none;
  object-fit: contain;
  width: calc(90vh);
  padding: 2rem;
  margin: auto;
  transform: rotate(${props => props.rotation}deg) scale(${props => props.scale});
  transition: all 0.2s ease-in-out;
    ${props => props.rotation % 180 === 0 && `
      height: calc(100vh - 55px - 45px);
      width: auto;
      margin: unset;
  `}
`

const StyledDraggableWrapper = styled.div`
  overflow: hidden;
  background-color:${props => props.theme.colors.grey500};
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
    ${props => props.fullSize && `
      height: calc(100vh - 55px);
      overflow: hidden;
      position: absolute;
      top: 55px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto auto;
    `}
`

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  aspect-ratio: 2/1;
  padding: 0;
    ${props => props.fullSize && `
      aspect-ratio: unset;
      padding: '15px 30px';
      height: calc(100vh - 55px - 45px);
    `}
`

const StyledWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px 30px;
  color: ${props => props.theme.colors.warning}
  font-size: 14px;
`

export {
    StyledContainer,
    StyledDraggableWrapper,
    StyledImage,
    StyledImageExpanded,
    StyledImageWrapper,
    StyledWarningWrapper,

}