import React, {Component} from 'react';
import Background         from '../../assets/bg_home.jpg';
import dokstorLogo        from '../../assets/dokstor_logo.png';
import logo               from '../../assets/logo-robolitics.png';

class HomeLayout extends Component {

    render() {
        return (
            <div>
                <div style={styles.container}>
                    <div style={styles.logosContainer}>
                        <img src={logo} alt={'Robolitics logo'} style={styles.logo}/>
                        <img src={dokstorLogo} alt={'Dokstor logo'} style={styles.logo}/>
                    </div>
                    <h3
                        className="home-heading"
                        style={styles.header}
                    >
                        {this.props.t('welcomeMessage', {name: this.props.name})}
                    </h3>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#555',
        flexDirection: 'column',
        margin: "0 auto",
        width: "100%",
        background: `url(${Background}) `,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    header: {
        color: 'white',
        margin: 30,
        textAlign: "center",
        fontSize: '21px'
    },

    logo: {
        width: "50%",
        height: "auto",
        margin: "20px"
    },

    logosContainer: {
        display: 'flex',
        flexDirection: 'row'
    }
}

export default HomeLayout
