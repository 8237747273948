import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { colors } from '../config/colors';
import {CLOSE_NOTIFICATION} from "./actionTypes"

const mapStateToProps = (store) => {
    return ({ notifications: store.notifications })
}

const mapDispatchToProps = dispatch => ({
    closeNotification: (index) => dispatch({type: CLOSE_NOTIFICATION, index})
})

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const variantColor = {
    success: colors.green_agree,
    warning: colors.clearOrange,
    error: colors.red,
    info: colors.blue,
}

class Notification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notifications: []
        }
    }

    componentDidUpdate(prevProps) {
        const { notifications } = this.props
        if (prevProps.notifications !== notifications) {
            this.setState({ notifications })
        }
    }

    handleClose = (e, r, index) => {
        if(r!=='clickaway'){
            this.props.closeNotification(index)
        }
    }

    render() {
        const {notifications} = this.state
        return (
            notifications && notifications.map((notification, index) => {
                const { open, message, type = 'success' } = notification
                const Icon = variantIcon[type]
                return(
                    <Snackbar
                        key={index}
                        data-cy={`notification-${type}`}
                        style={{paddingBottom:55*index}}
                        open={open}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        autoHideDuration={5000}
                        onClose={(e,r)=>this.handleClose(e,r,index)}
                    >
                        <SnackbarContent
                            message={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon style={{ fontSize: 20, marginRight: 10 }} />
                                    {message}
                        </span>
                            }
                            style={{
                                opacity: 0.8,
                                backgroundColor: variantColor[type],
                                fontSize: 13,
                                height: 48,
                                fontWeight: 200,
                                display: 'flex',
                                alignContent: 'center',
                                flexDirection: 'row'
                            }}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    onClick={(e,r)=>this.handleClose(e,r, index)}
                                >
                                    <CloseIcon color='action' />
                                </IconButton>
                            ]}
                        />
                    </Snackbar>
                )
            })
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Notification)


