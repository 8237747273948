import React from "react"
import { ToolTip } from './'

const ValidationIcon = ({ statusValidation = '', customDescription }) => {

    const buildToolTip = ({ text, type }) => type ? <ToolTip description={customDescription || text} size='24px' type={type} cursor='pointer' /> : ''

    function checkValidation(){
        const statusObject = statusDetail[statusValidation] || ''
        return buildToolTip(statusObject)
    } 
 
    return checkValidation()
}

const statusDetail = {
    CLEAR_AUTO: { text:'Clear Auto', type:'approved' },
    CLEAR_AUTO_PEP: { text:'Clear Auto PEP', type:'approved' },
    TRUE: { text:'Yes', type:'approved' },
    CLEAR_MANUAL: { text:'Clear Manual', type:'approved' },
    CLEAR_MANUAL_PEP: { text:'Clear Manual PEP', type:'approved' },
    VALID: { text:'Valid', type:'approved' },
    OK: { text:'Ok', type:'approved' },
    VALID_AUTO: { text:'Valid Auto', type:'approved' },
    VALID_MANUAL: { text:'Valid Manual', type:'approved' },
    ENABLED: { text:'Enabled', type:'approved' },
    INVALID_MANUAL: { text:'Invalid Manual', type:'denied' },
    INVALID_AUTO: { text:'Invalid Auto', type:'denied' },
    INVALID: { text:'Invalid', type:'denied' },
    MATCH: { text:'Match', type:'denied' },
    MATCH_PEP: { text:'Match PEP', type:'denied' },
    ERROR: { text:'Error', type:'error' },
    FALSE: { text:'No', type:'denied' },
    DISABLED: { text:'Disabled', type:'denied' },
    WARNING: { text:'Warning', type:'warning' },
    REVIEW: { text:'Review', type:'warning' },
    NOT_VALIDATED: {text:'Not Validated', type: 'wait'},
    NOT_CHECKED: { text: 'Not Checked', type: 'wait' },
    INFO_WARNING: { type: 'infoWarning' },
    OUT_FOR_SIGNATURE: { text: 'Waiting For Signatures', type: 'wait'},
    DOCUMENTS_NOT_YET_PROCESSED: { text: 'Document Being Processed', type: 'wait'},
    SIGNED: { text: 'Signed', type: 'approved'},
    CANCELLED: { text: 'Signing Process Cancelled', type: 'cancelled' },
    UBO: {text: 'Connected Party may be an UBO', type: 'warning'}
}

export default ValidationIcon
