import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import adminCn from './apps/Admin/translations/cn.admin.json'
import adminDe from './apps/Admin/translations/de.admin.json'
import adminEn from './apps/Admin/translations/en.admin.json'
import adminEs from './apps/Admin/translations/es.admin.json'
import adminFr from './apps/Admin/translations/fr.admin.json'
import adminJa from './apps/Admin/translations/ja.admin.json'

import globalCn from './translations/cn.global.json'
import globalDe from './translations/de.global.json'
import globalEn from './translations/en.global.json'
import globalEs from './translations/es.global.json'
import globalFr from './translations/fr.global.json'
import globalJa from './translations/ja.global.json'

import countriesCn from './translations/cn.countries.json'
import countriesDe from './translations/de.countries.json'
import countriesEn from './translations/en.countries.json'
import countriesEs from './translations/es.countries.json'
import countriesFr from './translations/fr.countries.json'
import countriesJa from './translations/ja.countries.json'

import dokstorCn from './apps/dokstor/translations/cn.dokstor.json'
import dokstorDe from './apps/dokstor/translations/de.dokstor.json'
import dokstorEn from './apps/dokstor/translations/en.dokstor.json'
import dokstorEs from './apps/dokstor/translations/es.dokstor.json'
import dokstorFr from './apps/dokstor/translations/fr.dokstor.json'
import dokstorJa from './apps/dokstor/translations/ja.dokstor.json'

import loginCn from './login/translations/cn.login.json'
import loginDe from './login/translations/de.login.json'
import loginEn from './login/translations/en.login.json'
import loginEs from './login/translations/es.login.json'
import loginFr from './login/translations/fr.login.json'
import loginJa from './login/translations/ja.login.json'

import superAdminCn from './apps/SuperAdmin/translations/cn.superAdmin.json'
import superAdminDe from './apps/SuperAdmin/translations/de.superAdmin.json'
import superAdminEn from './apps/SuperAdmin/translations/en.superAdmin.json'
import superAdminEs from './apps/SuperAdmin/translations/es.superAdmin.json'
import superAdminFr from './apps/SuperAdmin/translations/fr.superAdmin.json'
import superAdminJa from './apps/SuperAdmin/translations/ja.superAdmin.json'

import roboliticsCn from './apps/caseManagement/translations/cn.robolitics.json'
import roboliticsDe from './apps/caseManagement/translations/de.robolitics.json'
import roboliticsEn from './apps/caseManagement/translations/en.robolitics.json'
import roboliticsEs from './apps/caseManagement/translations/es.robolitics.json'
import roboliticsFr from './apps/caseManagement/translations/fr.robolitics.json'
import roboliticsJa from './apps/caseManagement/translations/ja.robolitics.json'

const NAMESPACES = ['admin', 'global', 'countries', 'dokstor', 'robolitics', 'login', 'superAdmin']

i18next
    .use(initReactI18next)
    .init({
        lng: 'en',                              
        ns: NAMESPACES,
        defaultNS: NAMESPACES[1],
        fallbackNS: NAMESPACES,
        fallbackLng: 'en',
        interpolation: { escapeValue: false, prefix: '${', suffix: '}' },
        returnedObjectHandler: (key) => key,
        resources: {
            zh: {
                admin: adminCn,
                countries: countriesCn,
                global: globalCn,
                dokstor: dokstorCn,
                login: loginCn,
                robolitics: roboliticsCn,
                superAdmin: superAdminCn
            },
            de: {
                admin: adminDe,
                countries: countriesDe,
                global: globalDe,
                dokstor: dokstorDe,
                login: loginDe,
                robolitics: roboliticsDe,
                superAdmin: superAdminDe
            },
            en: {
                admin: adminEn,
                countries: countriesEn,
                global: globalEn,
                dokstor: dokstorEn,
                login: loginEn,
                robolitics: roboliticsEn,
                superAdmin: superAdminEn
            },
            es: {
                admin: adminEs,
                countries: countriesEs,
                global: globalEs,
                dokstor: dokstorEs,
                login: loginEs,
                robolitics: roboliticsEs,
                superAdmin: superAdminEs
            },
            fr: {
                admin: adminFr,
                countries: countriesFr,
                global: globalFr,
                dokstor: dokstorFr,
                login: loginFr,
                robolitics: roboliticsFr,
                superAdmin: superAdminFr
            },
            ja: {
                admin: adminJa,
                countries: countriesJa,
                global: globalJa,
                dokstor: dokstorJa,
                login: loginJa,
                robolitics: roboliticsJa,
                superAdmin: superAdminJa
            }
        },
    });


export default i18next