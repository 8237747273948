import { DOMAIN } from '../config/domain'
import { store } from '../App'
import { call, put } from 'redux-saga/effects'
import { SET_GENERAL_STATUS } from '../login/store/actionTypes'
import HeliocorStorage from '../config/HeliocorStorage'

export function* db(method, endpoint, body) {
    const token = store.getState().auth.token
    let parsedBody = body ? { body: JSON.stringify(body) } : null
    let response
    let jsonResponse = {
        code: '400',
        errorCode: 'BAD_REQUEST',
        errors: [{ code: 'UNKNOWN_ERROR', message: 'Unknown error' }]
    }
    try {
        response = yield call(fetch, DOMAIN + endpoint, {
            method: method,
            dataType: 'json',
            ...parsedBody,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
    } catch (e) {
        console.error(e)
        //yield put({type: OPEN_NOTIFICATION, data: { message: 'something went wrong', type: 'error'}})
    }

    if (response && response.headers.get('Content-Type') === 'application/json') {
        try {
            jsonResponse = yield response.json()
        } catch (e) {
            jsonResponse.message = e
        }
        if (jsonResponse.code === '401' && (jsonResponse.errors.some(error => (error.code === 'UNAUTHORIZED' || error.code === 'EXPIRED')))) {
            if (jsonResponse.errors.find(error => error.code === 'UNAUTHORIZED')) {
                HeliocorStorage.clear()
            }
            yield put({ type: SET_GENERAL_STATUS, generalState: 'Invalid token' })
        }
    }
    return jsonResponse
}

export function db_thunk(method, endpoint, body) {
    const token = store.getState().auth.token
    let parsedBody = body ? { body: JSON.stringify(body) } : null
    let jsonResponse = {
        code: '400',
        errorCode: 'BAD_REQUEST',
        detail: 'something went wrong'
    }
    return fetch(
        DOMAIN + endpoint, {
        method: method,
        dataType: 'json',
        ...parsedBody,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            if (response.headers.get('Content-Type') === 'application/json') {
                if (response && response.status === 401) {
                    store.dispatch({ type: SET_GENERAL_STATUS, generalState: 'Invalid token' })
                }
                try {
                    jsonResponse = response.json()
                } catch (e) {
                    jsonResponse.message = e
                }
            }
            return jsonResponse
        }
        )
        .catch(function (e) {
            console.error(e)
            jsonResponse.message = e
            return jsonResponse
        })
}