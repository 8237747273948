import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SET_BREADCRUMB, CLEAR_BREADCRUMB } from '../login/store/actionTypes'

export default function useBreadcrumb(breadcrumb) {

    const dispatch = useDispatch()

    const setBreadcrumb = useCallback(
        breadcrumb => dispatch({ type: SET_BREADCRUMB, breadcrumb }),
        [dispatch]
    )

    const clearBreadcrumb = useCallback(
        () => dispatch({ type: CLEAR_BREADCRUMB }),
        [dispatch]
    )

    useEffect(() => {
        setBreadcrumb(breadcrumb)
    }, [breadcrumb])

    useEffect(() => {
        return () => {
            clearBreadcrumb()
        }
    }, [])
}
