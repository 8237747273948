import React from 'react'
import { Container, LeftArrowStyled, RightArrowStyled, Count } from './Paginator.styles'
import isNil from 'lodash/isNil'
import { useTranslation } from 'react-i18next';
import { TitleOrangeBold, PureSpinner, FlexContainer } from 'heliocor-ui'

const CountNumber = ({ index, setIndex, status }) =>
    <Count
        active={!isNil(index)}
        selected={(status === 'selected')}
        onClick={!isNil(index) ? () => setIndex(index - 1) : null}
    >
        {index || 0}
    </Count>

const Paginator = props => {

    const { t } = useTranslation()
    const { length, index, setIndex, elements, postfix = '', dataState, padding = true, showAllNumbers } = props;
    const isFirst = (index === 0)
    const isLast = (index === length - 1)

    return (
        <Container padding={padding} >
            {dataState === 'isLoading'
                ? <TitleOrangeBold><PureSpinner /></TitleOrangeBold>
                : <>
                    <TitleOrangeBold>
                        {`${length} ${elements ? elements : t("results")} ${postfix}`}
                    </TitleOrangeBold>
                    <FlexContainer margin='15px 0 0 0' justifyContent='center' alignItems='baseline'>
                        <LeftArrowStyled
                            onClick={() => isFirst ? setIndex(length - 1) : setIndex(index - 1)}
                            disable={isFirst}
                        />
                        {showAllNumbers ?
                            [...Array(length).keys()].map(i =>
                                <CountNumber
                                    key={i}
                                    index={i + 1}
                                    status={index === i ? 'selected' : 'on'}
                                    setIndex={setIndex}
                                />)
                            : <React.Fragment>
                                <CountNumber
                                    index={(index - 1 > -1) ? index : null}
                                    status={(index - 1 > -1) ? 'on' : 'off'}
                                    setIndex={setIndex}
                                />
                                <CountNumber
                                    index={index + 1}
                                    status={'selected'}
                                    setIndex={setIndex}
                                />
                                <CountNumber
                                    index={(index + 1 <= length - 1) ? index + 2 : null}
                                    status={(index + 1 <= length - 1) ? 'on' : 'off'}
                                    setIndex={setIndex}
                                />
                            </React.Fragment>
                        }
                        <RightArrowStyled
                            onClick={() => isLast ? setIndex(0) : setIndex(index + 1)}
                            disable={isLast}
                        />
                    </FlexContainer>
                </>
            }
        </Container>
    )
}
export default Paginator
