import React         from 'react';
import {useSelector} from 'react-redux';
import styled        from 'styled-components'
import { StrapLine } from 'heliocor-ui'

function isSelected({currentLocation, link}){

    const urlApp = currentLocation.split('/')[1]
    const menuItemLinkApp = link.split('/')[0]

    const app = urlApp === menuItemLinkApp
    //TODO: Fix item location
    //const menuItem = currentLocation === '/' + link 

    const selected = {
        app, 
        //menuItem
    }

    return selected
}

const Item = ({ activePathName, depth = 0, title, icon, iconActive, link, history, openMenuList, index, subMenu }) => {

    const currentLocation = history.location.pathname
    const sideBar = useSelector(store => store.auth.sidebar);
    const selected = isSelected({currentLocation, link})

    const navigateToLink = () => {
        history.push('/' + link)
    }

    return (
        <div onClick={() => sideBar ? navigateToLink() : depth === 0 ? openMenuList(index) : null}>
            <Box depth={depth} sideBar={sideBar}>
                {
                    sideBar ?
                        <React.Fragment>
                            <IconTitle active={!subMenu && activePathName ===  `/${link}`} depth={depth} sideBar={sideBar} value={title} /*selected={selected.menuItem}*/ />
                            { icon && <IconImage alt='' src={selected.app ? iconActive : icon}/> }
                        </React.Fragment>
                    :
                        <React.Fragment>
                            { icon && <IconImage alt='' src={selected.app ? iconActive : icon} /> }
                            <IconTitle depth={depth} value={title.id} /*selected={selected.menuItem}*/ />
                        </React.Fragment>
                }
            </Box>
        </div>
    )
}

const Box = styled.div`
    float: left;
    width: 100%;
    padding-left: ${props => ((props.depth * 16) + 24) + 'px'};
    margin-bottom: 1rem;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    display: flex;
    flex-direction: ${props => props.sideBar ? 'row' : 'row'};
    align-items: center;
    opacity: ${props => props.disabled ? '0.5' : '1'};
    justify-content: space-between;
    padding-right: 24px;
`

const IconImage = styled.img`
    height: 24px;
    margin-bottom: 5px;
    margin-top: 10px;
    matgin-left: 16px;
`

const IconTitle = styled(StrapLine)`
    font-weight: ${props => props.depth === 0 ? '500' : '300'};
    text-align: center;
    font-size: 14px;
    margin: 0;
    color: white;
    margin-left: ${props => props.sideBar ? '0' : '28px'};
    display: block;
    border-bottom: ${props => props.depth !== 0 && props.selected && `2px solid ${props.theme.colors.main}`};
    white-space: nowrap;
    ${({active, depth}) => depth !== 0 && active && `
        &::before {
            content: '»';
            color: #fe593b;
            position: absolute;
            margin-left: -14px;
            margin-top: -6px;
            font-size: 20px;
        }
    `}
`

export default Item
