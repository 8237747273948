import React from 'react'
import Draggable from 'react-draggable'
import { Paragraph } from 'heliocor-ui'
import WarningIcon from '@material-ui/icons/Warning'
import { MediaViewerActions } from '../MediaViewer.actions'
import { VIEWER_SIZES } from '../MediaViewer'
import { 
  StyledContainer,
  StyledImage,
  StyledImageExpanded,
  StyledImageWrapper,
  StyledWarningWrapper,
  StyledDraggableWrapper
} from './ImageViewer.styles'
import { Translate } from '../../Translate'

const ImageViewer = ({
    image,
    config,
    imgDimensionsForWarning = {},
    viewerSize = VIEWER_SIZES.MINI,
    onConfigChange
}) => {
  const { rotation = 0, scale = 1 } = config || {}
  const [position, setPosition] = React.useState({ x: 0, y: 0 })

  const handleRotateLeft = () => onConfigChange?.({rotation: rotation - 90})
  const handleRotateRight = () => onConfigChange?.({rotation: rotation + 90})

  const handleZoomIn = () =>  onConfigChange?.({scale: scale * 1.3})
  const handleZoomOut = () => onConfigChange?.({scale: Math.max(scale / 1.3, 1)})

  const handleDragStop = (_e, data) => setPosition({ x: data.x, y: data.y })

  const isExpandedDrawer = viewerSize === VIEWER_SIZES.FULL 
  const isRotationActive = !!config && !!onConfigChange
  const hasSizeWarning = imgDimensionsForWarning.width * imgDimensionsForWarning.height < 1000000

  const ViewerImage = isExpandedDrawer ? StyledImageExpanded : StyledImage

  return (
    <StyledContainer fullSize={isExpandedDrawer}>

      <StyledDraggableWrapper>
        <Draggable position={position} onStop={handleDragStop}>
          <StyledImageWrapper fullSize={isExpandedDrawer} rotation={rotation}>
            <ViewerImage 
              src={image}
              draggable="false"
              alt="document image"
              rotation={rotation}
              scale={scale}
            />
          </StyledImageWrapper>
        </Draggable>
      </StyledDraggableWrapper>

      {isRotationActive && (
        <MediaViewerActions.Wrapper>
          <MediaViewerActions.RotateLeft onClick={handleRotateLeft} />
          <MediaViewerActions.RotateRight onClick={handleRotateRight} />
          <MediaViewerActions.ZoomIn onClick={handleZoomIn} />
          <MediaViewerActions.ZoomOut onClick={handleZoomOut} />
        </MediaViewerActions.Wrapper>
      )}

      {!isExpandedDrawer && hasSizeWarning && (
        <StyledWarningWrapper>
          <WarningIcon />
          <Paragraph margin="0px 10px">
            <Translate id="warning.imgDimensions" data={{ field: "1MP" }} />
          </Paragraph>
        </StyledWarningWrapper>
      )}
    </StyledContainer>
  )
}

export default ImageViewer