import {db_thunk} from '../../api/db';

export const requestRecoverLink = username =>
    db_thunk('post','auth-api/api/authentication/recover',{username})

export const requestSetCredentials = (token, password) =>
    db_thunk('post','auth-api/api/authentication/credentials',{token,password})

export const checkToken = token =>
    db_thunk('post', 'auth-api/api/authentication/token', { token })
    
export const getSettings = () =>
    db_thunk('get','auth-api/api/authentication/settings')
