import { all, fork, put } from 'redux-saga/effects'

import { db } from '../../../api/db'
import { takeLatestSafe } from '../../../config/helpers/sagasHelper'
import {
    SET_COMMON_SETTINGS,
    GET_COMMON_SETTINGS
} from '../../common/store/actionTypes'

const watcher = () => function* watch() {
    yield takeLatestSafe(GET_COMMON_SETTINGS, runRequestCommonSettings)
}

function* runRequestCommonSettings() { 
    const resF = yield db('get', 'auth-api/api/profile/organization/settings/AUTH/formatterSettings')
    const formatters = resF.setting ? JSON.parse(resF.setting.value) : {}

    const resP = yield db('get', 'auth-api/api/profile/organization/settings/AUTH/personalisation')
    const personalisation = resP.setting ? JSON.parse(resP.setting.value) : {}

    const resS = yield db('get', 'dokstor-bo-api/api/settings')
    const { datasources = {}, validations = {}, settings = {} } = resS
    const _datasources = {...datasources, DATA_GENERATOR: settings.DATA_GENERATOR }

    yield put({ type: SET_COMMON_SETTINGS, formatters, personalisation, datasources: _datasources, validations })
}

export default function* rootSaga() {
    yield all([fork(watcher())])
}