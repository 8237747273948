import {IconButton}    from '@material-ui/core';
import Tooltip         from '@material-ui/core/Tooltip';
import Add             from '@material-ui/icons/Add';
import ContentAdd      from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React           from 'react';
import {useDropzone}   from 'react-dropzone';
import {useSelector}   from 'react-redux';
import styled          from 'styled-components';
import {PureSpinner}   from 'heliocor-ui';

const AddIcon = styled(Add)`&& { color: ${props => props.theme.colors.main}}`

const IconButtonStyled = styled(IconButton)`
    && {    
        border: ${props => `2px dashed ${props.theme.colors.main}`};
        border-radius: 5px;
        border-color: ${props => props.theme.colors.main};
        height: 100px;
        width: 100px;
    }
`

const MultiUploader = props => {

    const { tooltipText='Upload File', onLoad, icon, fileTypes} = props

    const statusUpload = useSelector(store => store.dokstor.dokstorStatus)
    const isLoading = statusUpload && statusUpload === 'loading'

    const onDrop =(e)=> {

        const reader = new FileReader();
        const file = e[0];

        reader.onloadend = () => {

            const extension = file.name.split('.').pop().toUpperCase()
            const isSuccess = fileTypes.includes(extension);
            let stringToRemove, image_data

            if(isSuccess){
                switch (extension){
                    case 'JPG':
                    case 'JPEG':
                        stringToRemove='data:image/jpeg;base64,'
                        break
                    case 'PNG':
                        stringToRemove='data:image/png;base64,'
                        break;
                    case 'PDF':
                        stringToRemove='data:application/pdf;base64,'
                        break;
                    case 'MP4':
                        stringToRemove='data:video/mp4;base64,'
                        break;
                    case 'MOV':
                        stringToRemove='data:video/quicktime;base64,'
                        break;
                    case 'CSV':
                        stringToRemove='data:application/octet-stream;base64,'
                        break;
                    default:
                        console.error('not supported extension')
                }
                image_data = reader.result.split(stringToRemove)[1];
            }else{
                image_data='Not Supported'
            }

            onLoad(image_data,file.name)
        }

        reader.readAsDataURL(file)
    }


    const AddIconElement = <IconButtonStyled><AddIcon color="primary"/></IconButtonStyled>

    const CloudIcon = <IconButton><CloudUploadIcon color="primary"/></IconButton>

    const SpinnerItem = <IconButton><PureSpinner color='grey100' size='32px'/></IconButton>

    const SpinnerItemDashed = <IconButtonStyled><PureSpinner strokeWidth='3' size='32px'/></IconButtonStyled>

    const PlusIcon = <IconButton><ContentAdd color="primary"/></IconButton>

    const IconSelector = (icon, isLoading) => {
        switch(icon){
            case 'cloud':
                return isLoading ? SpinnerItem : <Tooltip title={tooltipText}>{CloudIcon}</Tooltip>
            case 'add':
                return isLoading ? SpinnerItemDashed : <Tooltip title={tooltipText}>{AddIconElement}</Tooltip>
            case 'plus':
                return isLoading ? SpinnerItem : <Tooltip title={tooltipText}>{PlusIcon}</Tooltip>
            default:
                console.error('unknown icon type')
        }
    }

    // eslint-disable-next-line
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, isDragActive: true, noClick: props.clickable ? props.clickable : false})

    return (
            <div {...getRootProps()}>
                <input {...getInputProps()}/>
                {IconSelector(icon, isLoading)}
            </div>
        )
}

export default MultiUploader;