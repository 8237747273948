const styles = {
    root: {
        height:'100vh',
        marginTop:-55
    },
    container: {
        position:'relative',
        top:'50%',
        transform:'translateY(-50%)',
        textAlign: 'center'
    }
}

export default styles