import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { ACCEPTED_LANGUAGES } from '../../config/commonConfig'
import { CLEAR_STORE } from '../../store/actionTypes'

import {checkToken,} from '../api/login'
import {
    CLEAR_AUTH_ERRORS,
    REQUEST_TEAM_IDS,
    REQUEST_LOGIN_MCB,
    SET_LANGUAGE,
    SUCCEED_REQUEST_TOKEN
} from '../store/actionTypes'
import LoginLayout from './Layout'
import HeliocorStorage from '../../config/HeliocorStorage'
import { GET_COMMON_SETTINGS } from '../../apps/common/store/actionTypes'
import isEmpty from 'lodash/isEmpty'
import { OPEN_NOTIFICATION } from "../../notifications/actionTypes"
import { GET_ORG_TEAMS } from '../../apps/Admin/store/actionTypes'

const mapStateToProps = store => ({
    token: store.auth.token,
    isSysAdmin: store.auth.permissions?.SYSTEM?.includes('ADMIN'),
    permissions: store.auth.permissions,
    loginError: store.auth.loginError,
    passwordExpired: store.auth.passwordExpired,
    resetError: store.auth.resetError,
    resetStatus: store.auth.resetStatus,
    language: store.auth.language,
    formatters: store.common.formatters,
    systemCurrency: store.common.formatters.currency
})

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch({ type: REQUEST_LOGIN_MCB, username, password }),
    clearStore: () => dispatch({ type: CLEAR_STORE }),
    clearErrors: () => dispatch({ type: CLEAR_AUTH_ERRORS }),
    setLanguage: language => dispatch({ type: SET_LANGUAGE, language }),
    setLoginData: loginData => dispatch({ type: SUCCEED_REQUEST_TOKEN, ...loginData }),
    getFormatters: () => dispatch({ type: GET_COMMON_SETTINGS }),
    getTeam: () => dispatch({ type: REQUEST_TEAM_IDS }),
    getOrgTeams: () => dispatch({ type: GET_ORG_TEAMS }),
    openNotification: data => dispatch({ type: OPEN_NOTIFICATION, data })
})

class LoginContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loginStatus: 'login',
            username: ''
        }
    }

    login = () => {
        const { username, password } = this.state
        this.props.login(username, password)
    }

    handleInputChange = key => event => {
        this.setState({ [key]: event.target.value }, key === 'newpwd2' ? this.validatePasswords : null)
    }

    validatePasswords = () => {
        const { t } = this.props
        const { newpwd1, newpwd2 } = this.state
        const validated = newpwd1 === newpwd2
        this.setState({ error: !validated && t("login.errorEqual") })
        return validated
    }

    async componentDidMount() {
        const { location, setLoginData, i18n } = this.props
        if (location.state && location.state.changePwd) {
            this.setState({ loginStatus: 'changePwd', resetPwdRequested: true })
            this.props.history.replace({
                state: { resetPwdRequested: false }
            })
        }

        const recoverToken = location.search.split('?token=')[1]
        if (recoverToken) {
            this.setState({ loginStatus: 'credentials', loginSubstatus: 'checking', recoverToken })
            checkToken(recoverToken).then(res => this.setState({ loginSubstatus: res && res.errors ? 'error' : 'success' }))
        }

        const loginData = await HeliocorStorage.getItem('loginData')
        const sidebar = await HeliocorStorage.getItem('sideBar')

        loginData && setLoginData({ ...loginData, sidebar })

        const navigatorLanguage = window.navigator.language
        const storedLanguage = await HeliocorStorage.getItem('language')
        const fallbackLanguage = ACCEPTED_LANGUAGES.includes(navigatorLanguage) ? navigatorLanguage : 'en-EN'
        
        // TODO: we should not add language to store
        this.props.setLanguage(storedLanguage || fallbackLanguage)
        i18n.changeLanguage(storedLanguage || fallbackLanguage)
    }

    async componentDidUpdate(prevProps) {
        const { passwordExpired, permissions, resetStatus, formatters, t, isSysAdmin, getTeam, getOrgTeams, getFormatters } = this.props
        document.title = `${(t('button.login'))} - Heliocor Platform`
        if (prevProps.permissions !== permissions) {
            const loginData = await HeliocorStorage.getItem('loginData')
            if (loginData) {
                if (isEmpty(formatters) && !isSysAdmin) {
                    getFormatters()
                }
                getTeam()
                getOrgTeams()
            }
            if (passwordExpired) {
                this.setState({ loginStatus: 'changePwd' })
            }
        }
        if (prevProps.resetStatus !== resetStatus) {
            resetStatus === 'succeed' && this.setState({ resetPwdRequested: false })
        }
    }

    render() {
        const { url, resetPwdRequested, username } = this.state
        const { token, loginError, passwordExpired, location, isSysAdmin } = this.props

        const localURL = url ? { from: { pathname: this.state.url } } : null
        let { from } = localURL || location.state || { from: { pathname: '/home' } }

        isSysAdmin && !location.state?.changePwd && (from = '/sysadmin')

        return (
            token && !passwordExpired && !resetPwdRequested && !loginError
                ?
                <Redirect to={from} />
                :
                <LoginLayout
                    handleInputChange={this.handleInputChange}
                    loginError={loginError}
                    login={this.login}
                    username={username}
                />
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginContainer))

