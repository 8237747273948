import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import WarningDialog from './WarningDialog'
import { Button } from 'heliocor-ui'

/**
 ** props: content, open, onClose, buttons: array of objects (type, translate, onClick(fn)), title, defaultKeyAction (fn)
 ** important: please be aware to put choose the default action put a function on the prop: defaultKeyAction
 ** copyToClipboard: if the button is using CopyToClipboard pass an object in buttons as copyToClipboard:{text: text to copy}
 */

export const WarningBox = ({ buttons, content, noPadding, onClose, open, title, wizard, overflow }) => (
    <WarningDialog
        content={content}
        onClose={onClose}
        noPadding={noPadding}
        open={open}
        title={title}
        wizard={wizard}
        overflow={overflow}
    >
        {buttons && buttons.map((button, index) =>
            button.copyToClipboard
                ?
                <CopyToClipboard key={index} text={button.copyToClipboard.text}>
                    <Button
                        type={button.type}
                        value={button.value}
                        autoFocus={button.autoFocus}
                    />
                </CopyToClipboard>
                :
                <Button
                    key={index}
                    data-cy={button.dataCy}
                    type={button.type}
                    onClick={button.onClick}
                    value={button.value}
                    autoFocus={button.autoFocus}
                    disabled={button.disabled}
                    isLoading={button.loading}
                />
        )}
    </WarningDialog>
)

export default WarningBox