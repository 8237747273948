import React from 'react'
import styled from 'styled-components'

const PerfectCenterParent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const PerfectCenter = (props) => (
    <PerfectCenterParent>
        {props.children}
    </PerfectCenterParent>
)

export default PerfectCenter