import { device } from 'heliocor-ui'

const colors = {
    colors: {
        // NEW PALETTE //
        main: '#FE593B',
        main400: '#FFEAE7',
        red100: '#D62728',
        aqua100: '#7DDEBD',
        orange100: '#BF452F',
        orange300: '#FFA54E',
        mustard100: '#FFC107',
        mustard200: '#F6C244',
        lemon100: '#F9F905',
        blue100: '#2D5185',
        blue200: '#3BA4FE',
        blue300: '#C3F1F5',
        blue500: '#F1F2F3',
        bluelight100: '#F3F6FA',
        green50: '#006400',
        green100: '#288403',
        green200: '#309C30',
        green300: '#7BC15F',
        green400: '#66C2AD',
        grey100: '#777777',
        grey300: '#C9C9C9',
        grey500: '#DDDDDD',
        grey700: '#F7F7F7',
        black: '#000000',
        black100: '#333',
        white: '#fff',
        transparent: 'transparent',



        /* OLD PALETTE - Deprecated
        please use the ones above */

        blackTint:'#333',
        blue:'#3c6470',
        grey:'#ccc',
        greyBG:'#f5f5f5',
        lightBlueItem: '#F1F2F3',
        greyLight:'#e4e2e2',
        clearOrange:'#ffe0db',
        warning:'#ffa54e',
        darkOrange:'#bf452f',
        red:'#d62728',
        green:'#309c30',
        yellow:'#f9f905',
        green_agree: '#288403',
        greyTint: '#707070',
        grey666: '#666',
        grey777: '#777',
        mustard: '#ffc107',
        greyBorderMUI: '#dddddd',
        greyTopMenuClear: '#C9C9C9',
        blueLight: '#c3f1f5',
        greySettingBar: '#f7f7f7',
        greySettingBarBorder: '#d9d9d9',
        greyBB: '#bbbbbb'
    }
}

const shadows = {
    shadows: {
        menu: '0px 2px 11px 0px rgba(0,0,0,0.25)',
        alert: '0px 2px 8px 1px rgba(0,0,0,0.16)',
        button: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
        buttonHover: '0px 2px 10px 0px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.14), 0px 6px 2px -4px rgba(0, 0, 0, 0.12)',
        card: '0 4px 8px 0 rgba(0,0,0,0.2)',
        cardHover:'0 8px 16px 0 rgba(0,0,0,.2)'
    }
}



const typography = {
    typography: {
        titleH1: {
            fontSize: '1.423rem',
            fontWeight: '700',
            textTransform: 'capitalize',
            fontColor: colors.colors.black100
        },
        titleH2: {
            fontSize: '1.286rem',
            fontWeight: '500',
            textTransform: 'capitalize',
            fontColor: colors.colors.black100
        },
        titleH3: {
            fontSize: '1.142rem',
            fontWeight: '500',
            textTransform: 'capitalize',
            fontColor: colors.colors.black100
        },
        titleH4: {
            fontSize: '1rem',
            fontWeight: '400',
            textTransform: 'capitalize',
            fontColor: colors.colors.black100
        },
        titleH5: {
            fontSize: '0.857rem',
            fontWeight: '600',
            textTransform: 'capitalize',
            fontColor: colors.colors.grey100
        },
        titleH6: {
            fontSize: '0.714rem',
            fontWeight: '600',
            textTransform: 'capitalize',
            fontColor: colors.colors.grey100
        },
        titleH7: {
            fontSize: '0.643rem',
            fontWeight: '400',
            textTransform: 'capitalize',
            fontColor: colors.colors.black100
        },
        actionTitle: {
            fontSize: '16px', 
            fontWeight: '600',
            textTransform: 'uppercase',
            fontColor: colors.colors.black100
        },
        strapLine: {
            fontSize: '12px', 
            fontWeight: '300',
            fontColor: colors.colors.black100
        },
        paragraph: {
            fontSize: '1rem',
            fontWeight: '400',
            fontColor: colors.colors.grey100
        },
        paragraph2: {
            fontSize: '0.929rem',
            fontWeight: '400',
            fontColor: colors.colors.grey100,
            margin: '0rem 0rem 0.714rem 0rem'
        },
        paragraph3: {
            fontSize: '0.857rem',
            fontWeight: '400',
            fontColor: colors.colors.grey100
        },
        paragraphLight: {
            fontSize: '1rem',
            fontWeight: '200',
            fontColor: colors.colors.grey100
        },
        errorParagraph: {
            fontSize: '12px', 
            fontWeight: '300',
            fontColor: colors.colors.red100
        },
        cardTitle: {
            fontSize: '18px', 
            fontWeight: '400',
            textTransform: 'capitalize',
            fontColor: colors.colors.main
        },
        cardStrapLine: {
            fontSize: '13px', 
            fontWeight: '400',
            fontColor: colors.colors.black100
        },
        infoText: {
            fontSize: '14px', 
            fontWeight: '600',
            fontColor: colors.colors.black100
        }
    }
}

const layout = {
    layout: {
        boxSize: 'auto',
        padding: '0'
    }
}

const devices = {
    devices: {
        small: device.mobile,
        medium: device.midSize,
        normal: device.tablet,
        large: device.desktop
    }
}

const buttons = {
    buttons: {
        fontSize: {
            xSmall: '10px',
            small: '13px',
            medium: '14px',
            large: '20px'
        },
        backgroundColor: {
            'default': colors.colors.main,
            'info': colors.colors.blue100,
            'warning': colors.colors.mustard100,
            'error': colors.colors.red100,
            'success': colors.colors.green100,
            'disabled': colors.colors.grey500,
            'light': colors.colors.transparent,
            'light-hover': colors.colors.main400,
            'black': colors.colors.black,
            'white': colors.colors.white
        },
        color: {
            'default': colors.colors.white,
            'info': colors.colors.white,
            'warning': colors.colors.white,
            'error': colors.colors.white,
            'success': colors.colors.white,
            'disabled': colors.colors.grey100,
            'light': colors.colors.main,
            'black': colors.colors.white,
            'white': colors.colors.main
        }
    }
}

const styledTheme = {...colors, ...shadows, ...typography, ...layout, ...devices, ...buttons}

export default styledTheme