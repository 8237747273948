import {
    CLEAR_CASE_STATUS,
    DELETE_CASE,
    RESET_CURRENT_ALERT,
    RESET_CURRENT_CUSTOMER,
    RESET_DASHBOARD,
    SET_ALERT_REPORT,
    SET_ALERTS_HISTORY,
    SET_ALGOS,
    SET_CASE_MANAGEMENT_STATUS,
    SET_CASE_STATUS,
    SET_CURRENT_ALERT,
    SET_CURRENT_ALERT_CUSTOMER,
    SET_CURRENT_ALERT_CUSTOMER_ACCOUNTS,
    SET_CURRENT_ALERT_CUSTOMER_ALERTS,
    SET_CURRENT_ALERT_CUSTOMER_CASES,
    SET_CURRENT_ALERT_CUSTOMER_TRANSACTIONS,
    SET_CURRENT_ALGO_SETTINGS,
    SET_CURRENT_CASE,
    SET_CURRENT_EVIDENCE,
    SET_CURRENT_NUM_OF_ALERTS,
    SET_CURRENT_RADAR,
    SET_CURRENT_RADAR_BELOW_RADAR,
    SET_CURRENT_RADAR_ISSUES,
    SET_CURRENT_ROBOT,
    SET_RADAR_AUDITS,
    SET_ROBOT_AUDITS,
    SET_ROBOT_ISSUES_HISTORY,
    SET_ROBOT_SIMULATION,
    SET_ROBOTS,
    SET_USER_DASHBOARD_CONFIG,
    SET_WIDGET_DATA,
    SUCCEED_REQUEST_ALERT_AUDITS,
    SUCCEED_REQUEST_ALERT_EVIDENCE,
    SUCCEED_REQUEST_ALERTS,
    SUCCEED_REQUEST_CASE_ALERTS,
    SUCCEED_REQUEST_CASES,
    SET_CURRENT_ALERT_BELOW_RADAR,
    SET_CLUSTERS,
    SET_REPORTS,
    SET_CURRENT_CLUSTER_ALERTS,
    SET_CURRENT_CLUSTER_CUSTOMERS,
    SET_WATCH_LISTS,
    SET_CURRENT_WATCHLIST,
    SET_CURRENT_WATCHLIST_MEMBERS,
    RESET_CURRENT_WATCHLIST,
    SET_COUNTRIES_RISKS_SETTINGS,
    ADD_LOADING_COUNTRY_RISK_ID,
    REMOVE_LOADING_COUNTRY_RISK_ID,
    RESET_CLUSTERS,
    RESET_CURRENT_CLUSTER,
    RESET_CURRENT_CASE,
    SET_CURRENT_CHART_BELOW_RADAR,
    SET_CURRENT_CHART_ISSUES,
    RESET_CURRENT_CHART,
    SET_CURRENT_RADAR_CHART,
    SET_CURRENT_ALERT_SIMILARITY,
    SET_ALERT_NBA,
    SET_ALERT_COMMENTS,
    SET_CURRENT_ALERT_SAME_TXN_ALERTS,
    SET_NBA_DATA,
    SET_ALERTS_TRIAGE,
    SET_ROBOT_ISSUES, SET_CLASSIFICATION_TYPES,
    SUCCEED_REQUEST_CASE_AUDITS,
    UPDATE_DASHBOARD_FILTERS,
    SET_ALERTED_PAYMENT_IDS,
    SET_ROBOTS_TRANSACTION_DATA,
} from './actionTypes'

function caseManagement(
    state =
        {
            alertsData: {
                alerts: [],
                count: 0,
                summary: []
            },
            algosData: {
                algos: [],
                count: 0
            },
            clustersData: {
                count: 0,
                countList: [],
                data: []
            },
            countriesRisksData: {
                countriesRisks: [],
                count: 0
            },
            robotsData: {},
            robotAuditsData: {
                audits: [],
                count: 0
            },
            casesData: {
                cases: [],
                summary: [],
                count: 0
            },
            reportsData: {
                reports: [],
                count: 0
            },
            watchListsData: {
                watchLists: [],
                count: 0
            },
            currentAlert: {},
            currentCase: {},
            currentCustomer: {},
            currentAlgoSettings: [],
            currentCustomerTransactions: {},
            currentCustomerAccounts: [],
            currentCustomerAlerts: [],
            currentCustomerCases: [],
            currentEvidence: {},
            status: '',
            dashboardSettings: ["ABST", "CBST", "UA", "ABGA", "ABSC"],
            dashboardWidgetsData: {},
            currentRadar: undefined,
            currentCluster: {
                alertsData: {
                    alerts: [],
                    count: 0
                },
                customersData: {
                    customers: [],
                    count: 0
                }
            },
            currentWatchList: {
                membersData: {
                    watchListMembers: [],
                    count: 0
                }
            },
            countriesRiskLoadingIds: [],
            classificationTypesData: {
                classificationTypes: [],
                count: 0
            }
        }
    , action) {
    switch (action.type) {
        case SET_CLASSIFICATION_TYPES:
            return {
                ...state,
                classificationTypesData: {
                    classificationTypes: action.classificationTypes,
                    count: action.count
                }
            }
        case SET_ROBOT_ISSUES:
            return {
                ...state,
                currentRobotIssues: {
                    issues: action.issues,
                    count: action.count
                }
            }
        case SET_ALERTS_TRIAGE:
            return {
                ...state,
                alertsTriageData: {
                    alertsTriage: action.alertsTriage,
                    count: action.count,
                    max: action.max
                }
            }
        case SET_NBA_DATA:
            return {
                ...state,
                neighboursData: {
                    data: action.neighbours,
                    count: action.neighboursCount,
                    nbaCount: action.neighboursNbaCount,
                    nba: action.nba
                }
            }
        case SET_ALERT_COMMENTS:
            return {
                ...state,
                alertComments: action.alertComments
            }
        case SET_ALERT_NBA:
            return {
                ...state,
                currentAlert: {
                    ...state.currentAlert,
                    nba: action.nba
                }
            }
        case SET_CURRENT_ALERT_SIMILARITY:
            return {
                ...state,
                currentAlertSimilarity: action.alertSimilarity
            }
        case SET_CURRENT_RADAR_CHART:
            return {
                ...state,
                currentRadar: {
                    ...state.currentRadar,
                    chartValues: action.chartValues
                }
            }
        case RESET_CLUSTERS:
            return {
                ...state, clustersData: {
                    count: 0,
                    countList: [],
                    data: []
                }
            }
        case RESET_CURRENT_CLUSTER:
            return {
                ...state, currentCluster: {
                    alertsData: {
                        alerts: [],
                        count: 0
                    },
                    customersData: {
                        customers: [],
                        count: 0
                    }
                }
            }
        case ADD_LOADING_COUNTRY_RISK_ID:
            return { ...state, countriesRiskLoadingIds: [...state.countriesRiskLoadingIds, action.countryRiskId] }

        case REMOVE_LOADING_COUNTRY_RISK_ID:
            return { ...state, countriesRiskLoadingIds: state.countriesRiskLoadingIds.filter(id => id !== action.countryRiskId) }

        case SET_COUNTRIES_RISKS_SETTINGS:
            return { ...state, countriesRisksData: action.countriesRisksData }

        case RESET_CURRENT_WATCHLIST:
            return {
                ...state,
                currentWatchList: {
                    membersData: {
                        watchListMembers: [],
                        count: 0
                    }
                }
            }

        case SET_CURRENT_WATCHLIST:
            return {
                ...state,
                currentWatchList: {
                    ...state.currentWatchList,
                    ...action.watchListInfo
                }
            }

        case SET_CURRENT_WATCHLIST_MEMBERS:
            return {
                ...state,
                currentWatchList: {
                    ...state.currentWatchList,
                    membersData: action.membersData
                }
            }

        case SET_WATCH_LISTS:
            return { ...state, watchListsData: action.watchListsData }

        case SET_CURRENT_CLUSTER_ALERTS:
            return {
                ...state,
                currentCluster: {
                    ...state.currentCluster,
                    clusterId: action.currentClusterId,
                    alertsData: action.currentClusterAlerts
                }
            }

        case SET_CURRENT_CLUSTER_CUSTOMERS:
            return {
                ...state,
                currentCluster: {
                    ...state.currentCluster,
                    clusterId: action.currentClusterId,
                    customersData: action.currentClusterCustomers
                }
            }

        case SET_REPORTS:
            return { ...state, reportsData: action.reportsData }

        case SET_CLUSTERS:
            return { ...state, clustersData: action.clustersData }

        case SET_CURRENT_ALERT_BELOW_RADAR:
            return { ...state, currentAlert: { ...state.currentAlert, belowRadarData: action.belowRadarData } }

        case SET_RADAR_AUDITS:
            return { ...state, currentRadar: { ...state.currentRadar, audits: action.audits } }

        case SET_CURRENT_RADAR_BELOW_RADAR:
            return { ...state, currentRadar: { ...state.currentRadar, belowRadarData: action.belowRadarData } }

        case SET_CURRENT_CHART_BELOW_RADAR:
            return { ...state, currentRadar: { ...state.currentRadar, belowRadarIssues: action.belowRadarIssues } }

        case SET_CURRENT_RADAR_ISSUES:
            return { ...state, currentRadar: { ...state.currentRadar, issuesData: action.issuesData } }

        case SET_CURRENT_CHART_ISSUES:
            return { ...state, currentRadar: { ...state.currentRadar, issuesChart: action.issuesChart } }

        case RESET_CURRENT_CHART:
            return { ...state, currentRadar: { ...state.currentRadar, issuesChart: undefined, belowRadarIssues: undefined } }

        case SET_CURRENT_ALERT:
            if (state.currentAlert && state.currentAlert.report) window.URL.revokeObjectURL(state.currentAlert.report)
            return {
                ...state, currentAlert: {
                    ...action.currentAlert,
                    nba: state.currentAlert.nba
                }
            }

        case RESET_CURRENT_ALERT:
            if (state.currentAlert && state.currentAlert.report) window.URL.revokeObjectURL(state.currentAlert.report)
            return { ...state, currentAlert: {} }

        case SUCCEED_REQUEST_CASES:
            return {
                ...state,
                casesData: {
                    cases: action.cases.cases, count: action.cases.count, summary: action.cases.countList
                }
            }

        case SUCCEED_REQUEST_ALERTS:
            return {
                ...state,
                alertsData: {
                    alerts: action.alerts.alerts,
                    count: action.alerts.count,
                    summary: action.alerts.countList
                }
            }

        case SUCCEED_REQUEST_CASE_ALERTS:
            return {
                ...state,
                caseAlerts: action.caseAlerts.alerts,
                caseAlertsCount: action.caseAlerts.count,
                caseAlertsSummary: {
                    countNew: action.caseAlerts.countNew,
                    countOpen: action.caseAlerts.countOpen,
                    countInvestigating: action.caseAlerts.countInvestigating,
                    countAssigned: action.caseAlerts.countAssigned
                }
            }

        case SUCCEED_REQUEST_ALERT_AUDITS:
            return {
                ...state, alertAudits: action.alertAudits.audits, alertAuditsCount: action.alertAudits.count
            }

        case SUCCEED_REQUEST_CASE_AUDITS:
            return {
                ...state, caseAudits: action.audits, caseAuditsCount: action.count
            }

        case SUCCEED_REQUEST_ALERT_EVIDENCE:
            const { alertEvidences } = action.alertEvidence

            if (state.alertEvidence) {
                const { alertEvidence } = state

                alertEvidence.forEach(element => {
                    if (alertEvidences.find(item => item.identifier === element.identifier) === undefined) {
                        window.URL.revokeObjectURL(element.data.data)
                    }
                })
            }
            return {
                ...state, alertEvidence: alertEvidences, alertEvidenceCount: action.alertEvidence.count
            }

        case SET_CURRENT_CASE:
            return { ...state, currentCase: { ...action.currentCase } }

        case RESET_CURRENT_CASE:
            return { ...state, currentCase: {} }

        case DELETE_CASE:
            return { ...state, cases: state.cases.filter(c => c.identifier !== action.identifier) }

        case SET_CASE_STATUS:
            return {
                ...state, caseError: action.caseError, caseStatus: action.caseStatus, caseSuccess: action.caseSuccess
            }

        case CLEAR_CASE_STATUS:
            return { ...state, caseError: null, caseStatus: null, caseSuccess: null }

        case SET_ALGOS:
            return { ...state, algosData: action.algosData }

        case SET_ROBOTS:
            return { ...state, robotsData: action.robotsData }

        case SET_ROBOTS_TRANSACTION_DATA:
            return { ...state, robotsTransactionData: action.robotsTransactionData }
    

        case SET_CURRENT_ROBOT:
            return { ...state, currentRobot: action.robot }
        
        case SET_CURRENT_ALGO_SETTINGS:
            return { ...state, currentAlgoSettings: action.algoSettings }

        case SET_CASE_MANAGEMENT_STATUS:
            return { ...state, status: action.status }

        case SET_CURRENT_ALERT_CUSTOMER:
            return { ...state, currentCustomer: action.currentCustomer }

        case SET_CURRENT_ALERT_CUSTOMER_TRANSACTIONS:
            return { ...state, currentCustomerTransactions: action.transactions }

        case SET_CURRENT_ALERT_CUSTOMER_ALERTS:
            return { ...state, currentCustomerAlerts: action.alerts }

        case SET_CURRENT_ALERT_CUSTOMER_CASES:
            return { ...state, currentCustomerCases: action.cases }

        case SET_CURRENT_ALERT_SAME_TXN_ALERTS:
            return { ...state, currentCustomerSameTxn: action.alerts }

        case SET_CURRENT_ALERT_CUSTOMER_ACCOUNTS:
            return { ...state, currentCustomerAccounts: action.accounts }

        case SET_CURRENT_NUM_OF_ALERTS:
            return { ...state, numOfAlerts: action.numOfAlerts }

        case SET_USER_DASHBOARD_CONFIG:
            return { ...state, dashboardSettings: action.config }

        case SET_WIDGET_DATA:
            return { ...state, dashboardWidgetsData: { ...state.dashboardWidgetsData, [action.widget]: action.data } }

        case RESET_DASHBOARD:
            return { ...state, dashboardSettings: null, dashboardWidgetsData: {} }

        case RESET_CURRENT_CUSTOMER:
            return { ...state, currentCustomer: {} }

        case SET_ALERT_REPORT:
            return { ...state, currentAlert: { ...state.currentAlert, report: action.reportURL } }

        case SET_ALERTS_HISTORY:
            return { ...state, alertsHistory: action.alertsHistory }

        case SET_ROBOT_SIMULATION:
            return { ...state, robotSimulation: action.simulationResult }

        case SET_ROBOT_ISSUES_HISTORY:
            return { ...state, currentRobotIssuesHistory: action.issuesHistory }

        case SET_ROBOT_AUDITS:
            return {
                ...state, robotAuditsData: {
                    audits: action.audits,
                    count: action.count
                }
            }

        case SET_CURRENT_RADAR:
            return { ...state, currentRadar: action.radar }

        case SET_CURRENT_EVIDENCE:
            return { ...state, currentEvidence: action.evidence }

        case UPDATE_DASHBOARD_FILTERS:
            return {
                ...state,
                dashboardFilters: {
                    ...state.dashboardFilters,
                    [action.dashboardType]: {
                        ...state.dashboardFilters?.[action.dashboardType],
                        [action.filterType]: action.filterValue,
                    }
                }
            }
        case SET_ALERTED_PAYMENT_IDS:
            return { ...state, alertedPaymentIds: action.paymentIdList}

        default:
            return state
    }
}

export default caseManagement