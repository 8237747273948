import React from 'react'
import ContentLoader from 'react-content-loader'

const StatusChangerLoader = () => (
    <ContentLoader width={512} height={149} style={{backgroundColor:'#fff'}}>
        <rect x="30" y="30" rx="3" ry="3" width="200" height="20" />
        <rect x="30" y="75" rx="3" ry="3" width="400" height="55" />
    </ContentLoader>
)

export default StatusChangerLoader