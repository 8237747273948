import styled, { css } from 'styled-components'
import { device, FlexContainer } from 'heliocor-ui'

const KYCmediaLarge = css`
    align-self: baseline;
    padding: 5px;
    width: 100%;
    min-width: 450px;
    flex-direction: row;
`

const FlexContainerColorBox = styled(FlexContainer)`
    display: flex;
    flex: 0 0 60px;
    flex-direction: row;
    min-width: 450px;
    width: 100%;
    @media ${device.midSize}{
        flex:1;
        ${KYCmediaLarge}
    }
    @media (min-width: 1940px){
        justify-content: space-between;
        min-width: auto;
    }
`

const ColoredBox = styled.div`
    display: flex;
    flex: ${props => `1 1 ${100 / props.length}%`};
    margin: 32px 4px;
    background-color: ${props => props.theme.colors[props.bgColor]};
    align-items: center;
    padding: 8px;
    color: white;
    cursor: ${props => props.isButton ? 'pointer' : null};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${device.midSize} {
        font-size: 10px;
        margin: 4px;
    }
    @media (min-width: 1200px){
        font-size: 16px;
    }
    @media (min-width: 1940px){
        font-size: 14px;
    }
`

const BoxData = styled.div`
    font-size: 28px;
    margin: 0;
    font-weight:600;
    text-align: center;
`

const BoxTitle = styled.div`
    font-weight: 600;
    font-size: 9px;
    margin:0;
    text-align: center;
    text-transform: capitalize;
    @media ${device.midSize} {
        font-size: 10px;
    }
    @media (min-width: 1200px){
        font-size: 12px;
    }
    text-transform: uppercase;
`

export { ColoredBox, BoxData, BoxTitle, FlexContainerColorBox }