/**
 * Created by Ivan de la Fuente on 24/11/2017.
 */
export const colors ={
    blackTint:'#333',
    black:'#000',
    main:'#fe593b',
    blue:'#3c6470',
    grey:'#ccc',
    greyBG:'#f5f5f5',
    greyLight:'#e4e2e2',
    clearOrange:'#ffe0db',
    warning:'#ffa54e',
    darkOrange:'#bf452f',
    red:'#d62728',
    green:'#309c30',
    yellow:'#f9f905',
    green_agree: '#288403',
    greyTint: '#707070',
    grey666: '#666',
    grey777: '#777',
    mustard: '#ffc107',
    greyBorderMUI: '#dddddd',
    greyTopMenuClear: '#C9C9C9',
    blueLight: '#c3f1f5',
    greySettingBar: '#f7f7f7',
    greySettingBarBorder: '#d9d9d9',
    white: '#fff'
}