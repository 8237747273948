import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'
import THead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import styledTheme from "../../../styles/styledTheme"

const TableHead = props => {

    const { order, orderBy = 'DESC', columns, selectable, numSelected, onSelectAllClick, handleSort, checkBoxClass, maxNumToSelect,
        disableSelectAll, firstColumnPadding } = props

    const handleSortDirection = (key) => {
        let newSortingDirection = 'ASC'
        if (orderBy === key) {
            newSortingDirection = (order === 'ASC') ? 'DESC' : 'ASC'
        }
        handleSort(newSortingDirection, key)
    }

    const getPadding = (firstColumnPadding, i) => {
        if (firstColumnPadding) {
            if (i === 0) {
                return '3px 16px'
            } else {
                return '3px 8px'
            }
        } else {
            return '3px 8px'
        }
    }

    return (
        <THead>
            <TableRow>
                {selectable &&
                    <TableCell classes={{ root: checkBoxClass }} style={{ width: 48 }}>
                        {
                            !disableSelectAll &&
                            <Checkbox
                                color='primary'
                                indeterminate={numSelected > 0 && numSelected < maxNumToSelect}
                                checked={numSelected === maxNumToSelect}
                                onChange={onSelectAllClick}
                            />
                        }
                    </TableCell>
                }
                {columns.map((column, i) => {
                    return (
                        <TableCell
                            key={i}
                            numeric={column.numeric}
                            padding={column.disablePadding ? 'none' : 'normal'}
                            sortDirection={(orderBy && orderBy === column.key) && order.toLowerCase()}
                            style={
                                Object.assign(
                                    {},
                                    {
                                        fontSize: '0.929rem',
                                        fontWeight: 600,
                                        padding: getPadding(firstColumnPadding, i),
                                        color: styledTheme.colors.grey100,
                                        minWidth: column.minWidth || 'inherit',
                                        width: column.width
                                    },
                                    column.style
                                )}
                            align={column.align}
                        >
                            {column.sortable
                                ?
                                    <TableSortLabel
                                        active={orderBy === column.key || orderBy === column.sortingKey}
                                        direction={order && order.toLowerCase()}
                                    >
                                <Tooltip
                                    title={"Sort"}
                                    onClick={() => handleSortDirection(column.sortingKey || column.key)}
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                        <span>{column.label}</span>
                                </Tooltip>
                                    </TableSortLabel>
                                :
                                <p style={{ width: column.width }}>{column.label}</p>
                            }
                        </TableCell>
                    )
                }, this)}
            </TableRow>
        </THead>

    )
}

export default TableHead