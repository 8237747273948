import styled, { css } from 'styled-components';
import Info from '@material-ui/icons/Info'
import Required from '@material-ui/icons/ReportProblem'
import Wait from "@material-ui/icons/AccessTime"
import Yes from "@material-ui/icons/CheckCircle"
import No from "@material-ui/icons/HighlightOff"
import Warning from "@material-ui/icons/Warning"

const sharedIconStyle = css`
    fill: ${props => props.theme.colors[props.fill]};
    width: ${props => props.size || '14px'};
    height: ${props => props.size || '14px'};
    cursor: ${props => props.cursor || 'help'};
`

const topRightCorner = css`
    position: relative;
    margin: ${props => props.margin || '0px auto'};
    margin-bottom: 3px;
    margin-left: 3px;
`

const InfoStyled = styled(Info)`&& {${sharedIconStyle}${topRightCorner}}`
const RequiredStyled = styled(Required)`&& {${sharedIconStyle}${topRightCorner}}`
const WaitStyled = styled(Wait)`&& {${sharedIconStyle}}`
const YesStyled = styled(Yes)`&& {${sharedIconStyle}}`
const NoStyled = styled(No)`&& {${sharedIconStyle}}`
const WarningStyled = styled(Warning)`&& {${sharedIconStyle}}`
const ErrorStyled = styled(Warning)`&& {${sharedIconStyle}}`


export {
    InfoStyled,
    RequiredStyled,
    WaitStyled,
    YesStyled,
    NoStyled,
    WarningStyled,
    ErrorStyled
}