import React, { Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RELEASE_NUM } from '../../../../config/domain'
import HeliocorStorage from '../../../../config/HeliocorStorage'
import { SET_LANGUAGE } from '../../../../login/store/actionTypes'
import i18next from 'i18next'
import { LanguageSwitcher, Tab, TabPanel, TabSwitcher, Translate } from '../../index'
import {
    LogOutText,
    OpenInNewTabIconTopBarLink,
    OptionList,
    OptionListedItems,
    TopBarLogout,
    TopBarLinkContainer,
    TopBarLinkText,
    UserName,
    VersionNum
} from './TopBar.styles.js'

const DUM = {
    optionKey: 'dokstorUserManual',
    translateId: 'dokstorUserManual',
    hrefValue: 'https://storage.googleapis.com/dokstor-documentation/Dokstor User Manual.pdf'
}

const RUM = {
    optionKey: 'roboliticsUserManual',
    translateId: 'roboliticsUserManual',
    hrefValue: 'https://storage.googleapis.com/dokstor-documentation/Robolitics User Manual.pdf'
}

const DRN = {
    optionKey: 'releaseNotes',
    translateId: 'releaseNotes',
    hrefValue: 'https://storage.googleapis.com/dokstor-documentation/Dokstor Release Notes.pdf'
}

const DAM = {
    optionKey: 'dokstorAdminManual',
    translateId: 'dokstorAdminManual',
    hrefValue: 'https://storage.googleapis.com/dokstor-documentation/Dokstor Administrator Manual.pdf'
}

const UserOptions = ({ user, pwdChange, AD }) => (
    <Fragment>
        <UserName>{`${user.name} (${user.organization})`}</UserName>
        <OptionList>
            {!AD &&
                <OptionListedItems onClick={pwdChange}>
                    <Translate id="changePassword" />
                </OptionListedItems>
            }
            <Tab id="secondScreen">
                <OptionListedItems><Translate id="changeLanguage" /></OptionListedItems>
            </Tab>
        </OptionList>
    </Fragment>
)

const TopBarLink = ({ translateId, href }) => (
    <TopBarLinkContainer href={href} target="_blank">
        <TopBarLinkText><Translate id={translateId} /></TopBarLinkText>
        <OpenInNewTabIconTopBarLink />
    </TopBarLinkContainer>
)

const TopMenuOptions = React.forwardRef(({ user, pwdChange, signOut, AD }, ref) => {

    const dispatch = useDispatch()

    const language = useSelector(store => store.auth.language)
    const permissions = useSelector(store => store.auth.permissions)

    let options = []

    if (permissions.DOKSTOR) options.push(DRN, DUM)
    if (permissions.DOKSTOR?.includes('ADMIN')) options.push(DAM)
    if (permissions.ROBOLITICS) options.push(RUM)

    const setLanguage = useCallback(
        (language) => dispatch({ type: SET_LANGUAGE, language }),
        [dispatch]
    )

    const onLanguageChange = (language) => {
        HeliocorStorage.setItem('language', language)
        i18next.changeLanguage(language)
        // TODO: we should not add language to store
        setLanguage(language)
    }

    return (
        <TopBarLogout ref={ref}>
            <TabSwitcher>
                <TabPanel whenActive="firstScreen">
                    <UserOptions user={user} pwdChange={pwdChange} AD={AD} />
                </TabPanel>
                <TabPanel whenActive="secondScreen">
                    <LanguageSwitcher onLanguageChange={onLanguageChange} language={language} Tab={Tab} />
                </TabPanel>
            </TabSwitcher>
            {options.map(option => <TopBarLink key={option.optionKey} translateId={option.translateId} href={option.hrefValue} />)}
            <LogOutText onClick={signOut} data-cy='logoutButton'><Translate id="logout" /></LogOutText>
            <VersionNum><Translate id="version" />: {RELEASE_NUM}</VersionNum>
        </TopBarLogout>
    )
})

export default TopMenuOptions