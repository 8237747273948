import React, { useEffect } from 'react'
import Select from "react-select"
import { useDispatch, useSelector } from 'react-redux'

import { selectCustomStylesV2 } from '../../config/styles'
import { REQUEST_ADMITTED_USERS } from '../Admin/store/actionTypes'

const OwnerSelector = ({
    onChange, placeholder, value, styles, permissions, application, teamId = '', isClearable = false
}) => {

    const newStyles = styles ? Object.assign({ ...selectCustomStylesV2 }, styles) : selectCustomStylesV2

    const dispatch = useDispatch()
    const admittedUsers = useSelector(store => store.admin.admittedUsers) || []
    const apiCallInProgress = !admittedUsers?.length

    const getAdmittedUsers = (application, permissions) => dispatch({ type: REQUEST_ADMITTED_USERS, application, permissions })

    const userOptions = admittedUsers
        .filter(u => !teamId || u.teams?.find(t => t.identifier === teamId))
        .map(u => ({ label: u.name, value: u.identifier }))

    const selectedOption = userOptions.find(option => option.value === value) || null

    useEffect(() => {
        getAdmittedUsers(application, permissions)
    }, [])

    return (
        <Select
            isClearable={isClearable}
            isLoading={apiCallInProgress}
            isDisabled={apiCallInProgress}
            options={userOptions}
            value={selectedOption}
            onChange={onChange}
            styles={newStyles}
            placeholder={placeholder}
        />
    )
}

export default OwnerSelector