import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { Component } from 'react'

import { colors } from '../../../config/colors'

export const styleOptions = {
    default: 'default',
    inverted: 'inverted'
}
 
export const cursorOptions = {
    default: 'default',
    pointer: 'pointer'
}

const styledBy = mapping => props => mapping[props.sliderStyle]
const cursorBy = mapping => props => mapping[props.cursorStyle]

const CustomSlider = withStyles({
    root: {
        height: 18,
        marginTop: 16,
        cursor: cursorBy({
            [cursorOptions.default]: 'default',
            [cursorOptions.pointer]: 'pointer'
        }),
        '&.max .MuiSlider-valueLabel:before': {
            width: 56,
            left: -12
        }
    },
    thumb: (props) => ({
        opacity: props.disabled ? 0 : 1,
        height: 15,
        width: 15,
        '&:hover': {
            boxShadow: cursorBy({
                [cursorOptions.default]: 'unset'
            })
        }
    }),
    valueLabel: {
        fontSize: 9,
        top: -19,
        marginLeft: 2,
        '& *': {
            background: 'transparent',
            color: 'white',
        },
        '&:before': {
            width: 32,
            height: 15,
            background: 'rgb(255, 151, 79)',
            content: '\'\'',
            position: 'absolute',
            marginTop: 7,
            borderRadius: 4,
        },
        '&:after': {
            width: 10,
            height: 10,
            position: 'absolute',
            content: '\'\'',
            background: 'rgb(255, 151, 79)',
            bottom: 5,
            transform: 'rotate(45deg)',
            marginLeft: 'calc(50% - 5px)',
            zIndex: -1
        }
    },
    track: {
        height: 5,
        color: styledBy({
            [styleOptions.default]: colors.main,
            [styleOptions.inverted]: 'rgb(236, 236,236)'
        }),
        opacity: 1
    },
    rail: {
        height: 5,
        backgroundColor: styledBy({
            [styleOptions.default]: 'rgb(236, 236,236)',
            [styleOptions.inverted]: colors.main
        }),
        opacity: 1
    },
    markLabel: {
        fontSize: 9,
        marginTop: 8,
        color: '#ccc'
    },
    mark: {
        marginTop: 8,
        height: 8,
        color: '#ccc',
        width: 1
    },
    markActive: {
        backgroundColor: '#ccc'
    }
})(({ classes, cursorStyle, sliderStyle, ...rest }) => (<Slider className={classes.root} classes={classes} {...rest} />))

class MySlider extends Component {

    getMarks = (min, max, delta) => {
        const result = []
        for (let i = min; i <= max; i = i + delta) {
            result.push(
                {
                    label: i,
                    value: i
                }
            )
        }
        return result
    }

    valuetext = (value) => {
        const { postfix } = this.props
        return postfix ? `${value} ${postfix}` : value
    }

    render() {
        const { disable, from = 0, grid = true, marksDelta = 10, max, min, onChange = () => { },
            sliderStyle = styleOptions.default, step = 1, values, cursorStyle = cursorOptions.pointer,
            onChangeCommitted = () => null, customMarks } = this.props

        const marks = customMarks || (values ? values.map((value, index) => {
            return {
                value: index,
                label: value
            }
        }) : min !== undefined && max !== undefined && grid ?
                this.getMarks(min, max, marksDelta) : undefined)

        return <CustomSlider
            aria-labelledby="discrete-slider"
            className={clsx({
                max: max > 99999
            })}
            disabled={disable}
            cursorStyle={cursorStyle}
            sliderStyle={sliderStyle}
            marks={marks}
            max={values ? values.length - 1 : Number(max)}
            min={values ? 0 : Number(min)}
            onChange={(e, value) => onChange(value)}
            step={values ? 1 : Number(step)}
            value={Array.isArray(from) ? from : isNaN(Number(from)) ? Number(min) : Number(from)}
            valueLabelDisplay={values ? "off" : "on"}
            valueLabelFormat={this.valuetext}
            onChangeCommitted={(e, value) => onChangeCommitted(value)}
        />
    }
}

export default MySlider
