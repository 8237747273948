import styled from 'styled-components'

const StatusLabel = styled.div`
    background-color: ${props => props.theme.colors[props.status.bg]};
    color: ${props => props.theme.colors[props.status.color]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
    text-align: center;
    border: 1px dashed ${props => props.theme.colors.white};
    padding: 2px;
    font-size: 10px;
    height: 19px;
    box-sizing: content-box;
    text-transform: uppercase;
    margin: ${props => props.margin} ;
`

const infoColors = {
    label:{
        approve: 'green100',
        pending: 'grey300',
        error: 'red100',
        warning: 'mustard100',
        info: 'blue100',
        veryLow: 'green100',
        low: 'green300',
        medium: 'mustard100',
        high: 'orange300',
        veryHigh: 'red100',
        notSet: 'grey300',
        noRisk: 'grey100',
        infoMLight: 'blue200',
        infoLight: 'blue300',
        black: 'black',
        orange: 'orange300',
        darkOrange: 'orange100',
        grey: 'grey100',
        invalid: 'red100'
    },
    text:{
        clear: 'white',
        dark: 'black'
    }
}

const styledTableStatus = {
    ALERT_PENDING: {
        bg: 'transparent',
        color: infoColors.label.orange,
        text: 'pending'
    },
    ALERT_APPROVED: {
        bg: 'transparent',
        color: 'green',
        text: 'approved'
    },
    ALERT_REVIEWED: {
        bg: 'transparent',
        color: 'green',
        text: 'reviewed'
    },
    ALERT_FALSE_POSITIVE: {
        bg: 'transparent',
        color: 'green',
        text: 'false_positive'
    },
    ALERT_MATCH: {
        bg: 'transparent',
        color: 'red',
        text: 'match'
    },
    ALERT_REJECTED: {
        bg: 'transparent',
        color: 'red',
        text: 'rejected'
    },
    INFORMATIVE: {
        bg: infoColors.label.pending,
        color: infoColors.text.clear,
        text: 'informative'
    },
    INDICATIVE: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'indicative'
    },
    FIRM: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'firm'
    },
    EXECUTED: {
        bg: infoColors.label.info,
        color: infoColors.text.clear,
        text: 'executed'
    },
    CHECKED: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'checked'
    },
    COMPLETED: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'completed'
    },
    CANCELLED: {
        bg: infoColors.label.black,
        color: infoColors.text.clear,
        text: 'cancelled'
    },
    NOT_CHECKED: {
        bg: infoColors.label.pending,
        color: infoColors.text.clear,
        text: 'notChecked'
    },
    NOT_COMPLETED: {
        bg: infoColors.label.pending,
        color: infoColors.text.clear,
        text: 'notCompleted'
    },
    PENDING_Yellow: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    REVIEW: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'review'
    },
    INACTIVE: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'inactive'
    },
    APPROVED: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'approved'
    },
    APPROVED_AUTO:{
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'approved'
    },
    CLEAR:{
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'clear'
    },
    APPROVED_MANUAL: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'approved'
    },
    OK: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'approved'
    },
    REJECTED: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'rejected'
    },
    DISABLED: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'disabled'
    },
    ENABLED: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'enabled'
    },
    NEW: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'new'
    },
    ERROR: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'error'
    },
    WITH_ERRORS: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'errors'
    },
    DELETED: {
        bg: infoColors.label.pending,
        color: infoColors.text.clear,
        text: 'deleted'
    },
    UNAVISTA_PENDING: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    NCA_PENDING: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    SYSTEM_ERROR: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'error'
    },
    UNAVISTA_ERRORS: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'error'
    },
    NCA_ERRORS: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'error'
    },
    SENT: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    PENDING_INSTRUMENT_VALIDATION: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    CANCEL_PENDING: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    PENDING: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    PENDING_APPROVAL: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'pending'
    },
    VERY_LOW:{
        bg: infoColors.label.veryLow,
        color: infoColors.text.clear,
        text: 'veryLow' 
    },
    LOW:{
        bg: infoColors.label.low,
        color: infoColors.text.clear,
        text: 'low' 
    },
    MEDIUM:{
        bg: infoColors.label.medium,
        color: infoColors.text.clear,
        text: 'medium' 
    },
    HIGH:{
        bg: infoColors.label.high,
        color: infoColors.text.clear,
        text: 'high'
    },
    VERY_HIGH:{
        bg: infoColors.label.veryHigh,
        color: infoColors.text.clear,
        text: 'veryHigh' 
    },
    NOT_SET:{
        bg: infoColors.label.notSet,
        color: infoColors.text.clear,
        text: 'notSet' 
    },
    NO_RISK:{
        bg: infoColors.label.noRisk,
        color: infoColors.text.clear,
        text: 'noRisk'
    },
    OPEN: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'open'
    },
    CLOSED: {
        bg: infoColors.label.black,
        color: infoColors.text.clear,
        text: 'closed'
    },
    LOCKED: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'blocked'
    },
    BLOCKED: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'blocked'
    },
    INVESTIGATION: {
        bg: infoColors.label.darkOrange,
        color: infoColors.text.clear,
        text: 'investigation'
    },
    ASSIGNED:{
        bg: infoColors.label.infoLight,
        color: infoColors.text.dark,
        text: 'assigned'
    },
    REASSIGNED:{
        bg: infoColors.label.info,
        color: infoColors.text.clear,
        text: 'reassigned'
    },
    RE_ASSIGNED:{
        bg: infoColors.label.info,
        color: infoColors.text.clear,
        text: 'reassigned'
    },
    ESCALATED: {
        bg: infoColors.label.grey,
        color: infoColors.text.clear,
        text: 'escalated'
    },
    REPORTED: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'reported'
    },
    ACTIVE: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'active'
    },
    CLEAR_MANUAL:{
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'clearManual'
    },
    CLEAR_AUTO:{
        bg: infoColors.label.low,
        color: infoColors.text.clear,
        text: 'clearAuto'
    },
    CLEAR_AUTO_PEP:{
        bg: infoColors.label.infoMLight,
        color: infoColors.text.clear,
        text: 'clearAuto'
    },
    CLEAR_AUTO_RCA:{
        bg: infoColors.label.infoMLight,
        color: infoColors.text.clear,
        text: 'clearAuto'
    },
    MATCH:{
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'match'
    },
    MATCH_PEP:{
        bg: infoColors.label.info,
        color: infoColors.text.clear,
        text: 'match'
    },
    MATCH_RCA:{
        bg: infoColors.label.info,
        color: infoColors.text.clear,
        text: 'match'
    },
    IN_PROGRESS:{
        bg: infoColors.label.orange,
        color: infoColors.text.clear,
        text: 'in_progress'
    },
    INVALID:{
        bg: infoColors.label.invalid,
        color: infoColors.text.clear,
        text: 'invalid'
    },
    VALID: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'valid'
    },
    DEFAULT: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: '-'
    },
    BLACK: {
        bg: infoColors.label.black,
        color: infoColors.text.clear,
        text: 'BLACK'
    },
    WAITING_FOR_INFORMATION: {
        bg: infoColors.label.orange,
        color: infoColors.text.clear,
        text: 'WAITING'
    },
    RESOLVED: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'RESOLVED'
    },
    ESCALATION: {
        bg: infoColors.label.grey,
        color: infoColors.text.clear,
        text: 'ESCALATION'
    },
    FALSE_POSITIVE: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'FALSE_POSITIVE'
    },
    ENTER: {
        bg: infoColors.label.orange,
        color: infoColors.text.clear,
        text: 'ENTER'
    },
    TRADE: {
        bg: infoColors.label.approve,
        color: infoColors.text.clear,
        text: 'TRADE'
    },
    AMEND: {
        bg: infoColors.label.warning,
        color: infoColors.text.clear,
        text: 'AMEND'
    },
    DELETE: {
        bg: infoColors.label.error,
        color: infoColors.text.clear,
        text: 'DELETE'
    },
    DOCUMENTS_CAPTURE: {
        bg: infoColors.label.low,
        color: infoColors.text.clear,
        text: 'DOCUMENTS_CAPTURE'
    },
    VALIDATIONS: {
        bg: infoColors.label.veryLow,
        color: infoColors.text.clear,
        text: 'VALIDATIONS'
    },
    SCREENING: {
        bg: infoColors.label.medium,
        color: infoColors.text.clear,
        text: 'SCREENING'
    },
    PHASES_PENDING_APPROVAL: {
        bg: infoColors.label.high,
        color: infoColors.text.clear,
        text: 'PENDING_APPROVAL'
    },
}

export { StatusLabel, styledTableStatus}