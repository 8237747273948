export const PERMISSIONS = {
    SYSTEM: {
        ADMIN: 'ADMIN'
    },
    AUTH: {
        ADMIN: 'ADMIN',
        LOGIN: 'LOGIN',
        ADMIN_TEAM: 'ADMIN_TEAM',
        ADMIN_ROLE: 'ADMIN_ROLE',
        ADMIN_SERVICE_ACCOUNT: 'ADMIN_SERVICE_ACCOUNT',
        ADMIN_SETTING: 'ADMIN_SETTING',
        ADMIN_USER: 'ADMIN_USER',
        
    },
    DOKSTOR: {
        ADMIN: 'ADMIN',
        DOKSTOR_ASSIGN_CASE: 'DOKSTOR_ASSIGN_CASE',
        DOKSTOR_ARCHIVE_PARTY: 'DOKSTOR_ARCHIVE_PARTY',
        DOKSTOR_CREATE_PARTY: 'DOKSTOR_CREATE_PARTY',
        DOKSTOR_EDIT_PARTY: 'DOKSTOR_EDIT_PARTY',
        DOKSTOR_CAPTURE_DOCUMENTS: 'DOKSTOR_CAPTURE_DOCUMENTS',
        DOKSTOR_VALIDATE_DOCUMENTS: 'DOKSTOR_VALIDATE_DOCUMENTS',
        DOKSTOR_SCREEN: 'DOKSTOR_SCREEN',
        DOKSTOR_ASSESS_RISK: 'DOKSTOR_ASSESS_RISK',
        DOKSTOR_APPROVE: 'DOKSTOR_APPROVE',
        DOKSTOR_SECOND_APPROVE: 'DOKSTOR_SECOND_APPROVE',
        DOKSTOR_STRUCTURE: 'DOKSTOR_STRUCTURE',
        DOKSTOR_ACTIVITY: 'DOKSTOR_ACTIVITY',
        DOKSTOR_REPORT: 'DOKSTOR_REPORT',
        DOKSTOR_ADMIN_WATCH_LIST: 'DOKSTOR_ADMIN_WATCH_LIST',
        DOKSTOR_ADMIN_WATCH_LIST_READ_ONLY: 'DOKSTOR_ADMIN_WATCH_LIST_READ_ONLY',
        DOKSTOR_REPORTING: 'DOKSTOR_REPORTING',
        DOKSTOR_PHASES: 'DOKSTOR_PHASES'
    },
    ROBOLITICS: {
        ADMIN: 'ADMIN',
        FULL: 'FULL',
        ROBOLITICS_ALGOS: 'ROBOLITICS_ALGOS',
        ROBOLITICS_CASE_MANAGEMENT: 'ROBOLITICS_CASE_MANAGEMENT',
        ROBOLITICS_REF_DATA: 'ROBOLITICS_REF_DATA',
        ROBOLITICS_REPORTING: 'ROBOLITICS_REPORTING',
        ROBOLITICS_DASHBOARD: 'ROBOLITICS_DASHBOARD',
    }
}

export const APPLICATIONS = {
    SYSTEM: 'SYSTEM',
    AUTH: 'AUTH',
    DOKSTOR: 'DOKSTOR',
    ROBOLITICS: 'ROBOLITICS'
}