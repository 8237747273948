import { all, call, fork, put, select } from 'redux-saga/effects'

import { t } from 'i18next'
import { db } from '../../../api/db'
import { takeLatestSafe } from '../../../config/helpers/sagasHelper'
import { OPEN_NOTIFICATION } from '../../../notifications/actionTypes'
import { getQueryParamFromListConfig } from '../../common/MainTable/helpers/tableHelpers'
import {
    parseCoreDataForRequest,
    parsePaymentsDataForRequest,
    parseResponseToCoreData, parseResponseToPaymentsData
} from '../helpers/helper'
import {
    GENERATE_CORE_DATA,
    GENERATE_PAYMENTS_DATA,
    GET_ACCOUNTS,
    GET_CARD_TYPES,
    GET_CARDS,
    GET_CORE_DATA_EXECUTION_DETAILS,
    GET_CURRENT_ACCOUNT,
    GET_CURRENT_ACCOUNT_CARDS,
    GET_CURRENT_ACCOUNT_MEMBERS,
    GET_CURRENT_ACCOUNT_PAYMENTS,
    GET_CURRENT_CARD,
    GET_CURRENT_CARD_CUSTOMER_AND_ACCOUNT,
    GET_CURRENT_CUSTOMER,
    GET_CURRENT_CUSTOMER_ACOUNTS,
    GET_CURRENT_CUSTOMER_CARDS,
    GET_CURRENT_CUSTOMER_PAYMENTS,
    GET_CURRENT_PAYMENT,
    GET_CUSTOMER_TYPES,
    GET_CUSTOMERS,
    GET_DATA_GENERATOR_EXECUTIONS,
    GET_GENERATOR_KPI,
    GET_PAYMENTS,
    GET_PAYMENTS_DATA_EXECUTION_DETAILS,
    SET_ACCOUNTS, SET_CARD_TYPES,
    SET_CARDS,
    SET_CORE_DATA_EXECUTION_DETAILS,
    SET_CURRENT_ACCOUNT_MEMBERS,
    SET_CURRENT_ACCOUNT_PAYMENTS,
    SET_CURRENT_CARD_ACCOUNT,
    SET_CURRENT_CARD_CUSTOMER,
    SET_CURRENT_CUSTOMER_ACCOUNTS,
    SET_CURRENT_CUSTOMER_CARDS,
    SET_CURRENT_CUSTOMER_PAYMENTS,
    SET_CURRENT_REFDATA_ENTITY,
    SET_CUSTOMER_TYPES,
    SET_CUSTOMERS,
    SET_DATA_GENERATOR_EXECUTIONS,
    SET_GENERATOR_KPI,
    SET_PAYMENTS,
    SET_PAYMENTS_DATA_EXECUTION_DETAILS,
    SET_REF_DATA_STATUS
} from './actionTypes'

const listConfigs = (state, listId) => state.common.lists[listId]
const getTranslationFn = t

const watcher = () =>
    function* watch() {
        yield takeLatestSafe(GET_ACCOUNTS, runRequestAccounts)
        yield takeLatestSafe(GET_CARDS, runRequestCards)
        yield takeLatestSafe(GET_CUSTOMERS, runRequestCustomers)
        yield takeLatestSafe(GET_PAYMENTS, runRequestPayments)
        yield takeLatestSafe(GET_CURRENT_ACCOUNT, runRequestAccount)
        yield takeLatestSafe(GET_CURRENT_ACCOUNT_CARDS, runRequestAccountCards)
        yield takeLatestSafe(GET_CURRENT_ACCOUNT_MEMBERS, runRequestAccountMembers)
        yield takeLatestSafe(GET_CURRENT_ACCOUNT_PAYMENTS, runRequestAccountPayments)
        yield takeLatestSafe(GET_CURRENT_CARD, runRequestCard)
        yield takeLatestSafe(GET_CURRENT_CARD_CUSTOMER_AND_ACCOUNT, runRequestCardCustomerAndAccount)
        yield takeLatestSafe(GET_CURRENT_CUSTOMER, runRequestCustomer)
        yield takeLatestSafe(GET_CURRENT_CUSTOMER_ACOUNTS, runRequestCustomerAccounts)
        yield takeLatestSafe(GET_CURRENT_CUSTOMER_CARDS, runRequestCustomerCards)
        yield takeLatestSafe(GET_CURRENT_CUSTOMER_PAYMENTS, runRequestCustomerPayments)
        yield takeLatestSafe(GET_CURRENT_PAYMENT, runRequestPayment)
        yield takeLatestSafe(GET_CUSTOMER_TYPES, runRequestCustomerTypes)
        yield takeLatestSafe(GET_CARD_TYPES, runRequestCardTypes)
        yield takeLatestSafe(GET_GENERATOR_KPI, runRequestGeneratorKPIs)
        yield takeLatestSafe(GET_DATA_GENERATOR_EXECUTIONS, runRequestGeneratorExecutions)
        yield takeLatestSafe(GENERATE_CORE_DATA, runGenerateCoreData)
        yield takeLatestSafe(GENERATE_PAYMENTS_DATA, runGeneratePaymentsData)
        yield takeLatestSafe(GET_CORE_DATA_EXECUTION_DETAILS, runRequestCoreDataDetails)
        yield takeLatestSafe(GET_PAYMENTS_DATA_EXECUTION_DETAILS, runRequestPaymentsDataDetails)
    }

function* handlerError(response) {
    const translationFn = yield select(getTranslationFn)
    yield put({
        type: OPEN_NOTIFICATION, data:
            { type: 'error', message: response ? response.message : translationFn('genericErrorMessage') }
    })
}

function* runRequestPaymentsDataDetails({ dataId }) {
    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_PAYMENTS_DATA_DETAILS' })
    const response = yield db('get', `retail-data-api/api/data-generation/generation-activities/${dataId}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield put({ type: SET_REF_DATA_STATUS, status: 'ERRORS_RETRIEVING_DATA' })
        yield handlerError(response)
    }
    else {
        yield put({
            type: SET_PAYMENTS_DATA_EXECUTION_DETAILS,
            paymentsDataDetails: parseResponseToPaymentsData(response.generationActivity)
        })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runRequestCoreDataDetails({ dataId }) {
    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_CORE_DATA_DETAILS' })
    const response = yield db('get', `retail-data-api/api/data-generation/generation-activities/${dataId}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield put({ type: SET_REF_DATA_STATUS, status: 'ERRORS_RETRIEVING_DATA' })
        yield handlerError(response)
    }
    else {
        yield put({
            type: SET_CORE_DATA_EXECUTION_DETAILS,
            coreDataDetails: parseResponseToCoreData(response.generationActivity)
        })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runGenerateCoreData({ coreData }) {

    const body = parseCoreDataForRequest(coreData)

    const response = yield db('post', 'retail-data-api/api/data-generation/customers-accounts-cards', body)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield put({ type: SET_REF_DATA_STATUS, status: 'ERRORS_GENERATING_DATA' })
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_REF_DATA_STATUS, status: 'DATA_SUCCESSFULLY_GENERATED' })
        const translationFn = yield select(getTranslationFn)
        yield put({ type: OPEN_NOTIFICATION, data: { type: 'success', message: translationFn('dataSuccess') } })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runGeneratePaymentsData({ paymentsData }) {
    const body = parsePaymentsDataForRequest(paymentsData)

    const response = yield db('post', 'retail-data-api/api/data-generation/participants-payments', body)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield put({ type: SET_REF_DATA_STATUS, status: 'ERRORS_GENERATING_PAYMENTS_DATA' })
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_REF_DATA_STATUS, status: 'PAYMENTS_DATA_SUCCESSFULLY_GENERATED' })
        const translationFn = yield select(getTranslationFn)
        yield put({ type: OPEN_NOTIFICATION, data: { type: 'success', message: translationFn('dataSuccess') } })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runRequestGeneratorExecutions({ listId, currentFilter }) {
    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_GENERATOR_EXECUTIONS' })
    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter)
    const response = yield db('get', 'retail-data-api/api/data-generation/generation-activities?' + queryParam)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_DATA_GENERATOR_EXECUTIONS, generatorExecutionsData: response })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runRequestGeneratorKPIs() {
    const response = yield db('get', 'retail-data-api/api/data-generation/kpis')
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_GENERATOR_KPI, generationKPIs: response.generationKPIs })

}

function* runRequestAccount({ type, ...args }) {

    const response = yield db('get', `retail-data-api/api/accounts/${args.accountId}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_REFDATA_ENTITY, currentEntity: response.account })
}

function* runRequestAccountCards({ type, ...args }) {

    yield put({ type: SET_CURRENT_CUSTOMER_CARDS, cards: [], loading: true })

    const response = yield db('get', `retail-data-api/api/accounts/${args.accountId}/cards`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_CUSTOMER_CARDS, cards: response.cards, loadingCards: false })
}

function* runRequestAccountMembers({ type, ...args }) {

    yield put({ type: SET_CURRENT_ACCOUNT_MEMBERS, members: [], loading: true })

    const response = yield db('get', `retail-data-api/api/accounts/${args.accountId}/customers`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_ACCOUNT_MEMBERS, members: response.customers, loading: false })
}

function* runRequestAccountPayments({ type, ...args }) {

    const listConfig = yield select(listConfigs, args.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, args.currentFilter)

    const response = yield db('get', `retail-data-api/api/accounts/${args.accountId}/payments?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_ACCOUNT_PAYMENTS, payments: response, loading: false })
}

function* runRequestAccounts({ listId, currentFilter }) {
    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_ACCOUNTS' })

    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter)

    const response = yield db('get', `retail-data-api/api/accounts?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_ACCOUNTS, accounts: response })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runRequestCard({ type, ...args }) {

    yield put({ type: SET_CURRENT_REFDATA_ENTITY, currentEntity: {} })

    const response = yield db('get', `retail-data-api/api/cards/${args.cardId}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_REFDATA_ENTITY, currentEntity: response.card })
}

function* runRequestCardCustomerAndAccount({ type, ...args }) {
    yield put({ type: SET_CURRENT_CARD_CUSTOMER, currentCardCustomer: null })
    yield put({ type: SET_CURRENT_CARD_ACCOUNT, currentCardAccount: null })

    const response = yield db('get', `retail-data-api/api/cards/${args.cardId}/customer-account`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_CURRENT_CARD_CUSTOMER, currentCardCustomer: response.customerAccount.customer })
        yield put({ type: SET_CURRENT_CARD_ACCOUNT, currentCardAccount: response.customerAccount.account })
    }
}

function* runRequestCards({ type, ...args }) {

    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_CARDS' })

    const listConfig = yield select(listConfigs, args.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, args.currentFilter)

    const response = yield db('get', `retail-data-api/api/cards?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_CARDS, cards: response })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

function* runRequestCustomerTypes() {

    const response = yield db('get', 'retail-data-api/api/customer-types')


    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        const customerTypes = response.customerTypes.map(type => type.name)
        yield put({ type: SET_CUSTOMER_TYPES, customerTypes })
    }
}

function* runRequestCardTypes() {
    /* FAKE CALL: this is a fake call for testing purposes */
    function myFakeCall() {
        return new Promise((resolve) => {
            resolve(['VISA', 'AMEX', 'MASTERCARD'])
        })
    }
    /* FAKE CALL END */
    const response = yield call(myFakeCall)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_CARD_TYPES, cardTypes: response })
    }
}

function* runRequestCustomer({ type, ...args }) {

    const response = yield db('get', `retail-data-api/api/customers/${args.customerId}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_REFDATA_ENTITY, currentEntity: response.customer })
}

function* runRequestCustomerAccounts({ type, ...args }) {

    yield put({ type: SET_CURRENT_CUSTOMER_ACCOUNTS, accounts: [], loading: true })

    const response = yield db('get', `retail-data-api/api/customers/${args.customerId}/accounts`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_CUSTOMER_ACCOUNTS, accounts: response.accounts, loading: false })
}

function* runRequestCustomerCards({ type, ...args }) {

    yield put({ type: SET_CURRENT_CUSTOMER_CARDS, cards: [], loading: true })

    const response = yield db('get', `retail-data-api/api/customers/${args.customerId}/cards`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_CUSTOMER_CARDS, cards: response.cards, loading: false })
}

function* runRequestCustomerPayments({ type, ...args }) {

    const listConfig = yield select(listConfigs, args.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, args.currentFilter)

    const response = yield db('get', `retail-data-api/api/customers/${args.customerId}/payments?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_CUSTOMER_PAYMENTS, payments: response, loading: false })
}

function* runRequestCustomers({ type, ...args }) {
    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_CUSTOMERS' })

    const listConfig = yield select(listConfigs, args.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, args.currentFilter)

    const response = yield db('get', `retail-data-api/api/customers?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else {
        yield put({ type: SET_CUSTOMERS, customers: response })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })

}

function* runRequestPayment({ type, ...args }) {

    const response = yield db('get', `retail-data-api/api/payments/${args.paymentId}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    }
    else
        yield put({ type: SET_CURRENT_REFDATA_ENTITY, currentEntity: response.payment })
}

function* runRequestPayments({ type, ...args }) {
    yield put({ type: SET_REF_DATA_STATUS, status: 'LOADING_PAYMENTS' })

    const listConfig = yield select(listConfigs, args.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, args.currentFilter)

    const response = yield db('get', `retail-data-api/api/payments?${queryParam}`)
    if (response === undefined || response.hasOwnProperty('errorCode')) {
        yield handlerError(response)
    } else {
        yield put({ type: SET_PAYMENTS, payments: response })
    }
    yield put({ type: SET_REF_DATA_STATUS, status: '' })
}

export default function* rootSaga() {
    yield all([fork(watcher())])
}