import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import Layout from './Layout'
import { ADD_DRAWER_ELEMENT } from '../store/actionTypes'
import ExpandedImageDrawer from '../ExpandedImageDrawer'
import { DRAWER_SIZES } from '../DrawerWrapper/contants/drawerSizes.enum'
import useCloseDrawersOnUnmount from '../../../hooks/useCloseDrawersOnUnmount'
import { getForensicAnalysis } from './helpers'

const Container = ({ image }) => {

    const [heatmapIndex, setHeatmapIndex] = useState(0)
    const [forensicLoading, setForensicLoading] = useState(false)
    const [imageForensicDetails, setImageForensicDetails] = useState({})

    const entityId = useSelector(store => store.dokstor.currentEntity.identifier)
    const cpId = useSelector(store => store.dokstor.currentCP.identifier)
    const docId = useSelector(store => store.dokstor.currentDoc.identifier)

    const dispatch = useDispatch()
    const addDrawer = useCallback(element => dispatch({ type: ADD_DRAWER_ELEMENT, element }), [])

    const heatmapImage = isEmpty(imageForensicDetails)
        ? 'isEmpty'
        : imageForensicDetails?.forensicImages?.[heatmapIndex]

    useCloseDrawersOnUnmount()
    
    useEffect(() => {
        const loadForensicAnalysis = async () => {
            setForensicLoading(true)
            const res = await getForensicAnalysis({ docId, cpId, entityId })
            setImageForensicDetails(res)
            setForensicLoading(false)
        }

        loadForensicAnalysis()
    }, [])


    const onFullSizeImageOpen = () => {
        addDrawer({
            component: ExpandedImageDrawer,
            props: { content: heatmapImage.image, type: 'image/jpg' },
            drawerSize: DRAWER_SIZES.FULL
        })
    }

    useEffect(() => {
        if (!isEmpty(imageForensicDetails)) {
            if (imageForensicDetails.forensicImages) imageForensicDetails.forensicImages.forEach(obj => {
                window.URL.revokeObjectURL(obj.image);
            })
            setImageForensicDetails({})
        }
    }, [image])

    return (
        <Layout
            heatmapImage={heatmapImage}
            setHeatmapIndex={setHeatmapIndex}
            heatmapIndex={heatmapIndex}
            forensicLoading={forensicLoading}
            image={image}
            imageForensicDetails={imageForensicDetails}
            handleToolbar={onFullSizeImageOpen}
        />
    )
}

export default Container