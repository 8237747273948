import CircularProgress         from '@material-ui/core/CircularProgress';
import TableCell                from '@material-ui/core/TableCell';
import TableFooter              from '@material-ui/core/TableFooter';
import TableRow                 from '@material-ui/core/TableRow';
import React                    from 'react';
import styled, {keyframes, css} from 'styled-components'
import theme                    from "../../../styles/styledTheme"

const TableCellDateHeader = styled(TableCell)`
    && {
        backgroundColor: ${props => props.theme.colors.grey500} !important;
        color: ${props => props.theme.colors.white};
        text-align: center;
        fontSize:13px;
        font-weight:500;
        cursor: default;
    }
`

const TableCellStyled = styled(({shouldAdjustHeight,rowsPerPage, ...props}) =>  <TableCell  {...props}/>)`
    && {
        height: ${props => props.shouldAdjustHeight && `${props.rowsPerPage}*48px`};
        border-bottom: ${props => `1px solid ${props.theme.colorsgrey100}`};
        text-align: center;
        vertical-align: middle;
        position: relative;
    }
`

const CircularProgressStyled = styled(CircularProgress)`
    && { color: ${props => props.theme.colors.main}; }
`

const TableRowDateHeader = styled(({onCellClick, ...props}) =>  <TableRow  {...props}/>)`
    && { cursor: ${props => props.onCellClick && 'pointer'};
         background-color: ${props => props.theme.colors.grey300} !important;
         height: 25px;
        }
`
const bgKF = isOdd => keyframes`
    from { background-color: ${theme.colors.green300}80; }
    to { background-color: ${isOdd ? '#ffffff' : theme.colors.bluelight100}; }`;

const TableRowId = styled(({bgColor, onCellClick, clickable, isNew, isOdd, disableClick, ...props}) =>  <TableRow  {...props}/>)`
    && {
        cursor: ${props => props.onCellClick && !props.disableClick && 'pointer'};
        ${props => props.clickable && 'cursor: pointer'};
        ${props => props.isNew && css `animation: ${bgKF(props.isOdd)} 2s ease`};
        ${props => props.bgColor && css `background-color: ${theme.colors.main400} !important`}
    }
`

const TableCellWidth = styled(({columnNumber, firstColumnPadding, maxWidth, wrappedCells, colLink, overflow, ...props}) =>  <TableCell  {...props}/>)`
    && {
        font-size:0.929rem;
        font-weight:300;
        padding: ${props => props.firstColumnPadding ? props.columnNumber === 0 ? '3px 16px' : '3px 8px' : '3px 8px'};
        max-width: ${props => props.maxWidth !== undefined ? props.maxWidth + 'px' : 'none'};
        // padding-left: 20px;
        overflow-x: ${props => props.overflow || 'hidden'};
        text-overflow: ellipsis;
        // max-width: ${props => `${props.maxWidth}px` || '0px'};
        white-space: ${props => props.wrappedCells ?  'normal' : 'nowrap'};
        word-wrap: ${props => props.wrappedCells && 'break-word'};
        color: ${props => props.colLink && props.theme.colors.grey100};
        &:hover{
            color: ${props => props.colLink && props.theme.colors.blue100};
            text-decoration: ${props => props.colLink && 'underline'};
        }
    }
`

const TableOverflow = styled.div`
    overflow: auto;
    ${props => props.styler};
    &::-webkit-scrollbar{
            height:1rem;
        }
`

const TableFooterStyled = styled(TableFooter)`
    && { 
        div {
            flex-direction: row;
            align-items: center;
        }
        @media (max-width: 768px){
            div {
            flex-direction: column-reverse;
            height: auto;
            justify-content: center;
            align-items: center;
            span{
                order: 2;
            }
            [class^="MuiSelect-root"] {
                order: 2;
                text-align: center;
            }
        }
    }
`

const TableRowNoResults = styled(TableRow)`
    &&& {
        height: 200px;
        background-color: #fff;
    }
`

export {
    TableCellDateHeader,
    TableFooterStyled,
    TableCellStyled, 
    TableOverflow,
    CircularProgressStyled, 
    TableRowDateHeader, 
    TableRowId, 
    TableCellWidth,
    TableRowNoResults
}