import {
    SET_ACCOUNTS,
    SET_CUSTOMERS,
    SET_CURRENT_REFDATA_ENTITY,
    SET_CURRENT_CUSTOMER_ACCOUNTS,
    SET_CURRENT_ACCOUNT_MEMBERS,
    SET_PAYMENTS,
    SET_CURRENT_CUSTOMER_CARDS,
    SET_CURRENT_ACCOUNT_CARDS,
    SET_CARDS,
    SET_CURRENT_ACCOUNT_PAYMENTS,
    SET_CURRENT_CUSTOMER_PAYMENTS,
    SET_CURRENT_CARD_CUSTOMER,
    SET_CURRENT_CARD_ACCOUNT,
    SET_CUSTOMER_TYPES,
    SET_GENERATOR_KPI,
    SET_DATA_GENERATOR_EXECUTIONS,
    SET_REF_DATA_STATUS,
    RESET_CORE_DATA_EXECUTION_DETAILS,
    SET_CORE_DATA_EXECUTION_DETAILS,
    RESET_PAYMENTS_DATA_EXECUTION_DETAILS,
    SET_PAYMENTS_DATA_EXECUTION_DETAILS, SET_CARD_TYPES
} from './actionTypes';

const defaultCoreDataDetails = {
    duration: 0,
    generatedElements: {
        CUSTOMERS: 0,
        ACCOUNTS: 0,
        CARDS: 300
    },
    parameters: {
        pepDist: 0,
        individualVol: 0,
        legalEntVol: 0,
        maxNetWorth: 0,
        minNetWorth: 0,
        dateFrom: '',
        dateTo: '',
        risk: [0, 0],
        accountVol: 0,
        accountType: {
            SAVINGS: false, CHECKING: false, LOAN: false
        },
        netBalanceMin: 0,
        netBalanceMax: 0,
        cardVolumes: 0,
        cardType: {
            CREDIT: false, DEBIT: false
        },
        cardIssuer: {
            VISA: false, MASTERCARD: false, AMEX: false
        },
        dailyLimitMin: 0,
        dailyLimitMax: 0,
        monthlyLimitMin: 0,
        monthlyLimitMax: 0,
        transactionLimitMin: 0,
        transactionLimitMax: 0
    }
}

const defaultPaymentsDataDetails = {
    duration: 0,
    generatedElements: {
        CUSTOMERS: 0,
        ACCOUNTS: 0,
        CARDS: 300
    },
    parameters: {
        paymentsVol: 0,
        accountType: {
            SAVINGS: false,
            CHECKING: false,
            LOAN: false
        },
        amountBeforeCommissionMin: 0,
        amountBeforeCommissionMax: 0,
        dateFrom: '',
        dateTo: ''
    }
}

const refdata = (
    state = {
        accountsData: {
            accounts: [],
            count: 0
        },
        cardsData: {
            cards: [],
            count: 0
        },
        customersData: {
            customers: [],
            count: 0
        },
        generatorExecutionsData: {
            generationActivities: [],
            count: 0
        },
        paymentsData: {
            payments: [],
            count: 0
        },
        breadcrumb: [],
        breadcrumbIndex: 0,
        currentEntity: {
            entity: {}
        },
        currentCoreDataDetails: {
            ...defaultCoreDataDetails
        },
        currentPaymentsDataDetails: {
            ...defaultPaymentsDataDetails
        },
        generationKPIs: {
            cards: 0,
            payments: 0,
            customers: 0,
            accounts: 0
        },
        cardTypes: [],
        status: ''
    },
    action
) => {
    switch (action.type) {
        case SET_CARD_TYPES:
            return {...state, cardTypes: action.cardTypes}
        case SET_PAYMENTS_DATA_EXECUTION_DETAILS:
            return {...state, currentPaymentsDataDetails: action.paymentsDataDetails}
        case RESET_PAYMENTS_DATA_EXECUTION_DETAILS:
            return {...state, currentPaymentsDataDetails: defaultPaymentsDataDetails}
        case SET_CORE_DATA_EXECUTION_DETAILS:
            return {...state, currentCoreDataDetails: action.coreDataDetails}
        case RESET_CORE_DATA_EXECUTION_DETAILS:
            return {...state, currentCoreDataDetails: defaultCoreDataDetails}
        case SET_REF_DATA_STATUS:
            return {...state, status: action.status}
        case SET_DATA_GENERATOR_EXECUTIONS:
            return {...state, generatorExecutionsData: action.generatorExecutionsData}
        case SET_GENERATOR_KPI:
            return {...state, generationKPIs: action.generationKPIs}
        case SET_CUSTOMERS:
            return {...state, customersData: action.customers}
        case SET_ACCOUNTS:
            return {...state, accountsData: action.accounts}
        case SET_PAYMENTS:
            return {...state, paymentsData: action.payments}
        case SET_CARDS:
            return {...state, cardsData: action.cards}
        case SET_CURRENT_REFDATA_ENTITY:
            return {...state, currentEntity: {...state.currentEntity, entity: action.currentEntity}}
        case SET_CURRENT_CUSTOMER_ACCOUNTS:
            return {...state, currentEntity: {...state.currentEntity, accounts: action.accounts, loadingAccounts: action.loading}}
        case SET_CURRENT_CUSTOMER_CARDS:
            return {...state, currentEntity: {...state.currentEntity, cards: action.cards, loadingCards: action.loading}}
        case SET_CURRENT_CUSTOMER_PAYMENTS:
            return {...state, currentEntity: {...state.currentEntity, payments: action.payments, loadingPayments: action.loading}}
        case SET_CURRENT_ACCOUNT_MEMBERS:
            return {...state, currentEntity: {...state.currentEntity, members: action.members, loadingMembers: action.loading}}
        case SET_CURRENT_ACCOUNT_PAYMENTS:
            return {...state, currentEntity: {...state.currentEntity, payments: action.payments, loadingPayments: action.loading}}
        case SET_CURRENT_ACCOUNT_CARDS:
            return {...state, currentEntity: {...state.currentEntity, cards: action.cards, loadingCards: action.loading}}
        case SET_CURRENT_CARD_CUSTOMER:
            return {...state, currentEntity: {...state.currentEntity, currentCardCustomer: action.currentCardCustomer}}
        case SET_CURRENT_CARD_ACCOUNT:
            return {...state, currentEntity: {...state.currentEntity, currentCardAccount: action.currentCardAccount}}
        case SET_CUSTOMER_TYPES:
            return {...state, customerTypes: action.customerTypes}
        default:
            return state
    }
}

export default refdata