import {
    CLEAR_AUTH_ERRORS,
    CLEAR_BREADCRUMB,
    FAILED_PWD_CHANGE,
    FAILED_REQUEST_TOKEN,
    SET_BREADCRUMB,
    SET_GENERAL_STATUS,
    SET_TEAM_IDS,
    SET_LANGUAGE,
    SET_RIGHT_BAR,
    SET_SIDEBAR,
    SET_SUBMENU,
    SUCCEED_PWD_CHANGE,
    SUCCEED_REQUEST_TOKEN,
    UPDATE_PERMISSIONS
} from './actionTypes'
import HeliocorStorage from "../../config/HeliocorStorage"

function auth(state =
    {
        language: 'en-EN',
        rightBar: {
            open: false
        },
        generalState: 'Valid Token'
    }
    , action) {

    switch (action.type) {
        case SET_LANGUAGE:
            return { ...state, language: action.language }

        case SUCCEED_REQUEST_TOKEN:
            const permissions = [...action.permissions, 'AUTH:LOGIN'].reduce((totalPermissions, permission) => {
                let permissionBundle = permission.split(':')
                let permissionArr = totalPermissions[permissionBundle[0]]
                    ? [...totalPermissions[permissionBundle[0]], permissionBundle[1]]
                    : totalPermissions[permissionBundle[0]] = [permissionBundle[1]]
                return { ...totalPermissions, [permissionBundle[0]]: permissionArr }
            }, {})

            return {
                ...state,
                permissions,
                token: action.token,
                name: action.name,
                organization: action.organization,
                passwordExpired: action.passwordExpired,
                identifier: action.identifier,
                sidebar: action.sidebar,
                MCB_AD: action.MCB_AD,
                roles: action.roles
            }

        case FAILED_REQUEST_TOKEN:
            return { ...state, loginError: 'Your user or password is not correct. Try again!' }

        case SUCCEED_PWD_CHANGE:
            return { ...state, resetStatus: 'succeed', passwordExpired: false }

        case FAILED_PWD_CHANGE:
            console.error(action.error)
            return { ...state, loginError: action.error }

        case SET_SUBMENU:
            return { ...state, submenu: action.submenu }

        case SET_SIDEBAR:
            HeliocorStorage.setItem('sideBar', action.bool)
            return { ...state, sidebar: action.bool }

        case SET_RIGHT_BAR:
            return {
                ...state, rightBar: {
                    open: 'boolean' === typeof action.open ? action.open : !state.rightBar.open,
                    currentBar: action.currentBar || state.rightBar.currentBar,
                    barData: action.barData
                }
            }

        case CLEAR_AUTH_ERRORS:
            return { ...state, loginError: null, resetError: null, resetStatus: null }

        case UPDATE_PERMISSIONS:
            return { ...state, permissions: action.permissions }

        case SET_GENERAL_STATUS:
            return { ...state, generalState: action.generalState }

        case SET_BREADCRUMB:
            return { ...state, breadcrumb: action.breadcrumb }

        case CLEAR_BREADCRUMB:
            return { ...state, breadcrumb: null }

        case SET_TEAM_IDS:
            return {
                ...state,
                teamIds: action.teamIds
            }

        default:
            return state
    }
}

export default auth