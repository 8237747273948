import React from 'react'
import Success from '@material-ui/icons/Check'
import Warning from '@material-ui/icons/ErrorOutline'
import Cross from '@material-ui/icons/Clear'
import Info from '@material-ui/icons/Info'

export const alertDetails = {

    'SUCCESS': {
        title: 'Success!',
        description: 'Lore imps',
        icon: <Success />,
        colorBG: 'green',
        colorIcon: "white"
    },

    'INFO': {
        title: 'Info...',
        description: 'You must select default documents to use iframe url',
        icon: <Info />,
        colorBG: 'blueLight',
        colorIcon: 'greyTint'
    },

    'WARNING': {
        title: 'Warning...',
        description: 'You must select default documents to use iframe url',
        icon: <Warning />,
        colorBG: 'mustard',
        colorIcon: 'white'
    },

    'ERROR': {
        title: 'Error...',
        description: 'You must select default documents to use iframe url',
        icon: <Cross />,
        colorBG: 'red',
        colorIcon: 'white'
    }
}