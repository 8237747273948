import React from 'react'
import { all, fork, put, select, call } from 'redux-saga/effects'
import { db } from '../../api/db'
import { login } from '../../api/login'
import { loginMCB } from '../../api/loginMCB'
import HeliocorStorage from '../../config/HeliocorStorage'
import { takeLatestSafe } from '../../config/helpers/sagasHelper'
import { OPEN_NOTIFICATION } from '../../notifications/actionTypes'
import {
    CLEAR_AUTH_ERRORS,
    FAILED_PWD_CHANGE,
    FAILED_REQUEST_TOKEN,
    GET_USER_ROLES,
    REQUEST_TEAM_IDS,
    REQUEST_LOGIN,
    REQUEST_LOGIN_MCB,
    REQUEST_PWD_CHANGE,
    SET_TEAM_IDS,
    SUCCEED_PWD_CHANGE,
    SUCCEED_REQUEST_TOKEN,
    UPDATE_PERMISSIONS
} from './actionTypes'
import { getTranslationFn } from "../../apps/dokstor/store/sagas/storeGetters"
import { SET_DOKSTOR_STATUS } from "../../apps/dokstor/store/actionTypes"
import { Translate } from '../../apps/common/Translate'
import { SET_API_STATUS } from '../../apps/common/store/actionTypes'
import contextTypes from '../../apps/dokstor/store/contextTypes.enum'

const watcher = () => function* watch() {
    yield takeLatestSafe(REQUEST_LOGIN, runRequestLogin)
    yield takeLatestSafe(REQUEST_LOGIN_MCB, runRequestLoginMCB)
    yield takeLatestSafe(REQUEST_PWD_CHANGE, runRequestPwdChange)
    yield takeLatestSafe(GET_USER_ROLES, runRequestUserRoles)
    yield takeLatestSafe(REQUEST_TEAM_IDS, runRequestTeamIds)
}

function* throwErrorMessage(error) {
    const translationFn = yield select(getTranslationFn)
    yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: error.code ? translationFn('notification.' + error.code) : translationFn('genericErrorMessage') } })
}

function* handlerError(errors) {
    yield put({ type: SET_DOKSTOR_STATUS, dokstorError: true })
    yield all(errors.map(error => throwErrorMessage(error)))
}

function* runRequestLogin({ username, password }) {

    yield put({ type: CLEAR_AUTH_ERRORS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOGIN, value: true })

    const apiResponse = yield login(username, password)

    if (apiResponse?.token) {
        yield HeliocorStorage.setItem('loginData', apiResponse)

        yield put({
            type: SUCCEED_REQUEST_TOKEN,
            token: apiResponse.token,
            permissions: apiResponse.permissions,
            name: apiResponse.name,
            organization: apiResponse.organization,
            passwordExpired: apiResponse.passwordExpired,
            identifier: apiResponse.identifier,
            roles: apiResponse.roles
        })
    } else {
        yield put({ type: FAILED_REQUEST_TOKEN })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOGIN })
}

function* runRequestLoginMCB({ username, password }) {

    yield put({ type: CLEAR_AUTH_ERRORS })
    const apiResponse = yield loginMCB(username, password)

    if (apiResponse && apiResponse.token) {
        yield HeliocorStorage.setItem('loginData', { ...apiResponse, MCB_AD: true })

        yield put({
            type: SUCCEED_REQUEST_TOKEN,
            token: apiResponse.token,
            permissions: apiResponse.permissions,
            name: apiResponse.name,
            organization: apiResponse.organization,
            passwordExpired: apiResponse.passwordExpired,
            identifier: apiResponse.identifier,
            MCB_AD: true,
            roles: apiResponse.roles
        })
    } else {
        yield put({ type: FAILED_REQUEST_TOKEN })
    }
}

function* runRequestPwdChange({ oldPassword, newPassword }) {

    yield put({ type: CLEAR_AUTH_ERRORS })
    const apiResponse = yield db('put', 'auth-api/api/profile/credentials', { oldPassword, newPassword })

    if (!apiResponse.errors) {

        let loginData = yield HeliocorStorage.getItem('loginData')
        if (loginData) {
            loginData.passwordExpired = false
            HeliocorStorage.setItem('loginData', loginData)
        }

        yield put({ type: SUCCEED_PWD_CHANGE })
    } else {
        yield call(handlerError, apiResponse.errors)
        yield put({ type: FAILED_PWD_CHANGE, error: <Translate id={'notification.' + apiResponse.errors[0].code} /> })
    }
}

function* runRequestUserRoles() {
    const response = yield db('get', 'auth-api/api/profile')
    const storagePermissions = [...response.profile.permissions, 'AUTH:LOGIN']

    const permissions = [...response.profile.permissions, 'AUTH:LOGIN'].reduce((totalPermissions, permission) => {
        let permissionBundle = permission.split(':')
        let permissionsArr = totalPermissions[permissionBundle[0]] ?
            [...totalPermissions[permissionBundle[0]], permissionBundle[1]] :
            totalPermissions[permissionBundle[0]] = [permissionBundle[1]]
        return { ...totalPermissions, [permissionBundle[0]]: permissionsArr }
    }, {})

    let loginData = yield HeliocorStorage.getItem('loginData')
    if (loginData) {
        loginData.permissions = storagePermissions
        HeliocorStorage.setItem('loginData', loginData)
    }

    const teamIds = response?.profile?.teams || []
    yield put({ type: SET_TEAM_IDS, teamIds })

    yield put({ type: UPDATE_PERMISSIONS, permissions })
}

function* runRequestTeamIds() {
    const res = yield db('get', 'auth-api/api/profile')
    const teamIds = res?.profile?.teams || []

    yield put({ type: SET_TEAM_IDS, teamIds })
}


export default function* rootSaga() {
    yield all([fork(watcher())])
}