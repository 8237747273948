import React from 'react'
import clsx from 'clsx'
import styled from "styled-components"
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const style = {
    paper: {
        maxWidth: 900,
        width: '80%',
        height: '100%'
    },
    root: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden'
    }
}

const WarningDialog = ({
    classes, children, content, noPadding, onClose, onKeyPress, open, title, wizard, overflow, id
}) => (
    <DialogStyled
        id={id}
        data-cy="warningDialog"
        maxWidth={wizard && false}
        onClose={onClose}
        onKeyPress={onKeyPress}
        open={open}
        overflow={overflow}
        classes={{ paper: clsx({ [classes.paper]: wizard === 'xl' }) }}
    >
        {title && <DialogTitle>{title}</DialogTitle>}
        <ContentStyled overflow={overflow} classes={{ root: clsx({ [classes.root]: wizard === 'xl' }) }} style={noPadding && { padding: 0, maxWidth: wizard !== 'xl' && 800 }}>
            {content}
        </ContentStyled>
        {children && <DialogActions style={{ flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>{children}</DialogActions>}
    </DialogStyled>
)

const ContentStyled = styled(DialogContent)`
    &&{
        overflow: ${props => props.overflow};
        padding-bottom: ${props => props.paddingbottom};
      }
        
`

const DialogStyled = styled(Dialog)`
    &&
        .MuiDialog-paper {
            overflow: ${props => props.overflow};
        }
    `

export default withStyles(style)(WarningDialog)
