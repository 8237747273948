import { all, call, fork, put, select } from 'redux-saga/effects'
import { db } from '../../../api/db'
import { takeLatestSafe } from '../../../config/helpers/sagasHelper'
import { getQueryParamFromListConfig } from '../../common/MainTable/helpers/tableHelpers'
import {
    CLEAR_SYSADMIN_STATUS,
    DELETE_ORG,
    GET_APPLICATIONS,
    REQUEST_ADD_ADMIN,
    REQUEST_ADD_OR_UPDATE_ORG,
    REQUEST_ADMIN_PWD_CHANGE,
    REQUEST_DELETE_ADMIN,
    REQUEST_DELETE_ORG,
    REQUEST_ORG,
    REQUEST_ORGS,
    REQUEST_UPDATE_ADMIN_STATUS,
    SET_APPLICATIONS,
    SET_CURRENT_ORG,
    SET_SYSADMIN_STATUS,
    SUCCEED_REQUEST_ORGS
} from './actionTypes'
import { OPEN_NOTIFICATION } from "../../../notifications/actionTypes"
import React from "react"
import { getTranslationFn } from "../../dokstor/store/sagas/storeGetters"
import { SET_DOKSTOR_STATUS } from "../../dokstor/store/actionTypes"
import isEmpty from 'lodash/isEmpty'
import { Translate } from '../../common/Translate'

const listConfigs = (state, listId) => state.common.lists[listId]
const getCurrentOrgApplications = (state) => state.sysadmin.currentOrg.applications

const watcher = () =>
    function* watch() {
        yield takeLatestSafe(REQUEST_ORGS, runRequestOrgs)
        yield takeLatestSafe(REQUEST_ORG, runRequestOrg)
        yield takeLatestSafe(REQUEST_ADD_OR_UPDATE_ORG, runRequestAddOrUpdateOrg)
        yield takeLatestSafe(REQUEST_ADD_ADMIN, runRequestAddAdmin)
        yield takeLatestSafe(REQUEST_DELETE_ORG, runRequestDeleteOrg)
        yield takeLatestSafe(REQUEST_ADMIN_PWD_CHANGE, runRequestPwdChange)
        yield takeLatestSafe(REQUEST_DELETE_ADMIN, runRequestDeleteAdmin)
        yield takeLatestSafe(REQUEST_UPDATE_ADMIN_STATUS, runRequestUpdateAdminStatus)
        yield takeLatestSafe(GET_APPLICATIONS, runRequestApplications)
    }

function* throwErrorMessage(error) {
    const translationFn = yield select(getTranslationFn)
    yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: error.code ? translationFn('notification.' + error.code) : translationFn('genericErrorMessage') } })
}

function* handlerError(errors) {
    yield put({ type: SET_DOKSTOR_STATUS, dokstorError: true })
    yield all(errors.map(error => throwErrorMessage(error)))
}

function* runRequestOrgs(payload = {}) {
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    let orgs

    const listConfig = yield select(listConfigs, 'orgsList')

    const queryParam = getQueryParamFromListConfig(listConfig, payload.currentFilter)

    orgs = yield db('get', `auth-api/api/sysadmin/organizations?${queryParam}`)

    if (orgs.errors) {
        yield call(handlerError, orgs.errors)
    } else {
        yield put({ type: SUCCEED_REQUEST_ORGS, orgs })
    }
}

function* runRequestOrg({ identifier }) {
    let org

    yield put({ type: CLEAR_SYSADMIN_STATUS })

    org = yield db('get', `auth-api/api/sysadmin/organizations/${identifier}`)
    org = org.organization

    const admins = yield db('get', `auth-api/api/sysadmin/organizations/${identifier}/admins`)

    yield put({ type: SET_CURRENT_ORG, org: { ...org, admins } })
}

function* runRequestAddOrUpdateOrg({ org, orgConfiguration, dokstorConfiguration }) {

    const { name, status, identifier, applications } = org
    
    yield put({ type: CLEAR_SYSADMIN_STATUS })

    const isNew = !identifier

    let res = {}, res2 = {}, errors = []

    if (isNew) {
        res = yield db('post', 'auth-api/api/sysadmin/organizations', { name, applications, configuration: orgConfiguration })

        if (!res.errors) {
            if (applications.includes('DOKSTOR')) {
                if (dokstorConfiguration) {
                    res2 = yield db('post', `dokstor-bo-api/api/sysadmin/settings/organization/${res?.organization?.identifier}/import`, { configuration: dokstorConfiguration })
                } else {
                    res2 = yield db('post', `dokstor-bo-api/api/sysadmin/settings/organization/${res?.organization?.identifier}/refresh`)
                }
            }
            if (applications.includes('ROBOLITICS')) {
                res2 = yield db('post', `case-management-api/api/sysadmin/settings/organization/${res?.organization?.identifier}/refresh`)
                res2 = yield db('post', `algos-api/api/sysadmin/settings/organization/${res?.organization?.identifier}/refresh`)
            }
        }
    } else {
        const currentOrgApplications = yield select(getCurrentOrgApplications)

        res = yield db('put', 'auth-api/api/sysadmin/organizations/' + identifier, { name, applications })
        res2 = yield db('put', 'auth-api/api/sysadmin/organizations/' + identifier + '/status', { status })

        if (!res.errors && !res2.errors) {
            if (applications.includes('DOKSTOR') && !currentOrgApplications.includes('DOKSTOR')) {
                res2 = yield db('post', `dokstor-bo-api/api/sysadmin/settings/organization/${identifier}/refresh`)
            }
            if (applications.includes('ROBOLITICS') && !currentOrgApplications.includes('ROBOLITICS')) {
                res2 = yield db('post', `case-management-api/api/sysadmin/settings/organization/${identifier}/refresh`)
                res2 = yield db('post', `algos-api/api/sysadmin/settings/organization/${identifier}/refresh`)
            }
        }
    }

    if (res.errors) {
        errors = errors.concat(res.errors)
    }

    if (res2.errors) {
        errors = errors.concat(res2.errors)
    }

    if (isEmpty(errors)) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id={`notification.${isNew ? 'ADMIN_SUCCESS_ADDED' : 'ADMIN_SUCCESS_MODIFIED'}`} /> , type: 'success' } })
        yield put({ type: SET_SYSADMIN_STATUS, adminStatus: 'closing' })
    } else {
        yield call(handlerError, errors)
    }

    yield call(runRequestOrgs)
}

function* runRequestAddAdmin(payload) {
    const { admin } = payload
    const { name, username, email, password, phone, identifier, orgIdentifier, showChangePwd } = admin
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    const body = { name, username, email, password, phone: phone || null }

    let response
    if (identifier) {
        const editBody = { name, email, phone }
        response = yield db('put', 'auth-api/api/sysadmin/organizations/' + orgIdentifier + '/admins/' + identifier, editBody)
    }
    else {
        response = yield db('post', 'auth-api/api/sysadmin/organizations/' + orgIdentifier + '/admins', body)
    }

    if (showChangePwd) {
        const data = {
            newPassword: password,
            userid: identifier,
            orgid: orgIdentifier
        }
        yield call(runRequestPwdChange, { data })
    }

    if (!response.errors) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id={`notification.${identifier ? 'ADMIN_SUCCESS_MODIFIED' : 'ADMIN_SUCCESS_ADDED'}`} />, type: 'success' } })
        yield put({ type: SET_SYSADMIN_STATUS, adminStatus: 'closing' })
    } else {
        yield call(handlerError, response.errors)
    }

    yield call(runRequestOrg, { identifier: orgIdentifier })
}

function* runRequestDeleteOrg({ identifier }) {
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    let response = yield db('delete', 'auth-api/api/sysadmin/organizations/' + identifier)
    if (!response.errors) {
        yield db('post', 'dokstor-bo-api/api/sysadmin/settings/organization/' + identifier + '/purge')
        yield put({ type: DELETE_ORG, identifier })
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ORG_SUCCESS_DELETED' />, type: 'success' } })
        yield put({ type: SET_SYSADMIN_STATUS, adminStatus: 'closing' })
        yield call(runRequestOrgs)
    } else {
        yield call(handlerError, response.errors)
    }

}

function* runRequestPwdChange(payload) {
    const { newPassword, userid, orgid } = payload.data
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    const apiResponse = yield db('put', 'auth-api/api/sysadmin/organizations/' + orgid + '/admins/' + userid + '/credentials', { newPassword })
    if (!apiResponse.errors) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.PASSWORD_SUCCESS_CHANGE' />, type: 'success' } })
    } else {
        yield call(handlerError, apiResponse.errors)
    }
}

function* runRequestDeleteAdmin(payload) {
    const { userid, orgId } = payload.data
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    let response = yield db('delete', 'auth-api/api/sysadmin/organizations/' + orgId + '/admins/' + userid)
    if (!response.errors) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ADMIN_SUCCESS_DELETED' />, type: 'success' } })
        yield put({ type: SET_SYSADMIN_STATUS, adminStatus: 'closing' })
        yield call(runRequestOrg, { identifier: orgId })
    } else {
        yield call(handlerError, response.errors)
    }
}

function* runRequestUpdateAdminStatus(payload) {
    const { status, userid, orgid } = payload.data
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    let response = yield db('put', 'auth-api/api/sysadmin/organizations/' + orgid + '/admins/' + userid + '/status', { status })
    if (!response.errors) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id={'notification.ADMIN_SUCCESS_MODIFIED'} />, type: 'success' } })
        yield put({ type: SET_SYSADMIN_STATUS, adminStatus: status === 'DISABLE' ? 'closing' : null })
    } else {
        yield call(handlerError, response.errors)
    }
}

function* runRequestApplications() {
    yield put({ type: CLEAR_SYSADMIN_STATUS })
    const response = yield db('get', 'auth-api/api/sysadmin/applications')
    if (!response.errors) {
        yield put({ type: SET_APPLICATIONS, applications: response.applications })
    } else {
        yield call(handlerError, response.errors)
    }
}


export default function* rootSaga() {
    yield all([fork(watcher())])
}