import React from 'react'
import styled from 'styled-components'
import { ControlBar, Player } from 'video-react'
import 'video-react/dist/video-react.css'

const VideoViewer = ({ file }) => (
        <Wrapper>
            <Player autoPlay src={file}>
                <ControlBar autoHide={false} />
            </Player>
        </Wrapper>
)

const Wrapper = styled.div`
    width: auto;
    overflow: hidden;
    margin-bottom: 0;
`

export default VideoViewer
