import React, { useEffect } from 'react'

import loginBg from '../../assets/login_mcb.png'
import { LoginBG, LoginBox, LoginContainer, LoginInputsContent, SpacerV15 } from './Login.styles'
import Login from './screens/Login'


const LoginLayout = ({ classes, handleInputChange, login, loginError, username }) => {

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    const handleKeyDown = event => {
        if (event.key === 'Enter') login()
    }

    return (
        <LoginContainer>
            <LoginBox>
                <LoginBG>
                    <img
                        alt="login bg"
                        src={loginBg}
                    />
                </LoginBG>
                <LoginInputsContent>
                    <SpacerV15 />
                    <Login
                        classes={classes}
                        handleInputChange={handleInputChange}
                        loginError={loginError}
                        onLogin={login}
                        username={username}
                    />
                </LoginInputsContent>
            </LoginBox>
        </LoginContainer>
    )
}

export default LoginLayout