import { all, fork, put, select, call } from 'redux-saga/effects'
import { db, db_thunk } from '../../../api/db'
import { takeLatestSafe } from '../../../config/helpers/sagasHelper'
import { GET_USER_ROLES } from '../../../login/store/actionTypes'
import { OPEN_NOTIFICATION } from '../../../notifications/actionTypes'
import { getQueryParamFromListConfig } from '../../common/MainTable/helpers/tableHelpers'
import { RESET_CURRENT_PAGE, SET_API_STATUS, SET_COMMON_SETTINGS } from '../../common/store/actionTypes'
import {
    ADD_APIKEY,
    ADD_USER,
    CLEAR_ADMIN_STATUS,
    DELETE_APIKEY,
    DELETE_USER,
    GET_ORG_ROLES,
    GET_ORG_TEAMS,
    REQUEST_ADD_APIKEY,
    REQUEST_ADD_OR_UPDATE_USER, REQUEST_ADMITTED_USERS,
    REQUEST_APIKEYS,
    REQUEST_DELETE_APIKEY,
    REQUEST_DELETE_USER,
    REQUEST_USER_PWD_CHANGE,
    REQUEST_USERS,
    SET_ADMIN_STATUS,
    SET_APIKEYS,
    SET_ORG_ROLES, SUCCEED_REQUEST_ADMITTED_USERS,
    SUCCEED_REQUEST_USERS,
    UPDATE_ADMIN_SETTINGS,
    UPDATE_USER,
    REQUEST_GET_PERMISSIONS,
    SET_PERMISSIONS,
    REQUEST_ADD_ROLE,
    REQUEST_DELETE_ROLE,
    REQUEST_UPDATE_ROLE,
    REQUEST_USER_AUDITS,
    SET_USER_AUDITS,
    REQUEST_GET_DOKSTOR_CALLBACK_URL,
    REQUEST_SET_DOKSTOR_CALLBACK_URL,
    SET_DOKSTOR_URL_CALLBACK,
    REQUEST_GET_ROBOLITICS_CALLBACK_URL,
    REQUEST_SET_ROBOLITICS_CALLBACK_URL,
    SET_ROBOLITICS_URL_CALLBACK,
    SET_ORG_TEAMS,
    REQUEST_DELETE_TEAM,
    REQUEST_ADD_TEAM,
    REQUEST_UPDATE_TEAM,
    SET_ROBOLITICS_TRANSACTION_URL_CALLBACK,
    REQUEST_GET_ROBOLITICS_TRANSACTION_CALLBACK_URL,
    REQUEST_SET_ROBOLITICS_TRANSACTION_CALLBACK_URL
} from './actionTypes'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import React from "react"
import { removeFalsyValuesFromObject } from '../../../config/helpers/helpers'
import { getTranslationFn } from "../../dokstor/store/sagas/storeGetters"
import { SET_DOKSTOR_STATUS } from "../../dokstor/store/actionTypes"
import contextTypes from "../../dokstor/store/contextTypes.enum"
import { Translate } from '../../common/Translate'

const listConfigs = (state, listId) => state.common.lists[listId]
const currentUser = state => state.auth.identifier
const getOrgTeams = state => state.admin.orgTeams
const getOrgUsers = state => state.admin.users

const watcher = () => function* watch() {
    yield takeLatestSafe(REQUEST_USERS, runRequestUsers)
    yield takeLatestSafe(REQUEST_USER_AUDITS, runRequestUserAudtis)
    yield takeLatestSafe(REQUEST_ADD_OR_UPDATE_USER, runRequestAddOrUpdateUser)
    yield takeLatestSafe(REQUEST_DELETE_USER, runRequestDeleteUser)
    yield takeLatestSafe(REQUEST_USER_PWD_CHANGE, runRequestPwdChange)
    yield takeLatestSafe(REQUEST_ADD_APIKEY, runRequestAddAPIKey)
    yield takeLatestSafe(REQUEST_DELETE_APIKEY, runRequestDeleteAPIKey)
    yield takeLatestSafe(REQUEST_APIKEYS, runRequestAPIKeys)
    yield takeLatestSafe(UPDATE_ADMIN_SETTINGS, runUpdateFormatterSettings)
    yield takeLatestSafe(GET_ORG_ROLES, runRequestOrgRoles)
    yield takeLatestSafe(GET_ORG_TEAMS, runRequestOrgTeams)
    yield takeLatestSafe(REQUEST_ADMITTED_USERS, runRequestAdmittedUsers)
    yield takeLatestSafe(REQUEST_GET_PERMISSIONS, runRequestGetPermissions)
    yield takeLatestSafe(REQUEST_ADD_ROLE, runRequestAddRole)
    yield takeLatestSafe(REQUEST_ADD_TEAM, runRequestAddTeam)
    yield takeLatestSafe(REQUEST_DELETE_ROLE, runRequestDeleteRole)
    yield takeLatestSafe(REQUEST_DELETE_TEAM, runRequestDeleteTeam)
    yield takeLatestSafe(REQUEST_UPDATE_ROLE, runRequestUpdateRole)
    yield takeLatestSafe(REQUEST_UPDATE_TEAM, runRequestUpdateTeam)
    yield takeLatestSafe(REQUEST_GET_DOKSTOR_CALLBACK_URL, runRequestURLCallback)
    yield takeLatestSafe(REQUEST_SET_DOKSTOR_CALLBACK_URL, runRequestSetURLCallback)
    yield takeLatestSafe(REQUEST_GET_ROBOLITICS_CALLBACK_URL, runRequestRoboliticsURLCallback)
    yield takeLatestSafe(REQUEST_SET_ROBOLITICS_CALLBACK_URL, runRequestSetRoboliticsURLCallback)
    yield takeLatestSafe(REQUEST_GET_ROBOLITICS_TRANSACTION_CALLBACK_URL, runRequestRoboliticsTransactionsURLCallback)
    yield takeLatestSafe(REQUEST_SET_ROBOLITICS_TRANSACTION_CALLBACK_URL, runRequestSetRoboliticsTransactionsURLCallback)
}

function* throwErrorMessage(error) {
    const translationFn = yield select(getTranslationFn)
    yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: error.code ? translationFn('notification.' + error.code) : translationFn('genericErrorMessage') } })
}

function* handlerError(errors) {
    yield put({ type: SET_DOKSTOR_STATUS, dokstorError: true })
    yield all(errors.map(error => throwErrorMessage(error)))
}

function* runRequestOrgRoles() {
    const response = yield db('get', 'auth-api/api/admin/users/roles')
    yield put({ type: SET_ORG_ROLES, roles: response.roles })
}

function* runRequestOrgTeams() {
    const response = yield db('get', 'auth-api/api/admin/users/teams')
    yield put({ type: SET_ORG_TEAMS, teams: response.teams })
}

function* runUpdateFormatterSettings({ key, value }) {
    yield db('put', 'auth-api/api/admin/settings/AUTH/' + key, { value: JSON.stringify(value) })

    yield put({ type: SET_COMMON_SETTINGS, formatters: key === 'formatterSettings' && value, personalisation: key === 'personalisation' && value })

    yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.SETTINGS_SUCCESS_SAVED' />, type: 'success' } })

    yield put({ type: SET_ADMIN_STATUS, adminStatus: 'Settings changed successfully' })
    yield put({ type: CLEAR_ADMIN_STATUS })

}

function* runRequestAPIKeys({ listId, currentFilter }) {

    const listConfig = yield select(listConfigs, listId)

    const queryParams = getQueryParamFromListConfig(listConfig, currentFilter)

    yield put({ type: CLEAR_ADMIN_STATUS })
    yield put({ type: SET_ADMIN_STATUS, adminStatus: 'loading' })

    let APIKeys

    APIKeys = yield db('get', 'auth-api/api/admin/service-accounts?' + queryParams)

    if (APIKeys.errors) yield call(handlerError, APIKeys.errors)
    else {
        yield put({ type: SET_APIKEYS, APIKeys })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'success' })
    }
}

function* runRequestUsers({ listId, currentFilter }) {

    const listConfig = yield select(listConfigs, listId)

    const queryParams = getQueryParamFromListConfig(listConfig, currentFilter)

    yield put({ type: CLEAR_ADMIN_STATUS })
    yield put({ type: SET_ADMIN_STATUS, adminStatus: 'loading' })

    let users

    users = yield db('get', 'auth-api/api/admin/users?' + queryParams)

    if (users.errors) {
        yield call(handlerError, users.errors)
    }
    else {
        yield put({ type: SUCCEED_REQUEST_USERS, users })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'success' })
    }
}

function* runRequestAdmittedUsers({ application = '', permissions = '', teamId = '' }) {

    yield put({ type: SET_ADMIN_STATUS, adminStatus: 'loading' })
    let admittedUsers

    const getArrayValues = () => {
        if (isArray(permissions)) {
            let result = ''
            permissions.map(value => {
                if (isArray(value)) {
                    value.map(v => {
                        if (v.includes(',')) {
                            v.split(',').map(splitted => {
                                result += `&permissions=${splitted.trim()}`
                                return splitted
                            })
                        } else {
                            result += `&permissions=${v}`
                        }
                        return v
                    })
                } else {
                    result += `&permissions=${value}`
                }
                return value
            })
            return result
        } else {
            return permissions ? '&permissions=' + permissions : ''
        }
    }

    let arrayValues = getArrayValues()
    const teamIdQuery = teamId ? `&team-id=${teamId}` : ''

    admittedUsers = yield db('get', 'auth-api/api/profile/organization/users?application=' + application + arrayValues + teamIdQuery)

    if (admittedUsers.errors) yield call(handlerError, admittedUsers.errors)
    else {
        yield put({ type: SUCCEED_REQUEST_ADMITTED_USERS, admittedUsers })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'success' })
    }
    yield put({ type: CLEAR_ADMIN_STATUS })
}

function* runRequestAddOrUpdateUser({ data }) {
    const { name, username, email, phone, roleIds, status, identifier, password, user, teamIds } = data
    yield put({ type: CLEAR_ADMIN_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.ADD_EDIT_USER, value: true })

    let errors = [], response

    
    if (!identifier) {
        const body = { name, username, email, password, teamIds, roleIds, status }
        response = yield db('post', 'auth-api/api/admin/users', body)

        if (response.errors || !response?.user) errors = errors.concat(response.errors)
        else {
            yield put({ type: ADD_USER, user: response.user || {} })
        }
    } else {
        const body = { name, email, phone, teamIds, roleIds, status }
        response = yield db('put', 'auth-api/api/admin/users/' + identifier, body)

        if (response.errors) {
            errors = errors.concat(response.errors)
        } else {
            const _user = { ...user, ...body}
            yield put({ type: UPDATE_USER, user: _user })
            const currentUserIdentifier = yield select(currentUser)
            if (_user.identifier === currentUserIdentifier) yield put({ type: GET_USER_ROLES })
        }
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.ADD_EDIT_USER })

    if (!isEmpty(errors)) {
        yield call(handlerError, errors)
    } else {
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'closing' })
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id={identifier ? 'notification.USER_SUCCESS_MODIFIED' : 'notification.USER_SUCCESS_CREATED'} />, type: 'success' } })
        yield put({ type: REQUEST_USERS, listId: 'usersList' })
    }
}

function* runRequestDeleteUser({ identifier }) {

    let res
    res = yield db('get', 'dokstor-bo-api/api/cases/owners?case-owner-id=' + identifier)
    if (res.errors) {
        yield call(handlerError, res.errors)
    } else if (res.count > 0) {
        const orgTeams = yield select(getOrgUsers)
        const name = orgTeams.find(t => t.identifier === identifier)?.name
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ASSOCIATED_CASES' data={{ count: res.count, name }} />, type: 'error' } })
    } else {
        res = yield db('delete', 'auth-api/api/admin/users/' + identifier)
        if (res.errors) {
            yield call(handlerError, res.errors)
        } else {
            yield put({ type: DELETE_USER, identifier })
            yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.USER_SUCCESS_DELETED' />, type: 'success' } })
            yield put({ type: SET_ADMIN_STATUS, adminStatus: 'closing' })
            yield put({ type: RESET_CURRENT_PAGE, listId: 'usersList' })
            yield put({ type: REQUEST_USERS, listId: 'usersList' })
        }
    }
}

function* runRequestPwdChange({ newPassword, userId }) {
    yield put({ type: CLEAR_ADMIN_STATUS })
    const apiResponse = yield db('put', 'auth-api/api/admin/users/' + userId + '/credentials', { newPassword })

    if (!apiResponse.errors) {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.PASSWORD_SUCCESS_CHANGE' />, type: 'success' } })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.ADD_EDIT_USER, value: true })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'closing' })
    }
    else {
        yield call(handlerError, apiResponse.errors)
    }
    yield put({ type: CLEAR_ADMIN_STATUS })
}

function* runRequestAddAPIKey(payload = {}) {
    yield put({ type: CLEAR_ADMIN_STATUS })

    let errors = [], APIKey, newAPIToken = null
    let body = removeFalsyValuesFromObject(payload.payload)

    APIKey = yield db('post', 'auth-api/api/admin/service-accounts', { ...body })

    if (APIKey && APIKey.serviceAccount) {
        newAPIToken = APIKey.token
        APIKey = APIKey.serviceAccount
    }
    else {
        errors = errors.concat(APIKey.errors)
        APIKey = {}
    }

    if (!isEmpty(errors)) {
        yield call(handlerError, errors)
    }
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.APIKEY_SUCCES_CREATED' />, type: 'success' } })
        yield put({ type: ADD_APIKEY, APIKey, newAPIToken })
        yield put({ type: REQUEST_APIKEYS, listId: 'APIKeysList' })
        yield put({ type: SET_ADMIN_STATUS, adminSuccess: 'API Key succesfully created', adminStatus: 'Close API drawer' })
    }
}

function* runRequestDeleteAPIKey({ identifier }) {

    yield put({ type: CLEAR_ADMIN_STATUS })

    let errors = [], removedAPIKey

    removedAPIKey = yield db('delete', 'auth-api/api/admin/service-accounts/' + identifier)

    if (!removedAPIKey || removedAPIKey.errors) {
        errors = errors.concat(removedAPIKey.errors)
    }

    if (!isEmpty(errors)) {
        yield call(handlerError, errors)
    }
    else {
        yield put({ type: DELETE_APIKEY, identifier })
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.APIKEY_SUCCES_DELETED' />, type: 'success' } })
        yield put({
            type: SET_ADMIN_STATUS, adminStatus: 'closing', adminSuccess: 'Service Account successfully deleted'
        })
        yield put({ type: RESET_CURRENT_PAGE, listId: 'APIKeysList' })
        yield put({ type: REQUEST_APIKEYS, listId: 'APIKeysList' })
    }

}

function* runRequestGetPermissions() {

    let response = yield db('get', 'auth-api/api/admin/users/permissions')

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield put({ type: SET_PERMISSIONS, permissions: response.permissions })
    }
}

function* runRequestAddRole(payload = {}) {

    const { name, code, permissionids } = payload

    const endpoint = 'auth-api/api/admin/users/roles'

    const body = {
        name,
        code,
        permissionids
    }

    let response = yield db('post', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestOrgRoles)
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ROLE_SUCCESS_CREATED' />, type: 'success' } })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'close drawer' })
        yield put({ type: CLEAR_ADMIN_STATUS })
    }
}

function* runRequestAddTeam({ name, code }) {

    const endpoint = 'auth-api/api/admin/users/teams'

    let response = yield db('post', endpoint, { name, code })

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestOrgTeams)
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.TEAM_SUCCESS_CREATED' />, type: 'success' } })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'close drawer' })
        yield put({ type: CLEAR_ADMIN_STATUS })
    }
}

function* runRequestDeleteRole(payload = {}) {
    const { roleids = [] } = payload
    let errors = []
    
    for (let roleId of roleids) {
        const response = yield db('delete', `auth-api/api/admin/users/roles/${roleId}`)
        if (response.errors) errors.concat(response.errors)
    }

    if (!isEmpty(errors)) {
        yield call(handlerError, errors)
    } else {
        yield call(runRequestOrgRoles)
        yield put({ type: GET_USER_ROLES })
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ROLE_SUCCESS_DELETED' />, type: 'success' } })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'close drawer' })
        yield put({ type: CLEAR_ADMIN_STATUS })
    }
}

function* runRequestDeleteTeam({ teamids }) {    
    for (let teamId of teamids) {
        let res
        res = yield db('get', `dokstor-bo-api/api/cases/teams/?case-team-id=${teamId}`)
        if (res.errors) {
            yield call(handlerError, res.errors)
        } else if (res.count > 0) {
            const orgTeams = yield select(getOrgTeams)
            const name = orgTeams.find(t => t.identifier === teamId)?.name
            yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ASSOCIATED_CASES' data={{count: res.count, name }} />, type: 'error' } })
        } else {
            res = yield db('delete', `auth-api/api/admin/users/teams/${teamId}`)
            if (res.errors) yield call(handlerError, res.errors)
            else yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.TEAM_SUCCESS_DELETED' />, type: 'success' } })
        }
    }

    yield call(runRequestOrgTeams)
    yield put({ type: SET_ADMIN_STATUS, adminStatus: 'close drawer' })
    yield put({ type: CLEAR_ADMIN_STATUS })

}

function* runRequestUpdateRole(payload = {}) {

    const { roleid, name, code, permissionids } = payload

    const endpoint = `auth-api/api/admin/users/roles/${roleid}`

    const body = {
        name,
        code,
        permissionids
    }

    let response = yield db('put', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestOrgRoles)
        yield put({ type: GET_USER_ROLES })
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.ROLE_SUCCESS_UPDATED' />, type: 'success' } })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'close drawer' })
        yield put({ type: CLEAR_ADMIN_STATUS })
    }
}

function* runRequestUpdateTeam({ identifier, name, code }) {

    const endpoint = `auth-api/api/admin/users/teams/${identifier}`

    let response = yield db('put', endpoint, { name, code })

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestOrgTeams)
        yield put({ type: OPEN_NOTIFICATION, data: { message: <Translate id='notification.TEAM_SUCCESS_UPDATED' />, type: 'success' } })
        yield put({ type: SET_ADMIN_STATUS, adminStatus: 'close drawer' })
        yield put({ type: CLEAR_ADMIN_STATUS })
    }
}

function* runRequestUserAudtis(payload = {}) {
    const listConfig = yield select(listConfigs, payload.listId)
    const queryParam = getQueryParamFromListConfig(listConfig, payload.currentFilter)

    let res = yield db_thunk('get', 'auth-api/api/admin/users/' + payload.identifier + '/audits?' + queryParam)

    yield put({ type: SET_USER_AUDITS, userAudits: res.audits, userAuditsCount: res.count })
}

function* runRequestURLCallback() {
    const response = yield db('get', 'dokstor-bo-api/api/settings/callback')
    yield put({ type: SET_DOKSTOR_URL_CALLBACK, callback: response.callback })
}

function* runRequestSetURLCallback({ value }) {
    const response = yield db('put', 'dokstor-bo-api/api/settings/callback', { value })
    yield put({ type: SET_DOKSTOR_URL_CALLBACK, callback: response.callback })
}

function* runRequestRoboliticsURLCallback() {
    const response = yield db('get', 'case-management-api/api/settings/callback')
    yield put({ type: SET_ROBOLITICS_URL_CALLBACK, callback: response.callback })
}

function* runRequestSetRoboliticsURLCallback({ value }) {
    const response = yield db('put', 'case-management-api/api/settings/callback', { value })
    yield put({ type: SET_ROBOLITICS_URL_CALLBACK, callback: response.callback })
}

function* runRequestRoboliticsTransactionsURLCallback() {
    const response = yield db('get', 'case-management-api/api/settings/callback-transac')
    yield put({ type: SET_ROBOLITICS_TRANSACTION_URL_CALLBACK, callback: response.callback })
}

function* runRequestSetRoboliticsTransactionsURLCallback({ value }) {
    const response = yield db('put', 'case-management-api/api/settings/callback-transac', { value })
    yield put({ type: SET_ROBOLITICS_TRANSACTION_URL_CALLBACK, callback: response.callback })
}

export default function* rootSaga() {
    yield all([fork(watcher())])
}