import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider/index'
import Drawer from '@material-ui/core/Drawer/index'
import List from '@material-ui/core/List/index'
import ListItem from '@material-ui/core/ListItem/index'
import ListItemText from '@material-ui/core/ListItemText/index'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SET_SIDEBAR } from '../../../../login/store/actionTypes'
import Item from './Item'
import { menuItems } from './menuConfig'

const styles = {
    paper: {
        backgroundColor: 'rgba(47, 42, 42, 1)',
        transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        marginTop: 55,
        paddingTop: 8,
        paddingBottom: 55
    },
    drawerOpen: {
        width: 210
    },
    drawerClosed: {
        width: 73
    },
    sideBarTop: {
        padding: '5px 8px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 8
    },
    closedTop: {
        marginTop: 54
    },
    menuList: {
        position: 'fixed',
        background: 'rgba(47, 42, 42, 1)',
        left: 73,
        zIndex: 1101,
        padding: '0 16px',
        minWidth: 200,
        borderLeft: '1px solid rgba(255,255,255,.2)'
    },
    listText: {
        color: 'rgba(255,255,255, 0.7)',
    },
    listTextRoot: {
        marginLeft: 10
    },
    listTextRootActive: {
        '&:before': {
            content: '\'»\'',
            color: '#fe593b',
            position: 'absolute',
            left: '-4px'
        }
    },
    listItem: {
        borderBottom: '1px solid rgba(255,255,255,.2)',
        paddingLeft: 0
    },
    divider: {
        backgroundColor: 'rgba(255,255,255, 0.2)',
        width: 57,
        marginLeft: 8
    }
}

const mapStateToProps = store => ({
    sidebar: store.auth.sidebar,
    datasources: store.common.datasources,
    roles: store.auth.roles,
    dashboardsConfig: store.dokstor.dashboardsConfig,
    validations: store.common.validations
})

const mapDispatchToProps = dispatch => ({
    setSideBar: bool => dispatch({ type: SET_SIDEBAR, bool })
})

class SideBar extends Component {

    state = {
        selectedIndex: -1,
        activePathName: '',
        selectedMenus: [-1],
        dashboards: []
    }

    componentDidMount() {
        this.setState({ activePathName: this.props.history.location.pathname })
        this.unlisten = this.props.history.listen(e => this.updateActiveItem(e.pathname))

        const dashboards = this.getDashboards()
        this.setState({ dashboards })
    }

    componentDidUpdate(prevProps) {
        const {sidebar, dashboardsConfig} = this.props

        if ((sidebar !== prevProps.sidebar) && sidebar === true) {
            this.setState({ selectedIndex: -1 })
        }

        if (dashboardsConfig !== prevProps.dashboardsConfig) {
            const dashboards = this.getDashboards()
            this.setState({ dashboards })
        }
    }

    componentWillUnmount() {
        this.unlisten()
    }

    updateActiveItem = (newPathName) => {
        if (this.state.activePathName !== newPathName) {
            this.setState({ activePathName: newPathName })
        }
    }

    handleClickAway = () => {
        if (this.state.selectedIndex !== -1) {
            this.setState({ selectedIndex: -1 })
        }
    }

    navigateToChildren = (url) => {
        const { history } = this.props
        history.push({ pathname: '/' + url })
        this.setState({ selectedIndex: -1 })
    }

    openMenuList = index => {
        if (this.state.selectedIndex === index)
            this.setState({ selectedIndex: -1 })
        else
            this.setState(prevState => {
                let newSelectedMenus = [...prevState.selectedMenus]
                if (prevState.selectedMenus[0] !== index) {
                    newSelectedMenus = [index]
                }
                return { selectedIndex: index, selectedMenus: newSelectedMenus }
            })
    }

    itemClickHandler = (item, depth, index) => {
        item.subMenu ? this.setState(prevState => {
            let newSelectedMenus
            if (prevState.selectedMenus.length <= depth + 1) {
                newSelectedMenus = [...prevState.selectedMenus, index]
            }
            else {
                const indexToRemove = prevState.selectedMenus[depth + 1]
                newSelectedMenus = prevState.selectedMenus.slice(0, depth + 1)
                if (indexToRemove !== index) {
                    newSelectedMenus = [...newSelectedMenus, index]
                }
            }
            return { selectedMenus: newSelectedMenus }
        }) : this.navigateToChildren(item.link)
    }

    checkCollapsed = (depth, index) => {
        const { selectedMenus } = this.state
        return selectedMenus.length - 1 >= depth + 1 && selectedMenus[depth + 1] === index
    }

    getDashboards = () => {
        const { roles, dashboardsConfig } = this.props

        return dashboardsConfig
            .filter(dc => dc.permissions.some(p => roles.includes(p.roleIdentifier)))
            .map(dc => dc.code)
    }

    render() {
        const { permissions, classes, sidebar, history, datasources, validations } = this.props
        const { selectedIndex, dashboards } = this.state

        if (!permissions) return false

        const hideWatchlist = !validations?.person?.includes('WATCH_LIST') && !validations?.company?.includes('WATCH_LIST')
        let enabledIndex = 0

        const renderMenu = (items, depth = 0) => {
            return items.map((item, index) => {
                let result = []
                if (!item.disabledFn() && !(item.children && item.children.length > 0 && item.children.every(item => item.disabledFn()))) {
                    if (depth === 0) {
                        result.push(React.createElement(Divider, {
                            key: index + 'divider', classes: { root: classes.divider }
                        }))
                    }
                    result.push(React.createElement(Item, {
                        activePathName: this.state.activePathName,
                        depth: depth,
                        history: history,
                        icon: item.icon,
                        iconActive: item.iconActive,
                        index: enabledIndex,
                        key: depth + index + item.title,
                        link: depth === 0 ? item.children.filter(child => !child.disabledFn())[0].link : item.link,
                        openMenuList: this.openMenuList,
                        title: item.title,
                        subMenu: item.subMenu
                    }))
                    enabledIndex++
                }
                if (this.props.sidebar && item.children && item.children.length > 0) {
                    result = [...result, ...renderMenu(item.children, depth + 1)]
                }
                return result
            })
        }

        const renderExpand = (item, depth, index) => {
            const { selectedMenus } = this.state
            return item.children && item.children.length > 0 &&
                (selectedMenus.length - 1 >= depth + 1 && selectedMenus[depth + 1] === index ?
                    <ExpandLess style={{ color: 'white' }} /> :
                    <ExpandMore style={{ color: 'white' }} />)
        }

        const renderListItem = (index, item, depth = 0) => (
            <React.Fragment key={index}>
                <ListItem
                    button
                    className={classes.listItem}
                    key={index}
                    onClick={() => this.itemClickHandler(item, depth, index)}
                    style={{ paddingLeft: 16 * depth }}
                >
                    <ListItemText
                        classes={{
                            primary: classes.listText,
                            root: clsx([classes.listTextRoot,
                            {
                                [classes.listTextRootActive]:
                                    (item.children === undefined || item.children.length === 0) &&
                                    this.state.activePathName === `/${item.link}`
                            }
                            ])
                        }}
                        primary={item.title}
                    />
                    {renderExpand(item, depth, index)}
                </ListItem>
                {item.children && item.children.length > 0 &&
                    <Collapse
                        in={this.checkCollapsed(depth, index)}
                        timeout='auto'
                        unmountOnExit
                    >
                        {item.children.filter(subchild => !subchild.disabledFn()).map((subchild, subchildIndex) => (
                            renderListItem(index + '.' + subchildIndex, subchild, depth + 1)
                        ))}
                    </Collapse>
                }
            </React.Fragment>
        )

        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                {<Drawer
                    anchor='left'
                    classes={{
                        paper: clsx([classes.paper, {
                            [classes.drawerOpen]: sidebar, [classes.drawerClosed]: !sidebar
                        }])
                    }}
                    id='sidebar'
                    open={sidebar || false}
                    variant="permanent"
                >
                    {datasources && renderMenu(menuItems(permissions, datasources, dashboards, hideWatchlist))}
                    {selectedIndex !== -1 &&
                        <List
                            className={classes.menuList}
                            dense
                            style={{ top: 55 + 56 * selectedIndex }}
                        >
                            {datasources && menuItems(permissions, datasources, dashboards, hideWatchlist).filter(item =>
                                !item.disabledFn()
                                && !(item.children && item.children.length > 0
                                    && item.children.every(item => item.disabledFn())))[selectedIndex].children.filter(child =>
                                        !child.disabledFn()).map((child, index) => (
                                            renderListItem(index + '', child)
                                        )
                                        )
                            }
                        </List>}
                </Drawer>}
            </ClickAwayListener>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(SideBar)))