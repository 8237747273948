import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { ACCEPTED_LANGUAGES } from '../../config/commonConfig'
import { CLEAR_STORE } from '../../store/actionTypes'

import {
    checkToken,
    requestRecoverLink,
    requestSetCredentials as APIRequestSetCredentials,
    getSettings
} from '../api/login'
import {
    CLEAR_AUTH_ERRORS,
    REQUEST_TEAM_IDS,
    REQUEST_LOGIN,
    REQUEST_PWD_CHANGE,
    SET_LANGUAGE,
    SUCCEED_REQUEST_TOKEN
} from '../store/actionTypes'
import LoginLayout from './Layout'
import HeliocorStorage from '../../config/HeliocorStorage'
import { GET_COMMON_SETTINGS } from '../../apps/common/store/actionTypes'
import isEmpty from 'lodash/isEmpty'
import { OPEN_NOTIFICATION } from "../../notifications/actionTypes"
import { GET_ORG_TEAMS } from '../../apps/Admin/store/actionTypes'
import { REQUEST_GET_DASHBOARDS_CONFIG } from '../../apps/dokstor/store/actionTypes'
import contextTypes from '../../apps/dokstor/store/contextTypes.enum'

const mapStateToProps = store => ({
    token: store.auth.token,
    isSysAdmin: store.auth.permissions?.SYSTEM?.includes('ADMIN'),
    permissions: store.auth.permissions,
    loginError: store.auth.loginError,
    passwordExpired: store.auth.passwordExpired,
    resetError: store.auth.resetError,
    resetStatus: store.auth.resetStatus,
    language: store.auth.language,
    formatters: store.common.formatters,
    systemCurrency: store.common.formatters.currency,
    apiStatus: store.common.apiStatus
})

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch({ type: REQUEST_LOGIN, username, password }),
    clearStore: () => dispatch({ type: CLEAR_STORE }),
    onPwdChange: (oldPassword, newPassword) => dispatch({ type: REQUEST_PWD_CHANGE, oldPassword, newPassword }),
    clearErrors: () => dispatch({ type: CLEAR_AUTH_ERRORS }),
    setLanguage: language => dispatch({ type: SET_LANGUAGE, language }),
    setLoginData: loginData => dispatch({ type: SUCCEED_REQUEST_TOKEN, ...loginData }),
    getFormatters: () => dispatch({ type: GET_COMMON_SETTINGS }),
    getTeamIds: () => dispatch({ type: REQUEST_TEAM_IDS }),
    getOrgTeams: () => dispatch({ type: GET_ORG_TEAMS }),
    openNotification: data => dispatch({ type: OPEN_NOTIFICATION, data }),
    requestDashboardsconfig: () => dispatch({ type: REQUEST_GET_DASHBOARDS_CONFIG })
})

class LoginContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loginStatus: null,
            username: '',
            recover: false,
            supportEmail: '',
            settingsLoaded: false
        }
    }

    async componentDidMount() {
        const { location, setLoginData, i18n } = this.props

        if (location.state && location.state.changePwd) {
            this.setState({ loginStatus: 'changePwd', resetPwdRequested: true })
            this.props.history.replace({
                state: { resetPwdRequested: false }
            })
        }

        const recoverToken = location.search.split('?token=')[1]

        if (recoverToken) {
            this.setState({ loginStatus: 'credentials', loginSubstatus: 'checking', recoverToken })
            checkToken(recoverToken).then(res => {
                const errors = !!res?.errors
                this.setState((prevState) => ({
                    loginStatus: errors ?  prevState.loginStatus : 'credentials',
                    loginSubstatus: errors ? 'error' : 'success' 
                }))
            })
        }

        const loginData = await HeliocorStorage.getItem('loginData')
        const sidebar = await HeliocorStorage.getItem('sideBar')

        if (loginData) {
            setLoginData({ ...loginData, sidebar })
        } else {
            this.setState({ loginStatus: 'login'})
        }

        const navigatorLanguage = window.navigator.language
        const storedLanguage = await HeliocorStorage.getItem('language')
        const fallbackLanguage = ACCEPTED_LANGUAGES.includes(navigatorLanguage) ? navigatorLanguage : 'en-EN'

        // TODO: we should not add language to store
        this.props.setLanguage(storedLanguage || fallbackLanguage)
        i18n.changeLanguage(storedLanguage || fallbackLanguage)
    }

    async componentDidUpdate(prevProps) {
        const { passwordExpired, permissions, resetStatus, formatters, t, isSysAdmin, getTeamIds, getOrgTeams, getFormatters, requestDashboardsconfig } = this.props
        const { loginStatus, settingsLoaded } = this.state

        document.title = `${(t('button.login'))} - Heliocor Platform`

        if (prevProps.permissions !== permissions) {
            const loginData = await HeliocorStorage.getItem('loginData')
            if (loginData) {
                if (isEmpty(formatters) && !isSysAdmin) {
                    getFormatters()
                }
                getTeamIds()
                getOrgTeams()
                requestDashboardsconfig()
            }
            if (passwordExpired) {
                this.setState({ loginStatus: 'changePwd' })
            }
        }
        if (prevProps.resetStatus !== resetStatus) {
            resetStatus === 'succeed' && this.setState({ resetPwdRequested: false })
        }

        if (loginStatus === 'login' && !settingsLoaded) {
            this.setState({ settingsLoaded: true })
            getSettings().then(res => this.setState({ recover: res?.recover, supportEmail: res?.supportEmail }))
        } 
    }

    showRecoverPassword = () => this.setState({ loginStatus: 'recoverPassword' })

    requestPasswordRecover = () => {
        const { t } = this.props
        this.setState({ loginSubstatus: 'loading' })

        requestRecoverLink(this.state.username)
            .then(res => {
                if (res && res.errors) {
                    res.errors.map(error => this.props.openNotification({
                        message: t('notification.' + error.code), type: 'error'
                    }))
                }
                else {
                    this.setState({ loginSubstatus: 'username_sent' })
                    this.props.openNotification({ message: t('notification.SUCCESS_RECOVERY_EMAIL'), type: 'success' })
                }
            })
    }

    login = () => {
        const { username, password } = this.state
        this.props.login(username, password)
    }

    requestSetCredentials = () => {
        const { t } = this.props
        const { recoverToken, newpwd1, newpwd2 } = this.state
        newpwd1 === newpwd2 && APIRequestSetCredentials(recoverToken, newpwd2)
            .then(res => {
                if (res && res.errors) {
                    res.errors.map(error => this.props.openNotification({ message: t('notification.' + error.code), type: 'error' }))
                } else {
                    this.setState({ loginSubstatus: 'setSuccess' })
                }
            })
    }

    handleInputChange = key => event => {
        event.target.value = event.target.value.trim()
        this.setState({ [key]: event.target.value }, this.validatePasswords)
    }

    validatePasswords = () => {
        const { t } = this.props
        const { newpwd1, newpwd2 } = this.state
        if (!newpwd1 || !newpwd2 ) return null
        const validated = newpwd1 === newpwd2
        this.setState({ error: !validated && t("login.errorEqual") })
        return validated
    }

    redirectTo = status => {
        this.setState({ loginStatus: status, loginSubstatus: null })
    }

    resetPwd = () => {
        const { onPwdChange } = this.props
        const { oldpwd, newpwd1 } = this.state
        if (this.validatePasswords()) {
            onPwdChange(oldpwd, newpwd1)
        }
    }

    goToLogin = () => {
        HeliocorStorage.clear()
        this.props.clearStore()
        this.setState({ loginStatus: 'login', resetPwdRequested: false })
        this.props.history.push('/')
    }
 
    goToHome = () => {
        this.props.history.push('/home')
    }

    goToLastPage = () => {
        this.props.history.goBack()
    }

    render() {
        const { url, loginStatus, loginSubstatus, error, resetPwdRequested, username, oldpwd, newpwd1, newpwd2, recover, supportEmail } = this.state
        const { token, loginError, passwordExpired, location, onPwdChange, isSysAdmin, apiStatus } = this.props

        const localURL = url ? { from: { pathname: this.state.url } } : null
        let { from } = localURL || location.state || { from: { pathname: '/home' } }

        isSysAdmin && !location.state?.changePwd && (from = '/sysadmin')

        return (
            token && !passwordExpired && !resetPwdRequested && !loginError
                ?
                <Redirect to={from} />
                :
                <LoginLayout
                    error={error}
                    goToHome={this.goToHome}
                    goToLastPage={this.goToLastPage}
                    goToLogin={this.goToLogin}
                    handleInputChange={this.handleInputChange}
                    loginError={loginError}
                    login={this.login}
                    loginStatus={loginStatus}
                    loginSubstatus={loginSubstatus}
                    onPwdChange={onPwdChange}
                    passwordExpired={passwordExpired}
                    redirectTo={this.redirectTo}
                    requestPasswordRecover={this.requestPasswordRecover}
                    requestSetCredentials={this.requestSetCredentials}
                    resetPwd={this.resetPwd}
                    showRecoverPassword={this.showRecoverPassword}
                    username={username}
                    loginLoading={apiStatus[contextTypes.LOGIN]}
                    resetFilled={!!(oldpwd && newpwd1 && newpwd2)}
                    recoverEnabled={recover}
                    supportEmail={supportEmail}
                />
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginContainer))

