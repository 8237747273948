import React from 'react'
import ContentLoader from 'react-content-loader'

const RecordLoader = () => (
    <ContentLoader width={512} height={364}>
        <rect x="30" y="30" rx="3" ry="3" width="200" height="20" />
        <rect x="30" y="65" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="95" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="125" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="155" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="185" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="215" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="245" rx="3" ry="3" width="400" height="18" />
        <rect x="30" y="275" rx="3" ry="3" width="400" height="18" />
    </ContentLoader>
)

export default RecordLoader