import React from 'react'
import { Paragraph, TitleH1 } from 'heliocor-ui'
import { withTranslation } from 'react-i18next'
import { ButtonStyled, InputStyled, SpacerV15, SpacerV40 } from '../Login.styles'

const ForgotPwd = ({ handleInputChange, requestPasswordRecover, loginSubstatus, goToLogin, username, t, supportEmail }) => (
    <>
        <TitleH1 value={t('forgot.password')} margin='40px 0 4px 0' />
        <Paragraph value={t('forgot.write')} margin='0' />
        <SpacerV40 />
        <Paragraph value={t('username')} margin='16px 0 0 0' />
        <InputStyled
            name="username"
            onChange={handleInputChange('username')}
            value={username}
        />
        <SpacerV40 />
        <ButtonStyled
            onClick={requestPasswordRecover}
            variant='contained'
            color='primary'
            disabled={!username || username.trim() === ''}
            value={t("button.send")}
            isLoading={loginSubstatus === 'loading'}
        />
        {loginSubstatus === 'username_sent' && (
            <React.Fragment>
                <SpacerV15 />
                <Paragraph value={t("forgot.email")} />
                <a href={`mailto:${supportEmail}`} >{supportEmail}</a>
            </React.Fragment>
        )}
        <Paragraph onClick={goToLogin} cursor='pointer' color='main' value={t('login.loginLink')} />
    </>
);

export default withTranslation()(ForgotPwd);