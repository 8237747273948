import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ActionStyled = styled.div`
    display: flex;
    flex-direction: row !important;
    padding-left: 16px;
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        order: 2;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }
`;

class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onPageChange(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onPageChange(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onPageChange(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onPageChange(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
        );
    };

    render() {
        const { count, page, rowsPerPage } = this.props;

        return (
            <ActionStyled>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    <LastPageIcon />
                </IconButton>
            </ActionStyled>
        );
    }
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const PaginationActions = TablePaginationActions

export default PaginationActions