import { combineReducers } from 'redux'
import admin from '../apps/Admin/store/reducers'
import caseManagement from '../apps/caseManagement/store/reducers'
import common from '../apps/common/store/reducers'
import dokstor from '../apps/dokstor/store/reducers'
import refdata from '../apps/referenceData/store/reducers'
import sysadmin from '../apps/SuperAdmin/store/reducers'
import auth from '../login/store/reducers'
import notifications from '../notifications/reducers'
import { CLEAR_STORE } from './actionTypes'

const appReducer = combineReducers({
    auth,
    admin,
    common,
    refdata,
    dokstor,
    notifications,
    sysadmin,
    caseManagement
})

const reducer = (state, action) => {
    if (action.type === CLEAR_STORE) {
        state = {
            localize: { ...state.localize },
            formatters: {}
        }
    }
    return appReducer(state, action)
}

export default reducer