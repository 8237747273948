import { db_thunk } from "../../../api/db"
import { getDocumentsPath } from "./pathGetters"

export const getDoc = (entityid, documentid, cpid, isCompany) => {

    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    return db_thunk('get', `${documentsPath}/${documentid}`)
        .then(res => res?.document || {}
        )
}

export const getDocDatas = (entityid, documentid, cpid, isCompany) => {

    const documentsPath = getDocumentsPath(isCompany, entityid, cpid)

    return db_thunk('get', `${documentsPath}/${documentid}/data${cpid ? 's' : ''}`)
        .then(res => res.datas && res.datas.length > 0
            ? res.datas
            : []
        )
}

export const getSignatureDocumentContent = (docId) => {
    const path = `dokstor-bo-api/api/settings/signature-document-type/${docId}/data`
    return db_thunk('get', path).then(res => res?.content || '')
}


export const getHits = (status, identifier) => db_thunk('get', 'dokstor-bo-api/api/person/' + identifier + '/hit')
    .then((res = { res: { hits: [] } }) => res.hits)

export const getCompanyHits = (status, identifier) => db_thunk('get', 'dokstor-bo-api/api/company/' + identifier + '/hit')
    .then((res = { res: { hits: [] } }) => res.hits)
