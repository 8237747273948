import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Drawer from '@material-ui/core/Drawer'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { device } from 'heliocor-ui'

import { DRAWER_SIZES } from '../../contants/drawerSizes.enum'
import { SET_API_STATUS } from '../../../store/actionTypes'
import { useWindowWidth } from '../../../../../hooks'

const WarningBox = React.lazy(() => import('../../../WarningBox/WarningBox'))

const StyledDrawer = styled.div`
    width: 100vw;
    @media ${device.mobile} {
        width: ${props => {
        if (props.width === '100vw') return 'calc(100vw - 16px)';
        return props.width >= 768 ? '100vw' : props.width + 'px'
    }
    };
    }
    @media ${device.midSize} {
        width: ${props => {
        if (props.width === '100vw') return 'calc(100vw - 16px)';
        return props.width >= 1024 ? '100vw' : props.width + 'px'
    }
    };
    }
    @media ${device.tablet} {
        width: ${props => {
        if (props.width === '100vw') return 'calc(100vw - 16px)';
        return props.width + 'px'
    }
    };
    }
`

const CustomDrawer = props => {
    const {
        anchor = 'right',
        component,
        drawerWidth = DRAWER_SIZES.SMALL,
        elementProps,
        removeLastElement,
        contextType
    } = props

    const windowWidth = useWindowWidth()

    const { t } = useTranslation()
    const confirmChanges = useRef(false)
    const [open, setOpen] = useState(false)
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    const apiStatus = useSelector(store => store.common.apiStatus)

    const dispatch = useDispatch()

    const clearApiStatus = useCallback((context) => dispatch({
        type: SET_API_STATUS, action: 'CLEAR', context
    }), [dispatch])

    useEffect(() => { setOpen(true) }, [])

    useEffect(() => {
        apiStatus[contextType] && clearApiStatus(contextType)
        return () => apiStatus[contextType] && clearApiStatus(contextType)
    }, [apiStatus, contextType, clearApiStatus])

    const closeHandler = (options = {}) => {
        const { force = false } = options
        if(!confirmChanges.current || force) {
            setOpen(false)
            setTimeout(() => removeLastElement(), 100)
        } else {
            setIsConfirmationOpen(true)
        }
    }

    const openConfirmChangesDialog = (confirm) => {
        confirmChanges.current = confirm
    }

    const handleContinueWithoutSaving = () => {
        confirmChanges.current = false
        setIsConfirmationOpen(false)
        closeHandler()
    }

    const handleContinueEditing = () => {
        setIsConfirmationOpen(false)
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={closeHandler} data-cy="drawer">
            <StyledDrawer width={drawerWidth < windowWidth ? drawerWidth : '100%'}>
                {open && component &&
                    React.createElement(component, {
                        ...elementProps,
                        closeCurrent: closeHandler,
                        confirmChanges: openConfirmChangesDialog
                    })}
            </StyledDrawer>
            <React.Suspense fallback={<div />}>
                <WarningBox
                    open={isConfirmationOpen}
                    title={t('warning.unsavedChanges')}
                    content={t('warning.unsavedChangesConfirmation')}
                    buttons={[
                        {
                            value: t('button.continueEdition'),
                            onClick: handleContinueEditing,
                        },
                        {
                            type: 'light',
                            onClick: handleContinueWithoutSaving,
                            value: t('button.continueWOSaving')
                        }
                    ]}
                />
                </React.Suspense>
        </Drawer>
    )
}

export default CustomDrawer

CustomDrawer.propTypes = {
    anchor: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
    component: PropTypes.elementType,
    drawerWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    elementProps: PropTypes.object,
    removeLastElement: PropTypes.func,
    contextType: PropTypes.string
}
