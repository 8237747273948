import styled, {css}                                                                        from 'styled-components'

const flexEnd = css`
	display: flex;
	justify-content: flex-end;
`

const FieldsWrapper = styled.div`
    background-color: ${props => props.theme.colors.white};
    padding: ${props => props.fullSize ? 0 : '5px 30px'};
    padding-bottom: 0;
    ${props => props.flexEnd && flexEnd}
`

export { FieldsWrapper }