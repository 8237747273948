export const IconTypes = {
    ALERT: 'alert',
    ASSIGNMENT: 'description',
    BUILD: 'build',
    CALENDAR: 'calendar',
    CARD: 'card',
    CATEGORY: 'category',
    COMPANY: 'company',
    ENVELOPE: 'envelope',
    EURO: 'euro',
    FILE: 'file',
    FINGERPRINT: 'fingerprint',
    GROUP: 'group',
    ID: 'id',
    INFO: 'info',
    LABEL: 'label',
    PERSON: 'person',
    PHONE: 'phone',
    PUZZLE: 'puzzle',
    ROBOT: 'robot',
    STATS: 'stats',
    WORLD: 'world',
    CHANNEL: 'keyboard-tab',
    TREE: 'tree',
    TRANSACTION: 'transaction',
    FILTER: 'filter'
}