import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { LazyLoadingIndicator } from '../apps/common'
import { AccessControl } from './AccessControl'

const mapStateToProps = store => ({
    token: store.auth.token,
    permissions: store.auth.permissions,
    name: store.auth.name,
    organization: store.auth.organization,
    sidebar: store.auth.sidebar,
    AD: store.auth.MCB_AD
})

class PrivateRoute extends Component {
    render() {
        const { component: Component, token, permissions, ...rest } = this.props
        const access = permissions && AccessControl.checkAccess(permissions, rest.location.pathname)

        return (
            <Route {...rest} render={props => (
                token ? access.isPermitted ?
                    <Suspense fallback={<LazyLoadingIndicator />}>
                        <Component {...props} {...rest} />
                    </Suspense>
                    :
                    <Redirect to={{
                        pathname: '/forbidden',
                        state: { from: props.location, reason: access.reason }
                    }} />
                    :
                    <Redirect to={{
                        pathname: this.props.AD ? '/login-mcb' : '/',
                        state: { from: props.location }
                    }} />
            )} />
        )
    }
}

export default connect(mapStateToProps)(PrivateRoute)