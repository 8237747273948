import ClickAwayListener from '@material-ui/core/ClickAwayListener/index'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
    SET_LANGUAGE,
    SET_SIDEBAR
} from '../../../../login/store/actionTypes'
import Hamburger from './components/Hamburger'
import {
    AvatarImg,
    IconButtonStyled,
    TopBarContainer,
    User,
    AvatarContainer,
    Logo
} from './TopBar.styles'
import TopMenuOptions from './TopMenuOptions'
import HeliocorStorage from "../../../../config/HeliocorStorage"

// Keep this line as the last import, 
// so it does not override SC styles
import { TitleH2, TitleH4, TitleH5, FlexContainer } from "heliocor-ui"

const styles = {
    title: {
        marginLeft: '24px'
    }
}

const mapStateToProps = store => ({
    language: store.auth.language,
    sidebar: store.auth.sidebar,
    breadcrumbStore: store.auth.breadcrumb,
    personalisation: store.common.personalisation,
    AD: store.auth.MCB_AD
})

const mapDispatchToProps = dispatch => ({
    setLanguage: (language) => dispatch({ type: SET_LANGUAGE, language }),
    setSideBar: (bool) => dispatch({ type: SET_SIDEBAR, bool })
})

class TopBar extends Component {

    state = {
        logoutMenu: false,
        show: true
    }

    checkScroll = () => {
        if (window.scrollY === 0) {
            this.setState({ show: true })
        }
        else {
            this.setState({ show: false })
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.checkScroll)
    }

    handleClickAway = (e) => {
        if (this.state.logoutMenu !== false && e.target !== document.getElementById('userHeader') && e.target !== document.getElementById('userImg')) {
            this.setState({ logoutMenu: false })
        }
    }

    pwdChange = () => {
        this.props.history.push({
            pathname: '/', state: { changePwd: true }
        })
    }

    render() {
        const { name, organization, location, language, sidebar, breadcrumbStore, personalisation, AD, t } = this.props
        const { mainColor, headerTextColor='#ffffff', logo } = personalisation
        
        const { logoutMenu, show } = this.state
        const surname = name && name.split(' ')[1] && name.split(' ')[1].substring(0, 1)
        const initials = name && (surname ? name.split(' ')[0].substring(0, 1) + surname : name.split(' ')[0].substring(0, 1))

        const breadcrumb = location.pathname.split('/').filter(item => !/[0-9a-zA-Z]{32}/.test(item) && item !== '').map(item => t(item)).join(' > ').concat(breadcrumbStore ? breadcrumbStore : '')

        return (
            <TopBarContainer show={show} sysAdmin={name === 'System Admin'} backgroundColor={mainColor}>
                <FlexContainer>
                    {name !== 'System Admin' &&
                        <IconButtonStyled id="iconBtn" onClick={this.toggleBar}>
                            <Hamburger opened={sidebar} color={headerTextColor}/>
                        </IconButtonStyled>}
                    <div>
                        <TitleH2 value={organization} customColor={headerTextColor} margin='10px 0px 0px 4px' data-cy='orgName'/>
                        <TitleH5 value={breadcrumb} margin='0 4px' customColor={headerTextColor} fontWeight={300} />
                    </div>
                </FlexContainer>
                {logo && <Logo src={'data:image/jpeg;base64,' + logo }/>}
                <FlexContainer>
                    <User onClick={this.toggleLogout} data-cy='userName'>
                        <TitleH4 id='userHeader' customColor={headerTextColor} padding='8px 18px' value={name} cursor='pointer' />
                    </User>
                    <AvatarContainer onClick={this.toggleLogout}>
                        <AvatarImg id='userImg' alt="avatar">{initials}</AvatarImg>
                    </AvatarContainer>
                </FlexContainer>
                {logoutMenu && <ClickAwayListener onClickAway={this.handleClickAway}>
                    <TopMenuOptions
                        pwdChange={this.pwdChange}
                        user={{ name, organization }}
                        onLanguageChange={this.onLanguageChange}
                        language={language}
                        signOut={this.signOut}
                        AD={AD}
                    />
                </ClickAwayListener>}
            </TopBarContainer>)
    }

    signOut = () => {
        HeliocorStorage.clear()
        this.props.clearStore()
        this.props.history.push(this.props.AD ? '/login-mcb' : '/')
    }

    toggleBar = () => {
        const sideBar = !this.props.sidebar
        this.props.setSideBar(sideBar)
    }

    toggleLogout = () => {
        this.setState({ logoutMenu: !this.state.logoutMenu })
    }
}

TopBar.propTypes = {
    clearStore: PropTypes.func.isRequired
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopBar))))