import React from 'react'
import ContentLoader from 'react-content-loader'

const ImageLoader = () => (
    <ContentLoader width={512} height={364}>
        <rect x="30" y="30" rx="3" ry="3" width="100" height="30" />
        <rect x="30" y="85" rx="3" ry="3" width="400" height="300" />
    </ContentLoader>
)

export default ImageLoader