export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_MCB = 'REQUEST_LOGIN_MCB';
export const FAILED_REQUEST_TOKEN = 'FAILED_REQUEST_TOKEN';
export const SUCCEED_REQUEST_TOKEN = 'SUCCEED_REQUEST_TOKEN';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const REQUEST_PWD_CHANGE = 'REQUEST_PWD_CHANGE';
export const SUCCEED_PWD_CHANGE = 'SUCCEED_PWD_CHANGE';
export const FAILED_PWD_CHANGE = 'FAILED_PWD_CHANGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SUBMENU = 'SET_SUBMENU';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_RIGHT_BAR = 'SET_RIGHT_BAR';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const GET_USER_ROLES = 'GET_USER_ROLES'
export const SET_GENERAL_STATUS = 'SET_GENERAL_STATUS'
export const SET_BREADCRUMB = 'SET_BREADCRUMB'
export const CLEAR_BREADCRUMB = 'CLEAR_BREADCRUMB'

export const REQUEST_TEAM_IDS = 'REQUEST_TEAM_IDS'
export const SET_TEAM_IDS = 'SET_TEAM_IDS'
