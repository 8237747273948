import { Tooltip } from '@material-ui/core'
import React from 'react'
import { InfoStyled, RequiredStyled, WaitStyled, YesStyled, 
    NoStyled, WarningStyled, ErrorStyled } from './ToolTip.styles'

const ToolTip = ({ description, type='info', size, cursor, margin }) => (
    <Tooltip
        fill={toolTipDetail.colors[type]} 
        size={size} 
        title={description || toolTipDetail.message[type]}
        cursor={cursor}
        margin={margin}
        >
        {toolTipDetail.icon[type]}
    </Tooltip>
)

const toolTipDetail = {
    colors: {
        info: 'grey300',
        infoWarning: 'mustard100',
        required: 'red100',
        invalid: 'mustard100',
        approved: 'green100',
        warning: 'mustard100',
        error: 'red100',
        denied: 'red100',
        wait: 'grey300',
        cancelled: 'black100'
    },
    icon: {
        info: <InfoStyled/>,
        infoWarning: <InfoStyled/>,
        required: <RequiredStyled/>,
        invalid: <RequiredStyled/>,
        approved: <YesStyled />,
        warning: <WarningStyled />,
        error: <ErrorStyled />,
        denied: <NoStyled />,
        cancelled: <NoStyled />,
        wait: <WaitStyled />
    },
    message: {
        required: 'Required Field',
        invalid: 'Invalid Field Value'
    }
}

export default ToolTip