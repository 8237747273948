import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Button, FlexContainer}                               from 'heliocor-ui';
import { useTranslation }                                    from 'react-i18next';
import CollapsableBarSection                                 from '../CollapsableBarSection';
import { InputLabel }                                        from '@material-ui/core';
import { useSelector}                                        from 'react-redux';
import contextTypes                                          from '../../dokstor/store/contextTypes.enum';


const DocComments = ({comments = '', saveComment}) => {

    const { t } = useTranslation()
    const [comment, setComment] = useState('')
    const [saveButton, showSaveButton] = useState(false)

    const apiStatus = useSelector(store => store.common.apiStatus)

    useEffect(() => {
        setComment(comments)
    }, [])

    const firstRender = useRef(true);

    useLayoutEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
          }
        showSaveButton(comment !== comments)
    })

    return (
        <CollapsableBarSection title={t("comment")}>
        <FlexContainer column margin='0 0 5px 0' padding="0px 20px">
            <React.Fragment>
                <InputLabel>{t("comment")}</InputLabel>
                <textarea 
                    style={{boxSizing: 'border-box', fontSize: '16px'}} 
                    placeholder={t("commentMessage")}
                    value={comment} 
                    rows={3} 
                    onChange={e => setComment(e.target.value)}
                />
            </React.Fragment>
            
            <FlexContainer justifyContent='flex-end' height='50px'>
                <Button 
                    type="success" 
                    value={t("button.save")} 
                    disabled={apiStatus[contextTypes.SAVING_COMMENT] || !saveButton} 
                    onClick={() => saveComment(comment)}
                    isLoading={apiStatus[contextTypes.SAVING_COMMENT]}
                />
            </FlexContainer>
        </FlexContainer>
        </CollapsableBarSection>
    )
}

export default DocComments;