const contextTypes = {

    //LOADERS
    LOGIN: 'LOGIN',
    RECHECK: 'RECHECK',
    PHASE_CHANGE: 'PHASE_CHANGE',
    RECHECK_WEBSEARCH: 'RECHECK_WEBSEARCH',
    LOADING_PERSONS: 'LOADING_PERSONS',
    LOADING_COMPANIES: 'LOADING_COMPANIES',
    LOADING_SCREENING: 'LOADING_SCREENING',
    LOADING_RISK_ASSESMENT: 'LOADING_RISK_ASSESMENT',
    SETTINGS_DELETING_FIELDS: 'SETTINGS_DELETING_FIELDS',
    ADD_EDIT_USER: 'ADD_EDIT_USER',
    LOADING_HIT: 'LOADING_HIT',
    ADD_ADDITIONAL_RISK: 'ADD_ADDITIONAL_RISK',
    DELETE_ADDITIONAL_RISKS: 'DELETE_ADDITIONAL_RISKS',
    SAVING_ENTITY: 'SAVING_ENTITY',
    DELETING_ENTITY: 'DELETING_ENTITY',
    SCALE_RISK: 'SCALE_RISK',
    SAVING_COMMENT: 'SAVING_COMMENT',
    SAVING_KYC_CONFIG: 'SAVING_KYC_CONFIG',
    SAVING_DOCS_CATEGORY_CONFIG: 'SAVING_DOCS_CATEGORY_CONFIG',
    SAVING_DASHBOARD_CONFIG: 'SAVING_DASHBOARD_CONFIG',
    DELETING_CATEGORY: 'DELETING_CATEGORY',
    SAVING_CATEGORY: 'SAVING_CATEGORY',
    SAVING_FIELD: 'SAVING_FIELD',
    UPDATING_FIELD: 'UPDATING_FIELD',
    DOKSTOR_SETTINGS: 'DOKSTOR_SETTINGS',
    DOKSTOR_WATCHLIST: 'DOKSTOR_WATCHLIST',
    LOADING_RISKS: 'LOADING_RISKS',
    UPDATING_STATUS: 'UPDATING_STATUS',
    SAVING_DOC_TYPE: 'SAVING_DOC_TYPE',
    SIGNATURE_DOCUMENTS: 'SIGNATURE_DOCUMENTS',

    //BARS
    CLOSING_FIELD_BAR: 'CLOSING_FIELD_BAR',
    CLOSING_CATEGORY_BAR: 'CLOSING_CATEGORY_BAR',
    CLOSING_KYC_TYPE_BAR: 'CLOSING_KYC_TYPE_BAR',
    CLOSING_RISK_BAR: 'CLOSING_RISK_BAR',
    SAVING_KYC_TYPE: 'SAVING_KYC_TYPE',
    SAVING_RISK: 'SAVING_RISK',
    CLOSING_DOC_TYPE_BAR: 'CLOSING_DOC_TYPE_BAR',

    //ROBOLITICS
    UPDATE_ALERTS: 'UPDATE_ALERTS'
}

export default contextTypes