import React, { Component } from 'react'
import logo from "../assets/logo-robolitics.png"
import Background from "../assets/bg_home.jpg"
import SecurityDialog from "../apps/common/SecurityDialog"

class E403Layout extends Component {

    render() {
        const { reason } = this.props

        return (
            <div>
                <div style={styles.container}>
                    <img src={logo} alt={'Robolitics logo'} />
                    <h3 className="home-heading" style={styles.header}>Welcome to Robolitics, please select an app from the side menu to start</h3>
                </div>
                <SecurityDialog reason={reason} />
            </div>
        )
    }
}
export default E403Layout

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#555',
        flexDirection: 'column',
        margin: "0 auto",
        width: "100%",
        background: `url(${Background}) `,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    header: {
        color: 'white',
        marginTop: 30,
        width: "70%",
        textAlign: "center"
    },

    logo: {
        width: "100%",
        height: "auto",
        margin: "0 auto"
    }
}