import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 20px;
`

const Line = styled.div`
  width: 20px;
  height: 2px;
  background-color: ${props => props.color};
  display: block;
  margin: 3px auto;
  transition: all 0.3s ease-in-out;
  transform: ${props => {
        if (props.opened) {
            switch (props.index) {
                case 0:
                    return 'translateY(5px) rotate(45deg)';
                case 2:
                    return 'translateY(-5px) rotate(-45deg)';
                case 1:
                default:
                    return 'none';
            }
        }
        else {
            return 'none';
        }
    }};
  opacity: ${props => {
        if (props.opened) {
            return props.index === 1 ? 0 : 1
        }
        else {
            return 1
        }
    }};
`

const Hamburger = ({ opened, color }) => {
    return (
        <Container>
            {[0, 1, 2].map(i => (<Line key={i} index={i} opened={opened} color={color} />))}
        </Container>)
}

export default Hamburger;