import styled from "styled-components"
import { CardContainer } from "heliocor-ui"

const CardDashboard = styled(CardContainer)`
    margin-right: 10px;
    min-width: 450px;
    width: 100%;
    margin: 0 0 16px 0;
    height: ${props => props.height};
    @media (min-width: 700px) {
            flex: 1 2 47%;
        }
    @media (min-width: 1200px) {
            flex: 1 0 47%;
        }
    @media (min-width: 1855px){
            flex: 1 1 23%;
        }
`

export { CardDashboard } 