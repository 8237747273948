import React from 'react';
import styled from 'styled-components'
import {colors} from "../../config/colors"
import { Line } from 'rc-progress';
import { TitleH5, TitleH4 } from 'heliocor-ui'

const DataBar = ({data=0,title,percent=0,postfix=''}) => (
    <Box>
        <Title value={title} />
        <Data value={(Number.isInteger(data) ? data : 0) + postfix} />
            <PB>
                <Line
                    percent={percent}
                    strokeWidth={2}
                    trailWidth={2}
                    strokeColor={colors.main}
                    strokeLinecap='square'
                />
            </PB>
    </Box>
)

const Box = styled.div`
    display:flex;
    flex:0.25;
    padding: 6px 0;
    margin: 16px;
    align-items:center;
`

const Data = styled(TitleH4)`
    font-size:13px;
    margin:0;
    font-weight:400;
    width:80px;
`

const Title = styled(TitleH5)`
    font-weight:100;
    font-size:14px;
    margin:0;
    flex:0.5;
`

const PB = styled.div`
    flex:0.5;
`

export default DataBar
