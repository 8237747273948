import { t } from 'i18next'

const getCurrentEntity = store => store.dokstor.currentEntity
const getCurrentEntityId = store => store.dokstor.currentEntity.identifier
const getCurrentEntityStructure = store => store.dokstor.currentEntityStructure
const getCurrentEntityHits = store => store.dokstor.currentEntityHits

const getCurrentCP = store => store.dokstor.currentCP
const getCurrentCPId = store => store.dokstor.currentCP.identifier

const getEntityData = store => {
    const entity = store.dokstor.currentEntity
    const entityid = entity.identifier
    const cp = store.dokstor.currentCP
    const cpid = cp.identifier

    const isCompany = cpid ? cp.isCompany : entity.isCompany

    return { entityid, cpid, isCompany }
}

const listConfigs = (store, listId) => store.common.lists[listId]
const getSettings = store => store.dokstor.settings
const getKYCconfig = store => store.dokstor.kycConfig
const getFieldsSettings = store => store.dokstor.fieldsSettings
const getTranslationFn = () => t
const getIsAdmin = store => store.auth.permissions?.AUTH.includes('ADMIN')
const getCurrentField = store => store.dokstor.currentField

export {
    getCurrentEntity,
    getCurrentEntityId,
    getCurrentEntityStructure,
    getCurrentEntityHits,
    getCurrentField,
    getCurrentCP,
    getCurrentCPId,
    getEntityData,
    listConfigs,
    getSettings,
    getKYCconfig,
    getFieldsSettings,
    getTranslationFn,
    getIsAdmin
}