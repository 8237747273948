import styled from 'styled-components'
import Done from '@material-ui/icons/Done';
import ArrowBack from '@material-ui/icons/ArrowBack';

const List = styled.div`
    padding: 8px 0;
    margin: 0;
    list-style: none;
    border-bottom: ${props => `1px solid ${props.theme.colors.greyTopMenuClear}`};
`

const ListedItem = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    color: ${props => `1px solid ${props.theme.colors.greyTint}`};
`

const DoneStyled = styled(Done)`
    && { font-size: 14px; }
`

const ArrowBackStyled = styled(ArrowBack)`
    && { font-size: 14px; }
`

const GetBackLine = styled(ListedItem)`
    color: ${props => `1px solid ${props.theme.colors.greyTint}`};
    border-bottom: ${props => `1px solid ${props.theme.colors.greyTopMenuClear}`};
    padding-bottom: 14px;
`

export { List, ListedItem, DoneStyled, ArrowBackStyled, GetBackLine }