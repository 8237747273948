import styled from 'styled-components'
import { device } from 'heliocor-ui'

/**
 * @props display: flex | flex: 1 | padding: 30px | flex-direction: column
 */
const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 0;
    flex-direction: column;
    @media ${device.midSize}{
        padding-left: 30px;
    }
`

export default ContentContainer