export const getEntityPath = (isCompany, entityid, cpid) => {
    if (cpid) {
        if (isCompany) {
            return `dokstor-bo-api/api/companies/${entityid}/connected-companies/${cpid}`
        } else {
            return `dokstor-bo-api/api/companies/${entityid}/connected-persons/${cpid}`
        }
    } else {
        if (isCompany) {
            return `dokstor-bo-api/api/company/${entityid}`
        } else {
            return `dokstor-bo-api/api/person/${entityid}`
        }
    }
}

export const getDocumentsPath = (isCompany, entityid, cpid) => {
    const base =  getEntityPath(isCompany, entityid, cpid)
    return cpid
        ? base + '/documents'
        : base + '/document'
}