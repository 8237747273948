import React from 'react'
import { alertDetails } from './alertDetails'
import { IconContainer, ContainerTI, ContainerText, ContainerClose, IconClose, AlertDiv } from './Alert.styles'
import { TitleH3, Paragraph } from 'heliocor-ui'

const selectType = type => {
    switch(type){
        case type:
        return alertDetails[type]
        default:
        return alertDetails[type]
    }
}

const Alert = ({type}) => {

    const details = selectType(type)

    return <AlertDiv backgroundColor={details.colorBG}>
            <IconContainer backgroundColor={details.colorBG} color={details.colorIcon} icon={details.icon} />
            <ContainerTI>
                <ContainerText>
                    <TitleH3 value={details.title} color='greyTint' />
                    <Paragraph color='greyTint' value={details.description} margin='0 0 0 30px' />
                </ContainerText>
                <ContainerClose>
                    <IconClose />
                </ContainerClose>
            </ContainerTI>
        </AlertDiv>
}


export default Alert
