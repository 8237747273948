import Moment from 'moment'
import { db_thunk } from '../../api/db'
import { formInputTypes, VALIDATIONS } from 'heliocor-ui'

const {
    CREDIT_CARD_IS_VALID,
    IBAN_IS_VALID,
    BIC_IS_VALID,
    SORT_CODE_IS_VALID,
    EMAIL,
    URL,
    PHONE
} = VALIDATIONS


/**
 * @return {string}
 */
export const JSDateToAPIDate = (date) => {
    const ISODate = Moment(date).format()
    return ISODate.substring(0, ISODate.length - 6)
}

//FILTERCHIPS
/**
 * @return {string}
 */
export const getTablePeriod = (date) => {
    return date ? date.substr(5, 10) + ' ' + date.substr(21, 10) : ''
}

//RADARS
/**
 * @return {string}
 */
export const getTime = (date) => date
    ? date.substr(11, 5)
    : ''

/**
 * @return {string}
 */
export const getFullTableDate = (date) => date
    ? date.substr(8, 2) + '/' + date.substr(5, 2) + '/' + date.substr(0, 4) + ' ' + date.substr(11, 5)
    : ''

/**
 * @return {string}
 */
export function getDMhmDate(date) {
    return date.substr(5, 5) + ' ' + date.substr(11, 5)
}

/**
 * @return {string}
 */
export function capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.substr(1)
}

/**
 * @return {string}
 */
export function capitalize(str) {
    if (!str) return ''

    str = str.split(" ")

    for (let i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1)
    }

    return str.join(" ")
}

/**
* @return {boolean}
*/
export async function checkEmailExists(email) {
    const res = await db_thunk('get', 'dokstor-bo-api/api/person/email/check?email=' + email)
        .catch()

    return !!res?.exists
}

/**
* @return {boolean}
*/
export async function checkCompanyExists(registrationnumber, country) {
    const res = await db_thunk('get', `dokstor-bo-api/api/company/registration-number/check?registration-number=${registrationnumber}&country=${country}`)
        .catch()

    return !!res?.exists
}

/**
* @return companies
*/
export async function creditSafeGetCompanies({ name, country, region, registrationNumber }) {
    const response = await db_thunk(
        'get',
        `dokstor-bo-api/api/company/search?name=${name}&country=${country}${region ? `&region=${region}` : ''}${registrationNumber ? `&registration-number=${registrationNumber}` : ''}`
    )
        .catch(e => console.error(e))
    return response
}

export async function creditSafeGetCompanyDetail(regNumber, country, region) {
    const response = await db_thunk('get', `dokstor-bo-api/api/company/lookup?id=${encodeURIComponent(regNumber)}&country=${country}${region ? `&region=${region}` : ''}`)
        .catch(e => console.error(e))
    return response
}


/**
* @return {boolean}
*/
export function isEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false
    }
    return true
}

/**
  * Checks if the given value is null or undefined
  * @param val
  * @returns {boolean}
  */
export const isNullOrUndefined = val => val === null || val === undefined

/**
 * Returns true if the given value is an object
 * @param value
 * @returns {*|boolean}
 */
export const isObject = (value) => value && typeof value === 'object' && value.constructor === Object

/**
 * Flatten a given object on one depth level
 * @param obj
 * @returns {{}}
 */
export const flattenObject = (obj = {}) => {
    return Object.keys(obj).reduce((a, b) => {
        if (isObject(obj[b]))
            a = { ...a, ...obj[b] }
        else
            a[b] = obj[b]
        return a
    }, {})
}

/**
 * Filter an object as an array
 * @param object, filterBy
 * @returns {}
 */
export const objectFiltering = (object, filterBy) => {

    const filteredObject = Object.keys(object)
        .filter(key => filterBy.includes(key))
        .reduce((obj, key) => {
            obj[key] = object[key]
            return obj
        }, {})

    return filteredObject
}


/** Returns a function, that, as long as it continues to be invoked, will not
* be triggered. The function will be called after it stops being called for
* N milliseconds. If `immediate` is passed, trigger the function on the
* leading edge, instead of the trailing.
* var myEfficientFn = debounce(function() {All the taxing stuff you do}, 250)
*/
export function debounce(func, wait, immediate) {
    var timeout
    return function () {
        var context = this, args = arguments
        var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

/*
 * Returns the rgb of hex value
 * @param hex
 * @param alpha
 * @returns {*}
 */
export const hexToRgba = (hex, alpha = 1) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ?
        `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
        : null
}

/**
 * Format amount
 * @param value
 * @param fixedDecimals
 * @param formatInteger
 * @param decimalSeparator
 * @returns {string}
 */
export const formatAmount = (value, fixedDecimals, formatInteger, decimalSeparator = '.') => {

    if (value === null || value === undefined) return value

    let result = value % 1 === 0 ? value : value.toFixed(fixedDecimals)
    let parts = result.toString().includes(".") ? result.toString().split('.') : result.toString().split(',')
    let integer = formatInteger ? parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, decimalSeparator === '.' ? ',' : '.') : parts[0]
    let decimals = parts.length > 1 ? parts[1] : null

    return decimals ? [integer, decimals].join(decimalSeparator) : integer
}

export const orderArray = (unSortedArray = [], sortedArray = []) => {
    return unSortedArray.sort((a, b) => {
        const indexA = sortedArray.indexOf(a)
        if (indexA === -1) return 1
        const indexB = sortedArray.indexOf(b)
        if (indexB === -1) return -1
        return indexA - indexB
    })
}


/**
 * Transform input text to API storage key
 * @param input
 * @returns {string}
 */
export const inputToKey = input => {
    return input.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[\W_]+/g, " ").replace(/ /g, "_")
}

/**
 * Remove falsy values (null, undefined, empty string) from Object
 * @param object
 * @returns {string}
 */
export const removeFalsyValuesFromObject = object => {
    let newObj = {}
    Object.keys(object).forEach((prop) => {
        if (object[prop] !== '' && object[prop] !== undefined && object[prop] !== null) {
            newObj[prop] = object[prop]
        }
    })
    return newObj
}

const getTypeFromFormat = (format) => {
    switch (format) {
        case formInputTypes.url:
        case formInputTypes.email:
        case formInputTypes.telephone:
            return 'TEXT'
        case formInputTypes.currency:
            return 'CURRENCY'
        default:
            return format
    }
}
const getValidationsFromFormat = (type) => {
    switch (type) {
        case formInputTypes.creditCard:
            return CREDIT_CARD_IS_VALID

        case formInputTypes.iban:
            return IBAN_IS_VALID

        case formInputTypes.sortCode:
            return SORT_CODE_IS_VALID

        case formInputTypes.bic:
            return BIC_IS_VALID

        case formInputTypes.url:
            return URL

        case formInputTypes.email:
            return EMAIL

        case formInputTypes.telephone:
            return PHONE

        default:
            return ''
    }
}

export const parseField = (field) => {

    const { format, linesNumber, ...rest } = field
    const requiresLinesNumber = linesNumber && rest.valueType === 'TEXT_AREA'

    if (format || requiresLinesNumber) {
        switch (rest.valueType) {
            case "TEXT":
                return format === 'ALPHABETIC' ? { ...rest, valueType: 'TEXT', options: JSON.stringify({ subType: format }) } : rest
            case "TEXT_AREA":
                return { ...rest, valueType: 'TEXT', options: JSON.stringify({ subType: 'MULTILINE', linesNumber }) }
            case "COUNTRY":
                return { ...rest, valueType: format, options: JSON.stringify({ subType: 'COUNTRY' }) }
            case "SELECT":
                return format === "RADIO"
                ? { ...rest, options: JSON.stringify({ subType: format }) }
                : { ...rest, valueType: format }
            case "DATE":
                return { ...rest, options: JSON.stringify({ format }) }
            case "NUMBER":
                return format === "CURRENCY"
                    ? { ...rest, options: JSON.stringify({ currency: true }) }
                    : format === "FORMATTED" ? { ...rest, options: JSON.stringify({ formatted: true }) }
                        : rest
            case "SPECIAL":
                return { ...rest, valueType: getTypeFromFormat(format), options: JSON.stringify({ validations: [getValidationsFromFormat(format)], isSpecial: true }) }
            default:
                return rest
        }
    } else {
        return rest
    }
}

export const desParseField = (field) => {

    const getTextFormat = validation => {
        switch (validation) {
            case "URL_IS_VALID":
                return 'URL'
            case "VALID_EMAIL":
                return 'EMAIL'
            default:
                return validation
        }
    }
        

    if (field.options) {
        switch (field.valueType) {
            case "SELECT":
                const subType = field.options.subType
                return subType && subType !== 'COUNTRY' 
                ? { ...field, format: field.options.subType}
                : { ...field, format: field.valueType, valueType: 'COUNTRY' }
            case "MULTI_SELECT":
                return { ...field, format: field.valueType, valueType: 'COUNTRY' }
            case "DATE":
                return { ...field, format: field.options.format }
            case "NUMBER":
                return field.options.currency
                    ? { ...field, format: 'CURRENCY' }
                    : field.options.formatted ? { ...field, format: 'FORMATTED' }
                        : field
            case "TEXT":
                return field.options.isSpecial
                    ? { ...field, valueType: 'SPECIAL', format: getTextFormat(field.options.validations[0]) }
                    : field.options.subType === 'MULTILINE'
                        ? { ...field, valueType: 'TEXT_AREA', linesNumber: field.options.linesNumber }
                        : { ...field, format: 'ALPHABETIC'}
            case formInputTypes.creditCard:
            case formInputTypes.iban:
            case formInputTypes.sortCode:
            case formInputTypes.bic:
            case formInputTypes.currency:
                return { ...field, valueType: 'SPECIAL', format: field.valueType }
            default:
                return field
        }
    } else {
        if (field.valueType === 'NUMBER') return { ...field, format: 'UNFORMATTED' }
        if (field.valueType === 'SELECT') return { ...field, format: field.valueType }
        if (field.valueType === 'MULTI_SELECT') return { ...field, valueType: "SELECT", format: 'MULTI_SELECT' }
        if (field.valueType === 'RADIO') return { ...field, valueType: "SELECT", format: 'RADIO'}
        if (field.valueType === 'TEXT') return { ...field, format: 'ALL' }
        return field
    }
}

/**
 * Remove duplicate elements from array of objects, based on specific key
 * @param array
 * @param filterKey
 * @returns {array}
 */
export const deleteDuplicatesFromObjectsArray = (array = [], filterKey) => {
    return array.filter((v, i, a) => a.findIndex(t => (t[filterKey] === v[filterKey])) === i)
}

/**
 * Returns true if a number is odd and false if even
 * @param number
 * @return {boolean}
 */
export function isOdd(number) {
    return number % 2 !== 0
}

/**
 * Get days ago from date
 * @param date
 * @returns {number}
 */
export const getDaysAgo = (date) => {
    let inputDate = Moment(date)
    let currentDate = Moment().startOf('day')
    return currentDate.diff(inputDate, 'days')
}

export function camelCaseToCapitalized(text) {
    const result = text.replace(/([A-Z])/g, " $1")
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
}

export function snakeToKebabCase(text) {
    return text.toLowerCase().replaceAll("_", "-")
}

export const getExtensionFromMime = type => {
    switch (type) {
        case 'image/jpg':
            return '.jpg'

        case 'image/jpeg':
            return '.jpeg'

        case 'image/png':
            return '.png'

        case 'application/pdf':
            return '.pdf'

        case 'video/mp4':
            return '.mp4'

        case 'application/x-matroska':
            return '.webm'

        case 'application/vnd.ms-outlook':
            return '.msg'
        
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return '.xlsx'
        
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return '.docx'
        
        case 'text/plain':
            return '.csv'

        default:
            console.error('unknown extension')
            return ''
    }
};
