import React from 'react'
import styled from 'styled-components'
import { Paragraph, FlexContainer } from 'heliocor-ui'

export const MessageDate = ({date})=>{
    return(
        <FlexContainer justifyContent='center' >
            <StyledParagraph>{date}</StyledParagraph>
        </FlexContainer>
    )
}

const StyledParagraph = styled(Paragraph)`
    font-weight: 800;
    font-color: #999999;
    font-size: 13px;
`

