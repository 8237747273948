import React from 'react'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, FlexContainer } from 'heliocor-ui'

const toolbarStyles = theme => ({
    root: {
        padding: 15,
        height: 58,
        minHeight: 58
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            } : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    }
})

const EnhancedTableToolbar = props => {
    const { numSelected, classes, buttonForSelected, title, toolBarWidget } = props
    return (
        (numSelected > 0) &&
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0
                    ?
                    <Typography color="inherit" variant="h4">
                        {numSelected} selected
                        </Typography>
                    :
                    <Typography variant="h6" id="tableTitle">
                        {title}
                    </Typography>
                }
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0
                    ? buttonForSelected instanceof Array
                        ?
                        <FlexContainer>
                            {buttonForSelected.map((btn, index) =>
                                !btn.hide &&
                                <Button
                                    type={btn.type || 'default'}
                                    onClick={() => {
                                        btn.onClick()
                                    }}
                                    value={btn.label}
                                    fixDimension={{ width: btn.width || '140px' }}
                                    margin='0px 5px'
                                    key={index}
                                    disabled={btn.disabled}
                                />
                            )}
                        </FlexContainer>
                        : !buttonForSelected.hide &&
                        <Button
                            data-cy={buttonForSelected?.cy}
                            type={buttonForSelected.type || 'default'}
                            onClick={() => buttonForSelected.onClick()}
                            value={buttonForSelected.label}
                            fixDimension={{ width: buttonForSelected.width || '140px' }}
                            disabled={buttonForSelected.disabled}
                        />
                    :
                    toolBarWidget
                }
            </div>
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

const ToolBar = withStyles(toolbarStyles)(EnhancedTableToolbar)


export default ToolBar
