import {styledTheme} from '../styles/'


const styles = {
    table: {
        minWidth: 700,
    },
    row: {
        backgroundColor: styledTheme.colors.grey500,
    },
    login:{
        container:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height:'100vh',
            backgroundColor:'#efefef'
        },
        loginH1:{
            fontSize:36,
            color:styledTheme.colors.black100,
            fontWeight:400,
            marginTop:40,
            marginBottom:5
        },
        loginH4:{
            fontSize:16,
            color:styledTheme.colors.black100,
            fontWeight:300,
            marginTop:0
        },
        inputLabel:{
            color:styledTheme.colors.black100,
            marginBottom:0,
            fontWeight:300
        },
        support:{
            color:styledTheme.colors.main,
            cursor:'pointer',
            marginTop:10
        }
    },
    select:{
        border:'1px solid #ddd',
        width:'100%',
        paddingLeft:5
    },
    h1Black:{
        color:styledTheme.colors.black100,
        fontSize:24,
        fontWeight:800,
        margin:10,
        textTransform:'capitalize',
        fontFamily:'Lato'
    },
    h2Black:{
        color:styledTheme.colors.black100,
        fontSize:20,
        fontWeight:800
    },
    h3Black:{
        color:styledTheme.colors.black100,
        fontSize:14,
        fontWeight:600
    },
    h4Header:{
        color:'#ffffff',
        margin:5,
        fontSize:12,
        fontWeight:100
    },
    flex05:{
        flex:0.5
    },
    radioWrapper:{
        width:'100%',
        display:'flex',
        //marginBottom:45,
        minHeight:45,
        position: 'relative',
    },
    input:{
        height:30,
        marginBottom:15,
        border:'1px solid #ddd',
        width:250,
        paddingLeft:10
    },
    button:{
        color:'#ffffff',
        marginTop:10,
        lineHeight:'32px',
        marginRight:15
    },
    smBtn:{
        color:'#ffffff',
        height:28,
        width:60
    },
    smBtnLabel:{
        fontSize:12,
        top:-4
    },
    scrollableContainer:{
        overflowY:'auto',
        height:'100%'
    },
    barHeader:{
        maxHeight:60,
        borderBottom:'1px solid #ddd',
        padding:'10px 20px'
    },
    barBody:{
        padding:20,
        display:'flex',
        flex:1,
        flexDirection:'column'
    },
    checkBoxLabel:{
        fontSize:14,
        color:'#666',
        marginBottom:5,
        fontFamily:'Lato',
        fontWeight:600
    },
    checkBoxIcon:{
        fill:'#666'
    },
    superIndex:{
        fontSize: 10,
        position: 'relative',
        bottom: 6,
        color:styledTheme.colors.main
    },
    inputErrorText:{
        marginBottom:10,
        marginTop:0,
        opacity:1,
        color:styledTheme.colors.red100,
        fontSize:12
    },
    inputWarningText:{
        marginBottom:10,
        marginTop:0,
        opacity:1,
        color:styledTheme.colors.mustard100,
        fontSize:12
    },
    inputErrorHidden:{
        margin:0,
        height:0
    },
    content:{
        horizontalFlex:{
            display:'flex',
            flexDirection:'row',
            flex:1
        },
        halfHorizontalFlex:{
            display:'flex',
            flexDirection:'row',
            flex:0.5,
        },
        verticalFlex:{
            display:'flex',
            flexDirection:'column',
            flex:1
        },
        halfVerticalFlex:{
            display:'flex',
            flexDirection:'column',
            flex:0.5,
            paddingRight:20
        },
        spaceBetween:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            flex:1
        },
        cont50spaceRight:{
            flex:0.5,
            marginRight:25
        },
        cont50:{
            flex:0.5,
        },
        cont100:{
            flex:1,
        },
        card:{
            boxShadow:'0px 2px 8px 1px rgba(0,0,0,0.16)',
            display:'flex',
            flexDirection:'row',
            marginBottom:25,
            backgroundColor:'#fff'
        },
        verticalCard:{
            boxShadow:'0px 2px 8px 1px rgba(0,0,0,0.16)',
            display:'flex',
            flexDirection:'column',
            marginBottom:25,
        },
        padding15:{
            padding:15
        },
        height120:{
            flex:1,
            height:120,
        },
        fullHeight:{
            flex:1,
            height:265,
        },
        doubleHeight:{
            flex:1,
            height:420,
        },
        workflowHeight:{
            flex:1,
            height:130,
        },
        cardLink:{
            color:styledTheme.colors.main,
            textTransform:'uppercase',
            fontSize:14,
            fontWeight:700,
            margin:0
        },
        cardBlueLink:{
            color:styledTheme.colors.blue100,
            textTransform:'uppercase',
            fontSize:14,
            fontWeight:700,
            margin:0
        },
        cardOrangeTitle:{
            color:styledTheme.colors.main,
            textTransform:'capitalize',
            fontSize:18,
            fontWeight:400,
            margin:0,
            fontFamily:'Lato'
        },
        cardWhiteTitle:{
            color:'#ffffff',
            textTransform:'capitalize',
            fontSize:18,
            fontWeight:400,
            margin:0
        },
        cardBlackTitle:{
            color:styledTheme.colors.black100,
            textTransform:'capitalize',
            fontSize:18,
            fontWeight:400,
            margin:0,
            fontFamily:'Lato'
        },
        cardWhiteSubTitle:{
            color:'#ffffff',
            fontSize:13,
            fontWeight:400,
            marginTop:5,
            marginBottom:10
        },
        cardSubTitle:{
            color:'#333',
            fontSize:13,
            fontWeight:400,
            marginTop:5,
            marginBottom:10,
        },
        cardSubTitleNoMargin:{
            color:'#333',
            fontSize:13,
            fontWeight:400,
            marginTop:2,
            marginBottom:0
        },
        actionTitle:{
            textTransform:'uppercase',
            fontWeight:600,
            fontSize:16,
            marginLeft:10,
            position:'relative',
            clear:'both'
        },
        inboxPreSubTitle:{
            color:styledTheme.colors.main,
            fontSize:12,
            fontWeight:800,
            marginTop:0,
            marginRight:5
        },
        cardIcon:{
            height:48,
            width:42,
            marginTop:-3
        },
        cardLinkBox:{
            borderTop:'1px solid #eee',
            padding:'10px 15px',
            cursor:'pointer',
            position:'relative',
            bottom:0
        },
        arrowRight:{
            fontSize:38

        },
        orangeTop:{
            backgroundColor:styledTheme.colors.main,
            padding:15,
            height:90,
            boxSizing:'content-box'
        },
        horizontalOrangeTop:{
            backgroundColor:styledTheme.colors.main,
            marginBottom:0,
            padding:15,
            flex:0.3,
            height:90,
            boxSizing:'content-box'
        },
        blueTop:{
            backgroundColor:styledTheme.colors.blue100,
            padding:15,
            height:90,
            boxSizing:'content-box'
        },
        globalMid:{
            height:74,
            padding:15,
            boxSizing:'content-box'
        },
        globalDoubleMid:{
            height:100,
            padding:15,
            boxSizing:'content-box'
        },
        horizontalGlobalMid:{
            height:106,
            flex:0.7,
            padding:15
        },
        globalHr:{
            margin: '0px 0 0',
            borderColor:'#ddd',
            borderTop:'none',
            borderLeft:'none'
        },
        statusNumber:{
            fontSize:24,
            color:styledTheme.colors.grey500,
            fontWeight:700
        },
        statusTotalText:{
            fontSize:13,
            fontWeight:700,
            marginTop:5,
            marginBottom:10
        },
        avatar:{
            height:25,
            width:25,
            borderRadius:13,
            margin:'15px 15px 15px 0'
        },

        //--WORKSPACE--//
        workspaceCol1:{
            display:'flex',
            flexDirection:'column',
            flex:0.45,
            marginRight:30
        },
        workspaceCol2:{
            flex:0.35,
            marginRight:30
        },
        workspaceCol3:{
            flex:0.2
        },

        //--EMPLOYEE--//
        employeesCol1:{
            flex:0.22,
            marginRight:30
        },
        employeesCol2:{
            flex:0.22,
            marginRight:30
        },
        employeesCol3:{
            flex:0.56
        },

        //--ADMIN--//
        adminCol1:{
            flex:0.20,
            marginRight:30
        },
        adminCol2:{
            flex:0.45,
            marginRight:30
        },
        adminCol3:{
            flex:0.35
        },

        //--CHAT--//
        alertsBoxText:{
            padding:0,
            margin:0,
            fontSize:10,
            color:'#888',
            fontWeight:300
        },
        alertsBoxTime:{
            flex:0.1,
            padding:0,
            margin:0,
            fontSize:10,
            alignSelf:'flex-end',
            color:styledTheme.colors.grey500,
            fontWeight:300
        },
        userAlertBox:{
            alignSelf:'flex-start',
            alignItems: 'center',
            backgroundColor:'#ffffff',
            flexDirection:'row',
            display:'flex',
            justifyContent:'space-between',
            width:'75%',
            margin:15,
            marginTop:5,
            marginBottom:5,
            paddingTop:7,
            paddingBottom:7,
            padding:10,
            borderRadius:5
        },
        appAlertBox:{
            alignSelf:'flex-end',
            alignItems: 'center',
            backgroundColor:styledTheme.colors.main400,
            flexDirection:'row',
            display:'flex',
            justifyContent:'space-between',
            width:'75%',
            margin:15,
            marginTop:5,
            marginBottom:5,
            paddingTop:7,
            paddingBottom:7,
            padding:10,
            borderRadius:5
        },
        chatDate:{
            fontSize:12,
            color:'#888',
            margin:8,
            marginLeft:20,
            fontWeight:'bold',
            textAlign:'center'
        }
    },
    spacers:{
        v15:{
            height:15,
            flex:1,
            clear:'both'
        },
        v40:{
            height:40,
            flex:1,
            clear:'both'
        }
    },
    buttons:{
        success:{backgroundColor:styledTheme.colors.green100,color:'#fff'},
        primary:{backgroundColor:styledTheme.colors.main,color:'#fff'},
    },
    AMLiconStyle:{
        backgroundColor:styledTheme.colors.green200,
        height:20,
        width:20,
        borderRadius:10,
        fontWeight:800,
        fontSize:14,
        marginLeft:2,
        color:'#fff',
        fontFamily:'Lato'
    },
    }

/* Styles to inject on react-select v2*/


const selectCustomStylesV2 = {
    control: (base) => ({
        ...base,
        boxShadow: "none",
        borderRadius: 0,
        minHeight: '32px',
        border: `1px solid ${styledTheme.colors.grey500}`,
        fontSize: 14,
        '&:hover': { borderColor: styledTheme.colors.main400 },
        backgroundColor: 'white',
        cursor: 'pointer'
    }),
    placeholder: base => ({...base, color: styledTheme.colors.grey500}),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    clearIndicator: base => ({
        ...base,
        padding: 4
    }),
    multiValue: (base, state) => ({
        ...base,
        backgroundColor: state.isDisabled ? styledTheme.colors.grey500 : styledTheme.colors.main400,
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        color: state.isDisabled ? styledTheme.colors.grey100 : styledTheme.colors.black100,
    }),
    multiValueRemove: (base, state) => ({
        ...base,
        color: state.isDisabled ? styledTheme.colors.grey100 : styledTheme.colors.black100,
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    }),
    option: (base, state) => ({
        ...base,
        boxShadow: "none",
        paddingLeft: 6,
        borderRadius: 0,
        fontSize: 14,
        backgroundColor: 
        state.isSelected
            ? styledTheme.colors.main
            : state.isFocused
                ? styledTheme.colors.main400
                : null,
        '&:hover': { backgroundColor: styledTheme.colors.main400 },
        cursor: 'pointer'
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        marginBottom: 0,
        maxHeight: 170,
        minWidth: 150,
        overflowY: 'auto',
        position: 'absolute',
        zIndex: 9999,
    }),
    menuList: base => ({
        ...base,
        maxHeight: 170,
        paddingBottom: 0,
        paddingTop: 0,
    }),
    color: base => ({
        ...base,
        text: 'blue'
    })
  }
  

  export {
    selectCustomStylesV2,
    styles,
  }
