import React                                                           from 'react';
import { withTranslation }                                             from 'react-i18next';
import {ErrorParagraph, Paragraph, TitleH1}                            from 'heliocor-ui';
import {ButtonStyled, InputStyled, PasswordTextFieldStyled, SpacerV40} from '../Login.styles';

const Login = ({loginError, handleInputChange, onLogin, username, t}) => (
    <React.Fragment>
        <TitleH1 value={t('login.welcome')} margin='40px 0 4px 0' />
        <Paragraph value={t('login.form')} margin='0' />
        <SpacerV40/>
        <Paragraph value={t('username')} margin='16px 0 0 0' />
        <InputStyled
            name="username"
            onChange={handleInputChange('username')}
            value={username}
        />
        <Paragraph value={t('password')} margin='16px 0 0 0' />
        <PasswordTextFieldStyled
            name="password"
            onChange={handleInputChange('password')}
            type="password"
        />
        <ErrorParagraph error={loginError} value={loginError}/>
        <ButtonStyled
            color='primary'
            onClick={onLogin}
            variant='contained'
            value={t("button.login")}
        />
    </React.Fragment>
);

export default withTranslation()(Login);