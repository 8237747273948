export default class HeliocorStorage {
    static setItem = (key, value) => {
        try{
            localStorage.setItem(key, JSON.stringify(value));
            return true
        }catch{
            return false
        }
    }

    static getItem = (key) => {
        try{
            return JSON.parse(localStorage.getItem(key));
        }catch{
            return false
        }
    }

    static clear = () => {
        try{
            localStorage.clear()
            return true
        }catch{
            return false
        }
    }
}