import {call, put, takeLatest} from 'redux-saga/effects';
import {OPEN_NOTIFICATION}     from '../../notifications/actionTypes';

const safe = (saga, ...args) => function* (action) {
    try {
        yield call(saga, ...args, action)
    } catch (err) {
        console.error(err)
        yield put({type: OPEN_NOTIFICATION, data: { message: 'something went wrong', type: 'error'}})
    }
}

export const takeLatestSafe = (pattern, saga, ...args) => takeLatest(pattern, safe(saga, ...args))