import React from 'react'
import { Dots } from "react-activity"
import styles from './styles'
import { colors } from "../../../config/colors"

export default function LazyLoadingIndicator() {
    return (
        <div style={styles.root}>
            <div style={styles.container}>
                <Dots
                    size={15}
                    color={colors.main}
                />
            </div>
        </div>
    )
}
