import { InputLabel } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Input from '@material-ui/core/Input/Input'
import Assignment from '@material-ui/icons/Assignment'
import Id from '@material-ui/icons/AssignmentInd'
import CompanyIcon from '@material-ui/icons/Business'
import Card from '@material-ui/icons/CreditCard'
import DateIcon from '@material-ui/icons/DateRange'
import File from '@material-ui/icons/Description'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import Euro from '@material-ui/icons/EuroSymbol'
import Puzzle from '@material-ui/icons/Extension'
import Fingerprint from '@material-ui/icons/Fingerprint'
import Category from '@material-ui/icons/Folder'
import Info from '@material-ui/icons/Info'
import Label from '@material-ui/icons/LocalOffer'
import MailIcon from '@material-ui/icons/Mail'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Phone'
import World from '@material-ui/icons/Public'
import Channel from '@material-ui/icons/KeyboardTab'
import Status from '@material-ui/icons/Traffic'
import Tree from '@material-ui/icons/AccountTree'
import Alert from '@material-ui/icons/Notifications'
import Robot from '@material-ui/icons/Android'
import Build from '@material-ui/icons/Build'
import Transaction from '@material-ui/icons/SwapHoriz'
import Group from '@material-ui/icons/Group'
import Filter from '@material-ui/icons/FilterList'

import isEmpty from 'lodash/isEmpty'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Select from 'react-select'
import { SliderInput, FlexContainer, useDebounce } from 'heliocor-ui'

import { JSDateToAPIDate } from '../../../../config/helpers'
import { selectCustomStylesV2 } from '../../../../config/styles'
import { styledTheme } from '../../../../styles/index'
import {
    ContentAddStyled,
    DivLabel,
    DivStyled,
    GridContentAdd,
    GridInputsContainer,
    GridInputType,
    GridSelect,
    SliderWrapper
} from './AdvancedSearch.styles'
import { FilterType } from './constants/filterType.enum'
import { IconTypes } from './constants/icons.enum'
import FilterChips from './FilterChips'
import OwnerSelector from '../../OwnerSelector'


function AdvancedSearch(props) {

    const { onNewFilterRequest, availableFilters, onFiltersChange, filtersConfig, t } = props

    /**
     * Filter type selection
     */
    const [selected, setSelected] = useState(null)
    /**
     * Filter values
     */
    const [filterValue, setFilterValue] = useState(null)
    /**
     * Current filters applied chips
     */
    const [chips, setChips] = useState([])
    /**
     * Button disable/enable management
     */
    const [disabledButton, setDisabledButton] = useState(true)
    /**
     * Date range
     */
    const [dateRange, setDateRange] = useState(null)

    /**
     * DateRange open status
     */
    const [openDateRange, setOpenDateRange] = useState(false)

    const debouncedValue = useDebounce(filterValue)

    useEffect(() => {
        setDisabledButton(isEmpty(filterValue))
        if (selected) onNewFilterRequest(newChipObjectParse())

    }, [debouncedValue])

    useEffect(() => {
        if (filtersConfig) {
            setChips(filtersConfig.currentFilters)
            cleanInputs()
        }
    }, [filtersConfig])

    useEffect(() => {
        setDateRange(null)
        setFilterValue('')
        setOpenDateRange(false)
    }, [selected])

    /**
     * Clears the filter inputs
     */
    const cleanInputs = () => {
        setSelected('')
        setDateRange(null)
    }

    /**
     * Currently supported icons
     * @type {{}}
     */
    const icons = {
        [IconTypes.PERSON]: <PersonIcon fontSize='small' />,
        [IconTypes.ENVELOPE]: <MailIcon fontSize='small' />,
        [IconTypes.STATS]: <EqualizerIcon fontSize='small' />,
        [IconTypes.CALENDAR]: <DateIcon fontSize='small' />,
        [IconTypes.COMPANY]: <CompanyIcon fontSize='small' />,
        [IconTypes.PHONE]: <PhoneIcon fontSize='small' />,
        [IconTypes.WORLD]: <World fontSize='small' />,
        [IconTypes.EURO]: <Euro fontSize='small' />,
        [IconTypes.CATEGORY]: <Category fontSize='small' />,
        [IconTypes.CARD]: <Card fontSize='small' />,
        [IconTypes.FINGERPRINT]: <Fingerprint fontSize='small' />,
        [IconTypes.GROUP]: <Group fontSize='small' />,
        [IconTypes.INFO]: <Info fontSize='small' />,
        [IconTypes.FILE]: <File fontSize='small' />,
        [IconTypes.PUZZLE]: <Puzzle fontSize='small' />,
        [IconTypes.LABEL]: <Label fontSize='small' />,
        [IconTypes.ASSIGNMENT]: <Assignment fontSize='small' />,
        [IconTypes.CHANNEL]: <Channel fontSize='small' />,
        [IconTypes.STATUS]: <Status fontSize='small' />,
        [IconTypes.TREE]: <Tree fontSize='small' />,
        [IconTypes.ID]: <Id fontSize='small' />,
        [IconTypes.BUILD]: <Build fontSize='small' />,
        [IconTypes.ROBOT]: <Robot fontSize='small' />,
        [IconTypes.TRANSACTION]: <Transaction fontSize='small' />,
        [IconTypes.ALERT]: <Alert fontSize='small' />,
        [IconTypes.FILTER]: <Filter fontSize='small' />
    }

    const handleClickAway = () => {
        if (openDateRange === true) {
            setOpenDateRange(false)
        }
    }

    /**
     * Key down Handler. If the pressed key is enter, the filter is added
     * @param key
     */
    const handleOnKeyUp = key => {
        if (key.key === 'Enter') onAddFilter()
    }

    /**
     * Filter selector changed
     * @param value
     */
    const handleSelectorChange = (value, action) => {
        setFilterValue('')
        setSelected(value)
        if(action === 'clear' && !isEmpty(filterValue))  onNewFilterRequest({})
    }

    /**
     * List of available filters. It takes the given configured options that are not selected on the chips
     * @returns {*}
     */
    const listOptions = () => availableFilters && availableFilters.reduce((currentFiltersApplied, { key, label, icon, selector, filterKey, dynamicKey }) => {
        key = filterKey || label
        if (!chips.find(chip => chip.titleLabel === key))
            currentFiltersApplied.push({
                value: key,
                label: <DivLabel>{icons[icon]}<span style={{ marginLeft: '5px' }}>{t(label)}</span></DivLabel>,
                name: label,
                selector,
                dynamicKey
            })
        return currentFiltersApplied
    }, [])

    /**
     * Options for the select type filter
     * @param value: the given options
     * @returns {*}
     */
    const inputSelectOptions = value => value.map(v => ({ label: v.label, value: v.value }))


    const newChipObjectParse = () => {
        if (filterValue && filterValue instanceof Array) {
            return {
                titleLabel: selected.value,
                valueLabel: filterValue.map(a => {
                    if (selected.selector.dynamicOptions) {
                        return selected.selector.options.find(option => option.value === a).label
                    } else {
                        return t(selected.selector.options.find(option => option.value === a).label)
                    }
                }).join(' | '),
                value: filterValue,
                selectorType: selected.selector,
                dynamicKey: selected.dynamicKey,
                name: selected.name
            }
        } else if (filterValue instanceof Object) {
            if (selected.selector.type === FilterType.OWNER) {
                return {
                    titleLabel: selected.value,
                    valueLabel: filterValue.label,
                    value: { 'case-owner-id': filterValue.value },
                    selectorType: selected.selector,
                    name: selected.name
                }
            } else if (selected.selector.type === FilterType.NUMBER_RANGE)
                return {
                    titleLabel: selected.value,
                    valueLabel: 'from ' + filterValue[selected.selector.fromKey] + ' | to ' + filterValue[selected.selector.toKey],
                    value: filterValue,
                    selectorType: selected.selector,
                    name: selected.name
                }
            else
                return {
                    titleLabel: selected.value,
                    valueLabel: 'from ' +
                        Moment(filterValue[selected.selector.fromKey || 'timestampFrom']).format('DD/MM/YYYY') +
                        ' | to ' +
                        Moment(filterValue[selected.selector.toKey || 'timestampTo']).format('DD/MM/YYYY'),
                    value: filterValue,
                    selectorType: selected.selector,
                    dynamicKey: selected.dynamicKey,
                    name: selected.name
                }
        } else {
            return {
                titleLabel: selected.value,
                valueLabel: filterValue.trim().length > 20 ? filterValue.substr(0, 20) + ' ...' : filterValue,
                value: filterValue,
                selectorType: selected.selector,
                dynamicKey: selected.dynamicKey,
                name: selected.name
            }
        }
    }
    /**
     * Adds a new filter to the chips array
     */
    const onAddFilter = () => {
        const newChip = newChipObjectParse()
        const newChips = [...chips, newChip]
        onFiltersChange(newChips, false)
    }

    /**
     * Removes the given chip from the chips list
     * @param label
     */
    const onDelete = label => {
        const newChips = chips.filter(c => c.valueLabel !== label)
        onFiltersChange(newChips)
    }

    /**
     * Updates the value of the range-datepicker and the filterValue
     * @param value
     * @param fromKey
     * @param toKey
     */
    const onSelectDate = (range, fromKey = 'timestampFrom', toKey = 'timestampTo') => {
        let dateFrom = Moment(range[0])
        let dateTo = Moment(range[1]).add(23, 'hours').add(59, 'minutes').add(59, 'seconds')

        setDateRange(range)
        setFilterValue({ [fromKey]: JSDateToAPIDate(dateFrom), [toKey]: JSDateToAPIDate(dateTo) })
    }

    const toogleDateRange = () => {
        setOpenDateRange(!openDateRange)
    }

    /**
     * Renders the filter input based on the value from the filters selector
     * @param selector
     * @returns {*}
     */
    const renderFilterInput = (selector) => {
        switch (selector && selector.type) {
            case FilterType.TEXT:
                return (
                    <DivStyled marginTop='6px'>
                        <Input
                            value={filterValue}
                            onKeyDown={disabledButton ? () => { } : handleOnKeyUp}
                            onChange={(e) => setFilterValue(e.target.value)}
                            type={'text'}
                            disabled={!selected}
                        />
                    </DivStyled>
                )
            case FilterType.NUMBER:
                return (
                    <DivStyled marginTop='6px'>
                        <Input
                            value={filterValue}
                            onKeyDown={disabledButton ? () => { } : handleOnKeyUp}
                            onChange={(e) => setFilterValue(e.target.value)}
                            type={'number'}
                            disabled={!selected}
                        />
                    </DivStyled>
                )
            case FilterType.SELECT:
                return (
                    <DivStyled>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            styles={selectCustomStylesV2}
                            placeholder={t("multiSelect")}
                            value={!isEmpty(selector.options.filter(t => filterValue.includes(t.value))) ? selector.options.forEach(t => t) : []}
                            options={inputSelectOptions(selector.options.map(option => (
                                {
                                    ...option,
                                    label: selector.dynamicOptions
                                        ? option.label
                                        : t(option.label)
                                }
                            )))}
                            onChange={select => setFilterValue(select ? select.map(s => s.value) : '')}
                        />
                    </DivStyled>
                )
            case FilterType.SINGLE_SELECT:
                return (
                    <DivStyled>
                        <Select
                            closeMenuOnSelect={false}
                            styles={selectCustomStylesV2}
                            placeholder={t("multiSelect")}
                            value={selector.options.find(t => filterValue === t.value) || []}
                            options={inputSelectOptions(selector.options.map(option => (
                                {
                                    ...option,
                                    label: selector.dynamicOptions
                                        ? option.label
                                        : t(option.label)
                                }
                            )))}
                            onChange={select => setFilterValue(select.value)}
                        />
                    </DivStyled>
                )
            case FilterType.DATE:
                return (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ width: '100%' }}>
                            <DivStyled marginTop='6px'>
                                <Input
                                    style={{ color: 'black' }}
                                    placeholder="Select a Date"
                                    onClick={() => toogleDateRange()}
                                    value={filterValue[selector.fromKey || 'timestampFrom'] ? 'from ' +
                                        Moment(filterValue[selector.fromKey || 'timestampFrom']).format('DD/MM/YYYY') +
                                        ' | to ' +
                                        Moment(filterValue[selector.toKey || 'timestampTo']).format('DD/MM/YYYY') : ''
                                    }
                                />
                                <DateRangePicker 
                                    locale='en-US'
                                    isOpen={openDateRange}
                                    dayPlaceholder=''
                                    yearPlaceholder=''
                                    monthPlaceholder=''
                                    maxDate={new Date()}
                                    calendarIcon={null}
                                    clearIcon={null}
                                    value={dateRange}
                                    onChange={range => onSelectDate(range, selector.fromKey || 'last-update-from', selector.toKey || 'last-update-to')}
                                    shouldCloseCalendar={({reason}) =>  ['outsideAction', 'escape', 'buttonClick'].includes(reason)}
                                />
                            </DivStyled>
                        </div>
                    </ClickAwayListener>
                )
            case FilterType.NUMBER_RANGE:
                return (
                    <div style={{ display: 'flex', marginRight: 33 }}>
                        <InputLabel style={{ textAlign: 'right', paddingRight: 10, color: styledTheme.colors.grey100, fontSize: 12, lineHeight: 1.7 }}>{t('from')}</InputLabel>
                        <Input
                            style={{height: 32, marginTop: 6, textAlign: 'right'}}
                            value={filterValue.from}
                            onChange={(e) => setFilterValue({ [selector.fromKey]: e.target.value, [selector.toKey]: filterValue[selector.toKey] || '' })}
                            type={'number'}
                            disabled={!selected}
                        />
                        <InputLabel style={{ textAlign: 'right', paddingRight: 10, color: styledTheme.colors.grey100, fontSize: 12, lineHeight: 1.7 }}>{t('to')}</InputLabel>
                        <Input
                            style={{ height: 32, marginTop: 6}}
                            value={filterValue.to}
                            onChange={(e) => setFilterValue({ [selector.fromKey]: filterValue[selector.fromKey] || 0, [selector.toKey]: e.target.value })}
                            type={'number'}
                            disabled={!selected}
                        />
                    </div>
                )
            case FilterType.OWNER:
                return (
                    <DivStyled>
                        <OwnerSelector
                            value={filterValue.value}
                            onChange={select => setFilterValue(select)}
                            application={selector.application}
                        />
                    </DivStyled>
                )
            case FilterType.SLIDER_RANGE:
                return (
                    <SliderWrapper>
                        <SliderInput
                            min={selector.min}
                            max={selector.max}
                            postfix=""
                            onChange={(id, data) => {
                                setFilterValue({ [selector.fromKey]: data[0], [selector.toKey]: data[1] })
                            }}
                            value={[ filterValue[selector.fromKey] || selector.min, filterValue[selector.toKey] || selector.max ]}
                        />
                    </SliderWrapper>
                )
            default:
                return <DivStyled marginTop='6px'>
                    <Input placeholder={t('filters')} value='' disabled />
                </DivStyled>
        }
    }

    return (
        <FlexContainer column={!(props.width && props.width > 1100)} width='100%' padding='12px'>
            <GridInputsContainer>
                <GridSelect data-cy='filter-selector' item xs={4} sm={4} md={2} xl={2}>
                    <Select
                        styles={selectCustomStylesV2}
                        isClearable={true}
                        placeholder={t('filters')}
                        value={selected && availableFilters.find(t => t.value === selected)}
                        onChange={(select, {action}) => handleSelectorChange(select, action)}
                        options={listOptions()}
                    />
                </GridSelect>
                <GridInputType  data-cy='filter-selector-input'>
                    {renderFilterInput(selected ? selected.selector : {})}
                </GridInputType>
                <GridContentAdd>
                    <ContentAddStyled isDisabled={disabledButton} onClick={disabledButton ? () => { } : onAddFilter} />
                </GridContentAdd>
            </GridInputsContainer>
            <FilterChips chips={chips} onDelete={onDelete} t={t}/>
        </FlexContainer>
    )
}

export default AdvancedSearch