import {CLOSE_NOTIFICATION, OPEN_NOTIFICATION} from "./actionTypes"
import {deleteDuplicatesFromObjectsArray} from "../config/helpers"

function notifications(state = [] , action) {
    switch (action.type){
        case OPEN_NOTIFICATION:
            const {message,type} = action.data
            return deleteDuplicatesFromObjectsArray([...state,{message,type,open:true}], 'message')

        case CLOSE_NOTIFICATION:
            const {index} = action.index
            return state.length === 1 ? [] : [...state.splice(index,1)]

        default:
            return state
    }
}

export default notifications
