import React from 'react'
import { useTranslation } from 'react-i18next';
import InputLabel from "@material-ui/core/InputLabel"
import { TitleOrangeBold, FlexContainer } from 'heliocor-ui'

import IdCardHint from '../../../assets/identity_card_1.png'
import IdCardBack from '../../../assets/identity_card_2.png'
import DragDrop from '../../common/DragDrop/DragDrop'
import ImageLoader from '../../dokstor/bars/DocBar/loaders/ImageLoader'
import RecordLoader from '../../dokstor/bars/DocBar/loaders/RecordLoader'
import AutoDataExtraction from '../AutoDataExtaction'
import ForensicImageContainer from '../ForensicImage/Container'
import ToolBarImage from '../ToolBarImage/ToolBarImage'
import DocComments from './DocComments'
import { FieldsWrapper } from './DocDetails.styles'
import { dateConverter } from "../../../config/helpers"
import { TWO_MANDATORY_SIDES_DOCS } from '../../dokstor/config'
import { MediaViewer, WarningBox } from '../index'
import { VIEWER_SIZES } from '../MediaViewer'
import { getMediaType } from '../../../config/media'
import { MapView, extractLocationFromDocumentProperties } from '../MapView';

const DocDetailsLayout = ({
    canValidate, checkMaxFiles, clickable, comments, currentImage, deleteWarning, doc,
    docId, docType, handleToolbar, handleDeleteImage, phase,
    documentConfig = {
        extensions: ["JPG", "PNG", "JPEG", "PDF"],
        maxSize: 10000000
    },
    handleUpload, hasAutoDataExtraction, hasComments, header, height, image, imgDimensions,
    index, mono, viewerSize, readonly, saveComment, setBacksideWarning, showBacksideWarning,
    title, titleDisabled, properties, toolBarConfig, type, uploadDate, firstImage, setDeleteWarning
}) => {
    const { t } = useTranslation()
    const {filesNumber, maxSize, extensions} = documentConfig

    const isLoading = image === 'isLoading'

    const hasntSourceProperty = !(properties && properties.some(p => p.name !== 'source'))

    const file = !isLoading && header ? header + image : image
    const mediaType = getMediaType({ type, header })
    const imgDimensionsForWarning = hasntSourceProperty && hasAutoDataExtraction && imgDimensions
    const uploadTranslationKey = !firstImage ? 'warning.uploadFrontSide' : 'warning.uploadBackside'
    const { lat, lng } = extractLocationFromDocumentProperties(properties)

    return (
        <React.Fragment>
            {(image === 'isEmpty') || (image && TWO_MANDATORY_SIDES_DOCS.includes(docType) && firstImage)
                ?
                <FlexContainer
                    column
                    margin='0 0 5px 0'
                >
                    <FieldsWrapper>
                        {TWO_MANDATORY_SIDES_DOCS.includes(docType) &&
                            <div>
                                <p style={{ margin: '0px 0px 0px 8px' }}>
                                    {t(uploadTranslationKey)}
                                </p>
                                <img
                                    alt='specimen'
                                    src={!firstImage ? IdCardHint : IdCardBack}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        }
                        {!readonly && (
                            <FlexContainer justifyContent='flex-end' margin={'20px 0 0' }>
                                <DragDrop
                                    clickable={clickable}
                                    fileTypes={extensions}
                                    onLoad={handleUpload}
                                    maxSize={maxSize}
                                />
                            </FlexContainer>
                        )}
                    </FieldsWrapper>
                </FlexContainer>
                :
                <FlexContainer column margin='0 0 5px 0' >
                    {isLoading
                        ?
                        <React.Fragment>
                            <ImageLoader />
                            <RecordLoader />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <FieldsWrapper flexEnd fullSize={viewerSize === VIEWER_SIZES.FULL}>
                                <FlexContainer
                                    alignItems='center'
                                    justifyContent='space-between'
                                    width={titleDisabled ? 'auto' : '100%'}
                                >
                                    {!titleDisabled
                                        ?
                                        mono
                                            ?
                                            <TitleOrangeBold margin="10px 0px" value={t(title)} />
                                            :
                                            <TitleOrangeBold value={t('file') + ` ${index + 1}`} />
                                        :
                                        null
                                    }
                                    {!readonly &&
                                        <ToolBarImage
                                            docFileTypes={extensions}
                                            handleToolbar={handleToolbar}
                                            maxFiles={checkMaxFiles(filesNumber, doc)}
                                            mediaType={mediaType}
                                            toolBarConfig={toolBarConfig}
                                        />
                                    }
                                </FlexContainer>
                            </FieldsWrapper>
                            {!readonly
                                ?
                                <DragDrop
                                    maxSize={maxSize}
                                    fileTypes={extensions}
                                    notMaxFile={checkMaxFiles(filesNumber, doc)}
                                    onLoad={handleUpload}
                                    content={
                                        <MediaViewer
                                            file={file}
                                            height={height}
                                            mediaType={mediaType}
                                            properties={properties}
                                            viewerSize={viewerSize}
                                            imgDimensionsForWarning={imgDimensionsForWarning}
                                        />
                                    }
                                />
                                : 
                                <MediaViewer
                                    file={file}
                                    height={height}
                                    mediaType={mediaType}
                                    properties={properties}
                                    viewerSize={viewerSize}
                                    imgDimensionsForWarning={imgDimensionsForWarning}
                                />
                            }
                            {!readonly &&
                                <div>
                                    {uploadDate &&
                                        <FlexContainer padding={'0px 30px 10px 30px'}>
                                            <InputLabel style={{ marginTop: 0 }}>{t('uploaded')}</InputLabel>
                                            <FlexContainer>{dateConverter(uploadDate)}</FlexContainer>
                                        </FlexContainer>
                                    }
                                    {hasAutoDataExtraction &&
                                        <AutoDataExtraction
                                            docType={docType}
                                            properties={properties}
                                        />
                                    }
                                    {phase === 'VALIDATION' && !!lat && !!lng && (
                                        <MapView lat={lat} lng={lng} />
                                    )}
                                    {canValidate && docType === "PASSPORT" &&
                                        <ForensicImageContainer
                                            handleToolbar={handleToolbar}
                                            image={image}
                                        />
                                    }
                                    {hasComments &&
                                        <DocComments
                                            comments={comments}
                                            saveComment={saveComment}
                                        />
                                    }
                                </div>
                            }
                        </React.Fragment>
                    }
                </FlexContainer>
            }
            <WarningBox
                title={t("confirmationRequired")}
                content={t("warning.uploadBackside")}
                open={showBacksideWarning}
                onClose={() => setBacksideWarning(prevBackSide => !prevBackSide)}
                buttons={[{
                    autoFocus: true,
                    onClick: () => setBacksideWarning(prevBackSide => !prevBackSide),
                    value: t('button.gotIt'),
                    type: 'default',
                    dataCy: 'warningContinue'
                }]}
            />
            <WarningBox
                title={t("confirmationRequired")}
                content={t("warning.deleteFile")}
                open={deleteWarning}
                onClose={() => setDeleteWarning(false)}
                buttons={[
                    { type: 'default', onClick: () => handleDeleteImage(docId, currentImage?.identifier) & setDeleteWarning(false), value: t('button.delete') },
                    { type: 'light', onClick: () => setDeleteWarning(false), value: t('button.cancel') }
                ]}
            />
        </React.Fragment>
    )
}

export default DocDetailsLayout