import React, { Component, Fragment } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import DrawerWrapper from './apps/common/DrawerWrapper/DrawerWrapper'
import theme from './config/theme'
import InvalidToken from './config/invalidToken/InvalidToken'
import Notification from './notifications/Notification'
import Router from './router/Router'
import configureStore from './store/store'
import { GlobalStyle } from './styles/GlobalStyle'
import styledTheme from './styles/styledTheme'

import './App.css'

export const store = configureStore()

if(window.Cypress) {
    window.Cypress.store = store
}

class App extends Component {

    componentDidMount() {
        /*
        in case that there is an old loginData - which means loginData without permissions parameter - stored on the
        local storage, we are going to clean the localstorage to avoid errors, forcing this way the current logged
        user to log in again getting the new login credentials to be stored.
        */
        const loginDataStr = localStorage.getItem('loginData')
        if (loginDataStr) {
            if (!JSON.parse(loginDataStr).hasOwnProperty('permissions')) {
                localStorage.clear()
            }
        }
        // eslint-disable-next-line
        console.log('Powered by')
        // eslint-disable-next-line
        console.log(
            '██╗  ██╗███████╗██╗     ██╗ ██████╗  ██████╗ ██████╗ ██████╗ \n' +
            '██║  ██║██╔════╝██║     ██║██╔═══██╗██╔════╝██╔═══██╗██╔══██╗\n' +
            '███████║█████╗  ██║     ██║██║   ██║██║     ██║   ██║██████╔╝\n' +
            '██╔══██║██╔══╝  ██║     ██║██║   ██║██║     ██║   ██║██╔══██╗\n' +
            '██║  ██║███████╗███████╗██║╚██████╔╝╚██████╗╚██████╔╝██║  ██║\n' +
            '╚═╝  ╚═╝╚══════╝╚══════╝╚═╝ ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝  ╚═╝')
    }

    render() {
        return (
            <Fragment>
                <GlobalStyle theme={styledTheme} />
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <ThemeProvider theme={styledTheme}>
                            <MuiThemeProvider theme={theme}>
                                <DrawerWrapper>
                                    <Router />
                                </DrawerWrapper>
                                <Notification />
                                <InvalidToken />
                            </MuiThemeProvider>
                        </ThemeProvider>
                    </Provider>
                </I18nextProvider>
            </Fragment>

        )
    }
}

export default App