import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FlexContainer } from 'heliocor-ui'
import { Translate } from './Translate'

const SecurityDialog = () => (

    <Container alignItems='center' justifyContent='center' height='100vh'>
        <Dialog open>
            <DialogTitle>{"Security Warning"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Translate id='error.noPermission' />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Link to={'/'}>
                    <Button color="primary" variant='contained' autoFocus>
                        Accept
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    </Container>
)

const Container = styled(FlexContainer)`
    background-color: ${props => props.theme.colors.grey700}
`

export default SecurityDialog
