export const REQUEST_CASES = 'REQUEST_CASES'
export const SUCCEED_REQUEST_CASES = 'SUCCEED_REQUEST_CASES'
export const SET_CURRENT_CASE = 'SET_CURRENT_CASE'
export const RESET_CURRENT_CASE = 'RESET_CURRENT_CASE'
export const REQUEST_DELETE_CASE = 'REQUEST_DELETE_CASE'
export const DELETE_CASE = 'DELETE_CASE'

export const REQUEST_ALERTS = 'REQUEST_ALERTS'
export const SUCCEED_REQUEST_ALERTS = 'SUCCEED_REQUEST_ALERTS'

export const REQUEST_ALERT_AUDITS = 'REQUEST_ALERT_AUDITS'
export const SUCCEED_REQUEST_ALERT_AUDITS = 'SUCCEED_REQUEST_ALERT_AUDITS'

export const REQUEST_CASE_AUDITS = 'REQUEST_CASE_AUDITS'
export const SUCCEED_REQUEST_CASE_AUDITS = 'SUCCEED_REQUEST_CASE_AUDITS'

export const REQUEST_ALERT_EVIDENCE = 'REQUEST_ALERT_EVIDENCE'
export const SUCCEED_REQUEST_ALERT_EVIDENCE = 'SUCCEED_REQUEST_ALERT_EVIDENCE'
export const REQUEST_UPSERT_ALERT_EVIDENCE = 'REQUEST_UPSERT_ALERT_EVIDENCE'
export const REQUEST_DELETE_ALERT_EVIDENCE = 'REQUEST_DELETE_ALERT_EVIDENCE'
export const SET_CURRENT_EVIDENCE = 'SET_CURRENT_EVIDENCE'

export const REQUEST_CASE_ALERTS = 'REQUEST_CASE_ALERTS'
export const SUCCEED_REQUEST_CASE_ALERTS = 'SUCCEED_REQUEST_CASE_ALERTS'

export const CLEAR_CASE_STATUS = 'CLEAR_CASE_STATUS'
export const SET_CASE_STATUS = 'SET_CASE_STATUS'

export const GET_ALGOS = 'GET_ALGOS'
export const SET_ALGOS = 'SET_ALGOS'
export const GET_CURRENT_ALERT_CUSTOMER = 'GET_CURRENT_ALERT_CUSTOMER'
export const SET_CURRENT_ALERT_CUSTOMER = 'SET_CURRENT_ALERT_CUSTOMER'

export const SET_CURRENT_ALERT_CUSTOMER_TRANSACTIONS = 'SET_CURRENT_ALERT_CUSTOMER_TRANSACTIONS'

export const SET_CURRENT_ALGO_SETTINGS = 'SET_CURRENT_ALGO_SETTINGS'

export const GET_ROBOTS = 'GET_ROBOTS'
export const GET_ROBOTS_BY_ALGO = 'GET_ROBOTS_BY_ALGO'
export const GET_ROBOTS_TRANSACTION_DATA = 'GET_ROBOTS_TRANSACTION_DATA'
export const SET_ROBOTS_TRANSACTION_DATA = 'SET_ROBOTS_TRANSACTION_DATA'
export const SET_ROBOTS = 'SET_ROBOTS'

export const GET_ROBOT = 'GET_ROBOT'
export const SET_CURRENT_ROBOT = 'SET_CURRENT_ROBOT'

export const SAVE_ROBOT_PARAMS = 'SAVE_ROBOT_PARAMS'
export const EXECUTE_ALGO = 'EXECUTE_ALGO'

export const SET_CASE_MANAGEMENT_STATUS = 'SET_CASE_MANAGEMENT_STATUS'

export const GET_CURRENT_ALERT_CUSTOMER_ALERTS = 'GET_CURRENT_ALERT_CUSTOMER_ALERTS'
export const GET_CURRENT_ALERT_CUSTOMER_CASES = 'GET_CURRENT_ALERT_CUSTOMER_CASES'
export const GET_ALERTS_BY_SAME_TRANSACTION = 'GET_ALERTS_BY_SAME_TRANSACTION'

export const SET_CURRENT_ALERT_CUSTOMER_ALERTS = 'SET_CURRENT_ALERT_CUSTOMER_ALERTS'
export const SET_CURRENT_ALERT_CUSTOMER_CASES = 'SET_CURRENT_ALERT_CUSTOMER_CASES'
export const SET_CURRENT_ALERT_SAME_TXN_ALERTS = 'SET_CURRENT_ALERT_SAME_TXN_ALERTS'
export const SET_CURRENT_ALERT_CUSTOMER_ACCOUNTS = 'SET_CURRENT_ALERT_CUSTOMER_ACCOUNTS'

export const UPDATE_ALERT_VALIDATIONS = 'UPDATE_ALERT_VALIDATIONS'

export const UPDATE_ALERTS_AS_CURRENT = 'UPDATE_ALERTS_AS_CURRENT'

export const GET_ALERTS_TRIAGE = 'GET_ALERTS_TRIAGE'
export const SET_ALERTS_TRIAGE = 'SET_ALERTS_TRIAGE'

export const GET_NBA_DATA = 'GET_NBA_DATA'
export const SET_NBA_DATA = 'SET_NBA_DATA'

export const GET_CURRENT_NUM_OF_ALERTS = 'GET_CURRENT_NUM_OF_ALERTS'
export const SET_CURRENT_NUM_OF_ALERTS = 'SET_CURRENT_NUM_OF_ALERTS'

export const RESET_CURRENT_CUSTOMER = 'RESET_CURRENT_CUSTOMER'
export const GET_USER_DASHBOARD_CONFIG = 'GET_USER_DASHBOARD_CONFIG'
export const SET_USER_DASHBOARD_CONFIG = 'SET_USER_DASHBOARD_CONFIG'

export const ADD_DASHBOARD_CHART = 'ADD_DASHBOARD_CHART'
export const REMOVE_DASHBOARD_CHART = 'REMOVE_DASHBOARD_CHART'
export const RESET_DASHBOARD = 'RESET_DASHBOARD'

export const SET_WIDGET_DATA = 'SET_WIDGET_DATA'

export const GET_ABGA_DATA = 'GET_ABGA_DATA'
export const GET_ABCT_DATA = 'GET_ABCT_DATA'
export const GET_ABSTATUS_DATA = 'GET_ABSTATUS_DATA'
export const GET_WC_DATA = 'GET_WC_DATA'
export const GET_ABSCEN_DATA = 'GET_ABSCEN_DATA'
export const GET_UA_DATA = 'GET_UA_DATA'

export const GET_CURRENT_ALERT = 'GET_CURRENT_ALERT'
export const SET_CURRENT_ALERT = 'SET_CURRENT_ALERT'
export const RESET_CURRENT_ALERT = 'RESET_CURRENT_ALERT'

export const GET_ALERT_COMMENTS = 'GET_ALERT_COMMENTS'
export const ADD_NEW_ALERT_COMMENT = 'ADD_NEW_ALERT_COMMENT'
export const SET_ALERT_COMMENTS = 'SET_ALERT_COMMENTS'
export const DELETE_ALERT_COMMENT = 'DELETE_ALERT_COMMENT'
export const EDIT_ALERT_COMMENT = 'EDIT_ALERT_COMMENT'

export const GET_ALERT_REPORT = 'GET_ALERT_REPORT'
export const SET_ALERT_REPORT = 'SET_ALERT_REPORT'

export const RELOAD_WIDGETS = 'RELOAD_WIDGETS'

export const GET_ALERTS_HISTORY = 'GET_ALERTS_HISTORY'
export const SET_ALERTS_HISTORY = 'SET_ALERTS_HISTORY'

export const UPSERT_ROBOT = 'UPSERT_ROBOT'
export const GET_ROBOT_SIMULATION = 'GET_ROBOT_SIMULATION'
export const SET_ROBOT_SIMULATION = 'SET_ROBOT_SIMULATION'

export const GET_ROBOT_ISSUES_HISTORY = 'GET_ROBOT_ISSUES_HISTORY'
export const SET_ROBOT_ISSUES_HISTORY = 'SET_ROBOT_ISSUES_HISTORY'

export const GET_ROBOT_AUDITS = 'GET_ROBOT_AUDITS'
export const SET_ROBOT_AUDITS = 'SET_ROBOT_AUDITS'

export const GET_RADAR = 'GET_RADAR'

export const SET_CURRENT_RADAR = 'SET_CURRENT_RADAR'
export const GET_CURRENT_RADAR_ISSUES = 'GET_CURRENT_RADAR_ISSUES'
export const SET_CURRENT_RADAR_ISSUES = 'SET_CURRENT_RADAR_ISSUES'
export const GET_CURRENT_RADAR_BELOW_RADAR = 'GET_CURRENT_RADAR_BELOW_RADAR'
export const SET_CURRENT_RADAR_BELOW_RADAR = 'SET_CURRENT_RADAR_BELOW_RADAR'

export const GET_CURRENT_CHART_ISSUES = 'GET_CURRENT_CHART_ISSUES'
export const SET_CURRENT_CHART_ISSUES = 'SET_CURRENT_CHART_ISSUES'
export const GET_CURRENT_CHART_BELOW_RADAR = 'GET_CURRENT_CHART_BELOW_RADAR'
export const SET_CURRENT_CHART_BELOW_RADAR = 'SET_CURRENT_CHART_BELOW_RADAR'
export const GET_CURRENT_RADAR_CHART = 'GET_CURRENT_RADAR_CHART'
export const SET_CURRENT_RADAR_CHART = 'SET_CURRENT_RADAR_CHART'
export const RESET_CURRENT_CHART = 'RESET_CURRENT_CHART'

export const UPSERT_RADAR = 'UPSERT_RADAR'

export const GET_RADAR_AUDITS = 'GET_RADAR_AUDITS'
export const SET_RADAR_AUDITS = 'SET_RADAR_AUDITS'

export const GET_CURRENT_ALERT_BELOW_RADAR = 'GET_CURRENT_ALERT_BELOW_RADAR'
export const SET_CURRENT_ALERT_BELOW_RADAR = 'SET_CURRENT_ALERT_BELOW_RADAR'

export const CHANGE_CASE_STATUS = 'CHANGE_CASE_STATUS'
export const REFRESH_CURRENT_CASE = 'REFRESH_CURRENT_CASE'
export const LOCK_CASE = 'LOCK_CASE'

export const GET_CLUSTERS = 'GET_CLUSTERS'
export const SET_CLUSTERS = 'SET_CLUSTERS'
export const RESET_CLUSTERS = 'RESET_CLUSTERS'

export const GET_REPORTS = 'GET_REPORTS'
export const SET_REPORTS = 'SET_REPORTS'

export const GET_CLUSTER_ALERTS = 'GET_CLUSTER_ALERTS'
export const SET_CURRENT_CLUSTER_ALERTS = 'SET_CURRENT_CLUSTER_ALERTS'

export const GET_CLUSTER_CUSTOMERS = 'GET_CLUSTER_CUSTOMERS'
export const SET_CURRENT_CLUSTER_CUSTOMERS = 'SET_CURRENT_CLUSTER_CUSTOMERS'

export const RESET_CURRENT_CLUSTER = 'RESET_CURRENT_CLUSTER'

export const GET_WATCH_LISTS = 'GET_WATCH_LISTS'
export const SET_WATCH_LISTS = 'SET_WATCH_LISTS'
export const SET_COMPANY_WATCH_LISTS = 'SET_COMPANY_WATCH_LISTS'

export const GET_WATCHLIST = 'GET_WATCHLIST'
export const SET_CURRENT_WATCHLIST = 'SET_CURRENT_WATCHLIST'
export const RESET_CURRENT_WATCHLIST = 'RESET_CURRENT_WATCHLIST'

export const GET_WATCHLIST_MEMBERS = 'GET_WATCHLIST_MEMBERS'
export const SET_CURRENT_WATCHLIST_MEMBERS = 'SET_CURRENT_WATCHLIST_MEMBERS'

export const UPSERT_WATCHLIST = 'UPSERT_WATCHLIST'
export const UPSERT_WATCHLIST_MEMBER = 'UPSERT_WATCHLIST_MEMBER'
export const DELETE_WATCHLIST_MEMBER = 'DELETE_WATCHLIST_MEMBER'

export const GET_COUNTRIES_RISKS_SETTINGS = 'GET_COUNTRIES_RISKS_SETTINGS'
export const GET_ALL_COUNTRIES_RISKS_SETTINGS = 'GET_ALL_COUNTRIES_RISKS_SETTINGS'
export const SET_COUNTRIES_RISKS_SETTINGS = 'SET_COUNTRIES_RISKS_SETTINGS'

export const UPDATE_COUNTRY_RISK = 'UPDATE_COUNTRY_RISK'
export const ADD_LOADING_COUNTRY_RISK_ID = 'ADD_LOADING_COUNTRY_RISK_ID'
export const REMOVE_LOADING_COUNTRY_RISK_ID = 'REMOVE_LOADING_COUNTRY_RISK_ID'

export const GET_ALERT_SIMILARITY = 'GET_ALERT_SIMILARITY'
export const SET_CURRENT_ALERT_SIMILARITY = 'SET_CURRENT_ALERT_SIMILARITY'

export const GET_ALERT_NBA = 'GET_ALERT_NBA'
export const SET_ALERT_NBA = 'SET_ALERT_NBA'

export const GET_ROBOT_ISSUES = 'GET_ROBOT_ISSUES'
export const SET_ROBOT_ISSUES = 'SET_ROBOT_ISSUES'

export const CHANGE_ROBOT_STATUS = 'CHANGE_ROBOT_STATUS'

export const DELETE_ROBOT = 'DELETE_ROBOT'

export const BULK_ASSIGN_ALERTS = 'BULK_ASSIGN_ALERTS'

export const GET_CLASSIFICATION_TYPES = 'GET_CLASSIFICATION_TYPES'
export const SET_CLASSIFICATION_TYPES = 'SET_CLASSIFICATION_TYPES'

export const REQUEST_UPDATE_ALERTS = 'REQUEST_UPDATE_ALERTS'
export const REQUEST_CASE_SPLIT = 'REQUEST_CASE_SPLIT'

export const UPDATE_DASHBOARD_FILTERS = 'UPDATE_DASHBOARD_FILTERS'
export const SET_ALERTED_PAYMENT_IDS = 'SET_ALERTED_PAYMENT_IDS'