import { useEffect } from 'react'
import isEqual from 'lodash/isEqual'

export default function useSetSettingsChanges(settingName, settingsSaved, settingsChanges, setSettingsChanges) {


    useEffect(() => {
        const areSettingsModified = !isEqual(settingsSaved, settingsChanges)
        setSettingsChanges(settingName, areSettingsModified)

    }, [settingName, settingsSaved, settingsChanges])
}
