import React from 'react'
import styled from 'styled-components'
import { DrawerHeader, DocDetails } from '.'
import { VIEWER_SIZES } from './MediaViewer'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

const StyledExpandedDrawer = styled.div`
    height: 100vh;
`

const StyledExitIcon = styled(FullscreenExitIcon)`
    && {    
        colors: ${props => props.theme.colors.black100};
        cursor: pointer;
    }
`

const ExpandedImageDrawer = ({ content, closeCurrent, imagePos = 0, type }) => {

    return (
        <StyledExpandedDrawer>
            <DrawerHeader
                onClose={closeCurrent}
                title={`File ${imagePos + 1}`}
                closeIcon={<StyledExitIcon onClick={closeCurrent} data-cy="drawerClose"/>}
            />
            <DocDetails
                readonly
                image={content}
                titleDisabled
                type={type}
                toolBarConfig={{
                    delete: { show: false, disabled: false },
                    zoom: { show: false, disabled: false },
                    download: { show: false, disabled: false }
                }}
                viewerSize={VIEWER_SIZES.FULL}
            />
        </StyledExpandedDrawer>
    )
}

export default ExpandedImageDrawer