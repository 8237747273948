import React from 'react' 
import styled from 'styled-components'
import Close from '@material-ui/icons/Close'


const IconContainerStyled = styled.div`
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    background-color: ${props => props.theme.colors[props.backgroundColor]}
    color: ${props => props.theme.colors[props.color]}
`

const IconContainer = (props) => <IconContainerStyled {...props}>{props.icon}</IconContainerStyled>

const ContainerTI = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const ContainerText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const ContainerClose = styled.div`
    align-self:center;
    padding-right: 16px;
`

const IconClose = styled(Close)`
    color: ${props => props.theme.colors.grey777};
    font-size: 20px;
`

const AlertDiv = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    height: 4em;
    justify-content: space-between;
    margin: 20px 0;
    box-shadow: ${props => props.theme.shadows.alert};
    border-right: ${props => ` 8px solid ${props.theme.colors[props.backgroundColor]}`}
`

export { IconContainer, ContainerTI, ContainerText, ContainerClose, IconClose, AlertDiv } 