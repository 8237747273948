import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input/Input'
import React from 'react'
import styled from 'styled-components'
import { device } from 'heliocor-ui'
import PasswordTextField from '../../apps/common/PasswordTextField'

const ButtonStyled = styled(({ marginTop, ...rest }) => <Button {...rest} />)`
    && {
        width: 100%;
        margin-top: ${props => props.marginTop ? props.marginTop : '70px'};
        margin-right: 10px;
    }
`

const PasswordTextFieldStyled = styled(PasswordTextField)`
     && {
        height:32px;
        background-color: #faffbd;
        width: 100%;
        border: 0px;
        &:before {
            border-bottom: 1px solid #cccccc;
        };
        &:hover:before: {
            border-bottom: 1px solid #cccccc;
        };
        &:after: {
            border-bottom-width:1px;
            border-bottom-color:${props => props.theme.colors.main};
        };
    }
`

const InputStyled = styled(Input)`
    && {
        height:32px;
        background-color: #faffbd;
        width: 100%;
        border: 0px;
        &:before {
            border-bottom: 1px solid #cccccc;
        };
        &:hover:before: {
            border-bottom: 1px solid #cccccc;
        };
        &:after: {
            border-bottom-width:1px;
            border-bottom-color:${props => props.theme.colors.main};
        };
    }
`

const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    background-color:#efefef;
`

const LoginBox = styled.div`
    display:flex;
    width:900px;
    height:600px;
    background-color:#fff;
    color:#ffffff;
    flex-direction:row;
`

const LoginBG = styled.div`
    flex: 0.5;
    align-self: center;
    @media ${device.tablet}{
        flex: 1;
    }
`

const LoginInputsContent = styled.div`
    flex: 0.5;
    padding: 30px;
    @media ${device.tablet}{
        flex:1;
    }
`

const SpacerV15 = styled.div`
    height: 15px;
    flex: 1;
    clear: both;
`
const SpacerV40 = styled.div`
    height: 40px;
    flex: 1;
    clear: both;
`

export {
    LoginBox,
    LoginBG,
    LoginInputsContent,
    LoginContainer,
    SpacerV15,
    SpacerV40,
    ButtonStyled,
    PasswordTextFieldStyled,
    InputStyled,
}