import { store } from '../../App'
import Moment from 'moment'

/**
 * @param date string
 * 
 * @param options -{ toTimezone: bool, showMinutes: bool, showSeconds: bool }
 * 
 * @return {string} formatted date
 */
export function dateConverter(date, options = {}) {

    const {
        showMinutes = false,
        showSeconds = false,
        toUTC = false
    } = options

    if (!date) return null

    const newDate = typeof date === 'string' ? new Date(date.replace('Z', '').concat('Z')) : date
    const formattersConfig = store.getState().common.formatters || {}
    const dateFormat = formattersConfig.dateFormat || 'DD/MM/YYYY'
    let newFormat

    switch (true) {
        case showMinutes:
            newFormat = dateFormat + ' HH:mm'
            break
        case showSeconds:
            newFormat = dateFormat + ' HH:mm:ss'
            break
        default:
            newFormat = dateFormat
            break
    }

    return toUTC
        ? Moment(newDate).utc().format(newFormat)
        : Moment(newDate).format(newFormat)
}

export function amlDateFormatter(day, month, year) {
    const formattersConfig = store.getState().common.formatters || {}
    const dateFormat = formattersConfig.dateFormat || 'DD/MM/YYYY'
    let newFormat
    let formatter
    const _date = (day || month || year) && `${day ? day + '/' : ''}${month ? month + '/' : ''}${year}`
    if (dateFormat === 'DD/MM/YYYY') {
        if (day) {
            formatter = 'DD/MM/YYYY'
            newFormat = dateFormat.substring(0)
        } else if (month) {
            formatter = 'MM/YYYY'
            newFormat = dateFormat.substring(3)
        } else {
            formatter = 'YYYY'
            newFormat = dateFormat.substring(6)
        }
    } else if (dateFormat === 'MM/DD/YYYY') {
        const arrUnits = dateFormat.split('/')
        if (day) {
            formatter = 'DD/MM/YYYY'
            newFormat = dateFormat
        } else if (month) {
            formatter = 'MM/YYYY'
            newFormat = `${arrUnits[0]}/${arrUnits[2]}`
        } else {
            formatter = 'YYYY'
            newFormat = arrUnits[2]
        }
    }

    const objectDate = Moment(_date, formatter)
    const formattedDate = Moment(objectDate).format(newFormat)
    return (day || month || year) ? formattedDate : undefined
}

/**
 * 
 * @param {string} dateString date 
 * @param {string} dateFormat the format of the date ex: YYYY/MM/DD
 * 
 * @return {string} formatted date
 */
export function dateFormatterFromString(dateString, formatOfDateString) {
    const formattersConfig = store.getState().common.formatters || {}
    const dateFormat = formattersConfig.dateFormat || 'DD/MM/YYYY'

    const objectDate = Moment(dateString, formatOfDateString)
    const formattedDate = Moment(objectDate).format(dateFormat)
    return (dateString && formatOfDateString) && formattedDate
}


/**
 * getMonthNames('fr') --> ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
 * getMonthNames('en', 'short') --> ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
 * Use i18n.resolvedLanguage as a locale
 */
function getMonthNames(locale = 'en', format = 'long') {
    const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
      const mm = month < 10 ? `0${month}` : month;
      return new Date(`2017-${mm}-01T00:00:00+00:00`);
    });
    return months.map(date => formatter.format(date));
  }

export function getFormattedBirthDate(birthDate, locale = 'en') {
    const [ year, month, day ] = birthDate.split('-')
    const monthNames = getMonthNames(locale, 'short')
    const monthName = monthNames[Number(month) - 1]
    return `${day} ${monthName} ${year}`
}