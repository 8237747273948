import React, { useCallback, useEffect, useState } from 'react'
import { b64ToBlob, getBase64FileHeader } from 'heliocor-ui'
import { useDispatch, useSelector } from 'react-redux'
import saveAs from 'file-saver'

import { getExtensionFromMime } from '../../../config/helpers/helpers'
import { DRAWER_SIZES } from '../DrawerWrapper/contants/drawerSizes.enum'
import ExpandedImageDrawer from '../ExpandedImageDrawer'
import { ADD_DRAWER_ELEMENT, CLEAR_VIEWER_CONFIG } from '../store/actionTypes'
import { ToolClicked } from '../ToolBarImage/toolClicked.enum'
import DocDetailsLayout from './DocDetailsLayout'
import { TWO_MANDATORY_SIDES_DOCS } from '../../dokstor/config'
import { VIEWER_SIZES } from '../MediaViewer'

export default function DocDetails({
    canValidate, category, clickable = true, comments = '', currentImage,
    doc, docId, docType, entityType, downloadName, downloadCb,
    handleDeleteImage, handleSaveCommment, handleUpload, phase,
    hasComments = false, height, image, index, mono, properties,
    readonly, title, titleDisabled = false, toolBarConfig, type, viewerSize, uploadDate, firstImage
}) {

    const kycConfig = useSelector(store => store.dokstor.kycConfig).find(entity => entity.entityType === entityType)
    const categoryConfig = kycConfig?.categories?.find(cat => cat.code === category)
    const documentConfig = categoryConfig?.documents?.find(doc => doc.name === docType)
    
    const header = image.match(/\b(https?:\/\/\S*\b)/g) ? '' : getBase64FileHeader(image.charAt(0))

    const hasAutoDataExtraction = properties?.some(prop => prop.name !== 'source')

    const [showBacksideWarning, setBacksideWarning] = useState(false)
    const [imgDimensions, setImgDimensions] = useState({})
    const [deleteWarning, setDeleteWarning] = useState(false)

    // DISPATCH FUNCTIONS
    const dispatch = useDispatch()
    const addDrawer = useCallback(
        element => dispatch({ type: ADD_DRAWER_ELEMENT, element }),
        []
    )

    useEffect(() => {
        if (image && TWO_MANDATORY_SIDES_DOCS.includes(docType) && firstImage) {
            setBacksideWarning(true)
        }
    }, [doc, docType, image, firstImage])

    useEffect(() => {
        getImageDimensions()
    }, [image])

    const checkMaxFiles = (maxFiles, doc) => (!maxFiles) || (doc && (maxFiles > doc.length))

    const getImageDimensions = () => {
        if (image !== 'isLoading') {
            let _img = new Image()
            _img.src = header ? header + image : image
            setImgDimensions({ 'width': _img.width, 'height': _img.height })
        }
    }

    useEffect(() => {
        return () => {
            if(viewerSize === VIEWER_SIZES.MINI) {
                dispatch({type: CLEAR_VIEWER_CONFIG})
            }
        }
    }, [viewerSize])

    const handleToolbar = (enumType, args) => {
        switch (enumType) {
            case ToolClicked.ZOOMIN:
                onFullSizeImageOpen()
                break
            case ToolClicked.DOWNLOAD:
                downloadCb && downloadCb()
                handleDownload()
                break
            case ToolClicked.DELETE:
                setDeleteWarning(true)
                break
            case ToolClicked.UPLOAD:
                handleUpload(args.content)
                break
            default:
                break
        }
    }

    const handleDownload = () => {
        const isBlob = !!image.match(/\b(https|http?:\/\/\S*\b)/g)?.length

        // let _downloadName = downloadName
        // if (!isBlob) _downloadName += getFileExtensionFromB64(image.charAt(0))

        const blob = isBlob ? image : b64ToBlob(image)

        saveAs(blob, downloadName + getExtensionFromMime(doc.datas[index].mimeType))
    }

    const onFullSizeImageOpen = () => {
        addDrawer({
            component: ExpandedImageDrawer,
            props: {
                content: image,
                type
            },
            drawerSize: DRAWER_SIZES.FULL
        })
    }

    const saveComment = (comment) => {
        handleSaveCommment(docId, comment)
    }

    return (
        <DocDetailsLayout
            canValidate={canValidate}
            checkMaxFiles={checkMaxFiles}
            clickable={clickable}
            comments={comments}
            currentImage={currentImage}
            deleteWarning={deleteWarning}
            setDeleteWarning={setDeleteWarning}
            doc={doc}
            documentConfig={documentConfig}
            docId={docId}
            docType={docType}
            handleToolbar={handleToolbar}
            handleDeleteImage={handleDeleteImage}
            handleUpload={handleUpload}
            hasAutoDataExtraction={hasAutoDataExtraction}
            hasComments={hasComments}
            header={header}
            height={height}
            image={image}
            imgDimensions={imgDimensions}
            index={index}
            mono={mono}
            properties={properties}
            readonly={readonly}
            saveComment={saveComment}
            setBacksideWarning={setBacksideWarning}
            showBacksideWarning={showBacksideWarning}
            title={title}
            titleDisabled={titleDisabled}
            toolBarConfig={toolBarConfig}
            type={type}
            phase={phase}
            viewerSize={viewerSize}
            uploadDate={uploadDate}
            firstImage={firstImage}
        />
    )
}