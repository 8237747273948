import React from 'react'
import StatusChangerLoader from "./StatusChanger.loader"
import styled from 'styled-components'
import Slider from "./graphics/Slider"
import { FlexContainer } from 'heliocor-ui'


const StatusChanger = ({ status, from, onChangeStatus, sliderValues = [] }) =>

    <FlexContainer column flex='1' margin-bottom='0 0 5px 0'>
        {status
            ? <WhiteDiv>
                <Slider
                    from={from}
                    values={sliderValues}
                    onChangeCommitted={data => onChangeStatus(data, true)}
                    onChange={data => onChangeStatus(data, false)}
                />
            </WhiteDiv>
            : <StatusChangerLoader />
        }
    </FlexContainer>

const WhiteDiv = styled.div`background-color:${props => props.theme.colors.white}; padding: 0 30px;`

export default StatusChanger
