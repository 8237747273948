
export const REQUEST_USERS = 'REQUEST_USERS';
export const REQUEST_APIKEYS = 'REQUEST_APIKEYS';
export const SUCCEED_REQUEST_USERS = 'SUCCEED_REQUEST_USERS';
export const SET_APIKEYS = 'SET_APIKEYS';
export const REQUEST_USER_AUDITS = 'REQUEST_USER_AUDITS';
export const SET_USER_AUDITS = 'SET_USER_AUDITS';

export const REQUEST_ADD_OR_UPDATE_USER = 'REQUEST_ADD_OR_UPDATE_USER';
export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const REQUEST_ADD_APIKEY = 'REQUEST_ADD_APIKEY';
export const REQUEST_DELETE_APIKEY = 'REQUEST_DELETE_APIKEY';
export const ADD_APIKEY = 'ADD_APIKEY';
export const CLEAR_APITOKEN = 'CLEAR_APITOKEN';
export const DELETE_APIKEY = 'DELETE_APIKEY';

export const SET_ADMIN_STATUS = 'SET_ADMIN_STATUS';
export const CLEAR_ADMIN_STATUS = 'CLEAR_ADMIN_STATUS';

export const REQUEST_USER_PWD_CHANGE = 'REQUEST_USER_PWD_CHANGE';

export const UPDATE_ADMIN_SETTINGS = 'UPDATE_ADMIN_SETTINGS'

export const GET_ORG_TEAMS = 'GET_ORG_TEAMS'
export const SET_ORG_TEAMS = 'SET_ORG_TEAMS'
export const REQUEST_DELETE_TEAM = 'REQUEST_DELETE_TEAM'
export const REQUEST_ADD_TEAM = 'REQUEST_ADD_TEAM'
export const REQUEST_UPDATE_TEAM = 'REQUEST_UPDATE_TEAM'

export const GET_ORG_ROLES = 'GET_ORG_ROLES'
export const SET_ORG_ROLES = 'SET_ORG_ROLES'

export const REQUEST_ADMITTED_USERS = 'REQUEST_ADMITTED_USERS'
export const SUCCEED_REQUEST_ADMITTED_USERS = 'SUCCEED_REQUEST_ADMITTED_USERS'

export const REQUEST_GET_PERMISSIONS = "REQUEST_GET_PERMISSIONS"
export const SET_PERMISSIONS = "SET_PERMISSIONS"
export const REQUEST_ADD_ROLE = "REQUEST_ADD_ROLE"
export const REQUEST_DELETE_ROLE = "REQUEST_DELETE_ROLE"
export const REQUEST_UPDATE_ROLE = "REQUEST_UPDATE_ROLE"

export const REQUEST_GET_DOKSTOR_CALLBACK_URL = 'REQUEST_GET_DOKSTOR_CALLBACK_URL'
export const REQUEST_SET_DOKSTOR_CALLBACK_URL = 'REQUEST_SET_DOKSTOR_CALLBACK_URL'
export const SET_DOKSTOR_URL_CALLBACK = 'SET_DOKSTOR_URL_CALLBACK'

export const REQUEST_GET_ROBOLITICS_CALLBACK_URL = 'REQUEST_GET_ROBOLITICS_CALLBACK_URL'
export const REQUEST_SET_ROBOLITICS_CALLBACK_URL = 'REQUEST_SET_ROBOLITICS_CALLBACK_URL'
export const SET_ROBOLITICS_URL_CALLBACK = 'SET_ROBOLITICS_URL_CALLBACK'

export const REQUEST_GET_ROBOLITICS_TRANSACTION_CALLBACK_URL = 'REQUEST_GET_ROBOLITICS_TRANSACTION_CALLBACK_URL'
export const REQUEST_SET_ROBOLITICS_TRANSACTION_CALLBACK_URL = 'REQUEST_SET_ROBOLITICS_TRANSACTION_CALLBACK_URL'
export const SET_ROBOLITICS_TRANSACTION_URL_CALLBACK = 'SET_ROBOLITICS_TRANSACTION_URL_CALLBACK'
