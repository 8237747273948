import {Tab}         from '@material-ui/core';
import AppBar        from '@material-ui/core/AppBar';
import Typography    from '@material-ui/core/Typography';
import SettingsIcon  from '@material-ui/icons/Settings';
import React         from 'react';
import styled, {css} from 'styled-components';
import {device}      from 'heliocor-ui';

const tabHeight = css`
    height: 42px;
    min-height: 42px;
`

const AppBarStyled = styled(({sideBar, ...rest}) => <AppBar {...rest} />)`
    && {
        width: ${props => props.sideBar ? 'calc(100% - 210px)' : 'calc(100% - 73px)'};
        position: fixed;
        justify-content: space-between;
        margin-bottom: 25px;
        ${tabHeight}
        @media ${device.midSize} {
            margin-bottom: 0;
        }
        & ::-webkit-scrollbar{
            opacity:0;
        }
        & [class^='MuiPrivateTabIndicator']{
            height: 3px;
        }
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
`

const MenuLabel = styled(Typography)`
    && { 
        font-size: 14px;
        color: ${props => props.theme.colors.grey100}
        min-width: 60px;
        padding-right: 8px;
        font-weight: 400;
        text-transform: uppercase;
    }
`
    

const LabelStyled = styled(MenuLabel)`
    && {
        margin-right: 5px;
        text-transform: uppercase;
        display: none;
            @media ${device.tablet} {
                display: block;
        }
    }
`

const SettingsIconStyled = styled(SettingsIcon)`
    color: ${props => props.theme.colors.grey100};
`


const TabStyled = styled(Tab)`
    && { 
        ${tabHeight} 
        & span > p {
            font-weight: 400;
        }
    }
`
const TabRight = styled(Tab)`
    && {
        ${tabHeight} 
        position: static;
        @media ${device.midSize}{
            position: absolute;
            right: 0;
        }
    }
`

export {
    TabRight,
    TabStyled,
    AppBarStyled,
    MenuLabel,
    LabelStyled,
    SettingsIconStyled
}