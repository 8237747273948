import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { REMOVE_LAST_DRAWER } from '../store/actionTypes'
import CustomDrawer from './components/CustomDrawer/CustomDrawer'


const DrawerWrapper = props => {

    const elements = useSelector(store => store.common.drawerElements)

    const dispatch = useDispatch()

    const removeLastElement = useCallback(
        () => dispatch({ type: REMOVE_LAST_DRAWER }), []
    )

    const removeLastElementHandler = callback => {
        callback && callback()
        removeLastElement()
    }

    return (
        <>
            {React.Children.only(props.children)}
            {elements &&
                elements.map((element, i) => (
                    < CustomDrawer
                        key={i}
                        component={element.component}
                        elementProps={{ ...element.props, index: i }}
                        removeLastElement={() => removeLastElementHandler(element.closeCallback)}
                        drawerWidth={element.drawerSize}
                        contextType={element.contextType}
                    />
                ))
            }
        </>
    )
}

export default DrawerWrapper

DrawerWrapper.propTypes = {
    children: PropTypes.element.isRequired
}
