import IconButton from '@material-ui/core/IconButton/index';
import styled     from 'styled-components';
import {device}   from 'heliocor-ui';
import OpenInNewTabIcon from '@material-ui/icons/OpenInNew';

const flexRow = styled.div`
    display:flex;
    flexDirection:row;
`

const TopBarContainer = styled(flexRow)`
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color:${(props) => props.backgroundColor || props.theme.colors.main};
    height: 55px;
    width: 100%;
    justify-content:space-between;
    z-index: 1300;
    position: fixed;
    paddingLeft: 10px;
    padding-left: ${props => props.sysAdmin ? '8px' : '0'};
`

const TopBarLogout = styled.div`
    position: absolute;
    right: 0;
    border: ${(props) => `1px solid ${props.theme.colors.grey}`};
    padding: 16px;
    padding-bottom: 8px;
    background-color: ${(props) => props.theme.colors.white};
    top: 55px;
    box-shadow: ${props => props.theme.shadows.menu};
`

const AvatarImg = styled.div`
    &&{
        top: 20%;
        font-size: 12px;
        position: relative;
        color:${props => props.theme.colors.white};
        font-family: "Lato", monospace;
        font-weight: bold;
        text-align: center;
    }
`

const AvatarContainer = styled.div`
    &&{
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color:${props => props.theme.colors.grey100};
        margin: 15px 30px 0 0;
        cursor: pointer;
    }
`

const UserName = styled.div`
    border-bottom:${props => `1px solid ${props.theme.colors.greyTopMenuClear}`};
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.grey100};
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 14px;
    font-weight: 300;
`

const LogOutText = styled.p`
    padding-top: 14px;
    border-top:${props => `1px solid ${props.theme.colors.greyTopMenuClear}`};
    color: ${props => props.theme.colors.greyTint};
    width: 220px;
    cursor: pointer;
    margin: 10px 0;
`

const OptionList = styled.ul`
    list-style: none;
    color: ${props => props.theme.colors.greyTint};
    cursor: pointer;
    margin: 0;
    padding: 8px 0;
    border-bottom:${props => `1px solid ${props.theme.colors.greyTopMenuClear}`};
    margin-bottom: 8px;
`

const OptionListedItems = styled.ul`
    padding: 8px 0;
`

const SupportChat = styled.p`
    color:${props => props.theme.colors.white}
    cursor: pointer;
    margin-top: 18px;
    margin-right: 20px;
    font-weight: 300;
`

const IconButtonStyled = styled(IconButton)`
    width: 55px;
    && {
        margin: 0 8px;
    }
`

const User = styled.a`
    display: none;
    @media ${device.midSize}{
        display: inline;
    }
`
const TopBarLinkContainer = styled.a`
    align-items: center;
    display: flex;
    flex-direction: row;
`

const TopBarLinkText = styled.div`
    color: ${props => props.theme.colors.greyTint};
    width: 220px;
    cursor: pointer;
    margin: 10px 0;
`

const VersionNum = styled.div`
    margin-bottom: 6px;
    color: ${props => props.theme.colors.greyTint};
    font-size: 12px;
    text-align: right;
`

const OpenInNewTabIconTopBarLink = styled(OpenInNewTabIcon)`
    color: ${props => props.theme.colors.greyTint};
`

const Logo = styled.img`
    padding: 6px
`

export {
    TopBarContainer,
    TopBarLogout,
    AvatarImg,
    UserName,
    User,
    LogOutText,
    OptionList,
    OptionListedItems,
    SupportChat,
    IconButtonStyled,
    VersionNum,
    AvatarContainer,
    TopBarLinkContainer,
    TopBarLinkText,
    OpenInNewTabIconTopBarLink,
    Logo
}
