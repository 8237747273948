import { t } from "i18next"
import { takeLatestSafe } from "../../../../config/helpers/sagasHelper"
import {
    CLEAR_DOKSTOR_STATUS,
    GET_RISK_CHANGE_REASONS, REMOVE_FIELD_SETTINGS,
    REQUEST_ADD_ACTIVITY,
    REQUEST_ADD_CUSTOM_FIELD,
    REQUEST_ADD_CUSTOM_CATEGORY,
    REQUEST_ADD_RISK_VALUES,
    REQUEST_CASES_ACTIVITIES,
    REQUEST_CASES_STATISTICS,
    REQUEST_DELETE_CUSTOM_FIELD,
    REQUEST_DELETE_CUSTOM_CATEGORY,
    REQUEST_DELETE_DEFAULT_DOCUMENT,
    REQUEST_DELETE_RISK_VALUES,
    REQUEST_DOKSTOR_SETTINGS,
    REQUEST_FIELD_CATEGORIES,
    REQUEST_GET_ALL_KYC_CONFIG,
    REQUEST_GET_CURRENT_PREDEFINED_DOCS,
    REQUEST_GET_DASHBOARD_CONFIG,
    REQUEST_GET_DASHBOARDS_CONFIG,
    REQUEST_GET_FIELDS,
    REQUEST_GET_FIELDS_SETTINGS,
    REQUEST_GET_RISK_SETTINGS,
    REQUEST_RISKS_VALUES,
    REQUEST_SAVE_CATEGORY_CONFIG,
    REQUEST_SAVE_DEFAULT_DOCUMENT,
    REQUEST_SAVE_KYC_CONFIG,
    REQUEST_SET_DOKSTOR_SETTINGS,
    REQUEST_UPDATE_CUSTOM_FIELD,
    REQUEST_UPDATE_CUSTOM_CATEGORY,
    REQUEST_UPDATE_DASHBOARD_CONFIG,
    REQUEST_UPDATE_SCALE_RISK,
    SET_CASES_ACTIVITIES,
    SET_CASES_STATISTICS,
    SET_CURRENT_PREDEFINED_DOCS,
    SET_DASHBOARD_CONFIG,
    SET_DASHBOARDS_CONFIG, 
    SET_DOKSTOR_SETTING,
    SET_DOKSTOR_SETTINGS,
    SET_DOKSTOR_STATUS,
    SET_FIELD_CATEGORIES,
    SET_FIELDS,
    SET_FIELDS_SETTINGS,
    SET_KYC_CONFIG,
    SET_RISK_CHANGE_REASONS,
    SET_RISKS_VALUES,
    REQUEST_ADD_FIELD_VALUE,
    REQUEST_GET_FIELD,
    SET_CURRENT_FIELD,
    REQUEST_DELETE_FIELD_VALUES,
    REQUEST_SORT_FIELDS_LIST,
    REQUEST_SORT_FIELDS_CATEGORIES,
    REQUEST_SORT_DOCUMENTS_LIST,
    REQUEST_SORT_DOCUMENTS_CATEGORIES,
    GET_WATCH_LIST,
    UPSERT_MEMBER_WATCHLIST,
    DELETE_MEMBER_WATCHLIST,
    REQUEST_GET_KYC_TYPES,
    SET_KYC_TYPES,
    REQUEST_UPSERT_KYC_TYPE,
    REQUEST_DELETE_KYC_TYPE,
    REQUEST_SORT_KYC_TYPES,
    REQUEST_GET_APPROVAL_SETTINGS,
    REQUEST_SET_APPROVAL_SETTINGS,
    SET_APPROVAL_SETTINGS,
    SET_RISK_SETTINGS,
    REQUEST_UPSERT_RISK_CATEGORY,
    REQUEST_UPDATE_RISK_CATEGORY,
    REQUEST_DELETE_RISK_CATEGORY,
    UPDATE_PARTY_RISK_SETTINGS,
    REQUEST_UPSERT_RISK,
    REQUEST_DELETE_RISK,
    REQUEST_CURRENT_RISK,
    SET_CURRENT_RISK,
    REQUEST_SORT_RISKS,
    REQUEST_SORT_RISKS_CATEGORIES,
    REQUEST_UPDATE_VALIDATION_SETTINGS,
    CLEAR_PERSON_WATCHLIST,
    CLEAR_COMPANY_WATCHLIST,
    REQUEST_SORT_FIELD_VALUES,
    REQUEST_ADD_DOC_CATEGORY,
    REQUEST_DELETE_CUSTOM_DOC_CATEGORY,
    REQUEST_UPDATE_DOC_CATEGORY,
    REQUEST_UPDATE_DOC_TYPE,
    REQUEST_ADD_SIGNATURE_DOCUMENT,
    REQUEST_GET_SIGNATURE_DOCUMENTS,
    REQUEST_UPDATE_SIGNATURE_DOCUMENT,
    REQUEST_DELETE_SIGNATURE_DOCUMENT,
    REQUEST_GET_SIGNATURE_DOCUMENT_SETTINGS,
    REQUEST_UPDATE_SIGNATURE_DOCUMENT_SETTINGS,
    SET_SIGNATURE_DOCUMENTS,
    SET_SIGNATURE_DOCUMENT_SETTINGS,
    REQUEST_ADD_FIELD_VALUES,
    REQUEST_UPDATE_BASE64_FILE_OPTIONS,
    SET_WATCH_LIST_CATEGORIES,
    GET_WATCH_LIST_CATEGORIES,
    UPSERT_CATEGORY_WATCHLIST,
    DELETE_CATEGORY_WATCHLIST
} from "../actionTypes"
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects"
import { formInputTypes, convertBlobToBase64, isJSONString } from 'heliocor-ui'
import { db, db_thunk } from "../../../../api/db"
import { getQueryParamFromListConfig } from "../../../common/MainTable/helpers/tableHelpers"
import { OPEN_NOTIFICATION } from "../../../../notifications/actionTypes"

import { listConfigs, getTranslationFn, getFieldsSettings, getIsAdmin, getCurrentField } from './storeGetters'
import { parseField, desParseField } from '../../../../config/helpers/helpers'
import isEmpty from 'lodash/isEmpty'
import { SET_API_STATUS } from "../../../common/store/actionTypes"
import contextTypes from "../contextTypes.enum"
import { fieldsParser } from "../parsers/fieldsParser"
import { SET_WATCH_LISTS, SET_COMPANY_WATCH_LISTS } from "../../../caseManagement/store/actionTypes"
import { TYPES_WITH_DEFAULT } from "../../config"
import { CURRENCY_OPTIONS } from '../../../../config/validators/iso4217'
import { getParsedDependencies } from "../../helpers"

export default function* settingsSagas() {
    yield all([fork(watcher())])
}

const watcher = () =>
    function* watch() {
        yield takeLatestSafe(REQUEST_DOKSTOR_SETTINGS, runRequestSettings)
        yield takeEvery(REQUEST_SET_DOKSTOR_SETTINGS, runRequestSetSettings)
        yield takeEvery(REQUEST_UPDATE_VALIDATION_SETTINGS, runRequestUpdateValidations)
        yield takeLatestSafe(REQUEST_GET_ALL_KYC_CONFIG, runRequestGetAllKYCconfig)
        yield takeLatestSafe(REQUEST_GET_KYC_TYPES, runRequestGetKYCTypes)
        yield takeLatestSafe(REQUEST_UPSERT_KYC_TYPE, runRequestAddKYCType)
        yield takeLatestSafe(REQUEST_DELETE_KYC_TYPE, runRequestDeleteKYCType)
        yield takeLatestSafe(REQUEST_SORT_KYC_TYPES, runRequestSortKYCTypes)
        yield takeEvery(REQUEST_SAVE_CATEGORY_CONFIG, runRequestSaveCategoryConfig)
        yield takeEvery(REQUEST_SAVE_KYC_CONFIG, runRequestSaveKYCconfig)
        yield takeEvery(REQUEST_GET_CURRENT_PREDEFINED_DOCS, runRequestGetCurrentPredefinedDocs)
        yield takeLatestSafe(REQUEST_SAVE_DEFAULT_DOCUMENT, runRequestSaveDefaultDocument)
        yield takeLatestSafe(REQUEST_DELETE_DEFAULT_DOCUMENT, runRequestDeleteDefaultDocument)
        yield takeLatestSafe(REQUEST_GET_RISK_SETTINGS, runRequestRiskSettings)
        yield takeLatestSafe(REQUEST_RISKS_VALUES, runRequestRiskValues)
        yield takeLatestSafe(REQUEST_ADD_RISK_VALUES, runRequestAddRiskValues)
        yield takeLatestSafe(REQUEST_DELETE_RISK_VALUES, runRequestDeleteRiskValues)
        yield takeLatestSafe(REQUEST_UPSERT_RISK_CATEGORY, runRequestAddRiskCategory)
        yield takeLatestSafe(REQUEST_UPDATE_RISK_CATEGORY, runRequestUpdateRiskCategory)
        yield takeLatestSafe(REQUEST_DELETE_RISK_CATEGORY, runRequestDeleteRiskCategory)
        yield takeLatestSafe(REQUEST_UPSERT_RISK, runRequestUpsertRisk)
        yield takeLatestSafe(REQUEST_CURRENT_RISK, runRequestGetRisk)
        yield takeLatestSafe(REQUEST_DELETE_RISK, runRequestDeleteRisk)
        yield takeLatestSafe(REQUEST_SORT_RISKS, runRequestSortRisksList)
        yield takeLatestSafe(REQUEST_SORT_RISKS_CATEGORIES, runRequestSortRisksCategories)
        yield takeLatestSafe(REQUEST_UPDATE_SCALE_RISK, runRequestUpdateScaleRisk)
        yield takeLatestSafe(REQUEST_CASES_STATISTICS, runRequestCasesStatistics)
        yield takeLatestSafe(REQUEST_CASES_ACTIVITIES, runRequestCasesActivities)
        yield takeLatestSafe(REQUEST_ADD_ACTIVITY, runRequestAddActivity)
        yield takeLatestSafe(REQUEST_ADD_CUSTOM_FIELD, runRequestAddCustomField)
        yield takeEvery(REQUEST_GET_DASHBOARD_CONFIG, runRequestGetDashboardConfig)
        yield takeEvery(REQUEST_GET_DASHBOARDS_CONFIG, runRequestGetDashboardsConfig)
        yield takeEvery(REQUEST_UPDATE_DASHBOARD_CONFIG, runRequestUpdateDashboardConfig)
        yield takeEvery(REQUEST_GET_FIELDS, runRequestGetFields)
        yield takeEvery(REQUEST_GET_FIELDS_SETTINGS, runRequestGetFieldsSettings)
        yield takeLatestSafe(REQUEST_UPDATE_CUSTOM_FIELD, runRequestUpdateCustomField)
        yield takeLatestSafe(REQUEST_DELETE_CUSTOM_FIELD, runRequestDeleteCustomField)
        yield takeEvery(REQUEST_FIELD_CATEGORIES, runRequestFieldCategories)
        yield takeLatestSafe(GET_RISK_CHANGE_REASONS, runGetRiskChangeReasons)
        yield takeLatestSafe(REQUEST_ADD_DOC_CATEGORY, runRequestAddDocCategory)
        yield takeLatestSafe(REQUEST_UPDATE_DOC_CATEGORY, runRequestUpdateDocCategory)
        yield takeLatestSafe(REQUEST_DELETE_CUSTOM_DOC_CATEGORY, runRequestDeleteCustomDocCategory)
        yield takeLatestSafe(REQUEST_ADD_CUSTOM_CATEGORY, runRequestAddCustomCategory)
        yield takeLatestSafe(REQUEST_UPDATE_CUSTOM_CATEGORY, runRequestUpdateCustomCategory)
        yield takeLatestSafe(REQUEST_DELETE_CUSTOM_CATEGORY, runRequestDeleteCustomCategory)
        yield takeLatestSafe(REQUEST_ADD_FIELD_VALUE, runRequestAddFieldValue)
        yield takeLatestSafe(REQUEST_ADD_FIELD_VALUES, runRequestAddFieldValues)
        yield takeLatestSafe(REQUEST_DELETE_FIELD_VALUES, runRequestDeleteFieldValues)
        yield takeLatestSafe(REQUEST_SORT_FIELD_VALUES, runRequestSortFieldValues)
        yield takeLatestSafe(REQUEST_GET_SIGNATURE_DOCUMENT_SETTINGS, runRequestGetSignatureDocumentSettings)
        yield takeLatestSafe(REQUEST_UPDATE_SIGNATURE_DOCUMENT_SETTINGS, runRequestUpdateSignatureDocumentSettings)
        yield takeLatestSafe(REQUEST_ADD_SIGNATURE_DOCUMENT, runRequestAddSignatureDocuments)
        yield takeLatestSafe(REQUEST_GET_SIGNATURE_DOCUMENTS, runRequestGetSignatureDocuments)
        yield takeLatestSafe(REQUEST_UPDATE_SIGNATURE_DOCUMENT, runRequestUpdateSignatureDocuments)
        yield takeLatestSafe(REQUEST_DELETE_SIGNATURE_DOCUMENT, runRequestDeleteSignatureDocuments)
        yield takeEvery(REQUEST_GET_FIELD, runRequestGetField)
        yield takeEvery(REQUEST_SORT_FIELDS_LIST, runRequestSortFieldsList)
        yield takeEvery(REQUEST_SORT_FIELDS_CATEGORIES, runRequestSortFieldsCategories)
        yield takeEvery(REQUEST_SORT_DOCUMENTS_LIST, runRequestSortDocumentsList)
        yield takeEvery(REQUEST_SORT_DOCUMENTS_CATEGORIES, runRequestSortDocumentsCategories)
        yield takeEvery(GET_WATCH_LIST, runRequestWatchList)
        yield takeEvery(UPSERT_MEMBER_WATCHLIST, runRequestUpsertWatchListMember)
        yield takeEvery(DELETE_MEMBER_WATCHLIST, runDeleteWatchListMember)
        yield takeEvery(CLEAR_COMPANY_WATCHLIST, runDeleteCompanyWatchList)
        yield takeEvery(GET_WATCH_LIST_CATEGORIES, runRequestWatchListCategories)
        yield takeEvery(UPSERT_CATEGORY_WATCHLIST, runRequestUpsertWatchListCategory)
        yield takeEvery(DELETE_CATEGORY_WATCHLIST, runDeleteWatchListCategory)
        yield takeEvery(CLEAR_PERSON_WATCHLIST, runDeleteWatchList)
        yield takeEvery(REQUEST_GET_APPROVAL_SETTINGS, runGetApprovalSettings)
        yield takeEvery(REQUEST_SET_APPROVAL_SETTINGS, runSetApprovalSettings)
        yield takeEvery(REQUEST_UPDATE_DOC_TYPE, runRequestUpdateDocType)
        yield takeEvery(REQUEST_UPDATE_BASE64_FILE_OPTIONS, runRequestUpdateBase64FileOptions)
    } 

function* throwErrorMessage(error) {
    const translationFn = yield select(getTranslationFn)
    yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: error.code ? translationFn('notification.' + error.code) : translationFn('genericErrorMessage') } })
}

function* handlerError(errors) {
    yield put({ type: SET_DOKSTOR_STATUS, dokstorError: true })
    yield all(errors.map(error => throwErrorMessage(error)))
}

function* runRequestSettings() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_SETTINGS, value: true })

    let settings = [], companyRAM = {}, personRAM = {}, riskScale = {}, errors = []

    const isAdmin = yield select(getIsAdmin)

    const res = yield db('get', 'dokstor-bo-api/api/settings')
    const {datasources: dataSources, validations, settings: customSettings = {}} = res
    
    yield put({ type: SET_DOKSTOR_SETTINGS, settings: [], dataSources, validations })

    if (customSettings) {
        const _workOnlyOnOwnCases = customSettings?.['WORK_ONLY_ON_OWN_CASES']
        const _validationCompletePhaseValid = customSettings?.['VALIDATION_COMPLETE_PHASE_VALID']

        settings.push({
            key: 'customSettings',
            value: { 
                workOnlyOnOwnCases: _workOnlyOnOwnCases === 'true',
                validationCompletePhaseValid: _validationCompletePhaseValid === 'true'
            }
        })
    }

    let CAFe = yield db('get', 'dokstor-bo-api/api/settings/cafe') || {}

    if (CAFe.errors) {
        yield call(handlerError, CAFe.errors)
        errors = errors.concat(CAFe.errors)
    } else {
        CAFe = CAFe.settings && CAFe.settings.map(set => ({
            key: set.key,
            value: isJSONString(set.value) ? JSON.parse(set.value) : set.value
        }))
    }

    if (isAdmin) {
        settings = yield db('get', 'auth-api/api/admin/settings/DOKSTOR')
        if (settings.errors) {
            yield call(handlerError, settings.errors)
            errors = errors.concat(settings.errors)
        } else {
            settings = settings && settings.settings.map(set => ({
                key: set.key,
                value: isJSONString(set.value) ? JSON.parse(set.value) : set.value,
            }))
        }

        const res = yield db('get', 'dokstor-bo-api/api/settings/validations')
        if (settings.errors) {
            yield call(handlerError, settings.errors)
            errors = errors.concat(settings.errors)
        } else {

            const getParsedValues = validations => {
                const res = {}
                for (let cat in validations) {
                    res[cat] = {}
                    for (let type in validations[cat]) {
                        res[cat][type] = {}
                        for (let key in validations[cat][type]) {
                            res[cat][type][key] = {}
                            let _val = validations[cat][type][key]
                            let val
                            try {
                                val = JSON.parse(_val);
                            } catch (e) {
                                val = _val
                            }
                            res[cat][type][key] = val
                        }
                    }
                }
                return res
            }

            settings.push({
                key: 'validationss',
                value: getParsedValues(res.validations)
            })
        }
    }

    const { levels, maxValue } = yield db('get', 'dokstor-bo-api/api/settings/risks/scale')
    riskScale = { "key": "riskScale", "value": { levels, maxValue } }

    const resSC = yield db('get', 'dokstor-bo-api/api/company/search-countries')
    const supportedCountries = { key: "supportedCountries", value: resSC.countries || [] }
    const supportedRegions = { key: "supportedRegions", value: resSC.regions }

    if (isEmpty(errors)) {
        yield put({
            type: SET_DOKSTOR_SETTINGS,
            settings: [...settings, ...CAFe, companyRAM, personRAM, riskScale, supportedCountries, supportedRegions],
            dataSources,
            validations
        })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_SETTINGS })
}

function* runRequestSetSettings({ key, value }) {
    let errors = [], response
    const translationFn = yield select(getTranslationFn)
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_SETTINGS, value: true })

    switch (key) {
        case 'CAFE_SETTINGS':
        case 'CAFE_LANGUAGES':
        case 'CAFE_KYC_SETTINGS_PERSON':
        case 'CAFE_KYC_SETTINGS_COMPANY':
        case 'CAFE_BANK_VALIDATION':
         
            const valueString = isJSONString(value) ? value : JSON.stringify(value)
            response = yield db('put', 'dokstor-bo-api/api/settings/cafe/' + key, { value: valueString })

            if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            } else {
                yield put({ type: SET_DOKSTOR_SETTING, key, value: isJSONString(valueString) ? JSON.parse(valueString) : valueString })
            }
            
            break
        
        case 'COMPANY_CAFE':
        case 'PERSON_CAFE':
        case 'CAFE_PERSONALISATION':
            const valueParse = JSON.parse(value)

            if (valueParse.LOGO_IMAGE && (valueParse.LOGO_IMAGE).match(/\b(https?:\/\/\S*\b)/g)) {
                const B64LogoImage = yield call(convertBlobToBase64, valueParse.LOGO_IMAGE)
                value = { ...valueParse, LOGO_IMAGE: B64LogoImage }
            }
            response = yield db('put', 'dokstor-bo-api/api/settings/cafe/' + key, { value: isJSONString(value) ? value : JSON.stringify(value) })

            if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            }
            else {
                yield put({ type: SET_DOKSTOR_SETTING, key, value: isJSONString(value) ? JSON.parse(value) : value })
            }
            break
        default:
            response = yield db('put', 'auth-api/api/admin/settings/DOKSTOR/' + key, { value: isJSONString(value) ? value : JSON.stringify(value) })

            if (response.errors) {
                yield call(handlerError, response.errors)
                errors = errors.concat(response.errors)
            } else {
                yield put({ type: SET_DOKSTOR_SETTING, key, value: isJSONString(value) ? JSON.parse(value) : value })
            }
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_SETTINGS })
    

    if (isEmpty(errors)) {
        yield call(runRequestSettings)
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.SETTINGS_SUCCESS_SAVED'), type: 'success' } })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'Settings success' })
        yield put({ type: CLEAR_DOKSTOR_STATUS })
    }
}

function* runRequestUpdateValidations({ cat, settings }) {
    let errors = [], response
    const translationFn = yield select(getTranslationFn)
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_SETTINGS, value: true })

    const getParsedValues = cat => {
        const res = {}
        for (let type in cat) {
            res[type] = {}
            for (let key in cat[type]) {
                const val = cat[type][key]
                const isString = typeof val === 'string'
                res[type][key] = isString ? val : JSON.stringify(val)
            }
        }
        return res
    }

    response = yield db('put', 'dokstor-bo-api/api/settings/validations', { validations: { [cat]: getParsedValues(settings) } })

    if (response.errors) {
        yield call(handlerError, response.errors)
        errors = errors.concat(response.errors)
    } else {

        yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_SETTINGS })

        if (isEmpty(errors)) {
            yield call(runRequestSettings)
            yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.SETTINGS_SUCCESS_SAVED'), type: 'success' } })
            yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'Settings success' })
            yield put({ type: CLEAR_DOKSTOR_STATUS })
        }
    }
}

function* runRequestRiskSettings() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    const riskSettings = {}
    let res, errors = []

    const types = ['PERSON', 'COMPANY']

    for (let type of types) {
        riskSettings[type] = {}

        res = yield db('get', `dokstor-bo-api/api/settings/risks/categories?type=${type}`)
        if (res.errors)  errors = errors.concat(res.errors)
        else riskSettings[type].categories = res.categories

        res = yield db('get', `dokstor-bo-api/api/settings/risks?type=${type}`)
        if (res.errors) errors = errors.concat(res.errors)
        else riskSettings[type].risks = res.risks
    }

    if (!isEmpty(errors)) yield call(handlerError, errors)
    else {
        yield put({ type: SET_RISK_SETTINGS, riskSettings })
    }
}

function* runRequestPartyRisksSettings({ partyType }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    const riskSettings = {}
    let res, errors = []

    res = yield db('get', `dokstor-bo-api/api/settings/risks/categories?type=${partyType}`)
    if (res.errors) errors = errors.concat(res.errors)
    else riskSettings.categories = res.categories

    res = yield db('get', `dokstor-bo-api/api/settings/risks?type=${partyType}`)
    if (res.errors) errors = errors.concat(res.errors)
    else riskSettings.risks = res.risks

    if (!isEmpty(errors)) yield call(handlerError, errors)
    else {
        yield put({ type: UPDATE_PARTY_RISK_SETTINGS, riskSettings, partyType })
    }
}

function* runRequestRiskValues({ identifier }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    const translationFn = yield select(getTranslationFn)

    let res = yield db('get', `dokstor-bo-api/api/settings/risks/${identifier}/values`)

    if (res.errors) yield call(handlerError, res.errors)
    else {
        let riskList = res.risks
        riskList.forEach(risk => {
            risk.value = risk.value || risk.identifier
            risk.label = risk.code ? translationFn(risk.code) : risk.name
        })
        yield put({ type: SET_RISKS_VALUES, risks: riskList })
    }
}

function* runRequestAddRiskCategory({ category: { partyType, name }}) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: true })

    const body = { name, partyType }

    const endpoint = 'dokstor-bo-api/api/settings/risks/categories'
    const response = yield db('post', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestPartyRisksSettings, { partyType })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_CATEGORY_BAR, value: true })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestUpdateRiskCategory({ category: { partyType, name, identifier } }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: identifier })

    const body = { name }

    const endpoint = `dokstor-bo-api/api/settings/risks/categories/${identifier}`
    const response = yield db('put', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestPartyRisksSettings, { partyType })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_CATEGORY_BAR, value: true })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestDeleteRiskCategory( {category: {identifier, partyType}} ) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    let response = yield db('delete', `dokstor-bo-api/api/settings/risks/categories/${identifier}`)

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield call(runRequestPartyRisksSettings, { partyType })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorSuccess: 'Deleted successfully' })
    }
}

function* runRequestUpsertRisk({ values }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_RISK, value: true })

    const { partyType, name, categoryId, fieldId, enabled, weight, identifier } = values

    const postBody = { partyType, name, categoryId, fieldId, enabled: !!enabled, weight }
    const putBody = { name, categoryId, enabled: !!enabled, weight }

    const method = identifier ? 'put' : 'post'
    const endpoint = `dokstor-bo-api/api/settings/risks${identifier ? `/${identifier}` : ''}`
    const response = yield db(method, endpoint, identifier ? putBody : postBody)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestPartyRisksSettings, { partyType })
        yield call(runRequestGetRisk, { identifier: identifier || response.identifier })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_RISK })
}

function* runRequestGetRisk({ identifier }) {

    // yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.LOADING_RISKS, value: true })

    const response = yield db('get', 'dokstor-bo-api/api/settings/risks/' + identifier)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield put({ type: SET_API_STATUS, risk: response.risk})
    }

    yield put({ type: SET_CURRENT_RISK, risk: response.risk })
    // yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.LOADING_RISKS })
}

function* runRequestDeleteRisk({ identifier, partyType }) {

    // yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: true })

    const response = yield db('delete', 'dokstor-bo-api/api/settings/risks/' + identifier)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestPartyRisksSettings, { partyType })
    }

    // yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestSortRisksList({ risks }) {

    const sortedList = {
        "risks": risks.map((risk, index) => ({ identifier: risk.identifier, order: index + 1 }))
    }

    const response = yield db('put', 'dokstor-bo-api/api/settings/risks/sort', sortedList)

    if (response.errors) return yield call(handlerError, response.errors)

    yield call(runRequestRiskSettings)
}

function* runRequestSortRisksCategories({ categories, partyType }) {

    const sortedCats = {
        "categories": categories.map((cat, i) => ({ identifier: cat.identifier, order: i + 1 }))
    }

    const response = yield db_thunk('put', 'dokstor-bo-api/api/settings/risks/categories/sort', sortedCats)


    if (response.errors) return yield call(handlerError, response.errors)

    yield call(runRequestPartyRisksSettings, { partyType })
}

function* runRequestAddRiskValues({ addRisk, identifier }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    let response

    addRisk.risks && addRisk.risks.some(risk => risk.identifier)
        ? response = yield db('put', `dokstor-bo-api/api/settings/risks/${identifier}/values`, { ...addRisk })
        : response = yield db('post', `dokstor-bo-api/api/settings/risks/${identifier}/values`, addRisk)


    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: SET_DOKSTOR_STATUS, dokstorSuccess: 'Added successfully' })
        yield call(runRequestRiskValues, { identifier })
    }
}

function* runRequestDeleteRiskValues({ identifier, selected }) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    let response = yield db('delete', `dokstor-bo-api/api/settings/risks/${identifier}/values`, { riskIds: selected })

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield call(runRequestRiskValues, { identifier })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorSuccess: 'Deleted successfully' })
    }
}

function* runRequestUpdateScaleRisk(payload = {}) {
    const { maxValue, levels } = payload
    const translationFn = yield select(getTranslationFn)
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SCALE_RISK, value: true })

    let response = yield db('put', 'dokstor-bo-api/api/settings/risks/scale', { maxValue, levels })

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SCALE_RISK })

    if (response.errors) yield call(handlerError, response.errors)
    else {
        let key = 'riskScale'
        let value = { maxValue, levels }
        yield put({ type: SET_DOKSTOR_SETTING, key, value })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('settingsSuccesSaved'), type: 'success' } })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'Settings success' })

    }
}

function* runRequestCasesStatistics() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })

    let casesStatistics = yield db('get', `dokstor-bo-api/api/cases/statistics`)

    if (casesStatistics.errors) yield call(handlerError, casesStatistics.errors)
    else {
        yield put({ type: SET_CASES_STATISTICS, casesStatistics })
    }
}

function* runRequestCasesActivities(payload = {}) {
    const { caseId } = payload

    let listId = caseId + 'Activity'

    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })

    const listConfig = yield select(listConfigs, listId)

    const queryParam = getQueryParamFromListConfig(listConfig)

    let response = yield db('get', `dokstor-bo-api/api/cases/${caseId}/activities?${queryParam}`)

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: SET_CASES_ACTIVITIES, activities: response })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'success' })

    }
}

function* runRequestAddActivity(payload = {}) {
    const { caseId, action, message } = payload

    let response = yield db('post', `dokstor-bo-api/api/cases/${caseId}/activities`, { message, action })

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield call(runRequestCasesActivities, { caseId })
    }
}

function* runRequestGetAllKYCconfig() {
    const entityTypes = ['PERSON', 'COMPANY', 'SHAREHOLDER', 'DIRECTOR', 'AUTH_SIGNATORY', 'UBO']

    let kycConfig = yield all(entityTypes.map(entityType =>
        db('get', 'dokstor-bo-api/api/settings/document-kycs?entity=' + entityType)
    ))
    if (kycConfig.errors) yield call(handlerError, kycConfig.errors)
    else {
        kycConfig = kycConfig.map(e => ({
            ...e,
            categories: e.categories?.map(cat => ({
                ...cat, 
                ...(cat.dependencies ? { dependencies: getParsedDependencies(cat.dependencies) } : {})
            }))
        }))
            
        yield put({ type: REQUEST_GET_KYC_TYPES })
        yield put({ type: SET_KYC_CONFIG, kycConfig })
    }
}

function* runRequestGetKYCconfig({ entityType }) {

    const kycConfig = yield db('get', 'dokstor-bo-api/api/settings/document-kycs?entity=' + entityType)

    if (kycConfig.errors) {
        yield call(handlerError, kycConfig.errors)
    } else {
        const kc = {
            ...kycConfig,
            categories: kycConfig.categories.map(cat => ({
                ...cat,
                ...(cat.dependencies ? { dependencies: getParsedDependencies(cat.dependencies) } : {})
            }))
        }
        yield put({ type: SET_KYC_CONFIG, kycConfig: kc, entityType })
    }
}

function* runRequestSaveKYCconfig(payload = {}) {
    yield put({
        type: SET_API_STATUS, action: 'UPDATE',
        context: contextTypes.SAVING_KYC_CONFIG,
        value: true
    })

    const { kycConfigurations } = payload
    const translationFn = yield select(getTranslationFn)

    let response = yield db('put', 'dokstor-bo-api/api/settings/document-kycs', { kycConfigurations })

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('settingsSuccesSaved'), type: 'success' } })
        yield call(runRequestGetAllKYCconfig)
    }

    yield put({
        type: SET_API_STATUS, action: 'CLEAR',
        context: contextTypes.SAVING_KYC_CONFIG,
    })
}

function* runRequestGetKYCTypes() {
    const types = yield db('get', 'dokstor-bo-api/api/settings/kyc-types')

    if (types.errors) yield call(handlerError, types.errors)
    else {
        yield put({ type: SET_KYC_TYPES, types })
    }
}

function* runRequestAddKYCType({ values }) {
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_KYC_TYPE, value: true })

    const { partyType, identifier, order, teamId, ...type } = values
    const partyPath = partyType === 'PERSON' ? 'persons' : 'companies'

    const { name, shortName, nameCafe, visibleBackOffice, visibleCafe } = type
    
    const types = yield db(
        identifier ? 'put' : 'post',
        `dokstor-bo-api/api/settings/kyc-types/${partyPath}/${identifier || ''}`,
        { name, shortName, nameCafe, visibleBackOffice, visibleCafe, teamId } 
    )

    if (types.errors) yield call(handlerError, types.errors)
    else {
        yield put({ type: REQUEST_GET_KYC_TYPES })
        yield put({ type: REQUEST_GET_ALL_KYC_CONFIG })
        yield put({ type: REQUEST_GET_FIELDS_SETTINGS })
        yield put({ type: REQUEST_GET_APPROVAL_SETTINGS })
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'KYC Type successfully saved!', type: 'success' } })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_KYC_TYPE })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_KYC_TYPE_BAR, value: true })
}

function* runRequestDeleteKYCType({ identifier, partyType }) {
    const partyPath = partyType === 'PERSON' ? 'persons' : 'companies'

    const types = yield db('delete', `dokstor-bo-api/api/settings/kyc-types/${partyPath}/${identifier}`)

    if (types.errors) yield call(handlerError, types.errors)
    else {
        yield put({ type: REQUEST_GET_KYC_TYPES })
        yield put({ type: REQUEST_GET_ALL_KYC_CONFIG })
        yield put({ type: REQUEST_GET_FIELDS_SETTINGS })
        yield put({ type: REQUEST_GET_APPROVAL_SETTINGS })
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'KYC Type successfully deleted!', type: 'success' } })
    }
}

function* runRequestSortKYCTypes({ types, partyType }) {
    const partyPath = partyType === 'PERSON' ? 'persons' : 'companies'
    const sortedList = {
        "kycTypes": types.map((type, index) => ({ identifier: type.identifier, order: index + 1 }))
    }

    const res = yield db('put', `dokstor-bo-api/api/settings/kyc-types/${partyPath}/sort`, sortedList)

    if (res.errors) yield call(handlerError, types.errors)
    else {
        yield put({ type: REQUEST_GET_KYC_TYPES })
        yield put({ type: REQUEST_GET_ALL_KYC_CONFIG })
        yield put({ type: REQUEST_GET_FIELDS_SETTINGS })
        yield put({ type: REQUEST_GET_APPROVAL_SETTINGS })
    }
}

function* runRequestSaveCategoryConfig({ categoryId, configurationByKyc }) {
    yield put({
        type: SET_API_STATUS, action: 'UPDATE',
        context: contextTypes.SAVING_DOCS_CATEGORY_CONFIG,
        value: true
    })

    const translationFn = yield select(getTranslationFn)

    let response = yield db('put', `dokstor-bo-api/api/settings/document-categories/${categoryId}/kycs`, { configurationByKyc })

    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('settingsSuccesSaved'), type: 'success' } })
        yield call(runRequestGetAllKYCconfig)
    }

    yield put({
        type: SET_API_STATUS, action: 'CLEAR',
        context: contextTypes.SAVING_DOCS_CATEGORY_CONFIG,
    })
}

function* runRequestGetCurrentPredefinedDocs(payload = {}) {
    const { categoryId } = payload

    let res = yield db('get', `dokstor-bo-api/api/settings/document-categories/${categoryId}/standard-documents/`)

    if (res.errors) yield call(handlerError, res.errors)
    else {
        yield put({ type: SET_CURRENT_PREDEFINED_DOCS, currentPredefinedDocs: res.documents })
    }
}

function* runRequestSaveDefaultDocument({document, order = 0, currentKycConfig, enabledInIdentifiers}) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_DOC_TYPE, value: true })

    const translationFn = yield select(getTranslationFn)
    
    const body = {
        order,
        name: document.name,
        categoryId: document.category,
        filesNumber: document.filesNumber,
        maxSize: document.maxSize * 1000000,
        extensions: document.extensions,
        partyType: document.entityType,
        standardId: document.nameId,
        description: document.description,
        dependencies: JSON.stringify(document.dependencies),
    }
    
    const endpoint = 'dokstor-bo-api/api/settings/document-types/'
    const creationResponse = yield db('post', endpoint, body)

    if (creationResponse.errors) {
        yield call(handlerError, creationResponse.errors)
    } else {
        const identifier = creationResponse.identifier
        const kycConfigurations = currentKycConfig.map(kyc => enabledInIdentifiers.includes(kyc.identifier) ? { ...kyc, activeDocuments: [...kyc.activeDocuments, identifier]} : kyc)

        yield call(runRequestSaveKYCconfig, { kycConfigurations })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('notification.SAVE_SUCCESS'), type: 'success' } })
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'closing' })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_DOC_TYPE_BAR, value: true })
        yield call(runRequestGetAllKYCconfig)
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_DOC_TYPE })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.CLOSING_DOC_TYPE_BAR })
}

function* runRequestDeleteDefaultDocument(payload = {}) {
    const { documentId, newKycConfig, categoryId, newCategoryKycConfig } = payload
    const translationFn = yield select(getTranslationFn)

    let response = yield db('delete', 'dokstor-bo-api/api/settings/document-types/' + documentId)
    if (response.errors) yield call(handlerError, response.errors)
    else {
        yield call(runRequestSaveKYCconfig, { kycConfigurations: newKycConfig })
        yield call(runRequestSaveCategoryConfig, { categoryId, configurationByKyc: newCategoryKycConfig })
        yield put({ type: OPEN_NOTIFICATION, data: { message: translationFn('docDeletedSuccess'), type: 'success' } })
        yield call(runRequestGetAllKYCconfig)
        yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'Settings success' })
    }
}

function* runRequestAddCustomField({ field, entityType }) {
    
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_FIELD, value: true })

    const _field = parseField(field)

    const { name, partyType, valueType, options, dependencies, channels, category, internalName, 
        mandatory = {}, minLength, maxLength, validation, validationMessage, dateValidations, description } = _field

    const _dependencies = (dependencies && JSON.stringify(dependencies)) || ''

    let res = yield db('post', 'dokstor-bo-api/api/settings/fields', {
        name,
        channels,
        partyType,
        valueType,
        options,
        dependencies: _dependencies,
        category,
        internalName,
        mandatory,
        minLength,
        maxLength,
        validation,
        validationMessage,
        dateValidations,
        description
    })

    if (res.errors) yield call(handlerError, res.errors)
    else {

        if(res?.field?.valueType === 'CURRENCY') {
            const currencies = Object.keys(CURRENCY_OPTIONS).map((k, i) => ({name: k, value: true, order: i}))
            yield call(runRequestAddFieldValues, {fieldId: res.field.identifier, values: currencies})
        }

        yield call(runRequestGetFieldsSettings, { entityType })
        yield call(runRequestGetFields, { entityType })
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'Field successfully saved!', type: 'success' } })
    }
    yield call(runRequestGetField, {fieldId: res.field.identifier, categoryId: category.identifier, partyType})
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_FIELD })

    if (field.valueType !== 'SELECT') {
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_FIELD_BAR, value: true })
    }
}

function* runRequestUpdateDashboardConfig({ identifier, config }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_DASHBOARD_CONFIG, value: identifier })

    const { name, description, permissions, settings } = config
    const res = yield db('put', `dokstor-bo-api/api/dashboards/setting/${identifier}`, { 
        name, 
        description, 
        permissions: permissions.map(permission => {const { identifier, ...rest } = permission; return rest}),
        settings: settings.map(setting => {const { identifier, ...rest } = setting; return rest}),
    })

    if (res.errors) yield call(handlerError, res.errors)
    else {
        yield call(runRequestGetDashboardConfig, { identifier })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_DASHBOARD_CONFIG })

}

function* runRequestGetDashboardsConfig() {
    const config = yield db('get', `dokstor-bo-api/api/dashboards/setting`)

    if (config.errors) yield call(handlerError, config.errors)
    else {
        yield put({ type: SET_DASHBOARDS_CONFIG, config: config.dashboards || [] })
    }
}

function* runRequestGetDashboardConfig({ identifier }) {
    const config = yield db('get', `dokstor-bo-api/api/dashboards/setting/${identifier}`)

    if (config.errors) yield call(handlerError, config.errors)
    else {
        yield put({ type: SET_DASHBOARD_CONFIG, identifier, config: config.dashboard })
    }
}

function* runRequestGetFields( entityType ) {
    
    const entityList = entityType?.entityType ? [entityType.entityType]: ["PERSON", "COMPANY", "CONNECTED_PERSON", "CONNECTED_COMPANY"]
    const fields = {}

    if (entityList.includes("PERSON")){

        const personFields = yield db('get', `dokstor-bo-api/api/person/settings/fields`)
        if (personFields.errors) {
            call(handlerError, personFields.errors)
        } else {
            fields.PERSON = fieldsParser(personFields.categories)
        }
    }

    if (entityList.includes("COMPANY")){

        const companyFields = yield db('get', `dokstor-bo-api/api/company/settings/fields`)
        if (companyFields.errors) {
            call(handlerError, companyFields.errors)
        } else {
            fields.COMPANY = fieldsParser(companyFields.categories)

            const companiesHouseLookup =
            {
                id: 'companiesHouseLookup',
                type: formInputTypes.button,
                disabled: [{ field: 'registeredAddressCountry' }, { field: 'name' }],
                apiDependency: 'registeredAddressCountry',
                editable: true
            }

            const lookup =
            {
                id: 'lookup',
                type: formInputTypes.select,
                placeholder: t('lookupPlaceholder'),
                disabledPlaceholder: '',
                isOpen: true,
                styles: { menuList: (base) => ({ ...base, maxHeight: 200 }), input: { position: 'relative' } },
                disabled: [{ field: 'registeredAddressCountry' }, { field: 'name' }],
                apiDependency: 'registeredAddressCountry',
                options: 'COMPANYHOUSELOOKUP',
                isAsync: true,
                editable: true
            }

            Object.values(fields.COMPANY).forEach(cat => {
                const regNumberIndex = cat?.fields?.findIndex(field => field.id === 'registrationNumber')
                if (regNumberIndex !== -1 && cat) cat.fields.splice(regNumberIndex + 1, 0, { ...companiesHouseLookup }, { ...lookup })
            })
        }
    }

    if (entityList.includes("CONNECTED_PERSON")){

        const connectedPersonFields = yield db('get', `dokstor-bo-api/api/companies/settings/connected-person/fields`)
        if (connectedPersonFields.errors) {
            call(handlerError, connectedPersonFields.errors)
        } else {
            fields.CONNECTED_PERSON = fieldsParser(connectedPersonFields.categories)
        }
    }

    if (entityList.includes("CONNECTED_COMPANY")){

        const connectedCompanyFields = yield db('get', `dokstor-bo-api/api/companies/settings/connected-company/fields`)
        if (connectedCompanyFields.errors) {
            call(handlerError, connectedCompanyFields.errors)
        } else {
            fields.CONNECTED_COMPANY = fieldsParser(connectedCompanyFields.categories)

            const companiesHouseLookup =
            {
                id: 'companiesHouseLookup',
                type: formInputTypes.button,
                disabled: [{ field: 'registeredAddressCountry' }, { field: 'name' }],
                apiDependency: 'registeredAddressCountry',
                editable: true
            }

            const lookup =
            {
                id: 'lookup',
                type: formInputTypes.select,
                placeholder: t('lookupPlaceholder'),
                disabledPlaceholder: '',
                isOpen: true,
                styles: { menuList: (base) => ({ ...base, maxHeight: 200 }) },
                disabled: [{ field: 'registeredAddressCountry' }, { field: 'name' }],
                apiDependency: 'registeredAddressCountry',
                options: 'COMPANYHOUSELOOKUP',
                isAsync: true,
                editable: true
            }

            Object.values(fields.CONNECTED_COMPANY).forEach(cat => {
                const regNumberIndex = cat?.fields?.findIndex(field => field.id === 'registrationNumber')
                if (regNumberIndex !== -1 && cat) cat.fields.splice(regNumberIndex, 0, { ...companiesHouseLookup }, { ...lookup })
            })
        }
    }
    yield put({ type: SET_FIELDS, fields })
}

function* runRequestGetFieldsSettings( entityType ) {
    // Pre: each category has the same name, but are repeated 'cause backend had to do it this way. The difference is
    // on the channel. For  this reason, there's no need to iterate categories to generate the structure of the fields
    // which has the scaffolding of context > category > field

    //TODO: IMPROVE!!!
    let contextTypes = ['PERSON', 'COMPANY', 'CONNECTED_PERSON', 'CONNECTED_COMPANY']

    if (entityType?.entityType) contextTypes = [entityType.entityType]

    const fields = {}, orderedFields = {}
    for (let i = 0; i < contextTypes.length; ++i) {
        let res = yield db('get', 'dokstor-bo-api/api/settings/fields?type=' + contextTypes[i])
        if (res.errors) yield call(handlerError, res.errors)
        else {
            if (!fields.hasOwnProperty(contextTypes[i])) {
                fields[contextTypes[i]] = {}
            }
            const _fields = res.fields.map(f => ({ ...f, id: f.internalName }))
            let j;
            for (j = 0; j < _fields.length; ++j) {
                if (_fields[j].category) {
                    if (!fields[contextTypes[i]].hasOwnProperty(_fields[j].category.identifier)) {
                        fields[contextTypes[i]][_fields[j].category.identifier] = {};
                        fields[contextTypes[i]][_fields[j].category.identifier].fields = []
                        fields[contextTypes[i]][_fields[j].category.identifier].order = _fields[j].category.order
                    }
                    fields[contextTypes[i]][_fields[j].category.identifier].fields.push(_fields[j])
                }
            }

            orderedFields[contextTypes[i]] = {}
            Object.keys(fields[contextTypes[i]]).forEach(category => {
                orderedFields[contextTypes[i]][category] = fields[contextTypes[i]][category]
                orderedFields[contextTypes[i]][category].fields.sort((a, b) => (a.order ?
                    a.order : Infinity) - (b.order ? b.order : Infinity))
                orderedFields[contextTypes[i]][category].fields = orderedFields[contextTypes[i]][category].fields.map(f => {
                    f.channels.map(ch => ch.visibility = ch.visible)
                    if (f.dependencies) f.dependencies = getParsedDependencies(f.dependencies)
                    if (f.options) f.options = JSON.parse(f.options)
                    if (f.code === 'PHONE') f.options = { isSpecial: true, validations: ['PHONE'] }
                    return desParseField(f)
                })
            }
            )
        }
    }

    yield put({ type: SET_FIELDS_SETTINGS, fields: orderedFields })
}

function* runRequestUpdateCustomField({ field, identifier, entityType }) {

    yield put({
        type: SET_API_STATUS,
        action: 'UPDATE',
        context: contextTypes.SAVING_FIELD,
        value: identifier
    })

    const { channels, dependencies, options, mandatory = {}, minLength, maxLength, validation, 
        validationMessage, dateValidations, defaultValue, description, watchlist, watchlistRequired } = field
    let errors = []

    const _dependencies = (dependencies && JSON.stringify(dependencies)) || ''
    const _options = (options && JSON.stringify(options)) || ''

    try {
        yield db('put', 'dokstor-bo-api/api/settings/fields/' + identifier, {
            channels,
            dependencies: _dependencies,
            options: _options,
            mandatory,
            minLength,
            maxLength,
            validation,
            validationMessage,
            dateValidations,
            description,
            defaultValue: Array.isArray(defaultValue) ? JSON.stringify(defaultValue) : defaultValue,
            watchlist,
            watchlistRequired
        })
    } catch (e) {
        errors = errors.concat(e)
    }

    if (!isEmpty(errors)) yield call(handlerError, errors)
    else {

        yield call(runRequestGetFieldsSettings, { entityType })
        yield call(runRequestGetFields, { entityType })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_FIELD_BAR, value: true })
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'Field successfully updated!', type: 'success' } })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_FIELD })
}

function* runRequestDeleteCustomField({ identifier, context, category }) {
    yield put({ type: SET_API_STATUS, action: 'ARRAY_ADD', context: contextTypes.SETTINGS_DELETING_FIELDS, value: identifier })

    const response = yield db('delete', 'dokstor-bo-api/api/settings/fields/' + identifier)

    if (response.errors) {
        if (response.code === '409') {
            yield put({ type: OPEN_NOTIFICATION, data: { message: 'This field is associated to a risk. To delete the field, the risk must be deleted first.', type: 'error' } })
        } else {
            yield handlerError(response)
        }
    } else {
        yield put({ type: OPEN_NOTIFICATION, data: { message: 'Field successfully deleted!', type: 'success' } })
        yield put({ type: REMOVE_FIELD_SETTINGS, identifier, context, category })
        yield call(runRequestGetFieldsSettings, { entityType: context })
        yield call(runRequestGetFields, { entityType: context })
    }
    yield put({ type: SET_API_STATUS, action: 'ARRAY_REMOVE', context: contextTypes.SETTINGS_DELETING_FIELDS, value: identifier })
}

function* runRequestFieldCategories(payload = {}) {
    const { context } = payload
    let errors = [], res

    try {
        res = yield db('get', 'dokstor-bo-api/api/settings/fields/categories?type=' + context)
    }
    catch (e) {
        errors = errors.concat(e)
    }

    if (!isEmpty(errors)) yield handlerError(errors)
    else {
        const categories = res.categories.map(({dependencies = '{}', ...cat}) => ({ ...cat, dependencies: getParsedDependencies(dependencies) }))
        yield put({ type: SET_FIELD_CATEGORIES, context, fieldCategories: categories })
    }
}

function* runGetRiskChangeReasons() { 
    const reasons = yield db('get', 'dokstor-bo-api/api/settings/risks/reasons')
    yield put({ type: SET_RISK_CHANGE_REASONS, reasons: reasons.riskScoreReason })
}

function* runRequestAddCustomCategory({ category: { context, name, description = '', dependencies = {} } }) {


    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: true })

    const body = {
        name,
        description,
        partyType: context,
        dependencies: dependencies && JSON.stringify(dependencies)
    }

    const endpoint = 'dokstor-bo-api/api/settings/fields/categories'

    const response = yield db('post', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestFieldCategories, { context })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_CATEGORY_BAR, value: true })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestUpdateCustomCategory({ category: { context, name, identifier, description, dependencies = {} } }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: identifier })

    const body = { name, description, dependencies: JSON.stringify(dependencies) }

    const endpoint = `dokstor-bo-api/api/settings/fields/categories/${identifier}`
    const response = yield db('put', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestFieldCategories, { context })
        yield call(runRequestGetFieldsSettings)
        yield call(runRequestGetFields)
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_CATEGORY_BAR, value: true })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestDeleteCustomCategory({ category }) {
    const { identifier, context } = category

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DELETING_CATEGORY, value: identifier })

    const response = yield db('delete', `dokstor-bo-api/api/settings/fields/categories/${identifier}`)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else { yield call(runRequestFieldCategories, { context }) }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DELETING_CATEGORY })
}

function* runRequestAddFieldValues({ fieldId, values, categoryId, partyType }) {
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.UPDATING_FIELD, value: true })
    const res = yield db('put', 'dokstor-bo-api/api/settings/fields/' + fieldId + '/values', { values })

    if (res.errors) return yield call(handlerError, res.errors)

    yield call(runRequestGetField, {fieldId, categoryId, partyType})
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.UPDATING_FIELD })
}

function* runRequestAddFieldValue(payload) {
    const { values: {identifier, ...rest}, fieldId } = payload
    let response

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.UPDATING_FIELD, value: true })

    response = yield db('post', 'dokstor-bo-api/api/settings/fields/' + fieldId + '/values', rest)
    
    if (response.errors) return yield call(handlerError, response.errors)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.UPDATING_FIELD })
}

function* runRequestDeleteFieldValues({ fieldId, valuesId, list }) {
    let errors = []

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.UPDATING_FIELD, value: true })

    for (let valueId of valuesId) {
        const res = yield db('delete', `dokstor-bo-api/api/settings/fields/${fieldId}/values/${valueId}`)
        if (res.errors) errors = errors.concat(res.errors)
    }

    if (list.length) {
        yield call(runRequestSortFieldValues, { fieldId, values: list})
    }

    if (!isEmpty(errors)) yield call(handlerError, errors)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.UPDATING_FIELD })
}

function* runRequestSortFieldValues({ fieldId, values, element }) {
    let errors = []

    if (element) {
        const {identifier, name, value, order} = element
        const res = yield db('put', 'dokstor-bo-api/api/settings/fields/' + fieldId + '/values/' + identifier, { name, value, order })
        if (res.errors) errors = errors.concat(res.errors)
    }

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.UPDATING_FIELD, value: true })

    const res = yield db('put', `dokstor-bo-api/api/settings/fields/${fieldId}/values/sort`, {values})
    if (res.errors) errors = errors.concat(res.errors)

    if (!isEmpty(errors)) yield call(handlerError, errors)
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.UPDATING_FIELD })
}

function* runRequestGetField({ fieldId, categoryId, partyType }) {
    if (!fieldId) return yield put({ type: SET_CURRENT_FIELD, currentField: {} })

    const fieldsSettings = yield select(getFieldsSettings)

    const currentField = fieldsSettings?.[partyType]?.[categoryId]?.fields?.find(f => f.identifier === fieldId) || {}

    currentField.format = currentField.format || (currentField.valueType === 'DATE' ? 'DATE' : null)
    const isCurrency = currentField.valueType === 'SPECIAL' && currentField.format === 'CURRENCY'

    if (TYPES_WITH_DEFAULT.includes(currentField.valueType) || isCurrency) {
        const res = yield db('get', 'dokstor-bo-api/api/settings/fields/' + fieldId)
        currentField.list = res?.field?.list
    }

    yield put({ type: SET_CURRENT_FIELD, currentField })
}

function* runRequestSortFieldsList({ fields }) {

    const sortedList = {
        "fields": fields.map((field, index) => ({ identifier: field.identifier, order: index + 1 }))
    }
    
    const response = yield db('put', 'dokstor-bo-api/api/settings/fields/sort', sortedList)

    if (response.errors) return yield call(handlerError, response.errors)

    yield call(runRequestGetFieldsSettings)
    yield call(runRequestGetFields)
}

function* runRequestSortDocumentsList({ docs }) {

    const sortedList = {
        "documentTypes": docs.map((doc, index) => ({ identifier: doc.identifier, order: index + 1 }))
    }
    
    const response = yield db('put', 'dokstor-bo-api/api/settings/document-types/sort', sortedList)

    if (response.errors) return yield call(handlerError, response.errors)

    yield call(runRequestGetAllKYCconfig)
    yield call(runRequestGetKYCTypes)
}

function* runRequestSortFieldsCategories({ categories, contextType }) {

    const sortedCats = {
        "categories": categories.map((cat, i) => ({ identifier: cat.identifier, order: i + 1 }))
    }
    
    const response = yield db_thunk('put', 'dokstor-bo-api/api/settings/fields/categories/sort', sortedCats )


    if (response.errors) return yield call(handlerError, response.errors)

    yield call(runRequestFieldCategories, { context: contextType})
    yield call(runRequestGetFields)
}

function* runRequestSortDocumentsCategories({ cats, entityType }) {

    const sortedList = {
        "categories": cats.map((cat, index) => ({ identifier: cat.identifier, order: index + 1 }))
    }

    const response = yield db('put', 'dokstor-bo-api/api/settings/document-categories/sort', sortedList)

    if (response.errors) return yield call(handlerError, response.errors)

    yield call(runRequestGetKYCconfig, { entityType })
    yield call(runRequestGetKYCTypes)
}

function* runRequestWatchList({listId, currentFilter, entityType='PERSON'}) {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_WATCHLIST, value: true })

    const listConfig = yield select(listConfigs, listId)
    const queryParam = getQueryParamFromListConfig(listConfig, currentFilter)
    let response
    switch(entityType){
        default:
            response = yield db('get', `dokstor-bo-api/api/settings/watchlists/persons?${queryParam}` )
            if (response.errors) return yield call(handlerError, response.errors)
            yield put({ type: SET_WATCH_LISTS, watchList: response.items, count: response.count })
            break
        case 'COMPANY':
            response = yield db('get', `dokstor-bo-api/api/settings/watchlists/companies?${queryParam}`)
            if (response.errors) return yield call(handlerError, response.errors)
            yield put({ type: SET_COMPANY_WATCH_LISTS, watchList: response.items, count: response.count })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_WATCHLIST })
}

function* runRequestUpsertWatchListMember({ member }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_WATCHLIST, value: true })

    const { identifier, partyType, categoryIds, name, reason, ...values } = member
    let response

    Object.keys(values).forEach(k => {
        values[k] = values[k]?.value || values[k]
    })
    
    const entityType = partyType.value || partyType 
    const partyPath = entityType === 'PERSON' ? 'persons' : 'companies'
    const body = { name, reason, categoryIds: categoryIds?.value || categoryIds, values }

    // UPDATE
    if (identifier) {
        response = yield db('put', `dokstor-bo-api/api/settings/watchlists/${partyPath}/${identifier}`, { ...body })
    }
    // INSERT
    else {
        response = yield db('post', `dokstor-bo-api/api/settings/watchlists/${partyPath}`, {...body})
    }
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    }
    // SUCCESS
    else {
        yield put({ type: SET_DOKSTOR_STATUS, status: 'WATCHLIST_MEMBER_UPSERTED' })
        const translationFn = yield select(getTranslationFn)
        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn(identifier ? 'successfullyUpdated' : 'successfullyInserted') }
        })
    }

    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'WATCHLIST_MEMBER_UPSERTED' })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_WATCHLIST })
    yield put({ type: GET_WATCH_LIST, listId: entityType === 'PERSON' ? 'dokstorWatchList' : 'companyWatchlist', entityType })
}

function* runDeleteWatchListMember({ itemIds, entityType }) {
    
    const partyPath = entityType === 'PERSON' ? 'persons' : 'companies'
    const response = yield db('post', `dokstor-bo-api/api/settings/watchlists/${partyPath}/delete`, {itemIds} )

    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'WATCHLIST_MEMBER_UPSERTED' })
    if (response.errors) return yield call(handlerError, response.errors)
    yield put({ type: GET_WATCH_LIST, listId: entityType === 'PERSON' ? 'dokstorWatchList' : 'companyWatchlist', entityType })
}

function* runDeleteWatchList() {

    const response = yield db('delete', `dokstor-bo-api/api/settings/watchlists/persons`)

    if (response.errors) return yield call(handlerError, response.errors)
    yield put({ type: GET_WATCH_LIST, listId: 'dokstorWatchList' })
}

function* runDeleteCompanyWatchList() {

    const response = yield db('delete', `dokstor-bo-api/api/settings/watchlists/companies`)

    if (response.errors) return yield call(handlerError, response.errors)
    yield put({ type: GET_WATCH_LIST, listId: 'companyWatchList', entityType: 'COMPANY'})
}

function* runGetApprovalSettings() {
    const res = yield db('get', 'dokstor-bo-api/api/settings/approvals')

    if (res.errors) yield call(handlerError, res.errors)
    else {
        yield put({ type: SET_APPROVAL_SETTINGS, persons: res.persons, companies: res.companies })
    }
}

function* runRequestWatchListCategories() {
    yield put({ type: CLEAR_DOKSTOR_STATUS })
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_WATCHLIST_CATEGORIES, value: true })

    const personCatsRes = yield db('get', `dokstor-bo-api/api/settings/watchlists/persons/categories`)
    const companyCatsRes = yield db('get', `dokstor-bo-api/api/settings/watchlists/companies/categories`)

    if (personCatsRes.errors) return yield call(handlerError, personCatsRes.errors)
    if (companyCatsRes.errors) return yield call(handlerError, companyCatsRes.errors)

    yield put({
        type: SET_WATCH_LIST_CATEGORIES, categories: {
            persons: personCatsRes.categories?.map(c => ({ label: c.name, value: c.identifier, outcome: c.outcome })),
            companies: companyCatsRes.categories?.map(c => ({ label: c.name, value: c.identifier, outcome: c.outcome }))
        }
    })

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_WATCHLIST_CATEGORIES })
}

function* runRequestUpsertWatchListCategory({ values }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DOKSTOR_WATCHLIST_CATEGORIES, value: true })

    const { identifier, name, outcome, entityType } = values
    let response


    const partyType = entityType.value || entityType
    const partyPath = partyType === 'PERSON' ? 'persons' : 'companies'

    // UPDATE
    if (identifier) {
        response = yield db('put', `dokstor-bo-api/api/settings/watchlists/${partyPath}/categories/${identifier}`, { name, outcome: outcome.value || outcome })
    }
    // INSERT
    else {
        response = yield db('post', `dokstor-bo-api/api/settings/watchlists/${partyPath}/categories`, { name, outcome: outcome.value || outcome })
    }
    // ERROR
    if (response === undefined || response.hasOwnProperty('errorCode') || response.hasOwnProperty('errors')) {
        yield handlerError(response)
    }
    // SUCCESS
    else {
        yield put({ type: SET_DOKSTOR_STATUS, status: 'WATCHLIST_CATEGORY_UPSERTED' })
        const translationFn = yield select(getTranslationFn)
        yield put({
            type: OPEN_NOTIFICATION, data:
                { type: 'success', message: translationFn(identifier ? 'successfullyUpdated' : 'successfullyInserted') }
        })
    }
    //TODO: Too many statuses
    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'WATCHLIST_CATEGORY_UPSERTED' })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DOKSTOR_WATCHLIST_CATEGORIES })
    yield put({ type: GET_WATCH_LIST_CATEGORIES })
}

function* runDeleteWatchListCategory({ categoryIds, partyType }) {

    const partyPath = partyType === 'PERSON' ? 'persons' : 'companies'
    const response = yield db('post', `dokstor-bo-api/api/settings/watchlists/${partyPath}/categories/delete`, { categoryIds })

    yield put({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'WATCHLIST_CATEGORY_UPSERTED' })
    if (response.errors) return yield call(handlerError, response.errors)
    yield put({ type: GET_WATCH_LIST_CATEGORIES })
}

function* runSetApprovalSettings({ entityType, settings }) {
    const res = yield db('put', 'dokstor-bo-api/api/settings/approvals/' + entityType, JSON.parse(settings))

    if (res.errors) yield call(handlerError, res.errors)
    else {
        const set = yield db('get', 'dokstor-bo-api/api/settings/approvals/' + entityType)

        if (settings.errors) yield call(handlerError, res.errors)
        else {
            yield put({ type: SET_APPROVAL_SETTINGS, [entityType]: set })
        }
    }
}

function* runRequestAddDocCategory({ category: { context, name, dependencies } }) {
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: true })

    const body = {
        name,
        partyType: context,
        dependencies: dependencies && JSON.stringify(dependencies)
    }

    const endpoint = 'dokstor-bo-api/api/settings/document-categories'

    const response = yield db('post', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetKYCconfig, { entityType: context })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_CATEGORY_BAR, value: true })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestUpdateDocCategory({ category: { context, name, identifier, dependencies } }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_CATEGORY, value: identifier })

    const body = {
        name,
        partyType: context,
        dependencies: dependencies && JSON.stringify(dependencies)
    }

    const endpoint = `dokstor-bo-api/api/settings/document-categories/${identifier}`
    const response = yield db('put', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetKYCconfig, { entityType: context })
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_CATEGORY_BAR, value: true })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_CATEGORY })
}

function* runRequestDeleteCustomDocCategory({ category }) {
    const { identifier, entityType } = category

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.DELETING_CATEGORY, value: identifier })

    const response = yield db('delete', `dokstor-bo-api/api/settings/document-categories/${identifier}`)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetKYCconfig, { entityType })
    }

    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.DELETING_CATEGORY })
}

function* runRequestUpdateDocType({ doc: { identifier, name, filesNumber, maxSize, extensions, dependencies, description } }) {

    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SAVING_DOC_TYPE, value: identifier })

    const body = {
        name,
        filesNumber: filesNumber && Number(filesNumber),
        maxSize: maxSize * 1000000,
        extensions,
        description: description,
        dependencies: dependencies && JSON.stringify(dependencies),
        options: dependencies && JSON.stringify(dependencies),
    }

    const endpoint = `dokstor-bo-api/api/settings/document-types/${identifier}`
    const response = yield db('put', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetAllKYCconfig)
        yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.CLOSING_DOC_TYPE_BAR, value: true })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.CLOSING_DOC_TYPE_BAR })
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SAVING_DOC_TYPE })
}


function* runRequestGetSignatureDocumentSettings(){
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SIGNATURE_DOCUMENTS, value: true })

    const endpoint = `dokstor-bo-api/api/settings/signature-document-setting`
    const response = yield db('get', endpoint)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        const settings = response.signatureDocumentSetting || []
        const getProperty = (key) => settings.find(p => p.key === key)?.value
        const _settings = {
            AUTHENTICATION_METHOD: getProperty("AUTHENTICATION_METHOD"),
            PHASE: JSON.parse(getProperty("PHASE") || "[]"),
            EXPIRATION_AFTER_DAYS: getProperty("EXPIRATION_AFTER_DAYS"),
            REMINDER_FREQUENCY: getProperty("REMINDER_FREQUENCY")
        }
        yield put({ type: SET_SIGNATURE_DOCUMENT_SETTINGS, settings: _settings })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SIGNATURE_DOCUMENTS })
}

function* runRequestUpdateSignatureDocumentSettings({settings}){
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SIGNATURE_DOCUMENTS, value: true })

    const body = {
        properties: {
            AUTHENTICATION_METHOD: settings.AUTHENTICATION_METHOD.toString(),
            PHASE: JSON.stringify(settings.PHASE),
            EXPIRATION_AFTER_DAYS: settings.EXPIRATION_AFTER_DAYS,
            REMINDER_FREQUENCY: settings.REMINDER_FREQUENCY
        }
    }
    const endpoint = `dokstor-bo-api/api/settings/signature-document-setting`
    const response = yield db('put', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetSignatureDocumentSettings)
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SIGNATURE_DOCUMENTS })
}

function* runRequestAddSignatureDocuments({document, onSuccess}){
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SIGNATURE_DOCUMENTS, value: true })

    const body = {
        name: document.name,
        title: document.title,
        description: document.description, 
        content: document.content,
        enabledIn: document.enabledIn
    }
    const endpoint = `dokstor-bo-api/api/settings/signature-document-type`
    const response = yield db('post', endpoint, body)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetSignatureDocuments)
        onSuccess()
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SIGNATURE_DOCUMENTS })
}

function* runRequestGetSignatureDocuments(){
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SIGNATURE_DOCUMENTS, value: true })

    const endpoint = `dokstor-bo-api/api/settings/signature-document-type`
    const response = yield db('get', endpoint)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield put({ type: SET_SIGNATURE_DOCUMENTS, documents: response.signatureDocumentType || [] })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SIGNATURE_DOCUMENTS })
}

function* runRequestUpdateSignatureDocuments({id, document, onSuccess}){
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SIGNATURE_DOCUMENTS, value: true })

    const { name, ...data } = document
    const endpoint = `dokstor-bo-api/api/settings/signature-document-type/${id}`
    const response = yield db('put', endpoint, data)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetSignatureDocuments)
        onSuccess()
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SIGNATURE_DOCUMENTS })
}

function* runRequestDeleteSignatureDocuments({docId}){
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.SIGNATURE_DOCUMENTS, value: true })

    const endpoint = `dokstor-bo-api/api/settings/signature-document-type/${docId}`
    const response = yield db('delete', endpoint)

    if (response.errors) {
        yield call(handlerError, response.errors)
    } else {
        yield call(runRequestGetSignatureDocuments)
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.SIGNATURE_DOCUMENTS })
}


function* runRequestUpdateBase64FileOptions({fieldId, content}) {
    yield put({ type: SET_API_STATUS, action: 'UPDATE', context: contextTypes.UPDATING_FIELD, value: true })

    const response = yield db('post', `dokstor-bo-api/api/settings/fields/${fieldId}/bulk/values`, { values: content })

    if (response.errors) {
        const translationFn = yield select(getTranslationFn)
        const errorMessage = response?.errors?.[0].message || translationFn('genericErrorMessage')
        yield put({ type: OPEN_NOTIFICATION, data: { type: 'error', message: errorMessage } })
    } else {
        const currentField = yield select(getCurrentField)
        const partyType = currentField?.partyType
        const categoryId = currentField?.category?.identifier
        yield call(runRequestGetField, { fieldId, categoryId, partyType })
    }
    yield put({ type: SET_API_STATUS, action: 'CLEAR', context: contextTypes.UPDATING_FIELD })    
}