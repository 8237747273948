import heic2any from "heic2any";
import { convertBlobToBase64 } from "heliocor-ui";

async function convertHeicHeifToJPEG(dataUrl) {
    return fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob, toType:"image/jpeg", quality: 0.1}))
        .then((conversionResult) => URL.createObjectURL(conversionResult))
        .then((url) => convertBlobToBase64(url))
        .catch((e) => {
            console.error('Could not convert file from HEIC to JPEG', e);
            return ''
        });
}

export { convertHeicHeifToJPEG }