import React from 'react'
import { withTranslation } from 'react-i18next';
import { ErrorParagraph, Paragraph, TitleH1 } from 'heliocor-ui'
import { ButtonStyled, PasswordTextFieldStyled } from '../Login.styles'

const ResetPwd = ({ handleInputChange, loginError, resetPwd, goToLogin, error, passwordExpired, goToLastPage, filled, t }) =>
    <React.Fragment>
        <TitleH1 value={t('reset.change')} margin='40px 0 4px 0' />
        <Paragraph value={t('reset.enter')} margin='0' />
        <Paragraph value={t('oldPass')} margin='16px 0 0 0' />
        <PasswordTextFieldStyled
            data-cy='old-password-input'
            onChange={handleInputChange('oldpwd')}
            style={{ width: '100%' }}
            type="password"
        />
        <Paragraph value={t('newPass')} margin='16px 0 0 0' />
        <PasswordTextFieldStyled
            data-cy='new-password-input'
            onChange={handleInputChange('newpwd1')}
            style={{ width: '100%' }}
            type="password"
        />
        <Paragraph value={t('confirmPass')} margin='16px 0 0 0' />
        <PasswordTextFieldStyled
            data-cy='confirm-password-input'
            onChange={handleInputChange('newpwd2')}
            style={{ width: '100%' }}
            type="password"
        />
        <ErrorParagraph error={error || loginError} value={error || loginError} />
        <React.Fragment>
            <ButtonStyled
                data-cy='change-password-button'
                color='primary'
                margin='30px 0 0 0'
                onClick={resetPwd}
                variant='contained'
                disabled={error || !filled}
                value={t("button.reset")}
            />
            {!passwordExpired &&
                <Paragraph
                    color='main'
                    cursor='pointer'
                    onClick={goToLastPage}
                    value={t('login.homeLink')}
                />}
            <Paragraph
                color='main'
                cursor='pointer'
                onClick={goToLogin}
                value={t('login.loginAsOther')}
            />
        </React.Fragment>
    </React.Fragment>

export default withTranslation()(ResetPwd)