

import React from "react"
import ContentLoader from "react-content-loader"

export const PDFViewerLoader = (props) => {
    return <ContentLoader 
        speed={2}
        width={452}
        height={414}
        viewBox="0 0 452 414"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="3" y="364" rx="2" ry="2" width="452" height="45" /> 
        <rect x="2" y="2" rx="2" ry="2" width="452" height="350" />
    </ContentLoader>
}