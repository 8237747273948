import Drawer     from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';
import React      from 'react';

const styles = {
    paperAnchorRight:{
        '@media (min-width: 512px)': {
            width:'512px !important'
        },
    }
}
function RightBar(props){
    const {
        rightBarOpen,
        rightBarOnClose,
        component,
        classes,
        half
    } = props

    return (
        <Drawer
            open={rightBarOpen}
            anchor='right'
            onClose={rightBarOnClose}
            classes={half
                ? {paperAnchorRight:classes.paperAnchorRight}
                : {}
            }
        >
            {component}
        </Drawer>
    )
}

export default withStyles(styles)(RightBar)