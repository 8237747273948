import React from 'react'
import { useTranslation } from 'react-i18next';

import { BoxData, BoxTitle, ColoredBox, FlexContainerColorBox } from './ColorBox.styles'

const ColorBox = ({ statuses, data, details, isButton, filterButton, showTotalText = false, fixedHeight = false, translate = true }) => {
    const { t } = useTranslation()
    return (
        <FlexContainerColorBox column style={{height: fixedHeight ? fixedHeight : 'auto'}}>
            {statuses.map((status, index) =>
                <ColoredBox
                    key={index}
                    data-cy={`box-${status}`}
                    length={statuses.length}
                    color={details[status].color}
                    bgColor={details[status].bg}
                    onClick={isButton ? () => filterButton(status) : null}
                    isButton={isButton}
                >
                    <BoxData>
                        {data[status] || 0}
                    </BoxData>
                    <BoxTitle>
                        {showTotalText && t('total') + ' '}
                        {translate ? t(`statusCases.${details[status].text}`) : details[status].text}
                    </BoxTitle>
                </ColoredBox>
            )}
        </FlexContainerColorBox>
    )
}

export default ColorBox