import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { isNullOrUndefined, isOdd } from '../../../config/helpers/helpers'
import {
    UPDATE_LIST_FILTERS, UPDATE_LIST_PAGINATION, UPDATE_LIST_SORTING
} from '../store/actionTypes'
import AdvancedSearch from './AdvancedSearch/AdvancedSearch'
import PaginationActions from './PaginationActions'
import {
    CircularProgressStyled,
    TableCellDateHeader,
    TableCellStyled,
    TableCellWidth,
    TableFooterStyled,
    TableOverflow,
    TableRowDateHeader,
    TableRowId,
    TableRowNoResults
} from './Table.styles'
import TableHead from './TableHead'
import ToolBar from './ToolBar'
import isEmpty from 'lodash/isEmpty'
import { dateConverter } from '../../../config/helpers'
import { TitleH4 } from "heliocor-ui"
import { POLLING_INTERVAL } from '../../../config/commonConfig'


const mapStateToProps = (store, props) => ({
    listConfig: store.common.lists[props.listId],
})

const mapDispatchToProps = (dispatch) => ({
    updateSorting: (listId, sorting) => dispatch({ type: UPDATE_LIST_SORTING, listId, sorting }),
    updatePagination: (listId, pagination) => dispatch({ type: UPDATE_LIST_PAGINATION, listId, pagination }),
    updateFilters: (listId, filters) => dispatch({ type: UPDATE_LIST_FILTERS, listId, filters }),
})

class MainTable extends React.Component {

    constructor(props) {
        super(props)
        this.tableDiv = React.createRef()
        this.pollingId = null
        this.polling = false
        this.newElements = []
    }

    state = {
        selected: [],
        width: null,
        currentFilter: {},
        filters: [],
        loading: false
    }

    componentDidMount() {
        if (!this.props.listConfig && this.props.listId) {
            this.props.updatePagination(this.props.listId, { currentPage: 0, rowsPerPage: this.props.rowsPerPage || 10 })
        }
        this.setState({ filters: this.filtersGetFilters() })
        this.updateWindowDimensions()
        if (this.props.groupByKey) {
            this.props.data && this.props.data.sort(this.sortByKey)
        }
        this.props.poll && this.startPolling()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    shouldComponentUpdate(nextProps) {
        if (isEqual(this.props.data, nextProps.data) && nextProps.loading) return false
        if (!this.polling) return true
        return !(this.props.apiStatus && !nextProps.apiStatus)
    }

    componentDidUpdate(prevProps, prevState) {
        const { groupByTimestamp, groupByKey, data, forceCleanChecks, count, columns } = this.props
        this.newElements = []

        if (prevProps.groupByTimestamp !== groupByTimestamp) {
            data && data.sort(this.sortByTimestamp)
        }
        if (prevProps.groupByKey !== groupByKey) {
            data && data.sort(this.sortByKey)
        }
        if (prevProps.count !== count) {
            this.selectionClearChecks()
        }
        if (forceCleanChecks && prevProps.forceCleanChecks !== forceCleanChecks) {
            this.selectionClearChecks()
        }
        if(!isEqual(prevProps.columns, columns)) {
            this.setState({ filters: this.filtersGetFilters() })
        }
        if (!isEqual(prevProps.data, data)) {
            groupByKey && data && data.sort(this.sortByKey)
            groupByTimestamp && data && data.sort(this.sortByTimestamp)
            this.polling && this.getNewElements(prevProps.data)
        }
        if (prevState.loading) this.setState({ loading: false })
    }

    componentWillUnmount() {
        this.props.poll && this.stopPolling()
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    getNewElements = prevData => {
        this.newElements = this.props.data
            .filter(row => !prevData.find(_row => _row.identifier === row.identifier))
            .map(el => el.identifier)
    }

    startPolling = () => {
        this.pollingId = setInterval(() => {
            this.polling = true
            this.props.onNewDataRequest(this.props.listId, this.state.currentFilter)
        }, POLLING_INTERVAL)
    }

    stopPolling = () => clearInterval(this.pollingId)

    filtersGetFilters = () => {
        const { columns } = this.props
        return columns.reduce((filters, column) => {
            if (column.filterRules) {
                const filter = { ...column.filterRules, key: column.key }
                filters.push(filter)
            }
            return filters
        }, [])
    }

    filtersNewRequest = (currentFilter) => {
        const { onNewDataRequest, listId, updatePagination, listConfig } = this.props
        this.setState({ currentFilter: currentFilter })
        updatePagination(listId, { currentPage: 0, rowsPerPage: listConfig.pagination.rowsPerPage })
        this.polling = false
        currentFilter && onNewDataRequest(listId, currentFilter)
        this.setState({ loading: true })
    }

    filtersFiltersChange = (filter, refresh = true) => {
        const { listId, updateFilters, updatePagination, onNewDataRequest, listConfig } = this.props
        updatePagination(listId, { currentPage: 0, rowsPerPage: listConfig.pagination.rowsPerPage })
        updateFilters(listId, { currentFilters: filter })
        this.setState({ currentFilter: filter })
        if (refresh) {
            this.polling = false
            onNewDataRequest(listId, {})
            this.setState({ loading: true })
        }
    }

    paginationPageChangeHandler = (event, page) => {
        const { listConfig, listId, updatePagination, onNewDataRequest } = this.props
        const { currentFilter } = this.state
        updatePagination(listId, { rowsPerPage: listConfig.pagination.rowsPerPage, currentPage: page })
        this.polling = false
        onNewDataRequest(listId, currentFilter)
        this.setState({ loading: true })
    }

    paginationRowsPerPageHandler = event => {
        const { listConfig, listId, updatePagination, onNewDataRequest, count } = this.props
        const { currentFilter } = this.state
        const defaultLastPage = Math.ceil(count / event.target.value) - 1
        updatePagination(listId, { rowsPerPage: event.target.value, currentPage: listConfig.pagination.currentPage > defaultLastPage ? defaultLastPage : listConfig.pagination.currentPage })
        this.polling = false
        onNewDataRequest(listId, currentFilter)
        this.setState({ loading: true })
    }

    selectionCheckHandler = (id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        this.setState({ selected: newSelected }, this.selectionOnSelectCallBack)
    }

    selectionClearChecks = () => this.setState({ selected: [] })

    selectionIsSelected = id => this.state.selected.includes(id)

    selectionOnSelectCallBack = () => this.props.onSelect && this.props.onSelect(this.state.selected)

    selectionSelectAllHandler = event => {
        let selected = []
        if (event.target.checked) {
            this.props.data.map(n => {
                if (n.disabledSelection === false || n.disabledSelection === undefined) {
                    selected.push(n.identifier)
                }
                return n
            })
        } else {
            selected = []
        }
        this.setState({ selected }, this.selectionOnSelectCallBack)
    }

    sortByTimestamp = (a, b) => new Date(b.timestamp) - new Date(a.timestamp)

    sortByKey = (a, b) => this.props.sortingArray.indexOf(a[this.props.groupByKey]) - this.props.sortingArray.indexOf(b[this.props.groupByKey]) || (this.props.groupSort && a[this.props.groupSort] && a[this.props.groupSort].localeCompare(b[this.props.groupSort]))

    sortingHandler = (sortingDirection, sortingColumn) => {
        const { listId, updateSorting, updatePagination, onNewDataRequest, listConfig } = this.props
        const { currentFilter } = this.state
        updatePagination(listId, { currentPage: 0, rowsPerPage: listConfig.pagination.rowsPerPage })
        updateSorting(listId, { sortingColumn: sortingColumn, sortingDirection: sortingDirection })
        this.polling = false
        onNewDataRequest(listId, currentFilter)
        this.setState({ loading: true })
    }

    updateWindowDimensions = () => this.setState({ width: this.tableDiv.current.offsetWidth })

    loadingRow = (
        <TableBody>
            <TableRowNoResults>
                <TableCellStyled
                    colSpan={this.props.columns.length + (this.props.selectable ? 1 : 0)}
                    shouldAdjustHeight={this.props.shouldAdjustHeight}
                    rowsPerPage={this.props.listConfig && this.props.listConfig.pagination.rowsPerPage}
                >
                    <CircularProgressStyled thickness={3} />
                </TableCellStyled>
            </TableRowNoResults>
        </TableBody>
    )

    getMaxWidthValue = maxWidth => {
        switch (typeof maxWidth) {
            case 'string':
                const regex = /([0-9]*)%/.exec(maxWidth)
                if (regex && regex.length > 1 && !Number.isNaN(Number.parseInt(regex[1]))) {
                    return Number.parseInt(regex[1]) * 0.01 * this.state.width
                } else {
                    console.error('wrong list column config')
                    return 0
                }
            case 'number':
                return maxWidth
            case 'undefined':
            default:
                return 0
        }
    }

    render() {
        let prevDate, prevKey
        const { apiStatus, buttonForSelected, columns, count = 0, data = [], titleTranslationId,
            groupByTimestamp, hideTableFooter, listConfig, onCellClick, selectable, selectedId,
            title, toolBarWidget, styles = {}, t, groupByKey, disableSelectAll, firstColumnPadding = false, disableClick = false } = this.props
        const { width, selected, filters, loading } = this.state
        const rowCount = data.length
        const toolBarRequired = selectable || title || toolBarWidget
        const colSpan = columns.length + (selectable ? 1 : 0)
        const maxNumToSelect = data.filter(obj => obj.disabledSelection !== true).length || 0

        return (
            <TableOverflow styler={styles.TableOverFlow} ref={this.tableDiv}>
                {titleTranslationId &&
                    <div style={{ padding: "12px" }}>
                        <TitleH4 fontWeight={400} value={t(titleTranslationId)} />
                    </div>
                }
                {filters && filters.length > 0 &&
                    <AdvancedSearch
                        availableFilters={filters}
                        onNewFilterRequest={this.filtersNewRequest}
                        onFiltersChange={this.filtersFiltersChange}
                        filtersConfig={listConfig && listConfig.filters}
                        width={width}
                        t={t}
                    />
                }
                {toolBarRequired &&
                    <ToolBar
                        numSelected={selected.length}
                        title={title}
                        buttonForSelected={buttonForSelected}
                        key={'toolbar'}
                        toolBarWidget={toolBarWidget}
                        style={{ marginRigth: 5 }}
                    />
                }
                <Table>
                    <TableHead
                        columns={columns}
                        firstColumnPadding={firstColumnPadding}
                        handleSort={this.sortingHandler}
                        numSelected={selected.length}
                        onSelectAllClick={this.selectionSelectAllHandler}
                        order={listConfig && listConfig.sorting && listConfig.sorting.sortingDirection}
                        orderBy={listConfig && listConfig.sorting && listConfig.sorting.sortingColumn}
                        rowCount={rowCount}
                        selectable={selectable}
                        disableSelectAll={disableSelectAll}
                        width={width}
                        maxNumToSelect={maxNumToSelect}
                    />
                    {!this.polling && (apiStatus === 'loading' || loading) ? this.loadingRow
                        : <TableBody>
                            {isEmpty(data)
                                ? <TableRowNoResults>
                                    <TableCell style={{ textAlign: 'center' }} colSpan={selectable ? columns.length + 1 : columns.length}>
                                        {t('noResults')}
                                    </TableCell>
                                </TableRowNoResults>
                                : data.map((n, i) => {
                                    let showDateHeader, newDate
                                    if (groupByTimestamp) {
                                        newDate = dateConverter(n.timestamp)
                                        if (newDate !== prevDate) {
                                            prevDate = newDate
                                            showDateHeader = true
                                        }
                                    }
                                    let showKeyHeader, newKey
                                    if (groupByKey) {
                                        newKey = n[groupByKey]
                                        if (newKey !== prevKey) {
                                            prevKey = newKey
                                            showKeyHeader = true
                                        }
                                    }
                                    return (
                                        <React.Fragment key={i}>
                                            {showDateHeader &&
                                                <TableRowDateHeader>
                                                    <TableCellDateHeader colSpan={colSpan}>
                                                        {newDate}
                                                    </TableCellDateHeader>
                                                </TableRowDateHeader>
                                            }
                                            {showKeyHeader &&
                                                <TableRowDateHeader>
                                                    <TableCellDateHeader colSpan={colSpan}>
                                                        {t(newKey)}
                                                    </TableCellDateHeader>
                                                </TableRowDateHeader>
                                            }
                                            <TableRowId
                                                key={n.identifier}
                                                onClick={() => (onCellClick && !disableClick) ? onCellClick(n) : null}
                                                hover={!isNullOrUndefined(onCellClick)}
                                                onCellClick={onCellClick}
                                                bgColor={n.identifier && (selectedId === n.identifier)}
                                                isNew={this.newElements.includes(n.identifier)}
                                                isOdd={isOdd(i)}
                                                disableClick={disableClick}
                                            >
                                                {selectable &&
                                                    <TableCell>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={this.selectionIsSelected(n.identifier)}
                                                            onClick={event => {
                                                                event.stopPropagation()
                                                                this.selectionCheckHandler(n.identifier)
                                                            }}
                                                            disabled={n.disabledSelection || false}
                                                        />
                                                    </TableCell>
                                                }
                                                {columns.map((col, j) =>
                                                    <TableCellWidth
                                                        key={j}
                                                        colLink={col.link}
                                                        columnNumber={j}
                                                        component="td"
                                                        firstColumnPadding={firstColumnPadding}
                                                        scope="row"
                                                        wrappedCells={col.wrappedCells}
                                                        width={col.width}
                                                        maxWidth={this.getMaxWidthValue(col.maxWidth)}
                                                        overflow={col.overflow}
                                                        align={col.align}
                                                    >
                                                        {col.render ? col.render(n[col.key], n, i) : n[col.key]}
                                                    </TableCellWidth>
                                                )}
                                            </TableRowId>
                                        </React.Fragment>
                                    )
                                })}
                        </TableBody>
                    }
                    {!hideTableFooter &&
                        <TableFooterStyled>
                            <TableRow>
                                <TablePagination
                                    colSpan={colSpan}
                                    count={count}
                                    rowsPerPage={listConfig ? listConfig.pagination.rowsPerPage || 10 : 10}
                                    page={listConfig ? listConfig.pagination.currentPage || 0 : 0}
                                    onPageChange={this.paginationPageChangeHandler}
                                    onRowsPerPageChange={this.paginationRowsPerPageHandler}
                                    ActionsComponent={PaginationActions}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    labelRowsPerPage={t('rowsPerPage')}
                                />
                            </TableRow>
                        </TableFooterStyled>
                    }
                </Table>
            </TableOverflow>
        )
    }
}

const buttonShape = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    style: PropTypes.object
}

const noButtonShape = {
    hide: PropTypes.bool
}

MainTable.propTypes = {
    apiStatus: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.bool
    ]),
    buttonForSelected: PropTypes.oneOfType([
        PropTypes.shape(buttonShape),
        PropTypes.arrayOf(PropTypes.shape(buttonShape)),
        PropTypes.shape(noButtonShape)
    ]),
    columns: PropTypes.array,
    count: PropTypes.number,
    data: PropTypes.array,
    titleTranslationId: PropTypes.string,
    groupByTimestamp: PropTypes.bool,
    groupByKey: PropTypes.string,
    hideTableFooter: PropTypes.bool,
    poll: PropTypes.bool,
    onCellClick: PropTypes.func,
    selectable: PropTypes.bool,
    selectedId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    title: PropTypes.string,
    toolBarWidget: PropTypes.element,
    rowsPerPage: PropTypes.number
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MainTable))