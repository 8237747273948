import React, {useCallback}       from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled                     from 'styled-components';
import {CLEAR_STORE}              from '../../../../store/actionTypes';
import {TopBar}                   from '../../index';
import SideBar                    from '../SideBar/SideBar';

const AnimationContainer = styled.div`
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    margin-left: ${props => props.open ? '210px' : '73px'};
    width: ${props => props.open ? 'calc(100% - 210px)' : 'calc(100% - 73px)'};
    padding-top: 55px;
`

const withNavigation = Component => {

    return props => {

        const name = useSelector(store => store.auth.name);
        const organization = useSelector(store => store.auth.organization);
        const permissions = useSelector(store => store.auth.permissions);
        const sidebar = useSelector(store => store.auth.sidebar);

        const dispatch = useDispatch();
        const clearStore = useCallback(() => dispatch({type: CLEAR_STORE}))

        return (<React.Fragment>
                <TopBar
                    match={props.match}
                    name={name}
                    organization={organization}
                    clearStore={clearStore}
                />
                <SideBar
                    permissions={permissions}
                />
                <AnimationContainer open={sidebar}>
                    <Component {...props} name={name} permissions={permissions} organization={organization} />
                </AnimationContainer>
            </React.Fragment>)
    }
}

export default withNavigation;