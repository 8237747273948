export const GET_CUSTOMER_TYPES = 'GET_CUSTOMER_TYPES'
export const SET_CUSTOMER_TYPES = 'SET_CUSTOMER_TYPES'

export const GET_CARD_TYPES = 'GET_CARD_TYPES'
export const SET_CARD_TYPES = 'SET_CARD_TYPES'

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const GET_CURRENT_CUSTOMER = 'GET_CURRENT_CUSTOMER'
export const GET_CURRENT_CUSTOMER_ACOUNTS = 'GET_CURRENT_CUSTOMER_ACOUNTS'
export const SET_CURRENT_CUSTOMER_ACCOUNTS = 'SET_CURRENT_CUSTOMER_ACCOUNTS'
export const GET_CURRENT_CUSTOMER_CARDS = 'GET_CURRENT_CUSTOMER_CARDS'
export const SET_CURRENT_CUSTOMER_CARDS = 'SET_CURRENT_CUSTOMER_CARDS'
export const GET_CURRENT_CUSTOMER_PAYMENTS = 'GET_CURRENT_CUSTOMER_PAYMENTS'
export const SET_CURRENT_CUSTOMER_PAYMENTS = 'SET_CURRENT_CUSTOMER_PAYMENTS'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const GET_CURRENT_ACCOUNT = 'GET_CURRENT_ACCOUNT'
export const GET_CURRENT_ACCOUNT_MEMBERS = 'GET_CURRENT_ACCOUNT_MEMBERS'
export const SET_CURRENT_ACCOUNT_MEMBERS = 'SET_CURRENT_ACCOUNT_MEMBERS'
export const GET_CURRENT_ACCOUNT_CARDS = 'GET_CURRENT_ACCOUNT_CARDS'
export const SET_CURRENT_ACCOUNT_CARDS = 'SET_CURRENT_ACCOUNT_CARDS'
export const GET_CURRENT_ACCOUNT_PAYMENTS = 'GET_CURRENT_ACCOUNT_PAYMENTS'
export const SET_CURRENT_ACCOUNT_PAYMENTS = 'SET_CURRENT_ACCOUNT_PAYMENTS'

export const GET_PAYMENTS = 'GET_PAYMENTS'
export const SET_PAYMENTS = 'SET_PAYMENTS'
export const GET_CURRENT_PAYMENT = 'GET_CURRENT_PAYMENT'

export const GET_CURRENT_CARD = 'GET_CURRENT_CARD'
export const GET_CURRENT_CARD_CUSTOMER_AND_ACCOUNT = 'GET_CURRENT_CARD_CUSTOMER_AND_ACCOUNT'
export const SET_CURRENT_CARD_CUSTOMER = 'SET_CURRENT_CARD_CUSTOMER'
export const SET_CURRENT_CARD_ACCOUNT = 'SET_CURRENT_CARD_ACCOUNT'
export const GET_CARDS = 'GET_CARDS'
export const SET_CARDS = 'SET_CARDS'

export const SET_CURRENT_REFDATA_ENTITY = 'SET_CURRENT_REFDATA_ENTITY'

export const GET_GENERATOR_KPI = 'GET_GENERATOR_KPI'
export const SET_GENERATOR_KPI = 'SET_GENERATOR_KPI'

export const GET_DATA_GENERATOR_EXECUTIONS = 'GET_DATA_GENERATOR_EXECUTIONS'
export const SET_DATA_GENERATOR_EXECUTIONS = 'SET_DATA_GENERATOR_EXECUTIONS'

export const SET_REF_DATA_STATUS = 'SET_REF_DATA_STATUS'

export const GENERATE_CORE_DATA = 'GENERATE_CORE_DATA'
export const GENERATE_PAYMENTS_DATA = 'GENERATE_PAYMENTS_DATA'

export const GET_CORE_DATA_EXECUTION_DETAILS = 'GET_CORE_DATA_EXECUTION_DETAILS'
export const SET_CORE_DATA_EXECUTION_DETAILS = 'SET_CORE_DATA_EXECUTION_DETAILS'
export const RESET_CORE_DATA_EXECUTION_DETAILS = 'RESET_CORE_DATA_EXECUTION_DETAILS'

export const GET_PAYMENTS_DATA_EXECUTION_DETAILS = 'GET_PAYMENTS_DATA_EXECUTION_DETAILS'
export const SET_PAYMENTS_DATA_EXECUTION_DETAILS = 'SET_PAYMENTS_DATA_EXECUTION_DETAILS'
export const RESET_PAYMENTS_DATA_EXECUTION_DETAILS = 'RESET_PAYMENTS_DATA_EXECUTION_DETAILS'

