import React from 'react'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'
import { MediaViewerActions } from '../MediaViewer.actions'
import { VIEWER_SIZES } from '../MediaViewer'
import { PDFViewerLoader } from '../loaders/PDFViewer.loader'

// This line is added to use the external worker defined on post install. Otherwise, without worker, the pdf viewer
// wouldn't work.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFViewer = ({file, size, height, config, onConfigChange}) => {
    const [scale, setScale] = React.useState(1)
    const [numPages, setNumPages] = React.useState(null)
    const [position, setPosition] = React.useState({ x: 0, y: 0 })

    const rotation = config?.rotation || 0
    const pageNumber = config?.page || 1

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)
    const onDragStop = (_e, data) => setPosition({ x: data.x, y: data.y })
    
    const onZoomIn = () => setScale(s => s * 1.3)
    const onZoomOut = () => setScale(s => s / 1.3)

    const onNext = () => onConfigChange({page: pageNumber + 1})
    const onPrevious = () => onConfigChange({page: pageNumber - 1})

    const onRotateLeft = () => onConfigChange({rotation: rotation - 90})
    const onRotateRight = () => onConfigChange({rotation: rotation + 90})

    //We get the viewport height minus other elements height
    const PDFHeight = (window.innerHeight * 0.9  || height - 216)

    //We calculate the dimension of the Viewer depending of Bar size
    const dimension = size === VIEWER_SIZES.MINI 
        ? { width: 452 * scale } 
        : { height: PDFHeight * scale }

    return (
        <>
            <Wrapper size={size} height={height}>
                <Document
                    file={file}
                    loading={<PDFViewerLoader/>}
                    noData={<PDFViewerLoader/>}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Draggable position={position} onStop={onDragStop}>
                        <PageWrapper>
                            <Page
                                scale={scale}
                                rotate={rotation}
                                pageNumber={pageNumber}
                                {...dimension} 
                            />
                        </PageWrapper>
                    </Draggable>
                </Document>
            </Wrapper>

            <MediaViewerActions.Wrapper>
                <MediaViewerActions.RotateLeft onClick={onRotateLeft} />
                <MediaViewerActions.RotateRight onClick={onRotateRight} />
                <MediaViewerActions.ZoomIn onClick={onZoomIn} />
                <MediaViewerActions.ZoomOut onClick={onZoomOut} />
                <MediaViewerActions.Previous onClick={onPrevious} disabled={pageNumber === 1} />
                <MediaViewerActions.Next onClick={onNext} disabled={pageNumber === numPages} />
            </MediaViewerActions.Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    overflow: hidden;
    background-color:${props => props.theme.colors.grey500};
    margin: ${props => props.size === VIEWER_SIZES.FULL ? 0 : '30px'};
    width: ${props => props.size === VIEWER_SIZES.MINI ? '452px' : 'auto'};
    height: ${props => props.size === VIEWER_SIZES.MINI ? `${props.height - 500}px` : `calc(100vh - ${45 + 55}px)`};
`

const PageWrapper = styled.div`
    transform: translateX(50px);
`

export default PDFViewer