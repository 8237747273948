import React from 'react'
import GoogleMapReact from 'google-map-react';
import { FlexContainer } from 'heliocor-ui'
import { useTranslation } from 'react-i18next';
import CollapsableBarSection from './CollapsableBarSection'
import CopyIcon from '@material-ui/icons/FilterNone'
import Tooltip from "@material-ui/core/Tooltip"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { IconStyled } from '../dokstor/pages/Settings/styles/common';
import { Input } from '@material-ui/core';

const DEFAULT_CONFIG = {
    zoom: 11,
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
}

const extractLocationFromDocumentProperties = (properties = []) => {
    return {
        lat: Number(properties.find(p => p.name === 'LATITUDE')?.value),
        lng: Number(properties.find(p => p.name === 'LONGITUDE')?.value),
    }
}

// http://maps.google.com/maps?q=10.99835602,77.01502627&z=15

const MapView = ({
    title = '',
    lat = DEFAULT_CONFIG.center.lat,
    lng = DEFAULT_CONFIG.center.lng,
    zoom = DEFAULT_CONFIG.zoom,
}) => {
    const { t } = useTranslation()
    const [error, setError] = React.useState(false)

    const fallbackLinkUrl = `http://maps.google.com/maps?q=${lat},${lng}&z=${zoom}`

    const renderMarkers = (map, maps) => {
        if(!maps) return
        const position = { lat, lng }
        return new maps.Marker({map, title, position})
    };

    // Google Maps API by default calls a (globally accessible) gm_authFailure function that we have defined
    // in index.html in order to handle the error. The effect below is to observe the error and change the
    // UI reactively to hide/show the map and the fallback link.
    React.useEffect(() => {
        let googleMapsNotLoadedError = false
        Object.defineProperty(window, 'googleMapsNotLoadedError', {
            configurable: true,
            get () { return googleMapsNotLoadedError },
            set(value) {
                googleMapsNotLoadedError = value
                setError(value)
            }
        })
 
        return () => {
            setError(false)
            window.googleMapsNotLoadedError = false
        }
    }, [])

    return (
        <CollapsableBarSection title={t("location")}>
            <FlexContainer padding="20px" column>
                {!error && (
                    <div style={{ height: '50vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyC-5KKz-VsVmFS9iRSErbuxDAAIBnR4-1I' }}
                            defaultCenter={{ lat, lng}}
                            defaultZoom={zoom}
                            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                            />
                    </div>
                )}
                {error && (
                    <FlexContainer column>
                        <FlexContainer justifyContent='space-between' height='32px'>
                            <Input readOnly disabled value={fallbackLinkUrl} />
                            <FlexContainer margin='0 0 5px 0'>
                                <Tooltip title='Copy URL to Clipboard'>
                                    <CopyToClipboard text={fallbackLinkUrl}>
                                        <IconStyled aria-label="Copy to Clipboard">
                                            <CopyIcon fontSize="small" />
                                        </IconStyled>
                                    </CopyToClipboard>
                                </Tooltip>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                )}
            </FlexContainer>
        </CollapsableBarSection>
    )
}

export { MapView, extractLocationFromDocumentProperties }