import React    from 'react';
import styled   from 'styled-components'
import Left     from '@material-ui/icons/KeyboardArrowLeft';
import Right    from '@material-ui/icons/KeyboardArrowRight';

const Container = styled.div`
    flex: 1;
    margin-bottom: 5px;
    padding: ${props => props.padding ? 16 : 0}px;
    min-width: 250px;
`

const Count = styled.span`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
    background-color: ${props => props.selected && props.theme.colors.main};
    border-radius: 50%;
    color: ${props => props.selected ? props.theme.colors.white : props.active ? props.theme.colors.grey100 : 'transparent' };
    cursor: ${props => props.active ? 'pointer' : 'default'};
`

const LeftArrowStyled = styled(({disable, ...rest}) => <Left {...rest} />)`
    position:relative;
    top:7px;
    color: ${props => props.theme.colors.grey100};
    cursor: ${props => props.disable ? 'pointer' : 'default'};
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
`

const RightArrowStyled = styled(({disable, ...rest}) => <Right {...rest}/>)`
    position:relative;
    top:7px;
    color:${props => props.theme.colors.grey100};
    cursor:${props => props.disable ? 'pointer' : 'default'};
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
`

export { Container, Count, LeftArrowStyled, RightArrowStyled }