import React from 'react'
import styled from 'styled-components'
import { Paragraph } from 'heliocor-ui'

export const Message = ({ message, time })=>{
    return(
        <Container>
            <Paragraph color='greyBB' value={message} />
            <Paragraph color='greyBB' value={time} />
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    justifyContent:space-between;
    borderRadius:5;
    marginBottom:10;
    fontSize:10;
    padding:10px 10px 0 10px;
    background-color: ${props => props.theme.colors.white};
`
