import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PureSpinner, FlexContainer, removeStringB64 } from 'heliocor-ui'

import { Container, ContentWrapper, Paragraph, StyledIcon } from './DragDrop.styles'
import { convertHeicHeifToJPEG } from '../../../utils/heic-heif'
import { OPEN_NOTIFICATION } from '../../../notifications/actionTypes'
import { SET_DOKSTOR_STATUS } from '../../dokstor/store/actionTypes'

function DragDrop({
    acceptedMimeTypes,
    notMaxFile,
    fileTypes,
    maxSize,
    onLoad,
    content,
    height,
    fontSize,
    clickable,
    fontSizeIcon,
    iconWithText = true
}) {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const onUploadStart = React.useCallback(() => {
        dispatch({ type: SET_DOKSTOR_STATUS, dokstorStatus: 'loading' })
    }, [])
    
    const openErrorNotification = React.useCallback(errorMessage => {
        const data = { type: 'error', message: errorMessage }
        dispatch({ type: OPEN_NOTIFICATION, data })
    }, [])

    const statusUpload = useSelector(store => store.dokstor.dokstorStatus)
    const isLoading = statusUpload && statusUpload === 'loading'


    const handleLoad = async (file, reader, extension) => {
        onUploadStart()
        const fileData = ['HEIF', 'HEIC'].includes(extension)
            ? await convertHeicHeifToJPEG(reader.result)
            : reader.result
        const imageData = removeStringB64(fileData)
        onLoad(imageData, file.name)
    }

    const onDrop = acceptedFiles => {

        if (isLoading) return null

        if (notMaxFile === true || notMaxFile === undefined) {

            const reader = new FileReader()
            const file = acceptedFiles[0]

            reader.onloadend = async () => {
                const extension = file.name.split('.').pop().toUpperCase()

                const extensionIsValid = fileTypes.includes(extension)
                const sizeIsValid = maxSize ? file.size < maxSize : true

                if (!extensionIsValid) return openErrorNotification(t("notSupported"))
                if (!sizeIsValid) return openErrorNotification(t("sizeExceed"))

                return handleLoad(file, reader, extension)

            }

            reader.readAsDataURL(file)
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noClick: isLoading || !clickable,
        accept: acceptedMimeTypes
    })


    return (
        <Container height={height}  {...getRootProps({ isDragActive, content })}>
            <input {...getInputProps()} />
            {content
                ? <ContentWrapper>{content}</ContentWrapper>
                : <FlexContainer alignItems='center' column>
                    {isLoading ?
                        <PureSpinner color='grey500' size='30px' />
                        : <FlexContainer column alignItems='center'>
                            <StyledIcon fontSizeIcon={fontSizeIcon} />
                            {iconWithText && <Paragraph fontSize={fontSize}>{t("button.clickAndDrop")}</Paragraph>}
                        </FlexContainer>}
                    {iconWithText && fileTypes && <StyledP>{t("acceptedFiles")}: {fileTypes.toString().replace(/,/g, ', ')}</StyledP>}
                    {iconWithText && maxSize && <StyledP>{t("maxSize")}: {`${maxSize/1000000} MB`}</StyledP>}
                </FlexContainer>}
        </Container>
    )
}

const StyledP = styled.p`
    max-width: 22rem;
    text-align: center;
    color: ${props => props.theme.colors.warning};
    font-size: 14px;
    margin: 4px 0px;
`

export default DragDrop