
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from "@material-ui/core/IconButton"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import { device } from 'heliocor-ui'

const PrimaryButton = styled(Button)`
&& { 
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};
}
`

const RadioGroupStyled = styled(RadioGroup)`
    width: 100%;
    @media ${device.midSize}{
        width: 20%;
    }
`

const MarginFixes = styled.div`
    margin: ${props => props.margin};
`

const IconStyled = styled(IconButton)`
    && {
        position: relative;
        bottom: 10px;
        width: 58px;
        height: 58px;
    }
`

export { PrimaryButton, IconStyled, MarginFixes, RadioGroupStyled }
