import React                      from 'react';
import styled, {css}              from 'styled-components';
import {device, FlexContainer}    from 'heliocor-ui';

import {AddNewButton, CardDashboard, ModalVideo} from '../index';

const AddNewContainer = styled(CardDashboard)`
    width: 100%;
    height: ${props => props.height};
    @media ${device.midSize}{
        width: ${props => props.fullWidth ? '100%' : props.dense ? '250px' : '400px'};
    }
    @media (min-width: 1200px){
        min-width: ${props => props.dense ? '200px' : '400px'};
        width: ${props => props.fullWidth && '100%'}
    }
    ${props => props.dashboard && workspace}
`

const workspace = css`
    flex: 1 1 20%;
    height: 160px;
`
/**
 * Please pass directly the translation ID
 */
export const AddNewCard = ({ dense=false, disabled = false, onClick, type, titleTranslationId, subTitleTranslationId, videoUrl, fullWidth, height = '160px', padding='16px 0 10px 0', dashboard=false}) => (
    <AddNewContainer column dense={dense} height={height} fullWidth={fullWidth} dashboard={dashboard} justifyContent='center'>
        <FlexContainer style={{padding}}>
            <AddNewButton
                disabled={disabled}
                type={type}
                onClick={onClick}
                titleTranslationId={titleTranslationId}
                subTitleTranslationId={subTitleTranslationId}
            />
        </FlexContainer>
            { videoUrl &&
                <FlexContainer alignItems='flex-end' justifyContent='flex-end' width='100%'>
                    <ModalVideo type={type} url={videoUrl}/>
                </FlexContainer>
            }
    </AddNewContainer>
)
