import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TitleH1, Button, FlexContainer } from 'heliocor-ui'
import CloseIcon from '@material-ui/icons/Close'

const DrawerHeaderDiv = styled.div`
    min-height: 55px;
    border-bottom: ${props => `1px solid ${props.theme.colors.grey}`};
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    background-color:#ffffff;
    height: auto;
    padding: 10px;
`

const ButtonDiv = styled.div`
    margin-left: auto;
    display: flex;
`

const IconClose = styled(CloseIcon)`
    && {    
        colors: ${props => props.theme.colors.black100};
        cursor: pointer;
    }
`

const DrawerHeader = ({ onClose, buttons = [], title, disabled = false, children = null, closeIcon = null }) => (
    <DrawerHeaderDiv>
        <FlexContainer alignItems='center' width='100%'>
            {closeIcon || 
                <IconClose onClick={onClose} data-cy="drawerClose"/>
            }
            <TitleH1 value={title} margin='0px 0 2px 15px' />
            {!disabled &&
                <ButtonDiv>
                    {buttons?.map(b => b.value && !b.noPermisson &&
                        <Button
                            autoFocus={b.autoFocus}
                            data-cy={b.cy || b.id}
                            disabled={b.disabled || b.loading || false}
                            isLoading={b.loading}
                            key={b.labelId || b.value}
                            onClick={b.onClick}
                            value={b.value}
                            type={b.type}
                            style={{ marginLeft: 5 }}
                        />
                    )}
                    {children}
                </ButtonDiv>
            }
        </FlexContainer>
    </DrawerHeaderDiv>
)

DrawerHeader.propTypes = {
    buttons: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string
}

export default DrawerHeader
