export const ObjectFiltering = (object, filterBy) => {
    
    const filteredObject = Object.keys(object)
        .filter(key => filterBy.includes(key))
        .reduce((obj, key) => {
                obj[key] = object[key];
                return obj;
            }, {})

    return filteredObject
}
