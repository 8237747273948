import {routesPermissions} from './routesPermissions'
export class AccessControl {
    static checkAccess(permissions, route) {
        const isPermitted = this.checkPerimssion(permissions, route)
        return {
            isPermitted,
            reason: !isPermitted && 'Unauthorized'
        }
    }

    static checkPerimssion = (permissions, route) => {
        const currentPermissions = routesPermissions(route)
        return permissions[currentPermissions.app]?.some(pr => currentPermissions.permissions.includes(pr))
    }
}