import {
    ADD_DRAWER_ELEMENT,
    MODIFY_DRAWER_ELEMENT, MODIFY_DRAWER_PROPS,
    REMOVE_LAST_DRAWER,
    RESET_CURRENT_PAGE, SET_API_STATUS,
    SET_COMMON_SETTINGS, REMOVE_ALL_DRAWERS,
    UPDATE_LIST_FILTERS,
    UPDATE_LIST_PAGINATION,
    UPDATE_LIST_SORTING,
    SAVE_VIEWER_CONFIG,
    CLEAR_VIEWER_CONFIG,
} from './actionTypes'

const defaultState = {
    lists: {},
    drawerElements: [],
    formatters: {},
    personalisation: {},
    apiStatus: {}
}

const common = (state = defaultState, action) => {
    switch (action.type){
        case SAVE_VIEWER_CONFIG:
            return {
                ...state,
                viewerConfig: {
                    ...state.viewerConfig,
                    ...action
                }
            }
        case CLEAR_VIEWER_CONFIG:
            return { 
                ...state, 
                viewerConfig: {}
            }
        case UPDATE_LIST_PAGINATION:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.listId]: {
                        ...state.lists[action.listId],
                        pagination: action.pagination
                    }
                }
            }
        case UPDATE_LIST_FILTERS:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.listId]: {
                        ...state.lists[action.listId],
                        filters: action.filters
                    }
                }
            }
        case UPDATE_LIST_SORTING:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.listId]: {
                        ...state.lists[action.listId],
                        sorting: action.sorting
                    }
                }
            }
        case RESET_CURRENT_PAGE:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.listId]: {
                        ...state.lists[action.listId],
                        pagination: {
                            ...state.lists[action.listId].pagination,
                            currentPage: 0
                        }
                    }
                }
            }
        case ADD_DRAWER_ELEMENT:
            return { ...state, drawerElements: [...state.drawerElements, action.element] }
        case REMOVE_LAST_DRAWER:
            return {
                ...state,
                drawerElements: state.drawerElements.slice(0, state.drawerElements.length - 1)
            }
        case REMOVE_ALL_DRAWERS:
            return {
                ...state,
                drawerElements: []
            }
        case MODIFY_DRAWER_ELEMENT:
            let modifiedDrawer = {...state.drawerElements[state.drawerElements.length - 1], ...action.element}
            return {
                ...state,
                drawerElements: (state.drawerElements.slice(0, state.drawerElements.length - 1)).concat(modifiedDrawer)
            }
        case MODIFY_DRAWER_PROPS:
            let currentDrawer = {...state.drawerElements[state.drawerElements.length - 1]}
            currentDrawer.props = {...currentDrawer.props, ...action.props}
            return {
                ...state,
                drawerElements: (state.drawerElements.slice(0, state.drawerElements.length - 1)).concat(currentDrawer)
            }
        case SET_COMMON_SETTINGS:
            return {
                ...state,
                formatters: action.formatters || state.formatters,
                personalisation: action.personalisation || state.personalisation,
                datasources: action.datasources || state.datasources,
                validations: action.validations || state.validations
            }

        case SET_API_STATUS:
            switch(action.action){
                case 'CLEAR':
                    const {[action.context]: value, ...apiStatus} = state.apiStatus
                    return {
                        ...state,
                        apiStatus
                    }

                case 'ARRAY_ADD':
                    let arrayAdd = state.apiStatus[action.context] ? [...state.apiStatus[action.context]] : []
                    arrayAdd.push(action.value)
                    return {
                        ...state,
                        apiStatus: {
                            ...state.apiStatus,
                            [action.context]: arrayAdd
                        }
                    }

                case 'ARRAY_REMOVE':
                    let arrayRemove = state.apiStatus[action.context].filter(id=> id !== action.value)
                    if(arrayRemove.length === 0){
                        const {[action.context]: value, ...apiStatus} = state.apiStatus
                        return {
                            ...state,
                            apiStatus
                        }
                    }else{
                        return {
                            ...state,
                            apiStatus: {
                                ...state.apiStatus,
                                [action.context]:arrayRemove
                            }
                        }
                    }

                case 'UPDATE':
                default:
                    return {
                        ...state,
                        apiStatus: {
                            ...state.apiStatus,
                            [action.context]: action.value
                        }
                    }
            }

        default:
            return state
    }
}

export default common