import React from 'react';
import styled from 'styled-components'
import { colors } from "../../config/colors"
import { Dots } from "react-activity"

const Loader = ({ height }) =>

    <Container height={height}>
        <LoaderDiv>
            <Dots
                size={15}
                color={colors.main}
            />
        </LoaderDiv>
    </Container>

const Container = styled.div`
    height: ${props => props.height || '100vh'};
    margin-top: -55px;
`

const LoaderDiv = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`

export default Loader
