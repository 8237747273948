import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import ExpandIcon from '@material-ui/icons/Fullscreen'
import ExpandlessIcon from '@material-ui/icons/FullscreenExit'
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { FlexContainer } from 'heliocor-ui'


import MultiUploader from '../Multiuploader'
import { ToolClicked } from './toolClicked.enum'
import { Translate } from '../Translate'

const defaultConfig = {
    delete: { show: true, disabled: false },
    zoom: { show: true, disabled: false, type: 'zoomIn' },
    upload: { show: true, disabled: false },
    download: { show: true, disabled: false }
}

const ToolBarImage = ({ toolBarConfig = defaultConfig, handleToolbar, docFileTypes, maxFiles, mediaType = 'image' }) => {

    const canUpload = maxFiles !== undefined ? maxFiles : toolBarConfig.upload ? toolBarConfig.upload.disabled : false
    return (
        <FlexContainer>
            {toolBarConfig.zoom?.show && mediaType !== 'video' &&
                (toolBarConfig.zoom.type === "zoomOut"
                    ?
                    <Tooltip title={<Translate id="closeFileViewer" />}>
                        <IconButtonStyled disabled={toolBarConfig.zoom.disabled} onClick={() => handleToolbar(ToolClicked.ZOOMOUT)}>
                            {<ExpandlessIcon color="primary" />}
                        </IconButtonStyled>
                    </Tooltip>
                    :
                    <Tooltip title={<Translate id="expandFileViewer" />}>
                        <IconButtonStyled disabled={toolBarConfig.zoom.disabled} onClick={() => handleToolbar(ToolClicked.ZOOMIN)}>
                            {<ExpandIcon color="primary" />}
                        </IconButtonStyled>
                    </Tooltip>)
            }
            {toolBarConfig.download && toolBarConfig.download.show &&
                <Tooltip title={<Translate id="downloadDoc" />}>
                    <IconButtonStyled disabled={toolBarConfig.download.disabled} onClick={() => handleToolbar(ToolClicked.DOWNLOAD)}>
                        <CloudDownloadIcon color="primary" />
                    </IconButtonStyled>
                </Tooltip>
            }
            {toolBarConfig.upload && toolBarConfig.upload.show &&
                <div>
                    {canUpload
                        ?
                        <MultiUploader
                            fileTypes={docFileTypes}
                            onLoad={(content) => handleToolbar(ToolClicked.UPLOAD, { content })}
                            icon={'plus'}
                            tooltipText={<Translate id="uploadDoc" />}
                        />
                        :
                        <Tooltip title={<Translate id="uploadDocMaxFiles" />}>
                            <IconButtonLight>
                                <CloudUploadIconMod />
                            </IconButtonLight>
                        </Tooltip>
                    }
                </div>
            }
            {toolBarConfig.delete && toolBarConfig.delete.show &&
                <Tooltip title={<Translate id="deleteFile" />}>
                    {toolBarConfig.delete && toolBarConfig.delete.disabled
                        ?
                        <IconButtonLight>
                            <DeleteIconMod />
                        </IconButtonLight>
                        :
                        <IconButtonStyled onClick={() => handleToolbar(ToolClicked.DELETE)}>
                            <DeleteIcon color="primary" />
                        </IconButtonStyled>}
                </Tooltip>
            }
        </FlexContainer>
    )
}

const IconButtonStyled = styled(IconButton)`
    position: relative;
    bottom:15;
    margin-right: 15px;
`

const IconButtonLight = styled(IconButtonStyled)`
    background-color: transparent;
    color: ${props => props.theme.colors.greyLight}
`

const DeleteIconMod = styled(DeleteIcon)`
    &&{ 
        color: ${props => props.theme.colors.grey500};
    }
`

const CloudUploadIconMod = styled(CloudUploadIcon)`
    &&{ 
        color: ${props => props.theme.colors.grey500};
    }
`

export default ToolBarImage