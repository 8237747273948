import Tabs                                                                            from '@material-ui/core/Tabs';
import React                                                                           from 'react';
import {useSelector}                                                                   from 'react-redux'
import { useTranslation }                                                              from 'react-i18next';
import {Link, withRouter}                                                              from 'react-router-dom';
import {FlexContainer}                                                                 from 'heliocor-ui';
import {AppBarStyled, LabelStyled, MenuLabel, SettingsIconStyled, TabRight, TabStyled} from './SubMenu.styles';

const SubMenu = ({match, buttons, history}) => {

    const { t } = useTranslation()
    const sideBar = useSelector(store => store.auth.sidebar);

    const active = buttons.find(btn => btn.active);
    const value = '/' + history.location.pathname.split('/')[1] + (active ? active.link : '');

    return (
        <AppBarStyled sideBar={sideBar} position="static" color="default">
            <Tabs
                indicatorColor="primary"
                value={value}
                textColor="primary"
                variant="scrollable"
                scrollButtons="off"
                action={(actions) => actions && setTimeout(() => {
                    actions.updateIndicator()
                }, 225)}
            >
                {buttons.map((btn, i) => btn.label !== 'Settings' ?
                    <TabStyled
                        key={i}
                         label={<MenuLabel variant="inherit">{t(btn.translationId)}</MenuLabel>}
                         component={Link} to={match.url + btn.link}
                         value={match.url + btn.link}
                    /> :
                    <TabRight
                        key={i}
                        component={Link}
                        to={match.url + btn.link}
                        value={match.url + btn.link}
                        label={
                            <FlexContainer
                                alignItems='center'
                                justifyContent='center'
                                data-cy="settingsIcon"
                            >
                                <LabelStyled>
                                    {t(btn.translationId)}
                                </LabelStyled>
                                <SettingsIconStyled/>
                            </FlexContainer>
                        }
                    />
                )}
            </Tabs>
        </AppBarStyled>

    );
};

export default withRouter(SubMenu);