import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PDFViewer from "./viewers/PDFViewer"
import VideoViewer from "./viewers/VideoViewer"
import ImageViewer from './viewers/ImageViewer'
import { MEDIA_TYPES } from "../../../config/media";
import { SAVE_VIEWER_CONFIG } from '../store/actionTypes';


const VIEWER_SIZES = {
    MINI: 'MINI',
    FULL: 'FULL'
}

const MediaViewer = ({
    file,
    height = '',
    mediaType,
    properties = [],
    imgDimensionsForWarning = {},
    viewerSize = VIEWER_SIZES.MINI
}) => {

    const dispatch = useDispatch()
    const viewerConfig = useSelector(state => state.common.viewerConfig || {})
    const handleViewerConfigChange = (config) => dispatch({type: SAVE_VIEWER_CONFIG, ...config})
    
    if (mediaType === MEDIA_TYPES.IMAGE) {
        return <ImageViewer
            height={height}
            image={file}
            properties={properties}
            viewerSize={viewerSize}
            imgDimensionsForWarning={imgDimensionsForWarning}
            config={viewerConfig}
            onConfigChange={handleViewerConfigChange}
        />
    }

    if (mediaType === MEDIA_TYPES.PDF) {
        return <PDFViewer
            file={file}
            height={height || 732}
            size={viewerSize}
            config={viewerConfig}
            onConfigChange={handleViewerConfigChange}
        />
    }

    if (mediaType === MEDIA_TYPES.VIDEO) {
        return <VideoViewer file={file}/>
    }

    return null
}

export { MediaViewer, VIEWER_SIZES }