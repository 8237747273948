import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
    }

    h4, h5, h6 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    body {
        font-family: 'Lato', sans-serif;
        background-color: #f5f5f5
    }

    label {
        color:#333;
        font-size:14px;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 5;
        font-family: 'Lato', sans-serif;
    }

    textarea {
        border: 1px solid #ddd;
        background-color: #fff;
        width:100%;
        color:#888;
        padding:5px 10px;
        text-align: left;
        margin:5px 0 15px;
        font-family: 'Lato', sans-serif;
        resize: none;
        box-sizing: content-box;
    }

    hr {
        margin: 7px 0;
    }

    /* This removes the arrows from input numbers */
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }


    ::-webkit-scrollbar {
        width: 1rem;
        height: 5px;
        padding: 2px;
    }


    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.grey300}; 
    }

    
    @media (max-width: 740px) {
        tr{
            height: 30px !important;
        }
        th{
            height: 30px !important;
            padding: 4px !important;
        }
        td{
            height: 30px !important;
            padding: 8px 5px !important;
            font-size: 11px !important;
        }
        label{
            font-size: 12px;
        }
        ::-webkit-scrollbar {
            width: .5rem;
            height: 5px;
            padding: 2px;
        }
    
    }

`