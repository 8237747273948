import Moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { PERMISSIONS } from '../../login/consts/permissions.enum'

export const setPersonsSum = persons => persons.reduce((obj, person) => {
    obj[person.status] = obj[person.status] ? obj[person.status] + 1 : 1
    return obj
}, {})

export const getExpiryDays = date => Moment(date).diff(Moment(), 'days') || 0

/*  SETTINGS   */

export const getFromNamesValidation = value => {
    switch (value) {
        case 'Exact':
            return 1
        default:
            return 0
    }
}

export const parseSettings = (settings = [], key) => {
    const setting = settings.find(s => s.key === key) || []
    switch (key) {
        case 'personApproval':
            return setting.value ? {
                aml: setting.value.aml,
                identity: setting.value.identity,
                address: setting.value.address
            } : {
                    aml: [],
                    identity: [],
                    address: []
                }
        case 'defaultDocumentsShareholders':
        case 'defaultDocumentsDirectors':
        case 'defaultDocumentsAuthSignatory':
            return Array.isArray(setting.value)
                ? {
                    0: [],
                    1: [],
                    2: [],
                    3: [],
                    4: []
                }
                : setting && setting.value
        default:
            return setting.value || ''
    }
}

/*  SLIDERS  */
export const getIndexFromIdentityStatus = status => {
    switch (status) {
        case 'NOT_CHECKED':
            return 0
        case 'REVIEW':
            return 1
        case 'CLEAR_AUTO':
            return 2
        case 'CLEAR_MANUAL':
            return 2
        case 'INVALID':
            return 3
        default:
            return 0
    }
}
export const setIdentityStatusFromIndex = status => {
    switch (status) {
        case 0:
            return "NOT_CHECKED"
        case 1:
            return 'REVIEW'
        case 2:
            return 'CLEAR_MANUAL'
        case 3:
            return 'INVALID'
        default:
            return 'NOT_CHECKED'
    }
}

export const setRiskFromPosition = (risk, levels) => {
    switch (risk) {
        case 0:
            return 'notSet'
        case 1:
            return levels === 5 ? 'veryLow' : 'low'
        case 2:
            return 'low'
        case 3:
            return 'medium'
        case 4:
            return 'high'
        case 5:
            return levels === 5 ? 'veryHigh' : 'high'
        default:
            return 'notSet'
    }
}

export const translateRiskLevel = (risk) => {
    switch (risk) {
        case 'NOT_SET':
            return 'notSet'
        case 'VERY_LOW':
            return 'veryLow'
        case 'LOW':
            return 'low'
        case 'MEDIUM':
            return 'medium'
        case 'HIGH':
            return 'high'
        case 'VERY_HIGH':
            return 'veryHigh'
        case 'BLACK':
            return 'black'
        default:
            return 'notSet'
    }
}

export const riskToString = (risk, riskInfo) => {
    switch (risk) {
        case -1:
            return 'BLACK'
        case 0:
            return 'NOT_SET'
        case 1:
            return riskInfo.levels === 5 ? 'VERY_LOW' : 'LOW'
        case 2:
            return 'LOW'
        case 3:
            return 'MEDIUM'
        case 4:
            return 'HIGH'
        case 5:
            return riskInfo.levels === 5 ? 'VERY_HIGH' : 'HIGH'
        default:
            return 'NOT_SET'
    }
}

export function addCustomFieldsBeforeSave(fields, values) {
    const custom = {}
    const standardFields = {}
    const { externalIdentifier } = values

    fields.forEach(category => {
        category.fields.forEach(field => {
            if (field.scope === 'CUSTOM') {
                custom[field.id] = (field.isMulti && Array.isArray(values[field.id])) ? JSON.stringify(values[field.id]) : values[field.id]?.value || values[field.id]
            } else {
                standardFields[field.id] = values[field.id]?.value || values[field.id]?.identifier || values[field.id]
            }
        })
    })

    return { ...standardFields, custom, ...(externalIdentifier && { externalIdentifier }) }
}

export function getReportsColumns(dataSources) {
    const reports = ['DOKSTOR']

    if (dataSources?.companyInformation === 'CREDIT_SAFE') reports.push('CREDIT_SAFE')
    if (dataSources?.companyEnrichment.includes('HOLMES')) reports.push('HOLMES')

    return reports
}

export const getOwnableCompanies = (structure, entity) =>
    [{ value: entity.identifier, label: entity.name }]
        .concat(structure
            .filter(cp => cp.type === 'CONNECTED_COMPANY')
            .map(company => ({ value: company.identifier, label: company.name }))
    )
        
export const getRequiredFields = (categories, kycType, values) => {
    return kycType && categories
    ?.filter(cat => isCategoryConditionSatisfied({dependency: cat.dependencies, values}))
    ?.map(cat => cat.fields.filter(f => {
        const dependencyField = f.visibility?.[0]?.field
        const isMandatoryField = f.mandatory?.[kycType]

        return (isMandatoryField && dependencyField)
            ? determineVisibility(f, values[dependencyField])
            : isMandatoryField
    }))     
    .flat()
    .map(field => field.internalName) 
}
 
export const parseChannelVisibility = _channels => {

    if (!Array.isArray(_channels)) return _channels
    
    const channels = {
        CAFE_PHASE_1: {visible: false, editable: true},
        CAFE_PHASE_2: { visible: false, editable: true },
        BACK_OFFICE: { visible: false, editable: true }
    }
    _channels?.forEach(ch => {
        channels[ch.channel].visible = ch.visibility || ch.visible
        channels[ch.channel].editable = ch.editable
    })
    return channels
}

//Function to map the fields data to get a initial state
export const parseChannelsVisibility = (fields, categories) => categories.reduce((obj, category) => {
    //For each category we map the fields and add channels property, removing useless ones
    obj[category.code] = fields[category.identifier] && fields[category.identifier].fields.map(field => {
        const {
            maxLength, categories, createdById,
            updatedById,
            ...rest
        } = field

        const channels = parseChannelVisibility(field.channels)

        return { ...rest, channels }
    })
    return obj
}, {})

//Checks if an input complies the visibility conditions
export const determineVisibility = (input, value) => {
    const verifyConditions = input?.visibility?.map(condition => {
        let res = value
        if (condition.hasOwnProperty('value')) {
            res = typeof condition.value == 'boolean' 
                ? !!value === !!condition.value
                : value === condition.value
        } else if (condition.values) {
            if (Array.isArray(value)) {
                res = value.some(c => condition.values.includes(c?.value || c))
            } else {
                res = condition.values.includes(value?.value || value)
            }
        }
        return res
    })
    return verifyConditions?.every(field => field === true)
}

// Checks if the value complies with the given dependencies, only use it for field dependencies.
// Use isCategoryConditionSatisfied for category dependencies.
export const compliesConditions = (dependencies, value, fieldsConfig) => {
    let ret

    if (fieldsConfig?.some(c => c.fields.some(f => f.internalName === dependencies?.field))) ret = true
    else return true

    if (dependencies) {
        if (dependencies.type === 'BOOLEAN') {
            ret = value && value === dependencies.values[0]
        } else if (dependencies.type === 'SELECT') {
            ret = dependencies.values.includes(value)
        }
    }
    return ret
}

export const isCategoryConditionSatisfied = ({dependency = {}, values}) => {
    const condition = dependency?.BACK_OFFICE

    if(!dependency.field || !dependency.type) return true

    switch(dependency.type) {
        case 'SELECT':
            const value = values[dependency.field]?.value || values[dependency.field]
            return condition && value && Array.isArray(value)
                ? dependency.values.some(v => value.includes(v))
                : dependency.values.includes(value)
        case 'BOOLEAN':
            return condition && condition?.[values?.[dependency.field]];
        default:
            return false
    }
}

export const isKycTypeConditionSatisfied = ({field, kycType, kycTypes = []}) => {
    const currentKycType = kycType || kycTypes.find(kyc => kyc.default)?.identifier
    const kycTypeVisibility = field.dependencies?.kycTypesVisibility || {}

    return isEmpty(kycTypeVisibility) || !!kycTypeVisibility[currentKycType]
}

export const findFieldByName = (fieldsByEntity, internalName) => {
    if(!internalName) return
    let fields = []
    Object.values(fieldsByEntity).map((categories) => {
        Object.values(categories)
            .map((category) => (fields = [...fields, ...category.fields]))
    })
    
    return fields.find(field => field.internalName === internalName)
}

export const areFormErrorsEmpty = errors => {
    return (isEmpty(errors) || (!isEmpty(errors) && Object.keys(errors).every(k => isEmpty(errors[k]))))
}

export const getParsedDependencies = (dependenciesString) => {
    try {
        const dependencies = JSON.parse(dependenciesString) || {}

        // Some dependencies were created with the 'custom.' prefix, have to remove it
        // to keep the field name consistent in dependencies
        if(dependencies.field) dependencies.field = dependencies.field.replace('custom.', '')
        
        return dependencies

    } catch (e) {
        return undefined
    }
}

export const getPermissionsByCasePhase = (casePhase) => {
	switch (casePhase) {
		case 'DOCUMENTS_CAPTURE':
			return PERMISSIONS.DOKSTOR.DOKSTOR_CAPTURE_DOCUMENTS;
		case 'VALIDATIONS':
			return PERMISSIONS.DOKSTOR.DOKSTOR_VALIDATE_DOCUMENTS;
		case 'SCREENING':
			return PERMISSIONS.DOKSTOR.DOKSTOR_SCREEN;
		case 'RISK_ASSESSMENT':
			return PERMISSIONS.DOKSTOR.DOKSTOR_ASSESS_RISK;
		case 'APPROVAL':
			return PERMISSIONS.DOKSTOR.DOKSTOR_APPROVE;
		default:
			return;
	}
};