import React, { useEffect } from 'react'

import loginBg from '../../assets/login_bg.jpg'
import { LoginBG, LoginBox, LoginContainer, LoginInputsContent, SpacerV15 } from './Login.styles'
import Credentials from './screens/Credentials'
import ForgotPwd from './screens/ForgotPwd'
import Login from './screens/Login'
import ResetPwd from './screens/ResetPwd'


const LoginLayout = ({
                         classes, error, goToHome, goToLastPage, goToLogin, handleInputChange, login, loginError,
    loginStatus, loginSubstatus, passwordExpired, redirectTo, requestPasswordRecover, recoverEnabled, supportEmail,
                         showRecoverPassword, requestSetCredentials, resetPwd, username, resetFilled, loginLoading
}) => {

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    const handleKeyDown = event => {
        if (event.key === 'Enter') {

            switch(loginStatus) {
                case 'login':
                default:
                    login()
                    break
                case 'passwordExpired':
                case 'changePwd':
                    resetPwd()
                    break
                case 'recoverPassword':
                    requestPasswordRecover()
                    break
                case 'credentials':
                    requestSetCredentials()
                    break
            }
        }
    }

    let content

    switch (loginStatus){
        case 'login':
            content = <Login
                classes={classes}
                handleInputChange={handleInputChange}
                loginError={loginError}
                onLogin={login}
                showRecoverPassword={showRecoverPassword}
                username={username}
                loginLoading={loginLoading}
                recoverEnabled={recoverEnabled}
            />
            break
        case 'passwordExpired':
        case 'changePwd':
            content = <ResetPwd
                classes={classes}
                error={error}
                goToHome={goToHome}
                goToLogin={goToLogin}
                goToLastPage={goToLastPage}
                handleInputChange={handleInputChange}
                loginError={loginError}
                passwordExpired={passwordExpired}
                resetPwd={resetPwd}
                filled={resetFilled}
            />
            break
        case 'recoverPassword':
            content = <ForgotPwd
                classes={classes}
                goToLogin={goToLogin}
                handleInputChange={handleInputChange}
                loginSubstatus={loginSubstatus}
                requestPasswordRecover={requestPasswordRecover}
                username={username}
                supportEmail={supportEmail}
            />
            break
        case 'credentials':
            content = <Credentials
                classes={classes}
                error={error}
                handleInputChange={handleInputChange}
                loginSubstatus={loginSubstatus}
                redirectTo={redirectTo}
                requestSetCredentials={requestSetCredentials}
            />
            break
    }

    return (
        <LoginContainer>
            <LoginBox>
                <LoginBG>
                    <img
                        alt="login bg"
                        src={loginBg}
                    />
                </LoginBG>
                <LoginInputsContent>
                    <SpacerV15 />
                    {content}
                </LoginInputsContent>
            </LoginBox>
        </LoginContainer>
    )
}

export default LoginLayout