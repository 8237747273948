export function parseCoreDataForRequest(coreData) {
    return {
        individualsNumber: coreData.individualVol,
        organizationsNumber: coreData.legalEntVol,
        netWorthMin: coreData.minNetWorth,
        netWorthMax: coreData.maxNetWorth,
        pepValue: coreData.pepDist,
        riskMin: coreData.risk[0],
        riskMax: coreData.risk[1],
        accountsNumber: coreData.accountVol,
        accountsTypes: Object.keys(coreData.accountType).filter(key => coreData.accountType[key]),
        netBalanceMin: coreData.netBalanceMin,
        netBalanceMax: coreData.netBalanceMax,
        numberOfCustomerForAccountMax: coreData.numberOfCustomerForAccountMax,
        numCardsPerAccountCustomerMax: coreData.numCardsPerAccountCustomerMax,
        creditDebit: Object.keys(coreData.cardType).filter(key => coreData.cardType[key]),
        dailyLimitMin: coreData.dailyLimitMin,
        dailyLimitMax: coreData.dailyLimitMax,
        monthlyLimitMin: coreData.monthlyLimitMin,
        monthlyLimitMax: coreData.monthlyLimitMax,
        transactionLimitMin: coreData.transactionLimitMin,
        transactionLimitMax: coreData.transactionLimitMax,
        cardType: Object.keys(coreData.cardIssuer).filter(key => coreData.cardIssuer[key]),
        dateRangeFrom: coreData.dateFrom + 'T00:00:00',
        dateRangeTo: coreData.dateTo + 'T00:00:00'
    }
}

export function parsePaymentsDataForRequest(paymentsData) {
    return {
        paymentsNumber: paymentsData.paymentsVol,
        paymentTypes: Object.keys(paymentsData.paymentTypes).filter(key => paymentsData.paymentTypes[key]),
        paymentValueMin: paymentsData.amountBeforeCommissionMin,
        paymentValueMax: paymentsData.amountBeforeCommissionMax,
        dateRangeFrom: paymentsData.dateFrom + 'T00:00:00',
        dateRangeTo: paymentsData.dateTo + 'T00:00:00'
    }
}

export function parseResponseToCoreData(generationActivity) {
    const parsedParameters = JSON.parse(generationActivity.parameters)
    const newParameters = {
        individualVol: parsedParameters.individualsNumber,
        legalEntVol: parsedParameters.organizationsNumber,
        minNetWorth: parsedParameters.netWorthMin,
        maxNetWorth: parsedParameters.netWorthMax,
        pepDist: parsedParameters.pepValue,
        risk: [parsedParameters.riskMin, parsedParameters.riskMax],
        accountVol: parsedParameters.accountsNumber,
        accountType: {
            SAVINGS: parsedParameters.accountsTypes.includes('SAVINGS'),
            CHECKING: parsedParameters.accountsTypes.includes('CHECKING'),
            LOAN: parsedParameters.accountsTypes.includes('LOAN')
        },
        netBalanceMin: parsedParameters.netBalanceMin,
        netBalanceMax: parsedParameters.netBalanceMax,
        numberOfCustomerForAccountMax: parsedParameters.numberOfCustomerForAccountMax,
        numCardsPerAccountCustomerMax: parsedParameters.numCardsPerAccountCustomerMax,
        cardType: {
            CREDIT: parsedParameters.creditDebit.includes('CREDIT'),
            DEBIT: parsedParameters.creditDebit.includes('DEBIT')
        },
        dailyLimitMin: parsedParameters.dailyLimitMin,
        dailyLimitMax: parsedParameters.dailyLimitMax,
        monthlyLimitMin: parsedParameters.monthlyLimitMin,
        monthlyLimitMax: parsedParameters.monthlyLimitMax,
        transactionLimitMin: parsedParameters.transactionLimitMin,
        transactionLimitMax: parsedParameters.transactionLimitMax,
        cardIssuer: {
            VISA: parsedParameters.cardType.includes('VISA'),
            MASTERCARD: parsedParameters.cardType.includes('MASTERCARD'),
            AMEX: parsedParameters.cardType.includes('AMEX')
        },
        dateFrom: parsedParameters.dateRangeFrom.substr(0, 10),
        dateTo: parsedParameters.dateRangeTo.substr(0, 10),
    }
    return {
        ...generationActivity,
        generatedElements: JSON.parse(generationActivity.generatedElements),
        parameters: newParameters
    }
}

export function parseResponseToPaymentsData(generationActivity) {
    const parsedParameters = JSON.parse(generationActivity.parameters)
    const newParameters = {
        paymentsVol: parsedParameters.paymentsNumber,
        paymentTypes: {
            WIRE: parsedParameters.paymentTypes.includes('WIRE'),
            CASH: parsedParameters.paymentTypes.includes('CASH'),
            LOAN: parsedParameters.paymentTypes.includes('MONETARY_INSTRUMENT')
        },
        amountBeforeCommissionMin: parsedParameters.amountBeforeCommissionMin,
        amountBeforeCommissionMax: parsedParameters.amountBeforeCommissionMax,
        dateFrom: parsedParameters.dateRangeFrom.substr(0, 10),
        dateTo: parsedParameters.dateRangeTo.substr(0, 10),
    }
    return {
        ...generationActivity,
        generatedElements: JSON.parse(generationActivity.generatedElements),
        parameters: newParameters
    }
}