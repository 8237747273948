import { createTheme } from '@material-ui/core/styles';
import { styledTheme } from '../styles/';

const theme = createTheme({
    palette: {
        primary: {
            main: styledTheme.colors.main
        },
        action: {
            main: styledTheme.colors.black
        }
    },
    typography: {
        fontFamily: 'Lato',
        fontSize: 14
    },
    overrides: {
        MuiAppBar: {
            root: {
                color: 'rgb(0, 0, 0) !important',
                backgroundColor: '#fff !important'
            }
        },
        MuiDialogContent: {
            root: {
                color: styledTheme.colors.grey100,
                fontSize: styledTheme.typography.titleH5.fontSize
            }
        },
        MuiButton: {
            root: {
                opacity: 1,
                borderRadius: 0,
                height: 32,
                minHeight: 32,
                transition: 'opacity 250ms ease-out',
            },
            label: {
                fontSize: 14,
                textTransform: 'capitalize',
                minWidth: 68,
                height: 12
            },
            contained: {
                boxShadow: 'none'
            }
        },
        MuiCollapse: {
            wrapperInner: {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        MuiFab: {
            root: {
                boxShadow: 'none'
            }
        },
        MuiFormControlLabel: {
            root: {
                width: '100%',
            }
        },
        MuiFormGroup: {
            root: {
                flexWrap: 'unset'
            }
        },
        MuiGrid: {
            "spacing-xs-5": {
                marginTop: '3%'
            }
        },
        MuiInput: {
            input: {
                fontSize: 14,
                paddingBottom: 6
            },
            root: {
                lineHeight: 1.4,
                marginBottom: 5,
                minHeight: 32,
                border: '1px solid #dddddd',
                padding: '0 10px',
                width: '100%',
                '&$disabled': {
                    background: styledTheme.colors.grey700
                }
            },
            multiline: {
                padding: '9px 6px 3px'
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #ffffff'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '1px solid #ffffff'
                },
                '&:hover:not($disabled):before': {
                    borderBottom: '1px solid #ffffff'
                },
                '&:after': {
                    borderBottomWidth: 1,
                    borderBottomColor: styledTheme.colors.main
                },
                '&$disabled:before': {
                    borderBottom: '1px solid #ffffff'
                }
            }
        },
        MuiInputAdornment: {
            root: {
                color: styledTheme.colors.grey300
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: 14,
                fontWeight: 600,
                color: styledTheme.colors.black100,
                marginBottom: 5,
                marginTop: 15,
                width: '100%',
                display: 'initial'
            },
            outlined: {
                color: styledTheme.colors.red,
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    backgroundColor: styledTheme.colors.grey700 + '!important'
                }
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 14,
                "&$selected": {
                    fontWeight: 800,
                    color: styledTheme.colors.main,
                }
            }
        },
        MuiPaper: {
            root: {
                display: 'flex',
                flexDirection: 'column'
            },
            rounded: {
                borderRadius: 0
            },
            elevation2: {
                boxShadow: '0px 2px 8px 1px rgba(0,0,0,0.16)',
            }
        },
        MuiRadio: {
            root: {
                padding: '10px !important',
                color: '#c2c2c2 !important',
                "&$checked": {
                    color: styledTheme.colors.main + '!important'
                },
                "&$disabled": {
                    color: '#c2c2c2 !important'
                }
            }
        },
        MuiSelect: {
            root: {
                border: 'none'
            },
            selectMenu: {
                paddingLeft: 5
            }
        },
        MuiSnackbarContent: {
            root: {
                '@media (min-width: 960px)': {
                    minWidth: 308
                },
            }
        },
        MuiStepConnector: {
            lineVertical: {
                minHeight: 12
            },
            vertical: {
                padding: '0px 0 5px',
                marginLeft: 10
            }
        },
        MuiStepIcon: {
            root: {
                fontSize: '1.35rem'
            },
            completed: {
                fill: styledTheme.colors.aqua100
            }
        },
        MuiStepLabel: {
            root: {
                textAlign: 'initial'
            },
            labelContainer: {
                minHeight: '2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            },
            label: {
                fontSize: 12
            }
        },
        MuiTabs: {
            root: {
                display: 'inline-block',
                width: '100%',
                minHeight: 39
            },
            indicator: {
                backgroundColor: styledTheme.colors.main + '!important',
            },
            scroller: {
                borderBottom: '1px solid #cecece',
                display: 'flex'
            }
        },
        MuiTab: {
            root: {
                padding: 0,
                fontSize: 15,
                color: styledTheme.colors.blackTint + ' !important',
                fontWeight: 800,
                fontFamily: 'Lato',
                textTransform: 'initial',
                minWidth: 72,
                minHeight: 0,
                '@media (min-width: 600px)': {
                    minWidth: 72
                },
                '&:hover': {
                    color: styledTheme.colors.black100, opacity: 1
                },
                '&:focus': {
                    color: styledTheme.colors.blackTint
                }
            },
            wrapper: {
                '@media (min-width: 960px)': {
                    padding: '6px 24px'
                },
                padding: '6px 12px',
                flexDirection: 'row-reverse'
            },
            labelIcon: {
                minHeight: 0,
                paddingTop: 0
            }
        },
        MuiTablePagination: {
            selectRoot: {
                marginTop: 3,
            },
            input: {
                width: 60,
                border: 'none'
            },
            toolbar: {
                paddingRight: 2,
                paddingLeft: 2
            },
            select: {
                textAlignLast: 'left'
            }
        },
        MuiTableBody: {
            root: {
                '& > tr:nth-child(odd)': {
                    backgroundColor: styledTheme.colors.bluelight100
                }
            }
        },
        MuiTableCell: {
            root: {
                padding: 0,
                lineHeight: 1.3,
                borderBottom: 'none',
                fontSize: 13
            },
            body: {
                color: styledTheme.colors.grey100
            },
            footer: {
                borderBottom: 'none'
            },
            head: {
                lineHeight: 1.3
            }
        },
        MuiTableRow: {
            root: {
                height: 58
            }
        },
        MuiToolbar: {
            regular: {
                width: '100%'
            }
        },
        MuiCheckbox: {
            root: {
                color: `${styledTheme.colors.grey100} !important`,
                "&$checked": {
                    color: styledTheme.colors.main + '!important'
                },
                '&$disabled': {
                    color: styledTheme.colors.grey300 + '!important'
                }
            }
        },
        MuiTypography: {
            subtitle1: {
                fontSize: 16,
                color: styledTheme.colors.black100,
                fontWeight: 300,
            },
            body1: {
                fontSize: 14
            },
            body2: {
                fontSize: 14,
                color: styledTheme.colors.black100,
                fontWeight: 300
            },
            caption: {
                fontSize: 12
            },
            colorTextSecondary: {
                color: styledTheme.colors.main
            },
            h4: {
                fontSize: 14,
                fontWeight: 600,
                color: '#444'
            },
            h2: {
                fontSize: 12,
                fontWeight: 600,
                color: '#333'
            },
            h6: {
                fontSize: 20,
                color: styledTheme.colors.black100,
                fontWeight: 800,
                // textTransform:'capitalize'
            },
        },
        PrivateSwitchBase: {
            root: {
                padding: 12
            }
        }
    }
})

export default theme
