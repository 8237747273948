import {
    ADD_ORG,
    CLEAR_CREATED_ORG,
    CLEAR_SYSADMIN_STATUS,
    DELETE_ORG,
    SET_APPLICATIONS,
    SET_CURRENT_ORG,
    SET_SYSADMIN_FILTERS,
    SET_SYSADMIN_STATUS,
    SUCCEED_REQUEST_ORGS,
    UPDATE_ORG
} from './actionTypes';

function sysadmin(state = {
    applications: [],
    filters: {},
    orgs: [],
    currentOrg: {},
    orgsCount: 0
} , action) {
    let orgs

    switch (action.type){

        case SUCCEED_REQUEST_ORGS:
            return {...state, orgs: action.orgs.organizations, orgsCount: action.orgs.count, orgsSum: {
                    ENABLED: action.orgs.countEnabled,
                    DISABLED: action.orgs.countDisabled
                }}

        case SET_CURRENT_ORG:
            return {...state, currentOrg: action.org}

        case SET_SYSADMIN_STATUS:
            return {
                ...state,
                adminError: action.adminError,
                adminStatus: action.adminStatus,
                adminSuccess: action.adminSuccess
            }

        case ADD_ORG:
            return {...state, orgs: [...state.orgs, action.org], orgCreated: action.org}

        case UPDATE_ORG:
            orgs = state.orgs.map(item => {
                if (item.identifier === action.org.identifier){
                    return { ...item, ...action.org }
                }
                return item
            })
            return {...state, orgs, orgCreated: action.org}

        case DELETE_ORG:
            return {...state, orgs: state.orgs.filter(org => org.identifier !== action.identifier)}

        case CLEAR_CREATED_ORG:
            return {...state, orgCreated: null}

        case SET_SYSADMIN_FILTERS:
            return {...state, filters: {...action.filters}}

        case CLEAR_SYSADMIN_STATUS:
            return {...state, adminError: null, adminSuccess: null, adminStatus: null}

        case SET_APPLICATIONS:
            return {...state, applications: action.applications}
        default:
            return state
    }
}

export default sysadmin